import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

///////////////////////////////////////////////////////////////////////////////////stop frame 1
import { ReactComponent as LandPlot_1_681 } from "./land_plots/1/land_plot_block_681.svg";
import { ReactComponent as LandPlot_1_682 } from "./land_plots/1/land_plot_block_682.svg";
import { ReactComponent as LandPlot_1_683 } from "./land_plots/1/land_plot_block_683.svg";
import { ReactComponent as LandPlot_1_684 } from "./land_plots/1/land_plot_block_684.svg";
import { ReactComponent as LandPlot_1_685 } from "./land_plots/1/land_plot_block_685.svg";
import { ReactComponent as LandPlot_1_686 } from "./land_plots/1/land_plot_block_686.svg";
import { ReactComponent as LandPlot_1_687 } from "./land_plots/1/land_plot_block_687.svg";
import { ReactComponent as LandPlot_1_688 } from "./land_plots/1/land_plot_block_688.svg";
import { ReactComponent as LandPlot_1_689 } from "./land_plots/1/land_plot_block_689.svg";
import { ReactComponent as LandPlot_1_690 } from "./land_plots/1/land_plot_block_690.svg";
import { ReactComponent as LandPlot_1_691 } from "./land_plots/1/land_plot_block_691.svg";
import { ReactComponent as LandPlot_1_692 } from "./land_plots/1/land_plot_block_692.svg";
import { ReactComponent as LandPlot_1_693 } from "./land_plots/1/land_plot_block_693.svg";
import { ReactComponent as LandPlot_1_694 } from "./land_plots/1/land_plot_block_694.svg";
import { ReactComponent as LandPlot_1_695 } from "./land_plots/1/land_plot_block_695.svg";
import { ReactComponent as LandPlot_1_696 } from "./land_plots/1/land_plot_block_696.svg";
import { ReactComponent as LandPlot_1_697 } from "./land_plots/1/land_plot_block_697.svg";
import { ReactComponent as LandPlot_1_698 } from "./land_plots/1/land_plot_block_698.svg";
import { ReactComponent as LandPlot_1_699 } from "./land_plots/1/land_plot_block_699.svg";
import { ReactComponent as LandPlot_1_700 } from "./land_plots/1/land_plot_block_700.svg";
import { ReactComponent as LandPlot_1_701 } from "./land_plots/1/land_plot_block_701.svg";
import { ReactComponent as LandPlot_1_702 } from "./land_plots/1/land_plot_block_702.svg";
import { ReactComponent as LandPlot_1_703 } from "./land_plots/1/land_plot_block_703.svg";
import { ReactComponent as LandPlot_1_704 } from "./land_plots/1/land_plot_block_704.svg";
import { ReactComponent as LandPlot_1_705 } from "./land_plots/1/land_plot_block_705.svg";
import { ReactComponent as LandPlot_1_706 } from "./land_plots/1/land_plot_block_706.svg";
import { ReactComponent as LandPlot_1_707 } from "./land_plots/1/land_plot_block_707.svg";
import { ReactComponent as LandPlot_1_708 } from "./land_plots/1/land_plot_block_708.svg";
import { ReactComponent as LandPlot_1_709 } from "./land_plots/1/land_plot_block_709.svg";
import { ReactComponent as LandPlot_1_710 } from "./land_plots/1/land_plot_block_710.svg";
import { ReactComponent as LandPlot_1_711 } from "./land_plots/1/land_plot_block_711.svg";
import { ReactComponent as LandPlot_1_712 } from "./land_plots/1/land_plot_block_712.svg";
import { ReactComponent as LandPlot_1_713 } from "./land_plots/1/land_plot_block_713.svg";
import { ReactComponent as LandPlot_1_714 } from "./land_plots/1/land_plot_block_714.svg";
import { ReactComponent as LandPlot_1_715 } from "./land_plots/1/land_plot_block_715.svg";
import { ReactComponent as LandPlot_1_716 } from "./land_plots/1/land_plot_block_716.svg";
import { ReactComponent as LandPlot_1_717 } from "./land_plots/1/land_plot_block_717.svg";
import { ReactComponent as LandPlot_1_718 } from "./land_plots/1/land_plot_block_718.svg";
import { ReactComponent as LandPlot_1_719 } from "./land_plots/1/land_plot_block_719.svg";
import { ReactComponent as LandPlot_1_720 } from "./land_plots/1/land_plot_block_720.svg";
import { ReactComponent as LandPlot_1_721 } from "./land_plots/1/land_plot_block_721.svg";
import { ReactComponent as LandPlot_1_722 } from "./land_plots/1/land_plot_block_722.svg";
import { ReactComponent as LandPlot_1_723 } from "./land_plots/1/land_plot_block_723.svg";
import { ReactComponent as LandPlot_1_724 } from "./land_plots/1/land_plot_block_724.svg";
import { ReactComponent as LandPlot_1_725 } from "./land_plots/1/land_plot_block_725.svg";
import { ReactComponent as LandPlot_1_726 } from "./land_plots/1/land_plot_block_726.svg";
import { ReactComponent as LandPlot_1_727 } from "./land_plots/1/land_plot_block_727.svg";
import { ReactComponent as LandPlot_1_728 } from "./land_plots/1/land_plot_block_728.svg";
import { ReactComponent as LandPlot_1_729 } from "./land_plots/1/land_plot_block_729.svg";
import { ReactComponent as LandPlot_1_730 } from "./land_plots/1/land_plot_block_730.svg";
import { ReactComponent as LandPlot_1_731 } from "./land_plots/1/land_plot_block_731.svg";
import { ReactComponent as LandPlot_1_732 } from "./land_plots/1/land_plot_block_732.svg";
import { ReactComponent as LandPlot_1_733 } from "./land_plots/1/land_plot_block_733.svg";
import { ReactComponent as LandPlot_1_734 } from "./land_plots/1/land_plot_block_734.svg";
import { ReactComponent as LandPlot_1_735 } from "./land_plots/1/land_plot_block_735.svg";
import { ReactComponent as LandPlot_1_736 } from "./land_plots/1/land_plot_block_736.svg";
import { ReactComponent as LandPlot_1_737 } from "./land_plots/1/land_plot_block_737.svg";
import { ReactComponent as LandPlot_1_738 } from "./land_plots/1/land_plot_block_738.svg";
import { ReactComponent as LandPlot_1_739 } from "./land_plots/1/land_plot_block_739.svg";
import { ReactComponent as LandPlot_1_740 } from "./land_plots/1/land_plot_block_740.svg";
import { ReactComponent as LandPlot_1_741 } from "./land_plots/1/land_plot_block_741.svg";
import { ReactComponent as LandPlot_1_742 } from "./land_plots/1/land_plot_block_742.svg";
import { ReactComponent as LandPlot_1_743 } from "./land_plots/1/land_plot_block_743.svg";
import { ReactComponent as LandPlot_1_744 } from "./land_plots/1/land_plot_block_744.svg";
import { ReactComponent as LandPlot_1_745 } from "./land_plots/1/land_plot_block_745.svg";
import { ReactComponent as LandPlot_1_746 } from "./land_plots/1/land_plot_block_746.svg";
import { ReactComponent as LandPlot_1_747 } from "./land_plots/1/land_plot_block_747.svg";
import { ReactComponent as LandPlot_1_748 } from "./land_plots/1/land_plot_block_748.svg";
import { ReactComponent as LandPlot_1_749 } from "./land_plots/1/land_plot_block_749.svg";
import { ReactComponent as LandPlot_1_750 } from "./land_plots/1/land_plot_block_750.svg";
import { ReactComponent as LandPlot_1_751 } from "./land_plots/1/land_plot_block_751.svg";
import { ReactComponent as LandPlot_1_752 } from "./land_plots/1/land_plot_block_752.svg";
import { ReactComponent as LandPlot_1_753 } from "./land_plots/1/land_plot_block_753.svg";
import { ReactComponent as LandPlot_1_754 } from "./land_plots/1/land_plot_block_754.svg";
import { ReactComponent as LandPlot_1_755 } from "./land_plots/1/land_plot_block_755.svg";
import { ReactComponent as LandPlot_1_756 } from "./land_plots/1/land_plot_block_756.svg";
import { ReactComponent as LandPlot_1_757 } from "./land_plots/1/land_plot_block_757.svg";
import { ReactComponent as LandPlot_1_758 } from "./land_plots/1/land_plot_block_758.svg";
import { ReactComponent as LandPlot_1_759 } from "./land_plots/1/land_plot_block_759.svg";
import { ReactComponent as LandPlot_1_760 } from "./land_plots/1/land_plot_block_760.svg";
import { ReactComponent as LandPlot_1_761 } from "./land_plots/1/land_plot_block_761.svg";
import { ReactComponent as LandPlot_1_762 } from "./land_plots/1/land_plot_block_762.svg";
import { ReactComponent as LandPlot_1_763 } from "./land_plots/1/land_plot_block_763.svg";
import { ReactComponent as LandPlot_1_764 } from "./land_plots/1/land_plot_block_764.svg";
import { ReactComponent as LandPlot_1_765 } from "./land_plots/1/land_plot_block_765.svg";
import { ReactComponent as LandPlot_1_766 } from "./land_plots/1/land_plot_block_766.svg";
import { ReactComponent as LandPlot_1_767 } from "./land_plots/1/land_plot_block_767.svg";
import { ReactComponent as LandPlot_1_768 } from "./land_plots/1/land_plot_block_768.svg";
import { ReactComponent as LandPlot_1_769 } from "./land_plots/1/land_plot_block_769.svg";
import { ReactComponent as LandPlot_1_770 } from "./land_plots/1/land_plot_block_770.svg";
import { ReactComponent as LandPlot_1_771 } from "./land_plots/1/land_plot_block_771.svg";
import { ReactComponent as LandPlot_1_772 } from "./land_plots/1/land_plot_block_772.svg";
import { ReactComponent as LandPlot_1_773 } from "./land_plots/1/land_plot_block_773.svg";
import { ReactComponent as LandPlot_1_774 } from "./land_plots/1/land_plot_block_774.svg";
import { ReactComponent as LandPlot_1_775 } from "./land_plots/1/land_plot_block_775.svg";
import { ReactComponent as LandPlot_1_776 } from "./land_plots/1/land_plot_block_776.svg";
import { ReactComponent as LandPlot_1_777 } from "./land_plots/1/land_plot_block_777.svg";
import { ReactComponent as LandPlot_1_778 } from "./land_plots/1/land_plot_block_778.svg";
import { ReactComponent as LandPlot_1_779 } from "./land_plots/1/land_plot_block_779.svg";
import { ReactComponent as LandPlot_1_780 } from "./land_plots/1/land_plot_block_780.svg";
import { ReactComponent as LandPlot_1_781 } from "./land_plots/1/land_plot_block_781.svg";
import { ReactComponent as LandPlot_1_782 } from "./land_plots/1/land_plot_block_782.svg";
import { ReactComponent as LandPlot_1_783 } from "./land_plots/1/land_plot_block_783.svg";
import { ReactComponent as LandPlot_1_784 } from "./land_plots/1/land_plot_block_784.svg";
import { ReactComponent as LandPlot_1_785 } from "./land_plots/1/land_plot_block_785.svg";
import { ReactComponent as LandPlot_1_786 } from "./land_plots/1/land_plot_block_786.svg";
import { ReactComponent as LandPlot_1_787 } from "./land_plots/1/land_plot_block_787.svg";
import { ReactComponent as LandPlot_1_788 } from "./land_plots/1/land_plot_block_788.svg";
import { ReactComponent as LandPlot_1_789 } from "./land_plots/1/land_plot_block_789.svg";
import { ReactComponent as LandPlot_1_790 } from "./land_plots/1/land_plot_block_790.svg";
import { ReactComponent as LandPlot_1_791 } from "./land_plots/1/land_plot_block_791.svg";
import { ReactComponent as LandPlot_1_792 } from "./land_plots/1/land_plot_block_792.svg";
import { ReactComponent as LandPlot_1_793 } from "./land_plots/1/land_plot_block_793.svg";
import { ReactComponent as LandPlot_1_794 } from "./land_plots/1/land_plot_block_794.svg";
import { ReactComponent as LandPlot_1_795 } from "./land_plots/1/land_plot_block_795.svg";
import { ReactComponent as LandPlot_1_796 } from "./land_plots/1/land_plot_block_796.svg";
import { ReactComponent as LandPlot_1_797 } from "./land_plots/1/land_plot_block_797.svg";
import { ReactComponent as LandPlot_1_798 } from "./land_plots/1/land_plot_block_798.svg";
import { ReactComponent as LandPlot_1_799 } from "./land_plots/1/land_plot_block_799.svg";
import { ReactComponent as LandPlot_1_800 } from "./land_plots/1/land_plot_block_800.svg";
import { ReactComponent as LandPlot_1_801 } from "./land_plots/1/land_plot_block_801.svg";
import { ReactComponent as LandPlot_1_802 } from "./land_plots/1/land_plot_block_802.svg";
import { ReactComponent as LandPlot_1_803 } from "./land_plots/1/land_plot_block_803.svg";
import { ReactComponent as LandPlot_1_804 } from "./land_plots/1/land_plot_block_804.svg";
import { ReactComponent as LandPlot_1_805 } from "./land_plots/1/land_plot_block_805.svg";
import { ReactComponent as LandPlot_1_806 } from "./land_plots/1/land_plot_block_806.svg";
import { ReactComponent as LandPlot_1_807 } from "./land_plots/1/land_plot_block_807.svg";
import { ReactComponent as LandPlot_1_808 } from "./land_plots/1/land_plot_block_808.svg";
import { ReactComponent as LandPlot_1_809 } from "./land_plots/1/land_plot_block_809.svg";
import { ReactComponent as LandPlot_1_810 } from "./land_plots/1/land_plot_block_810.svg";
import { ReactComponent as LandPlot_1_811 } from "./land_plots/1/land_plot_block_811.svg";
import { ReactComponent as LandPlot_1_812 } from "./land_plots/1/land_plot_block_812.svg";
import { ReactComponent as LandPlot_1_813 } from "./land_plots/1/land_plot_block_813.svg";
import { ReactComponent as LandPlot_1_814 } from "./land_plots/1/land_plot_block_814.svg";
import { ReactComponent as LandPlot_1_815 } from "./land_plots/1/land_plot_block_815.svg";
import { ReactComponent as LandPlot_1_816 } from "./land_plots/1/land_plot_block_816.svg";
import { ReactComponent as LandPlot_1_817 } from "./land_plots/1/land_plot_block_817.svg";
import { ReactComponent as LandPlot_1_818 } from "./land_plots/1/land_plot_block_818.svg";
import { ReactComponent as LandPlot_1_819 } from "./land_plots/1/land_plot_block_819.svg";
import { ReactComponent as LandPlot_1_820 } from "./land_plots/1/land_plot_block_820.svg";
import { ReactComponent as LandPlot_1_821 } from "./land_plots/1/land_plot_block_821.svg";
import { ReactComponent as LandPlot_1_822 } from "./land_plots/1/land_plot_block_822.svg";
import { ReactComponent as LandPlot_1_823 } from "./land_plots/1/land_plot_block_823.svg";
import { ReactComponent as LandPlot_1_824 } from "./land_plots/1/land_plot_block_824.svg";
import { ReactComponent as LandPlot_1_825 } from "./land_plots/1/land_plot_block_825.svg";
import { ReactComponent as LandPlot_1_826 } from "./land_plots/1/land_plot_block_826.svg";
import { ReactComponent as LandPlot_1_827 } from "./land_plots/1/land_plot_block_827.svg";
import { ReactComponent as LandPlot_1_828 } from "./land_plots/1/land_plot_block_828.svg";
import { ReactComponent as LandPlot_1_829 } from "./land_plots/1/land_plot_block_829.svg";
import { ReactComponent as LandPlot_1_830 } from "./land_plots/1/land_plot_block_830.svg";
import { ReactComponent as LandPlot_1_831 } from "./land_plots/1/land_plot_block_831.svg";
import { ReactComponent as LandPlot_1_832 } from "./land_plots/1/land_plot_block_832.svg";
import { ReactComponent as LandPlot_1_833 } from "./land_plots/1/land_plot_block_833.svg";
import { ReactComponent as LandPlot_1_834 } from "./land_plots/1/land_plot_block_834.svg";
import { ReactComponent as LandPlot_1_835 } from "./land_plots/1/land_plot_block_835.svg";
import { ReactComponent as LandPlot_1_836 } from "./land_plots/1/land_plot_block_836.svg";
import { ReactComponent as LandPlot_1_837 } from "./land_plots/1/land_plot_block_837.svg";
import { ReactComponent as LandPlot_1_838 } from "./land_plots/1/land_plot_block_838.svg";
import { ReactComponent as LandPlot_1_839 } from "./land_plots/1/land_plot_block_839.svg";
import { ReactComponent as LandPlot_1_840 } from "./land_plots/1/land_plot_block_840.svg";
import { ReactComponent as LandPlot_1_841 } from "./land_plots/1/land_plot_block_841.svg";
import { ReactComponent as LandPlot_1_842 } from "./land_plots/1/land_plot_block_842.svg";
import { ReactComponent as LandPlot_1_843 } from "./land_plots/1/land_plot_block_843.svg";
import { ReactComponent as LandPlot_1_844 } from "./land_plots/1/land_plot_block_844.svg";
import { ReactComponent as LandPlot_1_845 } from "./land_plots/1/land_plot_block_845.svg";
import { ReactComponent as LandPlot_1_846 } from "./land_plots/1/land_plot_block_846.svg";
import { ReactComponent as LandPlot_1_847 } from "./land_plots/1/land_plot_block_847.svg";
import { ReactComponent as LandPlot_1_848 } from "./land_plots/1/land_plot_block_848.svg";
import { ReactComponent as LandPlot_1_849 } from "./land_plots/1/land_plot_block_849.svg";
import { ReactComponent as LandPlot_1_850 } from "./land_plots/1/land_plot_block_850.svg";
import { ReactComponent as LandPlot_1_851 } from "./land_plots/1/land_plot_block_851.svg";
import { ReactComponent as LandPlot_1_852 } from "./land_plots/1/land_plot_block_852.svg";
import { ReactComponent as LandPlot_1_853 } from "./land_plots/1/land_plot_block_853.svg";
import { ReactComponent as LandPlot_1_854 } from "./land_plots/1/land_plot_block_854.svg";
import { ReactComponent as LandPlot_1_855 } from "./land_plots/1/land_plot_block_855.svg";
import { ReactComponent as LandPlot_1_856 } from "./land_plots/1/land_plot_block_856.svg";
import { ReactComponent as LandPlot_1_857 } from "./land_plots/1/land_plot_block_857.svg";
import { ReactComponent as LandPlot_1_858 } from "./land_plots/1/land_plot_block_858.svg";
import { ReactComponent as LandPlot_1_859 } from "./land_plots/1/land_plot_block_859.svg";
import { ReactComponent as LandPlot_1_860 } from "./land_plots/1/land_plot_block_860.svg";
import { ReactComponent as LandPlot_1_861 } from "./land_plots/1/land_plot_block_861.svg";
import { ReactComponent as LandPlot_1_862 } from "./land_plots/1/land_plot_block_862.svg";
import { ReactComponent as LandPlot_1_863 } from "./land_plots/1/land_plot_block_863.svg";
import { ReactComponent as LandPlot_1_864 } from "./land_plots/1/land_plot_block_864.svg";
import { ReactComponent as LandPlot_1_865 } from "./land_plots/1/land_plot_block_865.svg";
import { ReactComponent as LandPlot_1_866 } from "./land_plots/1/land_plot_block_866.svg";
import { ReactComponent as LandPlot_1_867 } from "./land_plots/1/land_plot_block_867.svg";
import { ReactComponent as LandPlot_1_868 } from "./land_plots/1/land_plot_block_868.svg";
import { ReactComponent as LandPlot_1_869 } from "./land_plots/1/land_plot_block_869.svg";
import { ReactComponent as LandPlot_1_870 } from "./land_plots/1/land_plot_block_870.svg";
import { ReactComponent as LandPlot_1_871 } from "./land_plots/1/land_plot_block_871.svg";
import { ReactComponent as LandPlot_1_872 } from "./land_plots/1/land_plot_block_872.svg";
import { ReactComponent as LandPlot_1_873 } from "./land_plots/1/land_plot_block_873.svg";
import { ReactComponent as LandPlot_1_874 } from "./land_plots/1/land_plot_block_874.svg";
import { ReactComponent as LandPlot_1_875 } from "./land_plots/1/land_plot_block_875.svg";
import { ReactComponent as LandPlot_1_876 } from "./land_plots/1/land_plot_block_876.svg";
import { ReactComponent as LandPlot_1_877 } from "./land_plots/1/land_plot_block_877.svg";
import { ReactComponent as LandPlot_1_878 } from "./land_plots/1/land_plot_block_878.svg";
import { ReactComponent as LandPlot_1_879 } from "./land_plots/1/land_plot_block_879.svg";
import { ReactComponent as LandPlot_1_880 } from "./land_plots/1/land_plot_block_880.svg";
import { ReactComponent as LandPlot_1_881 } from "./land_plots/1/land_plot_block_881.svg";
import { ReactComponent as LandPlot_1_882 } from "./land_plots/1/land_plot_block_882.svg";
import { ReactComponent as LandPlot_1_883 } from "./land_plots/1/land_plot_block_883.svg";
import { ReactComponent as LandPlot_1_884 } from "./land_plots/1/land_plot_block_884.svg";
import { ReactComponent as LandPlot_1_885 } from "./land_plots/1/land_plot_block_885.svg";
import { ReactComponent as LandPlot_1_886 } from "./land_plots/1/land_plot_block_886.svg";
import { ReactComponent as LandPlot_1_887 } from "./land_plots/1/land_plot_block_887.svg";
import { ReactComponent as LandPlot_1_888 } from "./land_plots/1/land_plot_block_888.svg";
import { ReactComponent as LandPlot_1_889 } from "./land_plots/1/land_plot_block_889.svg";
import { ReactComponent as LandPlot_1_890 } from "./land_plots/1/land_plot_block_890.svg";
import { ReactComponent as LandPlot_1_891 } from "./land_plots/1/land_plot_block_891.svg";
import { ReactComponent as LandPlot_1_892 } from "./land_plots/1/land_plot_block_892.svg";
import { ReactComponent as LandPlot_1_893 } from "./land_plots/1/land_plot_block_893.svg";
import { ReactComponent as LandPlot_1_894 } from "./land_plots/1/land_plot_block_894.svg";
import { ReactComponent as LandPlot_1_895 } from "./land_plots/1/land_plot_block_895.svg";
import { ReactComponent as LandPlot_1_896 } from "./land_plots/1/land_plot_block_896.svg";
import { ReactComponent as LandPlot_1_897 } from "./land_plots/1/land_plot_block_897.svg";
import { ReactComponent as LandPlot_1_898 } from "./land_plots/1/land_plot_block_898.svg";
import { ReactComponent as LandPlot_1_899 } from "./land_plots/1/land_plot_block_899.svg";
import { ReactComponent as LandPlot_1_900 } from "./land_plots/1/land_plot_block_900.svg";
import { ReactComponent as LandPlot_1_901 } from "./land_plots/1/land_plot_block_901.svg";
import { ReactComponent as LandPlot_1_902 } from "./land_plots/1/land_plot_block_902.svg";
import { ReactComponent as LandPlot_1_903 } from "./land_plots/1/land_plot_block_903.svg";
import { ReactComponent as LandPlot_1_904 } from "./land_plots/1/land_plot_block_904.svg";
import { ReactComponent as LandPlot_1_905 } from "./land_plots/1/land_plot_block_905.svg";
import { ReactComponent as LandPlot_1_906 } from "./land_plots/1/land_plot_block_906.svg";
import { ReactComponent as LandPlot_1_907 } from "./land_plots/1/land_plot_block_907.svg";
import { ReactComponent as LandPlot_1_908 } from "./land_plots/1/land_plot_block_908.svg";
import { ReactComponent as LandPlot_1_909 } from "./land_plots/1/land_plot_block_909.svg";
import { ReactComponent as LandPlot_1_910 } from "./land_plots/1/land_plot_block_910.svg";
import { ReactComponent as LandPlot_1_911 } from "./land_plots/1/land_plot_block_911.svg";
import { ReactComponent as LandPlot_1_912 } from "./land_plots/1/land_plot_block_912.svg";
import { ReactComponent as LandPlot_1_913 } from "./land_plots/1/land_plot_block_913.svg";
import { ReactComponent as LandPlot_1_914 } from "./land_plots/1/land_plot_block_914.svg";
import { ReactComponent as LandPlot_1_915 } from "./land_plots/1/land_plot_block_915.svg";
import { ReactComponent as LandPlot_1_916 } from "./land_plots/1/land_plot_block_916.svg";
import { ReactComponent as LandPlot_1_917 } from "./land_plots/1/land_plot_block_917.svg";
import { ReactComponent as LandPlot_1_918 } from "./land_plots/1/land_plot_block_918.svg";
import { ReactComponent as LandPlot_1_919 } from "./land_plots/1/land_plot_block_919.svg";
import { ReactComponent as LandPlot_1_920 } from "./land_plots/1/land_plot_block_920.svg";
import { ReactComponent as LandPlot_1_921 } from "./land_plots/1/land_plot_block_921.svg";
import { ReactComponent as LandPlot_1_922 } from "./land_plots/1/land_plot_block_922.svg";
import { ReactComponent as LandPlot_1_923 } from "./land_plots/1/land_plot_block_923.svg";
import { ReactComponent as LandPlot_1_924 } from "./land_plots/1/land_plot_block_924.svg";
import { ReactComponent as LandPlot_1_925 } from "./land_plots/1/land_plot_block_925.svg";
import { ReactComponent as LandPlot_1_926 } from "./land_plots/1/land_plot_block_926.svg";
import { ReactComponent as LandPlot_1_927 } from "./land_plots/1/land_plot_block_927.svg";
import { ReactComponent as LandPlot_1_928 } from "./land_plots/1/land_plot_block_928.svg";
import { ReactComponent as LandPlot_1_929 } from "./land_plots/1/land_plot_block_929.svg";
import { ReactComponent as LandPlot_1_930 } from "./land_plots/1/land_plot_block_930.svg";
import { ReactComponent as LandPlot_1_931 } from "./land_plots/1/land_plot_block_931.svg";
import { ReactComponent as LandPlot_1_932 } from "./land_plots/1/land_plot_block_932.svg";
import { ReactComponent as LandPlot_1_933 } from "./land_plots/1/land_plot_block_933.svg";
import { ReactComponent as LandPlot_1_934 } from "./land_plots/1/land_plot_block_934.svg";
import { ReactComponent as LandPlot_1_935 } from "./land_plots/1/land_plot_block_935.svg";
import { ReactComponent as LandPlot_1_936 } from "./land_plots/1/land_plot_block_936.svg";
import { ReactComponent as LandPlot_1_937 } from "./land_plots/1/land_plot_block_937.svg";
import { ReactComponent as LandPlot_1_938 } from "./land_plots/1/land_plot_block_938.svg";
import { ReactComponent as LandPlot_1_939 } from "./land_plots/1/land_plot_block_939.svg";
import { ReactComponent as LandPlot_1_940 } from "./land_plots/1/land_plot_block_940.svg";
import { ReactComponent as LandPlot_1_941 } from "./land_plots/1/land_plot_block_941.svg";
import { ReactComponent as LandPlot_1_942 } from "./land_plots/1/land_plot_block_942.svg";
import { ReactComponent as LandPlot_1_943 } from "./land_plots/1/land_plot_block_943.svg";
import { ReactComponent as LandPlot_1_944 } from "./land_plots/1/land_plot_block_944.svg";
import { ReactComponent as LandPlot_1_945 } from "./land_plots/1/land_plot_block_945.svg";
import { ReactComponent as LandPlot_1_946 } from "./land_plots/1/land_plot_block_946.svg";
import { ReactComponent as LandPlot_1_947 } from "./land_plots/1/land_plot_block_947.svg";
import { ReactComponent as LandPlot_1_948 } from "./land_plots/1/land_plot_block_948.svg";
import { ReactComponent as LandPlot_1_949 } from "./land_plots/1/land_plot_block_949.svg";
import { ReactComponent as LandPlot_1_950 } from "./land_plots/1/land_plot_block_950.svg";
import { ReactComponent as LandPlot_1_951 } from "./land_plots/1/land_plot_block_951.svg";
import { ReactComponent as LandPlot_1_952 } from "./land_plots/1/land_plot_block_952.svg";
import { ReactComponent as LandPlot_1_953 } from "./land_plots/1/land_plot_block_953.svg";
import { ReactComponent as LandPlot_1_954 } from "./land_plots/1/land_plot_block_954.svg";
import { ReactComponent as LandPlot_1_955 } from "./land_plots/1/land_plot_block_955.svg";
import { ReactComponent as LandPlot_1_956 } from "./land_plots/1/land_plot_block_956.svg";
import { ReactComponent as LandPlot_1_957 } from "./land_plots/1/land_plot_block_957.svg";
import { ReactComponent as LandPlot_1_958 } from "./land_plots/1/land_plot_block_958.svg";
import { ReactComponent as LandPlot_1_959 } from "./land_plots/1/land_plot_block_959.svg";
import { ReactComponent as LandPlot_1_960 } from "./land_plots/1/land_plot_block_960.svg";
import { ReactComponent as LandPlot_1_961 } from "./land_plots/1/land_plot_block_961.svg";
import { ReactComponent as LandPlot_1_962 } from "./land_plots/1/land_plot_block_962.svg";
import { ReactComponent as LandPlot_1_963 } from "./land_plots/1/land_plot_block_963.svg";
import { ReactComponent as LandPlot_1_964 } from "./land_plots/1/land_plot_block_964.svg";
import { ReactComponent as LandPlot_1_965 } from "./land_plots/1/land_plot_block_965.svg";
import { ReactComponent as LandPlot_1_966 } from "./land_plots/1/land_plot_block_966.svg";
import { ReactComponent as LandPlot_1_967 } from "./land_plots/1/land_plot_block_967.svg";
import { ReactComponent as LandPlot_1_968 } from "./land_plots/1/land_plot_block_968.svg";
import { ReactComponent as LandPlot_1_969 } from "./land_plots/1/land_plot_block_969.svg";
import { ReactComponent as LandPlot_1_970 } from "./land_plots/1/land_plot_block_970.svg";
import { ReactComponent as LandPlot_1_971 } from "./land_plots/1/land_plot_block_971.svg";
import { ReactComponent as LandPlot_1_972 } from "./land_plots/1/land_plot_block_972.svg";
import { ReactComponent as LandPlot_1_973 } from "./land_plots/1/land_plot_block_973.svg";
import { ReactComponent as LandPlot_1_974 } from "./land_plots/1/land_plot_block_974.svg";
import { ReactComponent as LandPlot_1_975 } from "./land_plots/1/land_plot_block_975.svg";
import { ReactComponent as LandPlot_1_976 } from "./land_plots/1/land_plot_block_976.svg";
import { ReactComponent as LandPlot_1_977 } from "./land_plots/1/land_plot_block_977.svg";
import { ReactComponent as LandPlot_1_978 } from "./land_plots/1/land_plot_block_978.svg";
import { ReactComponent as LandPlot_1_979 } from "./land_plots/1/land_plot_block_979.svg";
import { ReactComponent as LandPlot_1_980 } from "./land_plots/1/land_plot_block_980.svg";
import { ReactComponent as LandPlot_1_981 } from "./land_plots/1/land_plot_block_981.svg";
import { ReactComponent as LandPlot_1_982 } from "./land_plots/1/land_plot_block_982.svg";
import { ReactComponent as LandPlot_1_983 } from "./land_plots/1/land_plot_block_983.svg";
import { ReactComponent as LandPlot_1_984 } from "./land_plots/1/land_plot_block_984.svg";
import { ReactComponent as LandPlot_1_985 } from "./land_plots/1/land_plot_block_985.svg";
import { ReactComponent as LandPlot_1_986 } from "./land_plots/1/land_plot_block_986.svg";
import { ReactComponent as LandPlot_1_987 } from "./land_plots/1/land_plot_block_987.svg";
import { ReactComponent as LandPlot_1_988 } from "./land_plots/1/land_plot_block_988.svg";
import { ReactComponent as LandPlot_1_989 } from "./land_plots/1/land_plot_block_989.svg";
import { ReactComponent as LandPlot_1_990 } from "./land_plots/1/land_plot_block_990.svg";
import { ReactComponent as LandPlot_1_991 } from "./land_plots/1/land_plot_block_991.svg";
import { ReactComponent as LandPlot_1_992 } from "./land_plots/1/land_plot_block_992.svg";
import { ReactComponent as LandPlot_1_993 } from "./land_plots/1/land_plot_block_993.svg";
import { ReactComponent as LandPlot_1_994 } from "./land_plots/1/land_plot_block_994.svg";
import { ReactComponent as LandPlot_1_995 } from "./land_plots/1/land_plot_block_995.svg";
import { ReactComponent as LandPlot_1_996 } from "./land_plots/1/land_plot_block_996.svg";
import { ReactComponent as LandPlot_1_997 } from "./land_plots/1/land_plot_block_997.svg";
import { ReactComponent as LandPlot_1_998 } from "./land_plots/1/land_plot_block_998.svg";
import { ReactComponent as LandPlot_1_999 } from "./land_plots/1/land_plot_block_999.svg";
import { ReactComponent as LandPlot_1_1000 } from "./land_plots/1/land_plot_block_1000.svg";
import { ReactComponent as LandPlot_1_1001 } from "./land_plots/1/land_plot_block_1001.svg";
import { ReactComponent as LandPlot_1_1002 } from "./land_plots/1/land_plot_block_1002.svg";
import { ReactComponent as LandPlot_1_1003 } from "./land_plots/1/land_plot_block_1003.svg";
import { ReactComponent as LandPlot_1_1004 } from "./land_plots/1/land_plot_block_1004.svg";
import { ReactComponent as LandPlot_1_1005 } from "./land_plots/1/land_plot_block_1005.svg";
import { ReactComponent as LandPlot_1_1006 } from "./land_plots/1/land_plot_block_1006.svg";
import { ReactComponent as LandPlot_1_1007 } from "./land_plots/1/land_plot_block_1007.svg";
import { ReactComponent as LandPlot_1_1008 } from "./land_plots/1/land_plot_block_1008.svg";
import { ReactComponent as LandPlot_1_1009 } from "./land_plots/1/land_plot_block_1009.svg";
import { ReactComponent as LandPlot_1_1010 } from "./land_plots/1/land_plot_block_1010.svg";
import { ReactComponent as LandPlot_1_1011 } from "./land_plots/1/land_plot_block_1011.svg";
import { ReactComponent as LandPlot_1_1012 } from "./land_plots/1/land_plot_block_1012.svg";
import { ReactComponent as LandPlot_1_1013 } from "./land_plots/1/land_plot_block_1013.svg";
import { ReactComponent as LandPlot_1_1014 } from "./land_plots/1/land_plot_block_1014.svg";
import { ReactComponent as LandPlot_1_1015 } from "./land_plots/1/land_plot_block_1015.svg";
import { ReactComponent as LandPlot_1_1016 } from "./land_plots/1/land_plot_block_1016.svg";
import { ReactComponent as LandPlot_1_1017 } from "./land_plots/1/land_plot_block_1017.svg";
import { ReactComponent as LandPlot_1_1018 } from "./land_plots/1/land_plot_block_1018.svg";
import { ReactComponent as LandPlot_1_1019 } from "./land_plots/1/land_plot_block_1019.svg";
import { ReactComponent as LandPlot_1_1020 } from "./land_plots/1/land_plot_block_1020.svg";
import { ReactComponent as LandPlot_1_1021 } from "./land_plots/1/land_plot_block_1021.svg";
import { ReactComponent as LandPlot_1_1022 } from "./land_plots/1/land_plot_block_1022.svg";
import { ReactComponent as LandPlot_1_1023 } from "./land_plots/1/land_plot_block_1023.svg";
import { ReactComponent as LandPlot_1_1024 } from "./land_plots/1/land_plot_block_1024.svg";
import { ReactComponent as LandPlot_1_1025 } from "./land_plots/1/land_plot_block_1025.svg";
import { ReactComponent as LandPlot_1_1026 } from "./land_plots/1/land_plot_block_1026.svg";
import { ReactComponent as LandPlot_1_1027 } from "./land_plots/1/land_plot_block_1027.svg";
import { ReactComponent as LandPlot_1_1028 } from "./land_plots/1/land_plot_block_1028.svg";
import { ReactComponent as LandPlot_1_1029 } from "./land_plots/1/land_plot_block_1029.svg";
import { ReactComponent as LandPlot_1_1030 } from "./land_plots/1/land_plot_block_1030.svg";
import { ReactComponent as LandPlot_1_1031 } from "./land_plots/1/land_plot_block_1031.svg";
import { ReactComponent as LandPlot_1_1032 } from "./land_plots/1/land_plot_block_1032.svg";
import { ReactComponent as LandPlot_1_1033 } from "./land_plots/1/land_plot_block_1033.svg";
import { ReactComponent as LandPlot_1_1034 } from "./land_plots/1/land_plot_block_1034.svg";
import { ReactComponent as LandPlot_1_1035 } from "./land_plots/1/land_plot_block_1035.svg";
import { ReactComponent as LandPlot_1_1036 } from "./land_plots/1/land_plot_block_1036.svg";
import { ReactComponent as LandPlot_1_1037 } from "./land_plots/1/land_plot_block_1037.svg";
import { ReactComponent as LandPlot_1_1038 } from "./land_plots/1/land_plot_block_1038.svg";
import { ReactComponent as LandPlot_1_1039 } from "./land_plots/1/land_plot_block_1039.svg";
import { ReactComponent as LandPlot_1_1040 } from "./land_plots/1/land_plot_block_1040.svg";
import { ReactComponent as LandPlot_1_1041 } from "./land_plots/1/land_plot_block_1041.svg";
import { ReactComponent as LandPlot_1_1042 } from "./land_plots/1/land_plot_block_1042.svg";
import { ReactComponent as LandPlot_1_1043 } from "./land_plots/1/land_plot_block_1043.svg";
import { ReactComponent as LandPlot_1_1044 } from "./land_plots/1/land_plot_block_1044.svg";
import { ReactComponent as LandPlot_1_1045 } from "./land_plots/1/land_plot_block_1045.svg";
import { ReactComponent as LandPlot_1_1046 } from "./land_plots/1/land_plot_block_1046.svg";
import { ReactComponent as LandPlot_1_1047 } from "./land_plots/1/land_plot_block_1047.svg";
import { ReactComponent as LandPlot_1_1048 } from "./land_plots/1/land_plot_block_1048.svg";
import { ReactComponent as LandPlot_1_1049 } from "./land_plots/1/land_plot_block_1049.svg";
import { ReactComponent as LandPlot_1_1050 } from "./land_plots/1/land_plot_block_1050.svg";
import { ReactComponent as LandPlot_1_1051 } from "./land_plots/1/land_plot_block_1051.svg";
import { ReactComponent as LandPlot_1_1052 } from "./land_plots/1/land_plot_block_1052.svg";
import { ReactComponent as LandPlot_1_1053 } from "./land_plots/1/land_plot_block_1053.svg";
import { ReactComponent as LandPlot_1_1054 } from "./land_plots/1/land_plot_block_1054.svg";
import { ReactComponent as LandPlot_1_1055 } from "./land_plots/1/land_plot_block_1055.svg";
import { ReactComponent as LandPlot_1_1056 } from "./land_plots/1/land_plot_block_1056.svg";
import { ReactComponent as LandPlot_1_1057 } from "./land_plots/1/land_plot_block_1057.svg";
import { ReactComponent as LandPlot_1_1058 } from "./land_plots/1/land_plot_block_1058.svg";
import { ReactComponent as LandPlot_1_1059 } from "./land_plots/1/land_plot_block_1059.svg";
import { ReactComponent as LandPlot_1_1060 } from "./land_plots/1/land_plot_block_1060.svg";
import { ReactComponent as LandPlot_1_1061 } from "./land_plots/1/land_plot_block_1061.svg";
import { ReactComponent as LandPlot_1_1062 } from "./land_plots/1/land_plot_block_1062.svg";
import { ReactComponent as LandPlot_1_1063 } from "./land_plots/1/land_plot_block_1063.svg";
import { ReactComponent as LandPlot_1_1064 } from "./land_plots/1/land_plot_block_1064.svg";
import { ReactComponent as LandPlot_1_1065 } from "./land_plots/1/land_plot_block_1065.svg";
import { ReactComponent as LandPlot_1_1066 } from "./land_plots/1/land_plot_block_1066.svg";
import { ReactComponent as LandPlot_1_1067 } from "./land_plots/1/land_plot_block_1067.svg";
import { ReactComponent as LandPlot_1_1068 } from "./land_plots/1/land_plot_block_1068.svg";
import { ReactComponent as LandPlot_1_1069 } from "./land_plots/1/land_plot_block_1069.svg";
import { ReactComponent as LandPlot_1_1070 } from "./land_plots/1/land_plot_block_1070.svg";
import { ReactComponent as LandPlot_1_1071 } from "./land_plots/1/land_plot_block_1071.svg";
import { ReactComponent as LandPlot_1_1072 } from "./land_plots/1/land_plot_block_1072.svg";
import { ReactComponent as LandPlot_1_1073 } from "./land_plots/1/land_plot_block_1073.svg";
import { ReactComponent as LandPlot_1_1074 } from "./land_plots/1/land_plot_block_1074.svg";
import { ReactComponent as LandPlot_1_1075 } from "./land_plots/1/land_plot_block_1075.svg";
import { ReactComponent as LandPlot_1_1076 } from "./land_plots/1/land_plot_block_1076.svg";
import { ReactComponent as LandPlot_1_1077 } from "./land_plots/1/land_plot_block_1077.svg";
import { ReactComponent as LandPlot_1_1078 } from "./land_plots/1/land_plot_block_1078.svg";
import { ReactComponent as LandPlot_1_1079 } from "./land_plots/1/land_plot_block_1079.svg";
import { ReactComponent as LandPlot_1_1080 } from "./land_plots/1/land_plot_block_1080.svg";
import { ReactComponent as LandPlot_1_1081 } from "./land_plots/1/land_plot_block_1081.svg";
import { ReactComponent as LandPlot_1_1082 } from "./land_plots/1/land_plot_block_1082.svg";
import { ReactComponent as LandPlot_1_1083 } from "./land_plots/1/land_plot_block_1083.svg";
import { ReactComponent as LandPlot_1_1084 } from "./land_plots/1/land_plot_block_1084.svg";
import { ReactComponent as LandPlot_1_1085 } from "./land_plots/1/land_plot_block_1085.svg";
import { ReactComponent as LandPlot_1_1086 } from "./land_plots/1/land_plot_block_1086.svg";
import { ReactComponent as LandPlot_1_1087 } from "./land_plots/1/land_plot_block_1087.svg";
import { ReactComponent as LandPlot_1_1088 } from "./land_plots/1/land_plot_block_1088.svg";
import { ReactComponent as LandPlot_1_1089 } from "./land_plots/1/land_plot_block_1089.svg";
import { ReactComponent as LandPlot_1_1090 } from "./land_plots/1/land_plot_block_1090.svg";
import { ReactComponent as LandPlot_1_1091 } from "./land_plots/1/land_plot_block_1091.svg";
import { ReactComponent as LandPlot_1_1092 } from "./land_plots/1/land_plot_block_1092.svg";
import { ReactComponent as LandPlot_1_1093 } from "./land_plots/1/land_plot_block_1093.svg";
import { ReactComponent as LandPlot_1_1094 } from "./land_plots/1/land_plot_block_1094.svg";
import { ReactComponent as LandPlot_1_1095 } from "./land_plots/1/land_plot_block_1095.svg";
import { ReactComponent as LandPlot_1_1096 } from "./land_plots/1/land_plot_block_1096.svg";
import { ReactComponent as LandPlot_1_1097 } from "./land_plots/1/land_plot_block_1097.svg";
import { ReactComponent as LandPlot_1_1098 } from "./land_plots/1/land_plot_block_1098.svg";
import { ReactComponent as LandPlot_1_1099 } from "./land_plots/1/land_plot_block_1099.svg";
import { ReactComponent as LandPlot_1_1100 } from "./land_plots/1/land_plot_block_1100.svg";
import { ReactComponent as LandPlot_1_1101 } from "./land_plots/1/land_plot_block_1101.svg";
import { ReactComponent as LandPlot_1_1102 } from "./land_plots/1/land_plot_block_1102.svg";
import { ReactComponent as LandPlot_1_1103 } from "./land_plots/1/land_plot_block_1103.svg";
import { ReactComponent as LandPlot_1_1104 } from "./land_plots/1/land_plot_block_1104.svg";
import { ReactComponent as LandPlot_1_1105 } from "./land_plots/1/land_plot_block_1105.svg";
import { ReactComponent as LandPlot_1_1106 } from "./land_plots/1/land_plot_block_1106.svg";
import { ReactComponent as LandPlot_1_1107 } from "./land_plots/1/land_plot_block_1107.svg";
import { ReactComponent as LandPlot_1_1108 } from "./land_plots/1/land_plot_block_1108.svg";
import { ReactComponent as LandPlot_1_1109 } from "./land_plots/1/land_plot_block_1109.svg";
import { ReactComponent as LandPlot_1_1110 } from "./land_plots/1/land_plot_block_1110.svg";
import { ReactComponent as LandPlot_1_1111 } from "./land_plots/1/land_plot_block_1111.svg";
import { ReactComponent as LandPlot_1_1112 } from "./land_plots/1/land_plot_block_1112.svg";
import { ReactComponent as LandPlot_1_1113 } from "./land_plots/1/land_plot_block_1113.svg";
import { ReactComponent as LandPlot_1_1114 } from "./land_plots/1/land_plot_block_1114.svg";
import { ReactComponent as LandPlot_1_1115 } from "./land_plots/1/land_plot_block_1115.svg";
import { ReactComponent as LandPlot_1_1116 } from "./land_plots/1/land_plot_block_1116.svg";
import { ReactComponent as LandPlot_1_1117 } from "./land_plots/1/land_plot_block_1117.svg";
import { ReactComponent as LandPlot_1_1118 } from "./land_plots/1/land_plot_block_1118.svg";
import { ReactComponent as LandPlot_1_1119 } from "./land_plots/1/land_plot_block_1119.svg";
import { ReactComponent as LandPlot_1_1120 } from "./land_plots/1/land_plot_block_1120.svg";
import { ReactComponent as LandPlot_1_1121 } from "./land_plots/1/land_plot_block_1121.svg";
import { ReactComponent as LandPlot_1_1122 } from "./land_plots/1/land_plot_block_1122.svg";
import { ReactComponent as LandPlot_1_1123 } from "./land_plots/1/land_plot_block_1123.svg";
import { ReactComponent as LandPlot_1_1124 } from "./land_plots/1/land_plot_block_1124.svg";
import { ReactComponent as LandPlot_1_1125 } from "./land_plots/1/land_plot_block_1125.svg";
import { ReactComponent as LandPlot_1_1126 } from "./land_plots/1/land_plot_block_1126.svg";
import { ReactComponent as LandPlot_1_1127 } from "./land_plots/1/land_plot_block_1127.svg";
import { ReactComponent as LandPlot_1_1128 } from "./land_plots/1/land_plot_block_1128.svg";
import { ReactComponent as LandPlot_1_1129 } from "./land_plots/1/land_plot_block_1129.svg";
import { ReactComponent as LandPlot_1_1130 } from "./land_plots/1/land_plot_block_1130.svg";
import { ReactComponent as LandPlot_1_1131 } from "./land_plots/1/land_plot_block_1131.svg";
import { ReactComponent as LandPlot_1_1132 } from "./land_plots/1/land_plot_block_1132.svg";
import { ReactComponent as LandPlot_1_1133 } from "./land_plots/1/land_plot_block_1133.svg";
import { ReactComponent as LandPlot_1_1134 } from "./land_plots/1/land_plot_block_1134.svg";
import { ReactComponent as LandPlot_1_1135 } from "./land_plots/1/land_plot_block_1135.svg";
import { ReactComponent as LandPlot_1_1136 } from "./land_plots/1/land_plot_block_1136.svg";
import { ReactComponent as LandPlot_1_1137 } from "./land_plots/1/land_plot_block_1137.svg";
import { ReactComponent as LandPlot_1_1138 } from "./land_plots/1/land_plot_block_1138.svg";
import { ReactComponent as LandPlot_1_1139 } from "./land_plots/1/land_plot_block_1139.svg";
import { ReactComponent as LandPlot_1_1140 } from "./land_plots/1/land_plot_block_1140.svg";
import { ReactComponent as LandPlot_1_1141 } from "./land_plots/1/land_plot_block_1141.svg";
import { ReactComponent as LandPlot_1_1142 } from "./land_plots/1/land_plot_block_1142.svg";
import { ReactComponent as LandPlot_1_1143 } from "./land_plots/1/land_plot_block_1143.svg";
import { ReactComponent as LandPlot_1_1144 } from "./land_plots/1/land_plot_block_1144.svg";
import { ReactComponent as LandPlot_1_1145 } from "./land_plots/1/land_plot_block_1145.svg";
import { ReactComponent as LandPlot_1_1146 } from "./land_plots/1/land_plot_block_1146.svg";
import { ReactComponent as LandPlot_1_1147 } from "./land_plots/1/land_plot_block_1147.svg";
import { ReactComponent as LandPlot_1_1148 } from "./land_plots/1/land_plot_block_1148.svg";
import { ReactComponent as LandPlot_1_1149 } from "./land_plots/1/land_plot_block_1149.svg";
import { ReactComponent as LandPlot_1_1150 } from "./land_plots/1/land_plot_block_1150.svg";
import { ReactComponent as LandPlot_1_1151 } from "./land_plots/1/land_plot_block_1151.svg";
import { ReactComponent as LandPlot_1_1152 } from "./land_plots/1/land_plot_block_1152.svg";
import { ReactComponent as LandPlot_1_1153 } from "./land_plots/1/land_plot_block_1153.svg";
import { ReactComponent as LandPlot_1_1154 } from "./land_plots/1/land_plot_block_1154.svg";
import { ReactComponent as LandPlot_1_1155 } from "./land_plots/1/land_plot_block_1155.svg";
import { ReactComponent as LandPlot_1_1156 } from "./land_plots/1/land_plot_block_1156.svg";
import { ReactComponent as LandPlot_1_1157 } from "./land_plots/1/land_plot_block_1157.svg";
import { ReactComponent as LandPlot_1_1158 } from "./land_plots/1/land_plot_block_1158.svg";
import { ReactComponent as LandPlot_1_1159 } from "./land_plots/1/land_plot_block_1159.svg";
import { ReactComponent as LandPlot_1_1160 } from "./land_plots/1/land_plot_block_1160.svg";
import { ReactComponent as LandPlot_1_1161 } from "./land_plots/1/land_plot_block_1161.svg";
import { ReactComponent as LandPlot_1_1162 } from "./land_plots/1/land_plot_block_1162.svg";
import { ReactComponent as LandPlot_1_1163 } from "./land_plots/1/land_plot_block_1163.svg";

//////////////////////////////////////////////////////////////////////////////////stop frame 30
import { ReactComponent as LandPlot_30_681 } from "./land_plots/30/land_plot_block_681.svg";
import { ReactComponent as LandPlot_30_682 } from "./land_plots/30/land_plot_block_682.svg";
import { ReactComponent as LandPlot_30_683 } from "./land_plots/30/land_plot_block_683.svg";
import { ReactComponent as LandPlot_30_684 } from "./land_plots/30/land_plot_block_684.svg";
import { ReactComponent as LandPlot_30_685 } from "./land_plots/30/land_plot_block_685.svg";
import { ReactComponent as LandPlot_30_686 } from "./land_plots/30/land_plot_block_686.svg";
import { ReactComponent as LandPlot_30_687 } from "./land_plots/30/land_plot_block_687.svg";
import { ReactComponent as LandPlot_30_688 } from "./land_plots/30/land_plot_block_688.svg";
import { ReactComponent as LandPlot_30_689 } from "./land_plots/30/land_plot_block_689.svg";
import { ReactComponent as LandPlot_30_690 } from "./land_plots/30/land_plot_block_690.svg";
import { ReactComponent as LandPlot_30_691 } from "./land_plots/30/land_plot_block_691.svg";
import { ReactComponent as LandPlot_30_692 } from "./land_plots/30/land_plot_block_692.svg";
import { ReactComponent as LandPlot_30_693 } from "./land_plots/30/land_plot_block_693.svg";
import { ReactComponent as LandPlot_30_694 } from "./land_plots/30/land_plot_block_694.svg";
import { ReactComponent as LandPlot_30_695 } from "./land_plots/30/land_plot_block_695.svg";
import { ReactComponent as LandPlot_30_696 } from "./land_plots/30/land_plot_block_696.svg";
import { ReactComponent as LandPlot_30_697 } from "./land_plots/30/land_plot_block_697.svg";
import { ReactComponent as LandPlot_30_698 } from "./land_plots/30/land_plot_block_698.svg";
import { ReactComponent as LandPlot_30_699 } from "./land_plots/30/land_plot_block_699.svg";
import { ReactComponent as LandPlot_30_700 } from "./land_plots/30/land_plot_block_700.svg";
import { ReactComponent as LandPlot_30_701 } from "./land_plots/30/land_plot_block_701.svg";
import { ReactComponent as LandPlot_30_702 } from "./land_plots/30/land_plot_block_702.svg";
import { ReactComponent as LandPlot_30_703 } from "./land_plots/30/land_plot_block_703.svg";
import { ReactComponent as LandPlot_30_704 } from "./land_plots/30/land_plot_block_704.svg";
import { ReactComponent as LandPlot_30_705 } from "./land_plots/30/land_plot_block_705.svg";
import { ReactComponent as LandPlot_30_706 } from "./land_plots/30/land_plot_block_706.svg";
import { ReactComponent as LandPlot_30_707 } from "./land_plots/30/land_plot_block_707.svg";
import { ReactComponent as LandPlot_30_708 } from "./land_plots/30/land_plot_block_708.svg";
import { ReactComponent as LandPlot_30_709 } from "./land_plots/30/land_plot_block_709.svg";
import { ReactComponent as LandPlot_30_710 } from "./land_plots/30/land_plot_block_710.svg";
import { ReactComponent as LandPlot_30_711 } from "./land_plots/30/land_plot_block_711.svg";
import { ReactComponent as LandPlot_30_712 } from "./land_plots/30/land_plot_block_712.svg";
import { ReactComponent as LandPlot_30_713 } from "./land_plots/30/land_plot_block_713.svg";
import { ReactComponent as LandPlot_30_714 } from "./land_plots/30/land_plot_block_714.svg";
import { ReactComponent as LandPlot_30_715 } from "./land_plots/30/land_plot_block_715.svg";
import { ReactComponent as LandPlot_30_716 } from "./land_plots/30/land_plot_block_716.svg";
import { ReactComponent as LandPlot_30_717 } from "./land_plots/30/land_plot_block_717.svg";
import { ReactComponent as LandPlot_30_718 } from "./land_plots/30/land_plot_block_718.svg";
import { ReactComponent as LandPlot_30_719 } from "./land_plots/30/land_plot_block_719.svg";
import { ReactComponent as LandPlot_30_720 } from "./land_plots/30/land_plot_block_720.svg";
import { ReactComponent as LandPlot_30_721 } from "./land_plots/30/land_plot_block_721.svg";
import { ReactComponent as LandPlot_30_722 } from "./land_plots/30/land_plot_block_722.svg";
import { ReactComponent as LandPlot_30_723 } from "./land_plots/30/land_plot_block_723.svg";
import { ReactComponent as LandPlot_30_724 } from "./land_plots/30/land_plot_block_724.svg";
import { ReactComponent as LandPlot_30_725 } from "./land_plots/30/land_plot_block_725.svg";
import { ReactComponent as LandPlot_30_726 } from "./land_plots/30/land_plot_block_726.svg";
import { ReactComponent as LandPlot_30_727 } from "./land_plots/30/land_plot_block_727.svg";
import { ReactComponent as LandPlot_30_728 } from "./land_plots/30/land_plot_block_728.svg";
import { ReactComponent as LandPlot_30_729 } from "./land_plots/30/land_plot_block_729.svg";
import { ReactComponent as LandPlot_30_730 } from "./land_plots/30/land_plot_block_730.svg";
import { ReactComponent as LandPlot_30_731 } from "./land_plots/30/land_plot_block_731.svg";
import { ReactComponent as LandPlot_30_732 } from "./land_plots/30/land_plot_block_732.svg";
import { ReactComponent as LandPlot_30_733 } from "./land_plots/30/land_plot_block_733.svg";
import { ReactComponent as LandPlot_30_734 } from "./land_plots/30/land_plot_block_734.svg";
import { ReactComponent as LandPlot_30_735 } from "./land_plots/30/land_plot_block_735.svg";
import { ReactComponent as LandPlot_30_736 } from "./land_plots/30/land_plot_block_736.svg";
import { ReactComponent as LandPlot_30_737 } from "./land_plots/30/land_plot_block_737.svg";
import { ReactComponent as LandPlot_30_738 } from "./land_plots/30/land_plot_block_738.svg";
import { ReactComponent as LandPlot_30_739 } from "./land_plots/30/land_plot_block_739.svg";
import { ReactComponent as LandPlot_30_740 } from "./land_plots/30/land_plot_block_740.svg";
import { ReactComponent as LandPlot_30_741 } from "./land_plots/30/land_plot_block_741.svg";
import { ReactComponent as LandPlot_30_742 } from "./land_plots/30/land_plot_block_742.svg";
import { ReactComponent as LandPlot_30_743 } from "./land_plots/30/land_plot_block_743.svg";
import { ReactComponent as LandPlot_30_744 } from "./land_plots/30/land_plot_block_744.svg";
import { ReactComponent as LandPlot_30_745 } from "./land_plots/30/land_plot_block_745.svg";
import { ReactComponent as LandPlot_30_746 } from "./land_plots/30/land_plot_block_746.svg";
import { ReactComponent as LandPlot_30_747 } from "./land_plots/30/land_plot_block_747.svg";
import { ReactComponent as LandPlot_30_748 } from "./land_plots/30/land_plot_block_748.svg";
import { ReactComponent as LandPlot_30_749 } from "./land_plots/30/land_plot_block_749.svg";
import { ReactComponent as LandPlot_30_750 } from "./land_plots/30/land_plot_block_750.svg";
import { ReactComponent as LandPlot_30_751 } from "./land_plots/30/land_plot_block_751.svg";
import { ReactComponent as LandPlot_30_752 } from "./land_plots/30/land_plot_block_752.svg";
import { ReactComponent as LandPlot_30_753 } from "./land_plots/30/land_plot_block_753.svg";
import { ReactComponent as LandPlot_30_754 } from "./land_plots/30/land_plot_block_754.svg";
import { ReactComponent as LandPlot_30_755 } from "./land_plots/30/land_plot_block_755.svg";
import { ReactComponent as LandPlot_30_756 } from "./land_plots/30/land_plot_block_756.svg";
import { ReactComponent as LandPlot_30_757 } from "./land_plots/30/land_plot_block_757.svg";
import { ReactComponent as LandPlot_30_758 } from "./land_plots/30/land_plot_block_758.svg";
import { ReactComponent as LandPlot_30_759 } from "./land_plots/30/land_plot_block_759.svg";
import { ReactComponent as LandPlot_30_760 } from "./land_plots/30/land_plot_block_760.svg";
import { ReactComponent as LandPlot_30_761 } from "./land_plots/30/land_plot_block_761.svg";
import { ReactComponent as LandPlot_30_762 } from "./land_plots/30/land_plot_block_762.svg";
import { ReactComponent as LandPlot_30_763 } from "./land_plots/30/land_plot_block_763.svg";
import { ReactComponent as LandPlot_30_764 } from "./land_plots/30/land_plot_block_764.svg";
import { ReactComponent as LandPlot_30_765 } from "./land_plots/30/land_plot_block_765.svg";
import { ReactComponent as LandPlot_30_766 } from "./land_plots/30/land_plot_block_766.svg";
import { ReactComponent as LandPlot_30_767 } from "./land_plots/30/land_plot_block_767.svg";
import { ReactComponent as LandPlot_30_768 } from "./land_plots/30/land_plot_block_768.svg";
import { ReactComponent as LandPlot_30_769 } from "./land_plots/30/land_plot_block_769.svg";
import { ReactComponent as LandPlot_30_770 } from "./land_plots/30/land_plot_block_770.svg";
import { ReactComponent as LandPlot_30_771 } from "./land_plots/30/land_plot_block_771.svg";
import { ReactComponent as LandPlot_30_772 } from "./land_plots/30/land_plot_block_772.svg";
import { ReactComponent as LandPlot_30_773 } from "./land_plots/30/land_plot_block_773.svg";
import { ReactComponent as LandPlot_30_774 } from "./land_plots/30/land_plot_block_774.svg";
import { ReactComponent as LandPlot_30_775 } from "./land_plots/30/land_plot_block_775.svg";
import { ReactComponent as LandPlot_30_776 } from "./land_plots/30/land_plot_block_776.svg";
import { ReactComponent as LandPlot_30_777 } from "./land_plots/30/land_plot_block_777.svg";
import { ReactComponent as LandPlot_30_778 } from "./land_plots/30/land_plot_block_778.svg";
import { ReactComponent as LandPlot_30_779 } from "./land_plots/30/land_plot_block_779.svg";
import { ReactComponent as LandPlot_30_780 } from "./land_plots/30/land_plot_block_780.svg";
import { ReactComponent as LandPlot_30_781 } from "./land_plots/30/land_plot_block_781.svg";
import { ReactComponent as LandPlot_30_782 } from "./land_plots/30/land_plot_block_782.svg";
import { ReactComponent as LandPlot_30_783 } from "./land_plots/30/land_plot_block_783.svg";
import { ReactComponent as LandPlot_30_784 } from "./land_plots/30/land_plot_block_784.svg";
import { ReactComponent as LandPlot_30_785 } from "./land_plots/30/land_plot_block_785.svg";
import { ReactComponent as LandPlot_30_786 } from "./land_plots/30/land_plot_block_786.svg";
import { ReactComponent as LandPlot_30_787 } from "./land_plots/30/land_plot_block_787.svg";
import { ReactComponent as LandPlot_30_788 } from "./land_plots/30/land_plot_block_788.svg";
import { ReactComponent as LandPlot_30_789 } from "./land_plots/30/land_plot_block_789.svg";
import { ReactComponent as LandPlot_30_790 } from "./land_plots/30/land_plot_block_790.svg";
import { ReactComponent as LandPlot_30_791 } from "./land_plots/30/land_plot_block_791.svg";
import { ReactComponent as LandPlot_30_792 } from "./land_plots/30/land_plot_block_792.svg";
import { ReactComponent as LandPlot_30_793 } from "./land_plots/30/land_plot_block_793.svg";
import { ReactComponent as LandPlot_30_794 } from "./land_plots/30/land_plot_block_794.svg";
import { ReactComponent as LandPlot_30_795 } from "./land_plots/30/land_plot_block_795.svg";
import { ReactComponent as LandPlot_30_796 } from "./land_plots/30/land_plot_block_796.svg";
import { ReactComponent as LandPlot_30_797 } from "./land_plots/30/land_plot_block_797.svg";
import { ReactComponent as LandPlot_30_798 } from "./land_plots/30/land_plot_block_798.svg";
import { ReactComponent as LandPlot_30_799 } from "./land_plots/30/land_plot_block_799.svg";
import { ReactComponent as LandPlot_30_800 } from "./land_plots/30/land_plot_block_800.svg";
import { ReactComponent as LandPlot_30_801 } from "./land_plots/30/land_plot_block_801.svg";
import { ReactComponent as LandPlot_30_802 } from "./land_plots/30/land_plot_block_802.svg";
import { ReactComponent as LandPlot_30_803 } from "./land_plots/30/land_plot_block_803.svg";
import { ReactComponent as LandPlot_30_804 } from "./land_plots/30/land_plot_block_804.svg";
import { ReactComponent as LandPlot_30_805 } from "./land_plots/30/land_plot_block_805.svg";
import { ReactComponent as LandPlot_30_806 } from "./land_plots/30/land_plot_block_806.svg";
import { ReactComponent as LandPlot_30_807 } from "./land_plots/30/land_plot_block_807.svg";
import { ReactComponent as LandPlot_30_808 } from "./land_plots/30/land_plot_block_808.svg";
import { ReactComponent as LandPlot_30_809 } from "./land_plots/30/land_plot_block_809.svg";
import { ReactComponent as LandPlot_30_810 } from "./land_plots/30/land_plot_block_810.svg";
import { ReactComponent as LandPlot_30_811 } from "./land_plots/30/land_plot_block_811.svg";
import { ReactComponent as LandPlot_30_812 } from "./land_plots/30/land_plot_block_812.svg";
import { ReactComponent as LandPlot_30_813 } from "./land_plots/30/land_plot_block_813.svg";
import { ReactComponent as LandPlot_30_814 } from "./land_plots/30/land_plot_block_814.svg";
import { ReactComponent as LandPlot_30_815 } from "./land_plots/30/land_plot_block_815.svg";
import { ReactComponent as LandPlot_30_816 } from "./land_plots/30/land_plot_block_816.svg";
import { ReactComponent as LandPlot_30_817 } from "./land_plots/30/land_plot_block_817.svg";
import { ReactComponent as LandPlot_30_818 } from "./land_plots/30/land_plot_block_818.svg";
import { ReactComponent as LandPlot_30_819 } from "./land_plots/30/land_plot_block_819.svg";
import { ReactComponent as LandPlot_30_820 } from "./land_plots/30/land_plot_block_820.svg";
import { ReactComponent as LandPlot_30_821 } from "./land_plots/30/land_plot_block_821.svg";
import { ReactComponent as LandPlot_30_822 } from "./land_plots/30/land_plot_block_822.svg";
import { ReactComponent as LandPlot_30_823 } from "./land_plots/30/land_plot_block_823.svg";
import { ReactComponent as LandPlot_30_824 } from "./land_plots/30/land_plot_block_824.svg";
import { ReactComponent as LandPlot_30_825 } from "./land_plots/30/land_plot_block_825.svg";
import { ReactComponent as LandPlot_30_826 } from "./land_plots/30/land_plot_block_826.svg";
import { ReactComponent as LandPlot_30_827 } from "./land_plots/30/land_plot_block_827.svg";
import { ReactComponent as LandPlot_30_828 } from "./land_plots/30/land_plot_block_828.svg";
import { ReactComponent as LandPlot_30_829 } from "./land_plots/30/land_plot_block_829.svg";
import { ReactComponent as LandPlot_30_830 } from "./land_plots/30/land_plot_block_830.svg";
import { ReactComponent as LandPlot_30_831 } from "./land_plots/30/land_plot_block_831.svg";
import { ReactComponent as LandPlot_30_832 } from "./land_plots/30/land_plot_block_832.svg";
import { ReactComponent as LandPlot_30_833 } from "./land_plots/30/land_plot_block_833.svg";
import { ReactComponent as LandPlot_30_834 } from "./land_plots/30/land_plot_block_834.svg";
import { ReactComponent as LandPlot_30_835 } from "./land_plots/30/land_plot_block_835.svg";
import { ReactComponent as LandPlot_30_836 } from "./land_plots/30/land_plot_block_836.svg";
import { ReactComponent as LandPlot_30_837 } from "./land_plots/30/land_plot_block_837.svg";
import { ReactComponent as LandPlot_30_838 } from "./land_plots/30/land_plot_block_838.svg";
import { ReactComponent as LandPlot_30_839 } from "./land_plots/30/land_plot_block_839.svg";
import { ReactComponent as LandPlot_30_840 } from "./land_plots/30/land_plot_block_840.svg";
import { ReactComponent as LandPlot_30_841 } from "./land_plots/30/land_plot_block_841.svg";
import { ReactComponent as LandPlot_30_842 } from "./land_plots/30/land_plot_block_842.svg";
import { ReactComponent as LandPlot_30_843 } from "./land_plots/30/land_plot_block_843.svg";
import { ReactComponent as LandPlot_30_844 } from "./land_plots/30/land_plot_block_844.svg";
import { ReactComponent as LandPlot_30_845 } from "./land_plots/30/land_plot_block_845.svg";
import { ReactComponent as LandPlot_30_846 } from "./land_plots/30/land_plot_block_846.svg";
import { ReactComponent as LandPlot_30_847 } from "./land_plots/30/land_plot_block_847.svg";
import { ReactComponent as LandPlot_30_848 } from "./land_plots/30/land_plot_block_848.svg";
import { ReactComponent as LandPlot_30_849 } from "./land_plots/30/land_plot_block_849.svg";
import { ReactComponent as LandPlot_30_850 } from "./land_plots/30/land_plot_block_850.svg";
import { ReactComponent as LandPlot_30_851 } from "./land_plots/30/land_plot_block_851.svg";
import { ReactComponent as LandPlot_30_852 } from "./land_plots/30/land_plot_block_852.svg";
import { ReactComponent as LandPlot_30_853 } from "./land_plots/30/land_plot_block_853.svg";
import { ReactComponent as LandPlot_30_854 } from "./land_plots/30/land_plot_block_854.svg";
import { ReactComponent as LandPlot_30_855 } from "./land_plots/30/land_plot_block_855.svg";
import { ReactComponent as LandPlot_30_856 } from "./land_plots/30/land_plot_block_856.svg";
import { ReactComponent as LandPlot_30_857 } from "./land_plots/30/land_plot_block_857.svg";
import { ReactComponent as LandPlot_30_858 } from "./land_plots/30/land_plot_block_858.svg";
import { ReactComponent as LandPlot_30_859 } from "./land_plots/30/land_plot_block_859.svg";
import { ReactComponent as LandPlot_30_860 } from "./land_plots/30/land_plot_block_860.svg";
import { ReactComponent as LandPlot_30_861 } from "./land_plots/30/land_plot_block_861.svg";
import { ReactComponent as LandPlot_30_862 } from "./land_plots/30/land_plot_block_862.svg";
import { ReactComponent as LandPlot_30_863 } from "./land_plots/30/land_plot_block_863.svg";
import { ReactComponent as LandPlot_30_864 } from "./land_plots/30/land_plot_block_864.svg";
import { ReactComponent as LandPlot_30_865 } from "./land_plots/30/land_plot_block_865.svg";
import { ReactComponent as LandPlot_30_866 } from "./land_plots/30/land_plot_block_866.svg";
import { ReactComponent as LandPlot_30_867 } from "./land_plots/30/land_plot_block_867.svg";
import { ReactComponent as LandPlot_30_868 } from "./land_plots/30/land_plot_block_868.svg";
import { ReactComponent as LandPlot_30_869 } from "./land_plots/30/land_plot_block_869.svg";
import { ReactComponent as LandPlot_30_870 } from "./land_plots/30/land_plot_block_870.svg";
import { ReactComponent as LandPlot_30_871 } from "./land_plots/30/land_plot_block_871.svg";
import { ReactComponent as LandPlot_30_872 } from "./land_plots/30/land_plot_block_872.svg";
import { ReactComponent as LandPlot_30_873 } from "./land_plots/30/land_plot_block_873.svg";
import { ReactComponent as LandPlot_30_874 } from "./land_plots/30/land_plot_block_874.svg";
import { ReactComponent as LandPlot_30_875 } from "./land_plots/30/land_plot_block_875.svg";
import { ReactComponent as LandPlot_30_876 } from "./land_plots/30/land_plot_block_876.svg";
import { ReactComponent as LandPlot_30_877 } from "./land_plots/30/land_plot_block_877.svg";
import { ReactComponent as LandPlot_30_878 } from "./land_plots/30/land_plot_block_878.svg";
import { ReactComponent as LandPlot_30_879 } from "./land_plots/30/land_plot_block_879.svg";
import { ReactComponent as LandPlot_30_880 } from "./land_plots/30/land_plot_block_880.svg";
import { ReactComponent as LandPlot_30_881 } from "./land_plots/30/land_plot_block_881.svg";
import { ReactComponent as LandPlot_30_882 } from "./land_plots/30/land_plot_block_882.svg";
import { ReactComponent as LandPlot_30_883 } from "./land_plots/30/land_plot_block_883.svg";
import { ReactComponent as LandPlot_30_884 } from "./land_plots/30/land_plot_block_884.svg";
import { ReactComponent as LandPlot_30_885 } from "./land_plots/30/land_plot_block_885.svg";
import { ReactComponent as LandPlot_30_886 } from "./land_plots/30/land_plot_block_886.svg";
import { ReactComponent as LandPlot_30_887 } from "./land_plots/30/land_plot_block_887.svg";
import { ReactComponent as LandPlot_30_888 } from "./land_plots/30/land_plot_block_888.svg";
import { ReactComponent as LandPlot_30_889 } from "./land_plots/30/land_plot_block_889.svg";
import { ReactComponent as LandPlot_30_890 } from "./land_plots/30/land_plot_block_890.svg";
import { ReactComponent as LandPlot_30_891 } from "./land_plots/30/land_plot_block_891.svg";
import { ReactComponent as LandPlot_30_892 } from "./land_plots/30/land_plot_block_892.svg";
import { ReactComponent as LandPlot_30_893 } from "./land_plots/30/land_plot_block_893.svg";
import { ReactComponent as LandPlot_30_894 } from "./land_plots/30/land_plot_block_894.svg";
import { ReactComponent as LandPlot_30_895 } from "./land_plots/30/land_plot_block_895.svg";
import { ReactComponent as LandPlot_30_896 } from "./land_plots/30/land_plot_block_896.svg";
import { ReactComponent as LandPlot_30_897 } from "./land_plots/30/land_plot_block_897.svg";
import { ReactComponent as LandPlot_30_898 } from "./land_plots/30/land_plot_block_898.svg";
import { ReactComponent as LandPlot_30_899 } from "./land_plots/30/land_plot_block_899.svg";
import { ReactComponent as LandPlot_30_900 } from "./land_plots/30/land_plot_block_900.svg";
import { ReactComponent as LandPlot_30_901 } from "./land_plots/30/land_plot_block_901.svg";
import { ReactComponent as LandPlot_30_902 } from "./land_plots/30/land_plot_block_902.svg";
import { ReactComponent as LandPlot_30_903 } from "./land_plots/30/land_plot_block_903.svg";
import { ReactComponent as LandPlot_30_904 } from "./land_plots/30/land_plot_block_904.svg";
import { ReactComponent as LandPlot_30_905 } from "./land_plots/30/land_plot_block_905.svg";
import { ReactComponent as LandPlot_30_906 } from "./land_plots/30/land_plot_block_906.svg";
import { ReactComponent as LandPlot_30_907 } from "./land_plots/30/land_plot_block_907.svg";
import { ReactComponent as LandPlot_30_908 } from "./land_plots/30/land_plot_block_908.svg";
import { ReactComponent as LandPlot_30_909 } from "./land_plots/30/land_plot_block_909.svg";
import { ReactComponent as LandPlot_30_910 } from "./land_plots/30/land_plot_block_910.svg";
import { ReactComponent as LandPlot_30_911 } from "./land_plots/30/land_plot_block_911.svg";
import { ReactComponent as LandPlot_30_912 } from "./land_plots/30/land_plot_block_912.svg";
import { ReactComponent as LandPlot_30_913 } from "./land_plots/30/land_plot_block_913.svg";
import { ReactComponent as LandPlot_30_914 } from "./land_plots/30/land_plot_block_914.svg";
import { ReactComponent as LandPlot_30_915 } from "./land_plots/30/land_plot_block_915.svg";
import { ReactComponent as LandPlot_30_916 } from "./land_plots/30/land_plot_block_916.svg";
import { ReactComponent as LandPlot_30_917 } from "./land_plots/30/land_plot_block_917.svg";
import { ReactComponent as LandPlot_30_918 } from "./land_plots/30/land_plot_block_918.svg";
import { ReactComponent as LandPlot_30_919 } from "./land_plots/30/land_plot_block_919.svg";
import { ReactComponent as LandPlot_30_920 } from "./land_plots/30/land_plot_block_920.svg";
import { ReactComponent as LandPlot_30_921 } from "./land_plots/30/land_plot_block_921.svg";
import { ReactComponent as LandPlot_30_922 } from "./land_plots/30/land_plot_block_922.svg";
import { ReactComponent as LandPlot_30_923 } from "./land_plots/30/land_plot_block_923.svg";
import { ReactComponent as LandPlot_30_924 } from "./land_plots/30/land_plot_block_924.svg";
import { ReactComponent as LandPlot_30_925 } from "./land_plots/30/land_plot_block_925.svg";
import { ReactComponent as LandPlot_30_926 } from "./land_plots/30/land_plot_block_926.svg";
import { ReactComponent as LandPlot_30_927 } from "./land_plots/30/land_plot_block_927.svg";
import { ReactComponent as LandPlot_30_928 } from "./land_plots/30/land_plot_block_928.svg";
import { ReactComponent as LandPlot_30_929 } from "./land_plots/30/land_plot_block_929.svg";
import { ReactComponent as LandPlot_30_930 } from "./land_plots/30/land_plot_block_930.svg";
import { ReactComponent as LandPlot_30_931 } from "./land_plots/30/land_plot_block_931.svg";
import { ReactComponent as LandPlot_30_932 } from "./land_plots/30/land_plot_block_932.svg";
import { ReactComponent as LandPlot_30_933 } from "./land_plots/30/land_plot_block_933.svg";
import { ReactComponent as LandPlot_30_934 } from "./land_plots/30/land_plot_block_934.svg";
import { ReactComponent as LandPlot_30_935 } from "./land_plots/30/land_plot_block_935.svg";
import { ReactComponent as LandPlot_30_936 } from "./land_plots/30/land_plot_block_936.svg";
import { ReactComponent as LandPlot_30_937 } from "./land_plots/30/land_plot_block_937.svg";
import { ReactComponent as LandPlot_30_938 } from "./land_plots/30/land_plot_block_938.svg";
import { ReactComponent as LandPlot_30_939 } from "./land_plots/30/land_plot_block_939.svg";
import { ReactComponent as LandPlot_30_940 } from "./land_plots/30/land_plot_block_940.svg";
import { ReactComponent as LandPlot_30_941 } from "./land_plots/30/land_plot_block_941.svg";
import { ReactComponent as LandPlot_30_942 } from "./land_plots/30/land_plot_block_942.svg";
import { ReactComponent as LandPlot_30_943 } from "./land_plots/30/land_plot_block_943.svg";
import { ReactComponent as LandPlot_30_944 } from "./land_plots/30/land_plot_block_944.svg";
import { ReactComponent as LandPlot_30_945 } from "./land_plots/30/land_plot_block_945.svg";
import { ReactComponent as LandPlot_30_946 } from "./land_plots/30/land_plot_block_946.svg";
import { ReactComponent as LandPlot_30_947 } from "./land_plots/30/land_plot_block_947.svg";
import { ReactComponent as LandPlot_30_948 } from "./land_plots/30/land_plot_block_948.svg";
import { ReactComponent as LandPlot_30_949 } from "./land_plots/30/land_plot_block_949.svg";
import { ReactComponent as LandPlot_30_950 } from "./land_plots/30/land_plot_block_950.svg";
import { ReactComponent as LandPlot_30_951 } from "./land_plots/30/land_plot_block_951.svg";
import { ReactComponent as LandPlot_30_952 } from "./land_plots/30/land_plot_block_952.svg";
import { ReactComponent as LandPlot_30_953 } from "./land_plots/30/land_plot_block_953.svg";
import { ReactComponent as LandPlot_30_954 } from "./land_plots/30/land_plot_block_954.svg";
import { ReactComponent as LandPlot_30_955 } from "./land_plots/30/land_plot_block_955.svg";
import { ReactComponent as LandPlot_30_956 } from "./land_plots/30/land_plot_block_956.svg";
import { ReactComponent as LandPlot_30_957 } from "./land_plots/30/land_plot_block_957.svg";
import { ReactComponent as LandPlot_30_958 } from "./land_plots/30/land_plot_block_958.svg";
import { ReactComponent as LandPlot_30_959 } from "./land_plots/30/land_plot_block_959.svg";
import { ReactComponent as LandPlot_30_960 } from "./land_plots/30/land_plot_block_960.svg";
import { ReactComponent as LandPlot_30_961 } from "./land_plots/30/land_plot_block_961.svg";
import { ReactComponent as LandPlot_30_962 } from "./land_plots/30/land_plot_block_962.svg";
import { ReactComponent as LandPlot_30_963 } from "./land_plots/30/land_plot_block_963.svg";
import { ReactComponent as LandPlot_30_964 } from "./land_plots/30/land_plot_block_964.svg";
import { ReactComponent as LandPlot_30_965 } from "./land_plots/30/land_plot_block_965.svg";
import { ReactComponent as LandPlot_30_966 } from "./land_plots/30/land_plot_block_966.svg";
import { ReactComponent as LandPlot_30_967 } from "./land_plots/30/land_plot_block_967.svg";
import { ReactComponent as LandPlot_30_968 } from "./land_plots/30/land_plot_block_968.svg";
import { ReactComponent as LandPlot_30_969 } from "./land_plots/30/land_plot_block_969.svg";
import { ReactComponent as LandPlot_30_970 } from "./land_plots/30/land_plot_block_970.svg";
import { ReactComponent as LandPlot_30_971 } from "./land_plots/30/land_plot_block_971.svg";
import { ReactComponent as LandPlot_30_972 } from "./land_plots/30/land_plot_block_972.svg";
import { ReactComponent as LandPlot_30_973 } from "./land_plots/30/land_plot_block_973.svg";
import { ReactComponent as LandPlot_30_974 } from "./land_plots/30/land_plot_block_974.svg";
import { ReactComponent as LandPlot_30_975 } from "./land_plots/30/land_plot_block_975.svg";
import { ReactComponent as LandPlot_30_976 } from "./land_plots/30/land_plot_block_976.svg";
import { ReactComponent as LandPlot_30_977 } from "./land_plots/30/land_plot_block_977.svg";
import { ReactComponent as LandPlot_30_978 } from "./land_plots/30/land_plot_block_978.svg";
import { ReactComponent as LandPlot_30_979 } from "./land_plots/30/land_plot_block_979.svg";
import { ReactComponent as LandPlot_30_980 } from "./land_plots/30/land_plot_block_980.svg";
import { ReactComponent as LandPlot_30_981 } from "./land_plots/30/land_plot_block_981.svg";
import { ReactComponent as LandPlot_30_982 } from "./land_plots/30/land_plot_block_982.svg";
import { ReactComponent as LandPlot_30_983 } from "./land_plots/30/land_plot_block_983.svg";
import { ReactComponent as LandPlot_30_984 } from "./land_plots/30/land_plot_block_984.svg";
import { ReactComponent as LandPlot_30_985 } from "./land_plots/30/land_plot_block_985.svg";
import { ReactComponent as LandPlot_30_986 } from "./land_plots/30/land_plot_block_986.svg";
import { ReactComponent as LandPlot_30_987 } from "./land_plots/30/land_plot_block_987.svg";
import { ReactComponent as LandPlot_30_988 } from "./land_plots/30/land_plot_block_988.svg";
import { ReactComponent as LandPlot_30_989 } from "./land_plots/30/land_plot_block_989.svg";
import { ReactComponent as LandPlot_30_990 } from "./land_plots/30/land_plot_block_990.svg";
import { ReactComponent as LandPlot_30_991 } from "./land_plots/30/land_plot_block_991.svg";
import { ReactComponent as LandPlot_30_992 } from "./land_plots/30/land_plot_block_992.svg";
import { ReactComponent as LandPlot_30_993 } from "./land_plots/30/land_plot_block_993.svg";
import { ReactComponent as LandPlot_30_994 } from "./land_plots/30/land_plot_block_994.svg";
import { ReactComponent as LandPlot_30_995 } from "./land_plots/30/land_plot_block_995.svg";
import { ReactComponent as LandPlot_30_996 } from "./land_plots/30/land_plot_block_996.svg";
import { ReactComponent as LandPlot_30_997 } from "./land_plots/30/land_plot_block_997.svg";
import { ReactComponent as LandPlot_30_998 } from "./land_plots/30/land_plot_block_998.svg";
import { ReactComponent as LandPlot_30_999 } from "./land_plots/30/land_plot_block_999.svg";
import { ReactComponent as LandPlot_30_1000 } from "./land_plots/30/land_plot_block_1000.svg";
import { ReactComponent as LandPlot_30_1001 } from "./land_plots/30/land_plot_block_1001.svg";
import { ReactComponent as LandPlot_30_1002 } from "./land_plots/30/land_plot_block_1002.svg";
import { ReactComponent as LandPlot_30_1003 } from "./land_plots/30/land_plot_block_1003.svg";
import { ReactComponent as LandPlot_30_1004 } from "./land_plots/30/land_plot_block_1004.svg";
import { ReactComponent as LandPlot_30_1005 } from "./land_plots/30/land_plot_block_1005.svg";
import { ReactComponent as LandPlot_30_1006 } from "./land_plots/30/land_plot_block_1006.svg";
import { ReactComponent as LandPlot_30_1007 } from "./land_plots/30/land_plot_block_1007.svg";
import { ReactComponent as LandPlot_30_1008 } from "./land_plots/30/land_plot_block_1008.svg";
import { ReactComponent as LandPlot_30_1009 } from "./land_plots/30/land_plot_block_1009.svg";
import { ReactComponent as LandPlot_30_1010 } from "./land_plots/30/land_plot_block_1010.svg";
import { ReactComponent as LandPlot_30_1011 } from "./land_plots/30/land_plot_block_1011.svg";
import { ReactComponent as LandPlot_30_1012 } from "./land_plots/30/land_plot_block_1012.svg";
import { ReactComponent as LandPlot_30_1013 } from "./land_plots/30/land_plot_block_1013.svg";
import { ReactComponent as LandPlot_30_1014 } from "./land_plots/30/land_plot_block_1014.svg";
import { ReactComponent as LandPlot_30_1015 } from "./land_plots/30/land_plot_block_1015.svg";
import { ReactComponent as LandPlot_30_1016 } from "./land_plots/30/land_plot_block_1016.svg";
import { ReactComponent as LandPlot_30_1017 } from "./land_plots/30/land_plot_block_1017.svg";
import { ReactComponent as LandPlot_30_1018 } from "./land_plots/30/land_plot_block_1018.svg";
import { ReactComponent as LandPlot_30_1019 } from "./land_plots/30/land_plot_block_1019.svg";
import { ReactComponent as LandPlot_30_1020 } from "./land_plots/30/land_plot_block_1020.svg";
import { ReactComponent as LandPlot_30_1021 } from "./land_plots/30/land_plot_block_1021.svg";
import { ReactComponent as LandPlot_30_1022 } from "./land_plots/30/land_plot_block_1022.svg";
import { ReactComponent as LandPlot_30_1023 } from "./land_plots/30/land_plot_block_1023.svg";
import { ReactComponent as LandPlot_30_1024 } from "./land_plots/30/land_plot_block_1024.svg";
import { ReactComponent as LandPlot_30_1025 } from "./land_plots/30/land_plot_block_1025.svg";
import { ReactComponent as LandPlot_30_1026 } from "./land_plots/30/land_plot_block_1026.svg";
import { ReactComponent as LandPlot_30_1027 } from "./land_plots/30/land_plot_block_1027.svg";
import { ReactComponent as LandPlot_30_1028 } from "./land_plots/30/land_plot_block_1028.svg";
import { ReactComponent as LandPlot_30_1029 } from "./land_plots/30/land_plot_block_1029.svg";
import { ReactComponent as LandPlot_30_1030 } from "./land_plots/30/land_plot_block_1030.svg";
import { ReactComponent as LandPlot_30_1031 } from "./land_plots/30/land_plot_block_1031.svg";
import { ReactComponent as LandPlot_30_1032 } from "./land_plots/30/land_plot_block_1032.svg";
import { ReactComponent as LandPlot_30_1033 } from "./land_plots/30/land_plot_block_1033.svg";
import { ReactComponent as LandPlot_30_1034 } from "./land_plots/30/land_plot_block_1034.svg";
import { ReactComponent as LandPlot_30_1035 } from "./land_plots/30/land_plot_block_1035.svg";
import { ReactComponent as LandPlot_30_1036 } from "./land_plots/30/land_plot_block_1036.svg";
import { ReactComponent as LandPlot_30_1037 } from "./land_plots/30/land_plot_block_1037.svg";
import { ReactComponent as LandPlot_30_1038 } from "./land_plots/30/land_plot_block_1038.svg";
import { ReactComponent as LandPlot_30_1039 } from "./land_plots/30/land_plot_block_1039.svg";
import { ReactComponent as LandPlot_30_1040 } from "./land_plots/30/land_plot_block_1040.svg";
import { ReactComponent as LandPlot_30_1041 } from "./land_plots/30/land_plot_block_1041.svg";
import { ReactComponent as LandPlot_30_1042 } from "./land_plots/30/land_plot_block_1042.svg";
import { ReactComponent as LandPlot_30_1043 } from "./land_plots/30/land_plot_block_1043.svg";
import { ReactComponent as LandPlot_30_1044 } from "./land_plots/30/land_plot_block_1044.svg";
import { ReactComponent as LandPlot_30_1045 } from "./land_plots/30/land_plot_block_1045.svg";
import { ReactComponent as LandPlot_30_1046 } from "./land_plots/30/land_plot_block_1046.svg";
import { ReactComponent as LandPlot_30_1047 } from "./land_plots/30/land_plot_block_1047.svg";
import { ReactComponent as LandPlot_30_1048 } from "./land_plots/30/land_plot_block_1048.svg";
import { ReactComponent as LandPlot_30_1049 } from "./land_plots/30/land_plot_block_1049.svg";
import { ReactComponent as LandPlot_30_1050 } from "./land_plots/30/land_plot_block_1050.svg";
import { ReactComponent as LandPlot_30_1051 } from "./land_plots/30/land_plot_block_1051.svg";
import { ReactComponent as LandPlot_30_1052 } from "./land_plots/30/land_plot_block_1052.svg";
import { ReactComponent as LandPlot_30_1053 } from "./land_plots/30/land_plot_block_1053.svg";
import { ReactComponent as LandPlot_30_1054 } from "./land_plots/30/land_plot_block_1054.svg";
import { ReactComponent as LandPlot_30_1055 } from "./land_plots/30/land_plot_block_1055.svg";
import { ReactComponent as LandPlot_30_1056 } from "./land_plots/30/land_plot_block_1056.svg";
import { ReactComponent as LandPlot_30_1057 } from "./land_plots/30/land_plot_block_1057.svg";
import { ReactComponent as LandPlot_30_1058 } from "./land_plots/30/land_plot_block_1058.svg";
import { ReactComponent as LandPlot_30_1059 } from "./land_plots/30/land_plot_block_1059.svg";
import { ReactComponent as LandPlot_30_1060 } from "./land_plots/30/land_plot_block_1060.svg";
import { ReactComponent as LandPlot_30_1061 } from "./land_plots/30/land_plot_block_1061.svg";
import { ReactComponent as LandPlot_30_1062 } from "./land_plots/30/land_plot_block_1062.svg";
import { ReactComponent as LandPlot_30_1063 } from "./land_plots/30/land_plot_block_1063.svg";
import { ReactComponent as LandPlot_30_1064 } from "./land_plots/30/land_plot_block_1064.svg";
import { ReactComponent as LandPlot_30_1065 } from "./land_plots/30/land_plot_block_1065.svg";
import { ReactComponent as LandPlot_30_1066 } from "./land_plots/30/land_plot_block_1066.svg";
import { ReactComponent as LandPlot_30_1067 } from "./land_plots/30/land_plot_block_1067.svg";
import { ReactComponent as LandPlot_30_1068 } from "./land_plots/30/land_plot_block_1068.svg";
import { ReactComponent as LandPlot_30_1069 } from "./land_plots/30/land_plot_block_1069.svg";
import { ReactComponent as LandPlot_30_1070 } from "./land_plots/30/land_plot_block_1070.svg";
import { ReactComponent as LandPlot_30_1071 } from "./land_plots/30/land_plot_block_1071.svg";
import { ReactComponent as LandPlot_30_1072 } from "./land_plots/30/land_plot_block_1072.svg";
import { ReactComponent as LandPlot_30_1073 } from "./land_plots/30/land_plot_block_1073.svg";
import { ReactComponent as LandPlot_30_1074 } from "./land_plots/30/land_plot_block_1074.svg";
import { ReactComponent as LandPlot_30_1075 } from "./land_plots/30/land_plot_block_1075.svg";
import { ReactComponent as LandPlot_30_1076 } from "./land_plots/30/land_plot_block_1076.svg";
import { ReactComponent as LandPlot_30_1077 } from "./land_plots/30/land_plot_block_1077.svg";
import { ReactComponent as LandPlot_30_1078 } from "./land_plots/30/land_plot_block_1078.svg";
import { ReactComponent as LandPlot_30_1079 } from "./land_plots/30/land_plot_block_1079.svg";
import { ReactComponent as LandPlot_30_1080 } from "./land_plots/30/land_plot_block_1080.svg";
import { ReactComponent as LandPlot_30_1081 } from "./land_plots/30/land_plot_block_1081.svg";
import { ReactComponent as LandPlot_30_1082 } from "./land_plots/30/land_plot_block_1082.svg";
import { ReactComponent as LandPlot_30_1083 } from "./land_plots/30/land_plot_block_1083.svg";
import { ReactComponent as LandPlot_30_1084 } from "./land_plots/30/land_plot_block_1084.svg";
import { ReactComponent as LandPlot_30_1085 } from "./land_plots/30/land_plot_block_1085.svg";
import { ReactComponent as LandPlot_30_1086 } from "./land_plots/30/land_plot_block_1086.svg";
import { ReactComponent as LandPlot_30_1087 } from "./land_plots/30/land_plot_block_1087.svg";
import { ReactComponent as LandPlot_30_1088 } from "./land_plots/30/land_plot_block_1088.svg";
import { ReactComponent as LandPlot_30_1089 } from "./land_plots/30/land_plot_block_1089.svg";
import { ReactComponent as LandPlot_30_1090 } from "./land_plots/30/land_plot_block_1090.svg";
import { ReactComponent as LandPlot_30_1091 } from "./land_plots/30/land_plot_block_1091.svg";
import { ReactComponent as LandPlot_30_1092 } from "./land_plots/30/land_plot_block_1092.svg";
import { ReactComponent as LandPlot_30_1093 } from "./land_plots/30/land_plot_block_1093.svg";
import { ReactComponent as LandPlot_30_1094 } from "./land_plots/30/land_plot_block_1094.svg";
import { ReactComponent as LandPlot_30_1095 } from "./land_plots/30/land_plot_block_1095.svg";
import { ReactComponent as LandPlot_30_1096 } from "./land_plots/30/land_plot_block_1096.svg";
import { ReactComponent as LandPlot_30_1097 } from "./land_plots/30/land_plot_block_1097.svg";
import { ReactComponent as LandPlot_30_1098 } from "./land_plots/30/land_plot_block_1098.svg";
import { ReactComponent as LandPlot_30_1099 } from "./land_plots/30/land_plot_block_1099.svg";
import { ReactComponent as LandPlot_30_1100 } from "./land_plots/30/land_plot_block_1100.svg";
import { ReactComponent as LandPlot_30_1101 } from "./land_plots/30/land_plot_block_1101.svg";
import { ReactComponent as LandPlot_30_1102 } from "./land_plots/30/land_plot_block_1102.svg";
import { ReactComponent as LandPlot_30_1103 } from "./land_plots/30/land_plot_block_1103.svg";
import { ReactComponent as LandPlot_30_1104 } from "./land_plots/30/land_plot_block_1104.svg";
import { ReactComponent as LandPlot_30_1105 } from "./land_plots/30/land_plot_block_1105.svg";
import { ReactComponent as LandPlot_30_1106 } from "./land_plots/30/land_plot_block_1106.svg";
import { ReactComponent as LandPlot_30_1107 } from "./land_plots/30/land_plot_block_1107.svg";
import { ReactComponent as LandPlot_30_1108 } from "./land_plots/30/land_plot_block_1108.svg";
import { ReactComponent as LandPlot_30_1109 } from "./land_plots/30/land_plot_block_1109.svg";
import { ReactComponent as LandPlot_30_1110 } from "./land_plots/30/land_plot_block_1110.svg";
import { ReactComponent as LandPlot_30_1111 } from "./land_plots/30/land_plot_block_1111.svg";
import { ReactComponent as LandPlot_30_1112 } from "./land_plots/30/land_plot_block_1112.svg";
import { ReactComponent as LandPlot_30_1113 } from "./land_plots/30/land_plot_block_1113.svg";
import { ReactComponent as LandPlot_30_1114 } from "./land_plots/30/land_plot_block_1114.svg";
import { ReactComponent as LandPlot_30_1115 } from "./land_plots/30/land_plot_block_1115.svg";
import { ReactComponent as LandPlot_30_1116 } from "./land_plots/30/land_plot_block_1116.svg";
import { ReactComponent as LandPlot_30_1117 } from "./land_plots/30/land_plot_block_1117.svg";
import { ReactComponent as LandPlot_30_1118 } from "./land_plots/30/land_plot_block_1118.svg";
import { ReactComponent as LandPlot_30_1119 } from "./land_plots/30/land_plot_block_1119.svg";
import { ReactComponent as LandPlot_30_1120 } from "./land_plots/30/land_plot_block_1120.svg";
import { ReactComponent as LandPlot_30_1121 } from "./land_plots/30/land_plot_block_1121.svg";
import { ReactComponent as LandPlot_30_1122 } from "./land_plots/30/land_plot_block_1122.svg";
import { ReactComponent as LandPlot_30_1123 } from "./land_plots/30/land_plot_block_1123.svg";
import { ReactComponent as LandPlot_30_1124 } from "./land_plots/30/land_plot_block_1124.svg";
import { ReactComponent as LandPlot_30_1125 } from "./land_plots/30/land_plot_block_1125.svg";
import { ReactComponent as LandPlot_30_1126 } from "./land_plots/30/land_plot_block_1126.svg";
import { ReactComponent as LandPlot_30_1127 } from "./land_plots/30/land_plot_block_1127.svg";
import { ReactComponent as LandPlot_30_1128 } from "./land_plots/30/land_plot_block_1128.svg";
import { ReactComponent as LandPlot_30_1129 } from "./land_plots/30/land_plot_block_1129.svg";
import { ReactComponent as LandPlot_30_1130 } from "./land_plots/30/land_plot_block_1130.svg";
import { ReactComponent as LandPlot_30_1131 } from "./land_plots/30/land_plot_block_1131.svg";
import { ReactComponent as LandPlot_30_1132 } from "./land_plots/30/land_plot_block_1132.svg";
import { ReactComponent as LandPlot_30_1133 } from "./land_plots/30/land_plot_block_1133.svg";
import { ReactComponent as LandPlot_30_1134 } from "./land_plots/30/land_plot_block_1134.svg";
import { ReactComponent as LandPlot_30_1135 } from "./land_plots/30/land_plot_block_1135.svg";
import { ReactComponent as LandPlot_30_1136 } from "./land_plots/30/land_plot_block_1136.svg";
import { ReactComponent as LandPlot_30_1137 } from "./land_plots/30/land_plot_block_1137.svg";
import { ReactComponent as LandPlot_30_1138 } from "./land_plots/30/land_plot_block_1138.svg";
import { ReactComponent as LandPlot_30_1139 } from "./land_plots/30/land_plot_block_1139.svg";
import { ReactComponent as LandPlot_30_1140 } from "./land_plots/30/land_plot_block_1140.svg";
import { ReactComponent as LandPlot_30_1141 } from "./land_plots/30/land_plot_block_1141.svg";
import { ReactComponent as LandPlot_30_1142 } from "./land_plots/30/land_plot_block_1142.svg";
import { ReactComponent as LandPlot_30_1143 } from "./land_plots/30/land_plot_block_1143.svg";
import { ReactComponent as LandPlot_30_1144 } from "./land_plots/30/land_plot_block_1144.svg";
import { ReactComponent as LandPlot_30_1145 } from "./land_plots/30/land_plot_block_1145.svg";
import { ReactComponent as LandPlot_30_1146 } from "./land_plots/30/land_plot_block_1146.svg";
import { ReactComponent as LandPlot_30_1147 } from "./land_plots/30/land_plot_block_1147.svg";
import { ReactComponent as LandPlot_30_1148 } from "./land_plots/30/land_plot_block_1148.svg";
import { ReactComponent as LandPlot_30_1149 } from "./land_plots/30/land_plot_block_1149.svg";
import { ReactComponent as LandPlot_30_1150 } from "./land_plots/30/land_plot_block_1150.svg";
import { ReactComponent as LandPlot_30_1151 } from "./land_plots/30/land_plot_block_1151.svg";
import { ReactComponent as LandPlot_30_1152 } from "./land_plots/30/land_plot_block_1152.svg";
import { ReactComponent as LandPlot_30_1153 } from "./land_plots/30/land_plot_block_1153.svg";
import { ReactComponent as LandPlot_30_1154 } from "./land_plots/30/land_plot_block_1154.svg";
import { ReactComponent as LandPlot_30_1155 } from "./land_plots/30/land_plot_block_1155.svg";
import { ReactComponent as LandPlot_30_1156 } from "./land_plots/30/land_plot_block_1156.svg";
import { ReactComponent as LandPlot_30_1157 } from "./land_plots/30/land_plot_block_1157.svg";
import { ReactComponent as LandPlot_30_1158 } from "./land_plots/30/land_plot_block_1158.svg";
import { ReactComponent as LandPlot_30_1159 } from "./land_plots/30/land_plot_block_1159.svg";
import { ReactComponent as LandPlot_30_1160 } from "./land_plots/30/land_plot_block_1160.svg";
import { ReactComponent as LandPlot_30_1161 } from "./land_plots/30/land_plot_block_1161.svg";
import { ReactComponent as LandPlot_30_1162 } from "./land_plots/30/land_plot_block_1162.svg";
import { ReactComponent as LandPlot_30_1163 } from "./land_plots/30/land_plot_block_1163.svg";

/////////////////////////////////////////////////////////////////////////////////////////stop frame 60
import { ReactComponent as LandPlot_60_681 } from "./land_plots/60/land_plot_block_681.svg";
import { ReactComponent as LandPlot_60_682 } from "./land_plots/60/land_plot_block_682.svg";
import { ReactComponent as LandPlot_60_683 } from "./land_plots/60/land_plot_block_683.svg";
import { ReactComponent as LandPlot_60_684 } from "./land_plots/60/land_plot_block_684.svg";
import { ReactComponent as LandPlot_60_685 } from "./land_plots/60/land_plot_block_685.svg";
import { ReactComponent as LandPlot_60_686 } from "./land_plots/60/land_plot_block_686.svg";
import { ReactComponent as LandPlot_60_687 } from "./land_plots/60/land_plot_block_687.svg";
import { ReactComponent as LandPlot_60_688 } from "./land_plots/60/land_plot_block_688.svg";
import { ReactComponent as LandPlot_60_689 } from "./land_plots/60/land_plot_block_689.svg";
import { ReactComponent as LandPlot_60_690 } from "./land_plots/60/land_plot_block_690.svg";
import { ReactComponent as LandPlot_60_691 } from "./land_plots/60/land_plot_block_691.svg";
import { ReactComponent as LandPlot_60_692 } from "./land_plots/60/land_plot_block_692.svg";
import { ReactComponent as LandPlot_60_693 } from "./land_plots/60/land_plot_block_693.svg";
import { ReactComponent as LandPlot_60_694 } from "./land_plots/60/land_plot_block_694.svg";
import { ReactComponent as LandPlot_60_695 } from "./land_plots/60/land_plot_block_695.svg";
import { ReactComponent as LandPlot_60_696 } from "./land_plots/60/land_plot_block_696.svg";
import { ReactComponent as LandPlot_60_697 } from "./land_plots/60/land_plot_block_697.svg";
import { ReactComponent as LandPlot_60_698 } from "./land_plots/60/land_plot_block_698.svg";
import { ReactComponent as LandPlot_60_699 } from "./land_plots/60/land_plot_block_699.svg";
import { ReactComponent as LandPlot_60_700 } from "./land_plots/60/land_plot_block_700.svg";
import { ReactComponent as LandPlot_60_701 } from "./land_plots/60/land_plot_block_701.svg";
import { ReactComponent as LandPlot_60_702 } from "./land_plots/60/land_plot_block_702.svg";
import { ReactComponent as LandPlot_60_703 } from "./land_plots/60/land_plot_block_703.svg";
import { ReactComponent as LandPlot_60_704 } from "./land_plots/60/land_plot_block_704.svg";
import { ReactComponent as LandPlot_60_705 } from "./land_plots/60/land_plot_block_705.svg";
import { ReactComponent as LandPlot_60_706 } from "./land_plots/60/land_plot_block_706.svg";
import { ReactComponent as LandPlot_60_707 } from "./land_plots/60/land_plot_block_707.svg";
import { ReactComponent as LandPlot_60_708 } from "./land_plots/60/land_plot_block_708.svg";
import { ReactComponent as LandPlot_60_709 } from "./land_plots/60/land_plot_block_709.svg";
import { ReactComponent as LandPlot_60_710 } from "./land_plots/60/land_plot_block_710.svg";
import { ReactComponent as LandPlot_60_711 } from "./land_plots/60/land_plot_block_711.svg";
import { ReactComponent as LandPlot_60_712 } from "./land_plots/60/land_plot_block_712.svg";
import { ReactComponent as LandPlot_60_713 } from "./land_plots/60/land_plot_block_713.svg";
import { ReactComponent as LandPlot_60_714 } from "./land_plots/60/land_plot_block_714.svg";
import { ReactComponent as LandPlot_60_715 } from "./land_plots/60/land_plot_block_715.svg";
import { ReactComponent as LandPlot_60_716 } from "./land_plots/60/land_plot_block_716.svg";
import { ReactComponent as LandPlot_60_717 } from "./land_plots/60/land_plot_block_717.svg";
import { ReactComponent as LandPlot_60_718 } from "./land_plots/60/land_plot_block_718.svg";
import { ReactComponent as LandPlot_60_719 } from "./land_plots/60/land_plot_block_719.svg";
import { ReactComponent as LandPlot_60_720 } from "./land_plots/60/land_plot_block_720.svg";
import { ReactComponent as LandPlot_60_721 } from "./land_plots/60/land_plot_block_721.svg";
import { ReactComponent as LandPlot_60_722 } from "./land_plots/60/land_plot_block_722.svg";
import { ReactComponent as LandPlot_60_723 } from "./land_plots/60/land_plot_block_723.svg";
import { ReactComponent as LandPlot_60_724 } from "./land_plots/60/land_plot_block_724.svg";
import { ReactComponent as LandPlot_60_725 } from "./land_plots/60/land_plot_block_725.svg";
import { ReactComponent as LandPlot_60_726 } from "./land_plots/60/land_plot_block_726.svg";
import { ReactComponent as LandPlot_60_727 } from "./land_plots/60/land_plot_block_727.svg";
import { ReactComponent as LandPlot_60_728 } from "./land_plots/60/land_plot_block_728.svg";
import { ReactComponent as LandPlot_60_729 } from "./land_plots/60/land_plot_block_729.svg";
import { ReactComponent as LandPlot_60_730 } from "./land_plots/60/land_plot_block_730.svg";
import { ReactComponent as LandPlot_60_731 } from "./land_plots/60/land_plot_block_731.svg";
import { ReactComponent as LandPlot_60_732 } from "./land_plots/60/land_plot_block_732.svg";
import { ReactComponent as LandPlot_60_733 } from "./land_plots/60/land_plot_block_733.svg";
import { ReactComponent as LandPlot_60_734 } from "./land_plots/60/land_plot_block_734.svg";
import { ReactComponent as LandPlot_60_735 } from "./land_plots/60/land_plot_block_735.svg";
import { ReactComponent as LandPlot_60_736 } from "./land_plots/60/land_plot_block_736.svg";
import { ReactComponent as LandPlot_60_737 } from "./land_plots/60/land_plot_block_737.svg";
import { ReactComponent as LandPlot_60_738 } from "./land_plots/60/land_plot_block_738.svg";
import { ReactComponent as LandPlot_60_739 } from "./land_plots/60/land_plot_block_739.svg";
import { ReactComponent as LandPlot_60_740 } from "./land_plots/60/land_plot_block_740.svg";
import { ReactComponent as LandPlot_60_741 } from "./land_plots/60/land_plot_block_741.svg";
import { ReactComponent as LandPlot_60_742 } from "./land_plots/60/land_plot_block_742.svg";
import { ReactComponent as LandPlot_60_743 } from "./land_plots/60/land_plot_block_743.svg";
import { ReactComponent as LandPlot_60_744 } from "./land_plots/60/land_plot_block_744.svg";
import { ReactComponent as LandPlot_60_745 } from "./land_plots/60/land_plot_block_745.svg";
import { ReactComponent as LandPlot_60_746 } from "./land_plots/60/land_plot_block_746.svg";
import { ReactComponent as LandPlot_60_747 } from "./land_plots/60/land_plot_block_747.svg";
import { ReactComponent as LandPlot_60_748 } from "./land_plots/60/land_plot_block_748.svg";
import { ReactComponent as LandPlot_60_749 } from "./land_plots/60/land_plot_block_749.svg";
import { ReactComponent as LandPlot_60_750 } from "./land_plots/60/land_plot_block_750.svg";
import { ReactComponent as LandPlot_60_751 } from "./land_plots/60/land_plot_block_751.svg";
import { ReactComponent as LandPlot_60_752 } from "./land_plots/60/land_plot_block_752.svg";
import { ReactComponent as LandPlot_60_753 } from "./land_plots/60/land_plot_block_753.svg";
import { ReactComponent as LandPlot_60_754 } from "./land_plots/60/land_plot_block_754.svg";
import { ReactComponent as LandPlot_60_755 } from "./land_plots/60/land_plot_block_755.svg";
import { ReactComponent as LandPlot_60_756 } from "./land_plots/60/land_plot_block_756.svg";
import { ReactComponent as LandPlot_60_757 } from "./land_plots/60/land_plot_block_757.svg";
import { ReactComponent as LandPlot_60_758 } from "./land_plots/60/land_plot_block_758.svg";
import { ReactComponent as LandPlot_60_759 } from "./land_plots/60/land_plot_block_759.svg";
import { ReactComponent as LandPlot_60_760 } from "./land_plots/60/land_plot_block_760.svg";
import { ReactComponent as LandPlot_60_761 } from "./land_plots/60/land_plot_block_761.svg";
import { ReactComponent as LandPlot_60_762 } from "./land_plots/60/land_plot_block_762.svg";
import { ReactComponent as LandPlot_60_763 } from "./land_plots/60/land_plot_block_763.svg";
import { ReactComponent as LandPlot_60_764 } from "./land_plots/60/land_plot_block_764.svg";
import { ReactComponent as LandPlot_60_765 } from "./land_plots/60/land_plot_block_765.svg";
import { ReactComponent as LandPlot_60_766 } from "./land_plots/60/land_plot_block_766.svg";
import { ReactComponent as LandPlot_60_767 } from "./land_plots/60/land_plot_block_767.svg";
import { ReactComponent as LandPlot_60_768 } from "./land_plots/60/land_plot_block_768.svg";
import { ReactComponent as LandPlot_60_769 } from "./land_plots/60/land_plot_block_769.svg";
import { ReactComponent as LandPlot_60_770 } from "./land_plots/60/land_plot_block_770.svg";
import { ReactComponent as LandPlot_60_771 } from "./land_plots/60/land_plot_block_771.svg";
import { ReactComponent as LandPlot_60_772 } from "./land_plots/60/land_plot_block_772.svg";
import { ReactComponent as LandPlot_60_773 } from "./land_plots/60/land_plot_block_773.svg";
import { ReactComponent as LandPlot_60_774 } from "./land_plots/60/land_plot_block_774.svg";
import { ReactComponent as LandPlot_60_775 } from "./land_plots/60/land_plot_block_775.svg";
import { ReactComponent as LandPlot_60_776 } from "./land_plots/60/land_plot_block_776.svg";
import { ReactComponent as LandPlot_60_777 } from "./land_plots/60/land_plot_block_777.svg";
import { ReactComponent as LandPlot_60_778 } from "./land_plots/60/land_plot_block_778.svg";
import { ReactComponent as LandPlot_60_779 } from "./land_plots/60/land_plot_block_779.svg";
import { ReactComponent as LandPlot_60_780 } from "./land_plots/60/land_plot_block_780.svg";
import { ReactComponent as LandPlot_60_781 } from "./land_plots/60/land_plot_block_781.svg";
import { ReactComponent as LandPlot_60_782 } from "./land_plots/60/land_plot_block_782.svg";
import { ReactComponent as LandPlot_60_783 } from "./land_plots/60/land_plot_block_783.svg";
import { ReactComponent as LandPlot_60_784 } from "./land_plots/60/land_plot_block_784.svg";
import { ReactComponent as LandPlot_60_785 } from "./land_plots/60/land_plot_block_785.svg";
import { ReactComponent as LandPlot_60_786 } from "./land_plots/60/land_plot_block_786.svg";
import { ReactComponent as LandPlot_60_787 } from "./land_plots/60/land_plot_block_787.svg";
import { ReactComponent as LandPlot_60_788 } from "./land_plots/60/land_plot_block_788.svg";
import { ReactComponent as LandPlot_60_789 } from "./land_plots/60/land_plot_block_789.svg";
import { ReactComponent as LandPlot_60_790 } from "./land_plots/60/land_plot_block_790.svg";
import { ReactComponent as LandPlot_60_791 } from "./land_plots/60/land_plot_block_791.svg";
import { ReactComponent as LandPlot_60_792 } from "./land_plots/60/land_plot_block_792.svg";
import { ReactComponent as LandPlot_60_793 } from "./land_plots/60/land_plot_block_793.svg";
import { ReactComponent as LandPlot_60_794 } from "./land_plots/60/land_plot_block_794.svg";
import { ReactComponent as LandPlot_60_795 } from "./land_plots/60/land_plot_block_795.svg";
import { ReactComponent as LandPlot_60_796 } from "./land_plots/60/land_plot_block_796.svg";
import { ReactComponent as LandPlot_60_797 } from "./land_plots/60/land_plot_block_797.svg";
import { ReactComponent as LandPlot_60_798 } from "./land_plots/60/land_plot_block_798.svg";
import { ReactComponent as LandPlot_60_799 } from "./land_plots/60/land_plot_block_799.svg";
import { ReactComponent as LandPlot_60_800 } from "./land_plots/60/land_plot_block_800.svg";
import { ReactComponent as LandPlot_60_801 } from "./land_plots/60/land_plot_block_801.svg";
import { ReactComponent as LandPlot_60_802 } from "./land_plots/60/land_plot_block_802.svg";
import { ReactComponent as LandPlot_60_803 } from "./land_plots/60/land_plot_block_803.svg";
import { ReactComponent as LandPlot_60_804 } from "./land_plots/60/land_plot_block_804.svg";
import { ReactComponent as LandPlot_60_805 } from "./land_plots/60/land_plot_block_805.svg";
import { ReactComponent as LandPlot_60_806 } from "./land_plots/60/land_plot_block_806.svg";
import { ReactComponent as LandPlot_60_807 } from "./land_plots/60/land_plot_block_807.svg";
import { ReactComponent as LandPlot_60_808 } from "./land_plots/60/land_plot_block_808.svg";
import { ReactComponent as LandPlot_60_809 } from "./land_plots/60/land_plot_block_809.svg";
import { ReactComponent as LandPlot_60_810 } from "./land_plots/60/land_plot_block_810.svg";
import { ReactComponent as LandPlot_60_811 } from "./land_plots/60/land_plot_block_811.svg";
import { ReactComponent as LandPlot_60_812 } from "./land_plots/60/land_plot_block_812.svg";
import { ReactComponent as LandPlot_60_813 } from "./land_plots/60/land_plot_block_813.svg";
import { ReactComponent as LandPlot_60_814 } from "./land_plots/60/land_plot_block_814.svg";
import { ReactComponent as LandPlot_60_815 } from "./land_plots/60/land_plot_block_815.svg";
import { ReactComponent as LandPlot_60_816 } from "./land_plots/60/land_plot_block_816.svg";
import { ReactComponent as LandPlot_60_817 } from "./land_plots/60/land_plot_block_817.svg";
import { ReactComponent as LandPlot_60_818 } from "./land_plots/60/land_plot_block_818.svg";
import { ReactComponent as LandPlot_60_819 } from "./land_plots/60/land_plot_block_819.svg";
import { ReactComponent as LandPlot_60_820 } from "./land_plots/60/land_plot_block_820.svg";
import { ReactComponent as LandPlot_60_821 } from "./land_plots/60/land_plot_block_821.svg";
import { ReactComponent as LandPlot_60_822 } from "./land_plots/60/land_plot_block_822.svg";
import { ReactComponent as LandPlot_60_823 } from "./land_plots/60/land_plot_block_823.svg";
import { ReactComponent as LandPlot_60_824 } from "./land_plots/60/land_plot_block_824.svg";
import { ReactComponent as LandPlot_60_825 } from "./land_plots/60/land_plot_block_825.svg";
import { ReactComponent as LandPlot_60_826 } from "./land_plots/60/land_plot_block_826.svg";
import { ReactComponent as LandPlot_60_827 } from "./land_plots/60/land_plot_block_827.svg";
import { ReactComponent as LandPlot_60_828 } from "./land_plots/60/land_plot_block_828.svg";
import { ReactComponent as LandPlot_60_829 } from "./land_plots/60/land_plot_block_829.svg";
import { ReactComponent as LandPlot_60_830 } from "./land_plots/60/land_plot_block_830.svg";
import { ReactComponent as LandPlot_60_831 } from "./land_plots/60/land_plot_block_831.svg";
import { ReactComponent as LandPlot_60_832 } from "./land_plots/60/land_plot_block_832.svg";
import { ReactComponent as LandPlot_60_833 } from "./land_plots/60/land_plot_block_833.svg";
import { ReactComponent as LandPlot_60_834 } from "./land_plots/60/land_plot_block_834.svg";
import { ReactComponent as LandPlot_60_835 } from "./land_plots/60/land_plot_block_835.svg";
import { ReactComponent as LandPlot_60_836 } from "./land_plots/60/land_plot_block_836.svg";
import { ReactComponent as LandPlot_60_837 } from "./land_plots/60/land_plot_block_837.svg";
import { ReactComponent as LandPlot_60_838 } from "./land_plots/60/land_plot_block_838.svg";
import { ReactComponent as LandPlot_60_839 } from "./land_plots/60/land_plot_block_839.svg";
import { ReactComponent as LandPlot_60_840 } from "./land_plots/60/land_plot_block_840.svg";
import { ReactComponent as LandPlot_60_841 } from "./land_plots/60/land_plot_block_841.svg";
import { ReactComponent as LandPlot_60_842 } from "./land_plots/60/land_plot_block_842.svg";
import { ReactComponent as LandPlot_60_843 } from "./land_plots/60/land_plot_block_843.svg";
import { ReactComponent as LandPlot_60_844 } from "./land_plots/60/land_plot_block_844.svg";
import { ReactComponent as LandPlot_60_845 } from "./land_plots/60/land_plot_block_845.svg";
import { ReactComponent as LandPlot_60_846 } from "./land_plots/60/land_plot_block_846.svg";
import { ReactComponent as LandPlot_60_847 } from "./land_plots/60/land_plot_block_847.svg";
import { ReactComponent as LandPlot_60_848 } from "./land_plots/60/land_plot_block_848.svg";
import { ReactComponent as LandPlot_60_849 } from "./land_plots/60/land_plot_block_849.svg";
import { ReactComponent as LandPlot_60_850 } from "./land_plots/60/land_plot_block_850.svg";
import { ReactComponent as LandPlot_60_851 } from "./land_plots/60/land_plot_block_851.svg";
import { ReactComponent as LandPlot_60_852 } from "./land_plots/60/land_plot_block_852.svg";
import { ReactComponent as LandPlot_60_853 } from "./land_plots/60/land_plot_block_853.svg";
import { ReactComponent as LandPlot_60_854 } from "./land_plots/60/land_plot_block_854.svg";
import { ReactComponent as LandPlot_60_855 } from "./land_plots/60/land_plot_block_855.svg";
import { ReactComponent as LandPlot_60_856 } from "./land_plots/60/land_plot_block_856.svg";
import { ReactComponent as LandPlot_60_857 } from "./land_plots/60/land_plot_block_857.svg";
import { ReactComponent as LandPlot_60_858 } from "./land_plots/60/land_plot_block_858.svg";
import { ReactComponent as LandPlot_60_859 } from "./land_plots/60/land_plot_block_859.svg";
import { ReactComponent as LandPlot_60_860 } from "./land_plots/60/land_plot_block_860.svg";
import { ReactComponent as LandPlot_60_861 } from "./land_plots/60/land_plot_block_861.svg";
import { ReactComponent as LandPlot_60_862 } from "./land_plots/60/land_plot_block_862.svg";
import { ReactComponent as LandPlot_60_863 } from "./land_plots/60/land_plot_block_863.svg";
import { ReactComponent as LandPlot_60_864 } from "./land_plots/60/land_plot_block_864.svg";
import { ReactComponent as LandPlot_60_865 } from "./land_plots/60/land_plot_block_865.svg";
import { ReactComponent as LandPlot_60_866 } from "./land_plots/60/land_plot_block_866.svg";
import { ReactComponent as LandPlot_60_867 } from "./land_plots/60/land_plot_block_867.svg";
import { ReactComponent as LandPlot_60_868 } from "./land_plots/60/land_plot_block_868.svg";
import { ReactComponent as LandPlot_60_869 } from "./land_plots/60/land_plot_block_869.svg";
import { ReactComponent as LandPlot_60_870 } from "./land_plots/60/land_plot_block_870.svg";
import { ReactComponent as LandPlot_60_871 } from "./land_plots/60/land_plot_block_871.svg";
import { ReactComponent as LandPlot_60_872 } from "./land_plots/60/land_plot_block_872.svg";
import { ReactComponent as LandPlot_60_873 } from "./land_plots/60/land_plot_block_873.svg";
import { ReactComponent as LandPlot_60_874 } from "./land_plots/60/land_plot_block_874.svg";
import { ReactComponent as LandPlot_60_875 } from "./land_plots/60/land_plot_block_875.svg";
import { ReactComponent as LandPlot_60_876 } from "./land_plots/60/land_plot_block_876.svg";
import { ReactComponent as LandPlot_60_877 } from "./land_plots/60/land_plot_block_877.svg";
import { ReactComponent as LandPlot_60_878 } from "./land_plots/60/land_plot_block_878.svg";
import { ReactComponent as LandPlot_60_879 } from "./land_plots/60/land_plot_block_879.svg";
import { ReactComponent as LandPlot_60_880 } from "./land_plots/60/land_plot_block_880.svg";
import { ReactComponent as LandPlot_60_881 } from "./land_plots/60/land_plot_block_881.svg";
import { ReactComponent as LandPlot_60_882 } from "./land_plots/60/land_plot_block_882.svg";
import { ReactComponent as LandPlot_60_883 } from "./land_plots/60/land_plot_block_883.svg";
import { ReactComponent as LandPlot_60_884 } from "./land_plots/60/land_plot_block_884.svg";
import { ReactComponent as LandPlot_60_885 } from "./land_plots/60/land_plot_block_885.svg";
import { ReactComponent as LandPlot_60_886 } from "./land_plots/60/land_plot_block_886.svg";
import { ReactComponent as LandPlot_60_887 } from "./land_plots/60/land_plot_block_887.svg";
import { ReactComponent as LandPlot_60_888 } from "./land_plots/60/land_plot_block_888.svg";
import { ReactComponent as LandPlot_60_889 } from "./land_plots/60/land_plot_block_889.svg";
import { ReactComponent as LandPlot_60_890 } from "./land_plots/60/land_plot_block_890.svg";
import { ReactComponent as LandPlot_60_891 } from "./land_plots/60/land_plot_block_891.svg";
import { ReactComponent as LandPlot_60_892 } from "./land_plots/60/land_plot_block_892.svg";
import { ReactComponent as LandPlot_60_893 } from "./land_plots/60/land_plot_block_893.svg";
import { ReactComponent as LandPlot_60_894 } from "./land_plots/60/land_plot_block_894.svg";
import { ReactComponent as LandPlot_60_895 } from "./land_plots/60/land_plot_block_895.svg";
import { ReactComponent as LandPlot_60_896 } from "./land_plots/60/land_plot_block_896.svg";
import { ReactComponent as LandPlot_60_897 } from "./land_plots/60/land_plot_block_897.svg";
import { ReactComponent as LandPlot_60_898 } from "./land_plots/60/land_plot_block_898.svg";
import { ReactComponent as LandPlot_60_899 } from "./land_plots/60/land_plot_block_899.svg";
import { ReactComponent as LandPlot_60_900 } from "./land_plots/60/land_plot_block_900.svg";
import { ReactComponent as LandPlot_60_901 } from "./land_plots/60/land_plot_block_901.svg";
import { ReactComponent as LandPlot_60_902 } from "./land_plots/60/land_plot_block_902.svg";
import { ReactComponent as LandPlot_60_903 } from "./land_plots/60/land_plot_block_903.svg";
import { ReactComponent as LandPlot_60_904 } from "./land_plots/60/land_plot_block_904.svg";
import { ReactComponent as LandPlot_60_905 } from "./land_plots/60/land_plot_block_905.svg";
import { ReactComponent as LandPlot_60_906 } from "./land_plots/60/land_plot_block_906.svg";
import { ReactComponent as LandPlot_60_907 } from "./land_plots/60/land_plot_block_907.svg";
import { ReactComponent as LandPlot_60_908 } from "./land_plots/60/land_plot_block_908.svg";
import { ReactComponent as LandPlot_60_909 } from "./land_plots/60/land_plot_block_909.svg";
import { ReactComponent as LandPlot_60_910 } from "./land_plots/60/land_plot_block_910.svg";
import { ReactComponent as LandPlot_60_911 } from "./land_plots/60/land_plot_block_911.svg";
import { ReactComponent as LandPlot_60_912 } from "./land_plots/60/land_plot_block_912.svg";
import { ReactComponent as LandPlot_60_913 } from "./land_plots/60/land_plot_block_913.svg";
import { ReactComponent as LandPlot_60_914 } from "./land_plots/60/land_plot_block_914.svg";
import { ReactComponent as LandPlot_60_915 } from "./land_plots/60/land_plot_block_915.svg";
import { ReactComponent as LandPlot_60_916 } from "./land_plots/60/land_plot_block_916.svg";
import { ReactComponent as LandPlot_60_917 } from "./land_plots/60/land_plot_block_917.svg";
import { ReactComponent as LandPlot_60_918 } from "./land_plots/60/land_plot_block_918.svg";
import { ReactComponent as LandPlot_60_919 } from "./land_plots/60/land_plot_block_919.svg";
import { ReactComponent as LandPlot_60_920 } from "./land_plots/60/land_plot_block_920.svg";
import { ReactComponent as LandPlot_60_921 } from "./land_plots/60/land_plot_block_921.svg";
import { ReactComponent as LandPlot_60_922 } from "./land_plots/60/land_plot_block_922.svg";
import { ReactComponent as LandPlot_60_923 } from "./land_plots/60/land_plot_block_923.svg";
import { ReactComponent as LandPlot_60_924 } from "./land_plots/60/land_plot_block_924.svg";
import { ReactComponent as LandPlot_60_925 } from "./land_plots/60/land_plot_block_925.svg";
import { ReactComponent as LandPlot_60_926 } from "./land_plots/60/land_plot_block_926.svg";
import { ReactComponent as LandPlot_60_927 } from "./land_plots/60/land_plot_block_927.svg";
import { ReactComponent as LandPlot_60_928 } from "./land_plots/60/land_plot_block_928.svg";
import { ReactComponent as LandPlot_60_929 } from "./land_plots/60/land_plot_block_929.svg";
import { ReactComponent as LandPlot_60_930 } from "./land_plots/60/land_plot_block_930.svg";
import { ReactComponent as LandPlot_60_931 } from "./land_plots/60/land_plot_block_931.svg";
import { ReactComponent as LandPlot_60_932 } from "./land_plots/60/land_plot_block_932.svg";
import { ReactComponent as LandPlot_60_933 } from "./land_plots/60/land_plot_block_933.svg";
import { ReactComponent as LandPlot_60_934 } from "./land_plots/60/land_plot_block_934.svg";
import { ReactComponent as LandPlot_60_935 } from "./land_plots/60/land_plot_block_935.svg";
import { ReactComponent as LandPlot_60_936 } from "./land_plots/60/land_plot_block_936.svg";
import { ReactComponent as LandPlot_60_937 } from "./land_plots/60/land_plot_block_937.svg";
import { ReactComponent as LandPlot_60_938 } from "./land_plots/60/land_plot_block_938.svg";
import { ReactComponent as LandPlot_60_939 } from "./land_plots/60/land_plot_block_939.svg";
import { ReactComponent as LandPlot_60_940 } from "./land_plots/60/land_plot_block_940.svg";
import { ReactComponent as LandPlot_60_941 } from "./land_plots/60/land_plot_block_941.svg";
import { ReactComponent as LandPlot_60_942 } from "./land_plots/60/land_plot_block_942.svg";
import { ReactComponent as LandPlot_60_943 } from "./land_plots/60/land_plot_block_943.svg";
import { ReactComponent as LandPlot_60_944 } from "./land_plots/60/land_plot_block_944.svg";
import { ReactComponent as LandPlot_60_945 } from "./land_plots/60/land_plot_block_945.svg";
import { ReactComponent as LandPlot_60_946 } from "./land_plots/60/land_plot_block_946.svg";
import { ReactComponent as LandPlot_60_947 } from "./land_plots/60/land_plot_block_947.svg";
import { ReactComponent as LandPlot_60_948 } from "./land_plots/60/land_plot_block_948.svg";
import { ReactComponent as LandPlot_60_949 } from "./land_plots/60/land_plot_block_949.svg";
import { ReactComponent as LandPlot_60_950 } from "./land_plots/60/land_plot_block_950.svg";
import { ReactComponent as LandPlot_60_951 } from "./land_plots/60/land_plot_block_951.svg";
import { ReactComponent as LandPlot_60_952 } from "./land_plots/60/land_plot_block_952.svg";
import { ReactComponent as LandPlot_60_953 } from "./land_plots/60/land_plot_block_953.svg";
import { ReactComponent as LandPlot_60_954 } from "./land_plots/60/land_plot_block_954.svg";
import { ReactComponent as LandPlot_60_955 } from "./land_plots/60/land_plot_block_955.svg";
import { ReactComponent as LandPlot_60_956 } from "./land_plots/60/land_plot_block_956.svg";
import { ReactComponent as LandPlot_60_957 } from "./land_plots/60/land_plot_block_957.svg";
import { ReactComponent as LandPlot_60_958 } from "./land_plots/60/land_plot_block_958.svg";
import { ReactComponent as LandPlot_60_959 } from "./land_plots/60/land_plot_block_959.svg";
import { ReactComponent as LandPlot_60_960 } from "./land_plots/60/land_plot_block_960.svg";
import { ReactComponent as LandPlot_60_961 } from "./land_plots/60/land_plot_block_961.svg";
import { ReactComponent as LandPlot_60_962 } from "./land_plots/60/land_plot_block_962.svg";
import { ReactComponent as LandPlot_60_963 } from "./land_plots/60/land_plot_block_963.svg";
import { ReactComponent as LandPlot_60_964 } from "./land_plots/60/land_plot_block_964.svg";
import { ReactComponent as LandPlot_60_965 } from "./land_plots/60/land_plot_block_965.svg";
import { ReactComponent as LandPlot_60_966 } from "./land_plots/60/land_plot_block_966.svg";
import { ReactComponent as LandPlot_60_967 } from "./land_plots/60/land_plot_block_967.svg";
import { ReactComponent as LandPlot_60_968 } from "./land_plots/60/land_plot_block_968.svg";
import { ReactComponent as LandPlot_60_969 } from "./land_plots/60/land_plot_block_969.svg";
import { ReactComponent as LandPlot_60_970 } from "./land_plots/60/land_plot_block_970.svg";
import { ReactComponent as LandPlot_60_971 } from "./land_plots/60/land_plot_block_971.svg";
import { ReactComponent as LandPlot_60_972 } from "./land_plots/60/land_plot_block_972.svg";
import { ReactComponent as LandPlot_60_973 } from "./land_plots/60/land_plot_block_973.svg";
import { ReactComponent as LandPlot_60_974 } from "./land_plots/60/land_plot_block_974.svg";
import { ReactComponent as LandPlot_60_975 } from "./land_plots/60/land_plot_block_975.svg";
import { ReactComponent as LandPlot_60_976 } from "./land_plots/60/land_plot_block_976.svg";
import { ReactComponent as LandPlot_60_977 } from "./land_plots/60/land_plot_block_977.svg";
import { ReactComponent as LandPlot_60_978 } from "./land_plots/60/land_plot_block_978.svg";
import { ReactComponent as LandPlot_60_979 } from "./land_plots/60/land_plot_block_979.svg";
import { ReactComponent as LandPlot_60_980 } from "./land_plots/60/land_plot_block_980.svg";
import { ReactComponent as LandPlot_60_981 } from "./land_plots/60/land_plot_block_981.svg";
import { ReactComponent as LandPlot_60_982 } from "./land_plots/60/land_plot_block_982.svg";
import { ReactComponent as LandPlot_60_983 } from "./land_plots/60/land_plot_block_983.svg";
import { ReactComponent as LandPlot_60_984 } from "./land_plots/60/land_plot_block_984.svg";
import { ReactComponent as LandPlot_60_985 } from "./land_plots/60/land_plot_block_985.svg";
import { ReactComponent as LandPlot_60_986 } from "./land_plots/60/land_plot_block_986.svg";
import { ReactComponent as LandPlot_60_987 } from "./land_plots/60/land_plot_block_987.svg";
import { ReactComponent as LandPlot_60_988 } from "./land_plots/60/land_plot_block_988.svg";
import { ReactComponent as LandPlot_60_989 } from "./land_plots/60/land_plot_block_989.svg";
import { ReactComponent as LandPlot_60_990 } from "./land_plots/60/land_plot_block_990.svg";
import { ReactComponent as LandPlot_60_991 } from "./land_plots/60/land_plot_block_991.svg";
import { ReactComponent as LandPlot_60_992 } from "./land_plots/60/land_plot_block_992.svg";
import { ReactComponent as LandPlot_60_993 } from "./land_plots/60/land_plot_block_993.svg";
import { ReactComponent as LandPlot_60_994 } from "./land_plots/60/land_plot_block_994.svg";
import { ReactComponent as LandPlot_60_995 } from "./land_plots/60/land_plot_block_995.svg";
import { ReactComponent as LandPlot_60_996 } from "./land_plots/60/land_plot_block_996.svg";
import { ReactComponent as LandPlot_60_997 } from "./land_plots/60/land_plot_block_997.svg";
import { ReactComponent as LandPlot_60_998 } from "./land_plots/60/land_plot_block_998.svg";
import { ReactComponent as LandPlot_60_999 } from "./land_plots/60/land_plot_block_999.svg";
import { ReactComponent as LandPlot_60_1000 } from "./land_plots/60/land_plot_block_1000.svg";
import { ReactComponent as LandPlot_60_1001 } from "./land_plots/60/land_plot_block_1001.svg";
import { ReactComponent as LandPlot_60_1002 } from "./land_plots/60/land_plot_block_1002.svg";
import { ReactComponent as LandPlot_60_1003 } from "./land_plots/60/land_plot_block_1003.svg";
import { ReactComponent as LandPlot_60_1004 } from "./land_plots/60/land_plot_block_1004.svg";
import { ReactComponent as LandPlot_60_1005 } from "./land_plots/60/land_plot_block_1005.svg";
import { ReactComponent as LandPlot_60_1006 } from "./land_plots/60/land_plot_block_1006.svg";
import { ReactComponent as LandPlot_60_1007 } from "./land_plots/60/land_plot_block_1007.svg";
import { ReactComponent as LandPlot_60_1008 } from "./land_plots/60/land_plot_block_1008.svg";
import { ReactComponent as LandPlot_60_1009 } from "./land_plots/60/land_plot_block_1009.svg";
import { ReactComponent as LandPlot_60_1010 } from "./land_plots/60/land_plot_block_1010.svg";
import { ReactComponent as LandPlot_60_1011 } from "./land_plots/60/land_plot_block_1011.svg";
import { ReactComponent as LandPlot_60_1012 } from "./land_plots/60/land_plot_block_1012.svg";
import { ReactComponent as LandPlot_60_1013 } from "./land_plots/60/land_plot_block_1013.svg";
import { ReactComponent as LandPlot_60_1014 } from "./land_plots/60/land_plot_block_1014.svg";
import { ReactComponent as LandPlot_60_1015 } from "./land_plots/60/land_plot_block_1015.svg";
import { ReactComponent as LandPlot_60_1016 } from "./land_plots/60/land_plot_block_1016.svg";
import { ReactComponent as LandPlot_60_1017 } from "./land_plots/60/land_plot_block_1017.svg";
import { ReactComponent as LandPlot_60_1018 } from "./land_plots/60/land_plot_block_1018.svg";
import { ReactComponent as LandPlot_60_1019 } from "./land_plots/60/land_plot_block_1019.svg";
import { ReactComponent as LandPlot_60_1020 } from "./land_plots/60/land_plot_block_1020.svg";
import { ReactComponent as LandPlot_60_1021 } from "./land_plots/60/land_plot_block_1021.svg";
import { ReactComponent as LandPlot_60_1022 } from "./land_plots/60/land_plot_block_1022.svg";
import { ReactComponent as LandPlot_60_1023 } from "./land_plots/60/land_plot_block_1023.svg";
import { ReactComponent as LandPlot_60_1024 } from "./land_plots/60/land_plot_block_1024.svg";
import { ReactComponent as LandPlot_60_1025 } from "./land_plots/60/land_plot_block_1025.svg";
import { ReactComponent as LandPlot_60_1026 } from "./land_plots/60/land_plot_block_1026.svg";
import { ReactComponent as LandPlot_60_1027 } from "./land_plots/60/land_plot_block_1027.svg";
import { ReactComponent as LandPlot_60_1028 } from "./land_plots/60/land_plot_block_1028.svg";
import { ReactComponent as LandPlot_60_1029 } from "./land_plots/60/land_plot_block_1029.svg";
import { ReactComponent as LandPlot_60_1030 } from "./land_plots/60/land_plot_block_1030.svg";
import { ReactComponent as LandPlot_60_1031 } from "./land_plots/60/land_plot_block_1031.svg";
import { ReactComponent as LandPlot_60_1032 } from "./land_plots/60/land_plot_block_1032.svg";
import { ReactComponent as LandPlot_60_1033 } from "./land_plots/60/land_plot_block_1033.svg";
import { ReactComponent as LandPlot_60_1034 } from "./land_plots/60/land_plot_block_1034.svg";
import { ReactComponent as LandPlot_60_1035 } from "./land_plots/60/land_plot_block_1035.svg";
import { ReactComponent as LandPlot_60_1036 } from "./land_plots/60/land_plot_block_1036.svg";
import { ReactComponent as LandPlot_60_1037 } from "./land_plots/60/land_plot_block_1037.svg";
import { ReactComponent as LandPlot_60_1038 } from "./land_plots/60/land_plot_block_1038.svg";
import { ReactComponent as LandPlot_60_1039 } from "./land_plots/60/land_plot_block_1039.svg";
import { ReactComponent as LandPlot_60_1040 } from "./land_plots/60/land_plot_block_1040.svg";
import { ReactComponent as LandPlot_60_1041 } from "./land_plots/60/land_plot_block_1041.svg";
import { ReactComponent as LandPlot_60_1042 } from "./land_plots/60/land_plot_block_1042.svg";
import { ReactComponent as LandPlot_60_1043 } from "./land_plots/60/land_plot_block_1043.svg";
import { ReactComponent as LandPlot_60_1044 } from "./land_plots/60/land_plot_block_1044.svg";
import { ReactComponent as LandPlot_60_1045 } from "./land_plots/60/land_plot_block_1045.svg";
import { ReactComponent as LandPlot_60_1046 } from "./land_plots/60/land_plot_block_1046.svg";
import { ReactComponent as LandPlot_60_1047 } from "./land_plots/60/land_plot_block_1047.svg";
import { ReactComponent as LandPlot_60_1048 } from "./land_plots/60/land_plot_block_1048.svg";
import { ReactComponent as LandPlot_60_1049 } from "./land_plots/60/land_plot_block_1049.svg";
import { ReactComponent as LandPlot_60_1050 } from "./land_plots/60/land_plot_block_1050.svg";
import { ReactComponent as LandPlot_60_1051 } from "./land_plots/60/land_plot_block_1051.svg";
import { ReactComponent as LandPlot_60_1052 } from "./land_plots/60/land_plot_block_1052.svg";
import { ReactComponent as LandPlot_60_1053 } from "./land_plots/60/land_plot_block_1053.svg";
import { ReactComponent as LandPlot_60_1054 } from "./land_plots/60/land_plot_block_1054.svg";
import { ReactComponent as LandPlot_60_1055 } from "./land_plots/60/land_plot_block_1055.svg";
import { ReactComponent as LandPlot_60_1056 } from "./land_plots/60/land_plot_block_1056.svg";
import { ReactComponent as LandPlot_60_1057 } from "./land_plots/60/land_plot_block_1057.svg";
import { ReactComponent as LandPlot_60_1058 } from "./land_plots/60/land_plot_block_1058.svg";
import { ReactComponent as LandPlot_60_1059 } from "./land_plots/60/land_plot_block_1059.svg";
import { ReactComponent as LandPlot_60_1060 } from "./land_plots/60/land_plot_block_1060.svg";
import { ReactComponent as LandPlot_60_1061 } from "./land_plots/60/land_plot_block_1061.svg";
import { ReactComponent as LandPlot_60_1062 } from "./land_plots/60/land_plot_block_1062.svg";
import { ReactComponent as LandPlot_60_1063 } from "./land_plots/60/land_plot_block_1063.svg";
import { ReactComponent as LandPlot_60_1064 } from "./land_plots/60/land_plot_block_1064.svg";
import { ReactComponent as LandPlot_60_1065 } from "./land_plots/60/land_plot_block_1065.svg";
import { ReactComponent as LandPlot_60_1066 } from "./land_plots/60/land_plot_block_1066.svg";
import { ReactComponent as LandPlot_60_1067 } from "./land_plots/60/land_plot_block_1067.svg";
import { ReactComponent as LandPlot_60_1068 } from "./land_plots/60/land_plot_block_1068.svg";
import { ReactComponent as LandPlot_60_1069 } from "./land_plots/60/land_plot_block_1069.svg";
import { ReactComponent as LandPlot_60_1070 } from "./land_plots/60/land_plot_block_1070.svg";
import { ReactComponent as LandPlot_60_1071 } from "./land_plots/60/land_plot_block_1071.svg";
import { ReactComponent as LandPlot_60_1072 } from "./land_plots/60/land_plot_block_1072.svg";
import { ReactComponent as LandPlot_60_1073 } from "./land_plots/60/land_plot_block_1073.svg";
import { ReactComponent as LandPlot_60_1074 } from "./land_plots/60/land_plot_block_1074.svg";
import { ReactComponent as LandPlot_60_1075 } from "./land_plots/60/land_plot_block_1075.svg";
import { ReactComponent as LandPlot_60_1076 } from "./land_plots/60/land_plot_block_1076.svg";
import { ReactComponent as LandPlot_60_1077 } from "./land_plots/60/land_plot_block_1077.svg";
import { ReactComponent as LandPlot_60_1078 } from "./land_plots/60/land_plot_block_1078.svg";
import { ReactComponent as LandPlot_60_1079 } from "./land_plots/60/land_plot_block_1079.svg";
import { ReactComponent as LandPlot_60_1080 } from "./land_plots/60/land_plot_block_1080.svg";
import { ReactComponent as LandPlot_60_1081 } from "./land_plots/60/land_plot_block_1081.svg";
import { ReactComponent as LandPlot_60_1082 } from "./land_plots/60/land_plot_block_1082.svg";
import { ReactComponent as LandPlot_60_1083 } from "./land_plots/60/land_plot_block_1083.svg";
import { ReactComponent as LandPlot_60_1084 } from "./land_plots/60/land_plot_block_1084.svg";
import { ReactComponent as LandPlot_60_1085 } from "./land_plots/60/land_plot_block_1085.svg";
import { ReactComponent as LandPlot_60_1086 } from "./land_plots/60/land_plot_block_1086.svg";
import { ReactComponent as LandPlot_60_1087 } from "./land_plots/60/land_plot_block_1087.svg";
import { ReactComponent as LandPlot_60_1088 } from "./land_plots/60/land_plot_block_1088.svg";
import { ReactComponent as LandPlot_60_1089 } from "./land_plots/60/land_plot_block_1089.svg";
import { ReactComponent as LandPlot_60_1090 } from "./land_plots/60/land_plot_block_1090.svg";
import { ReactComponent as LandPlot_60_1091 } from "./land_plots/60/land_plot_block_1091.svg";
import { ReactComponent as LandPlot_60_1092 } from "./land_plots/60/land_plot_block_1092.svg";
import { ReactComponent as LandPlot_60_1093 } from "./land_plots/60/land_plot_block_1093.svg";
import { ReactComponent as LandPlot_60_1094 } from "./land_plots/60/land_plot_block_1094.svg";
import { ReactComponent as LandPlot_60_1095 } from "./land_plots/60/land_plot_block_1095.svg";
import { ReactComponent as LandPlot_60_1096 } from "./land_plots/60/land_plot_block_1096.svg";
import { ReactComponent as LandPlot_60_1097 } from "./land_plots/60/land_plot_block_1097.svg";
import { ReactComponent as LandPlot_60_1098 } from "./land_plots/60/land_plot_block_1098.svg";
import { ReactComponent as LandPlot_60_1099 } from "./land_plots/60/land_plot_block_1099.svg";
import { ReactComponent as LandPlot_60_1100 } from "./land_plots/60/land_plot_block_1100.svg";
import { ReactComponent as LandPlot_60_1101 } from "./land_plots/60/land_plot_block_1101.svg";
import { ReactComponent as LandPlot_60_1102 } from "./land_plots/60/land_plot_block_1102.svg";
import { ReactComponent as LandPlot_60_1103 } from "./land_plots/60/land_plot_block_1103.svg";
import { ReactComponent as LandPlot_60_1104 } from "./land_plots/60/land_plot_block_1104.svg";
import { ReactComponent as LandPlot_60_1105 } from "./land_plots/60/land_plot_block_1105.svg";
import { ReactComponent as LandPlot_60_1106 } from "./land_plots/60/land_plot_block_1106.svg";
import { ReactComponent as LandPlot_60_1107 } from "./land_plots/60/land_plot_block_1107.svg";
import { ReactComponent as LandPlot_60_1108 } from "./land_plots/60/land_plot_block_1108.svg";
import { ReactComponent as LandPlot_60_1109 } from "./land_plots/60/land_plot_block_1109.svg";
import { ReactComponent as LandPlot_60_1110 } from "./land_plots/60/land_plot_block_1110.svg";
import { ReactComponent as LandPlot_60_1111 } from "./land_plots/60/land_plot_block_1111.svg";
import { ReactComponent as LandPlot_60_1112 } from "./land_plots/60/land_plot_block_1112.svg";
import { ReactComponent as LandPlot_60_1113 } from "./land_plots/60/land_plot_block_1113.svg";
import { ReactComponent as LandPlot_60_1114 } from "./land_plots/60/land_plot_block_1114.svg";
import { ReactComponent as LandPlot_60_1115 } from "./land_plots/60/land_plot_block_1115.svg";
import { ReactComponent as LandPlot_60_1116 } from "./land_plots/60/land_plot_block_1116.svg";
import { ReactComponent as LandPlot_60_1117 } from "./land_plots/60/land_plot_block_1117.svg";
import { ReactComponent as LandPlot_60_1118 } from "./land_plots/60/land_plot_block_1118.svg";
import { ReactComponent as LandPlot_60_1119 } from "./land_plots/60/land_plot_block_1119.svg";
import { ReactComponent as LandPlot_60_1120 } from "./land_plots/60/land_plot_block_1120.svg";
import { ReactComponent as LandPlot_60_1121 } from "./land_plots/60/land_plot_block_1121.svg";
import { ReactComponent as LandPlot_60_1122 } from "./land_plots/60/land_plot_block_1122.svg";
import { ReactComponent as LandPlot_60_1123 } from "./land_plots/60/land_plot_block_1123.svg";
import { ReactComponent as LandPlot_60_1124 } from "./land_plots/60/land_plot_block_1124.svg";
import { ReactComponent as LandPlot_60_1125 } from "./land_plots/60/land_plot_block_1125.svg";
import { ReactComponent as LandPlot_60_1126 } from "./land_plots/60/land_plot_block_1126.svg";
import { ReactComponent as LandPlot_60_1127 } from "./land_plots/60/land_plot_block_1127.svg";
import { ReactComponent as LandPlot_60_1128 } from "./land_plots/60/land_plot_block_1128.svg";
import { ReactComponent as LandPlot_60_1129 } from "./land_plots/60/land_plot_block_1129.svg";
import { ReactComponent as LandPlot_60_1130 } from "./land_plots/60/land_plot_block_1130.svg";
import { ReactComponent as LandPlot_60_1131 } from "./land_plots/60/land_plot_block_1131.svg";
import { ReactComponent as LandPlot_60_1132 } from "./land_plots/60/land_plot_block_1132.svg";
import { ReactComponent as LandPlot_60_1133 } from "./land_plots/60/land_plot_block_1133.svg";
import { ReactComponent as LandPlot_60_1134 } from "./land_plots/60/land_plot_block_1134.svg";
import { ReactComponent as LandPlot_60_1135 } from "./land_plots/60/land_plot_block_1135.svg";
import { ReactComponent as LandPlot_60_1136 } from "./land_plots/60/land_plot_block_1136.svg";
import { ReactComponent as LandPlot_60_1137 } from "./land_plots/60/land_plot_block_1137.svg";
import { ReactComponent as LandPlot_60_1138 } from "./land_plots/60/land_plot_block_1138.svg";
import { ReactComponent as LandPlot_60_1139 } from "./land_plots/60/land_plot_block_1139.svg";
import { ReactComponent as LandPlot_60_1140 } from "./land_plots/60/land_plot_block_1140.svg";
import { ReactComponent as LandPlot_60_1141 } from "./land_plots/60/land_plot_block_1141.svg";
import { ReactComponent as LandPlot_60_1142 } from "./land_plots/60/land_plot_block_1142.svg";
import { ReactComponent as LandPlot_60_1143 } from "./land_plots/60/land_plot_block_1143.svg";
import { ReactComponent as LandPlot_60_1144 } from "./land_plots/60/land_plot_block_1144.svg";
import { ReactComponent as LandPlot_60_1145 } from "./land_plots/60/land_plot_block_1145.svg";
import { ReactComponent as LandPlot_60_1146 } from "./land_plots/60/land_plot_block_1146.svg";
import { ReactComponent as LandPlot_60_1147 } from "./land_plots/60/land_plot_block_1147.svg";
import { ReactComponent as LandPlot_60_1148 } from "./land_plots/60/land_plot_block_1148.svg";
import { ReactComponent as LandPlot_60_1149 } from "./land_plots/60/land_plot_block_1149.svg";
import { ReactComponent as LandPlot_60_1150 } from "./land_plots/60/land_plot_block_1150.svg";
import { ReactComponent as LandPlot_60_1151 } from "./land_plots/60/land_plot_block_1151.svg";
import { ReactComponent as LandPlot_60_1152 } from "./land_plots/60/land_plot_block_1152.svg";
import { ReactComponent as LandPlot_60_1153 } from "./land_plots/60/land_plot_block_1153.svg";
import { ReactComponent as LandPlot_60_1154 } from "./land_plots/60/land_plot_block_1154.svg";
import { ReactComponent as LandPlot_60_1155 } from "./land_plots/60/land_plot_block_1155.svg";
import { ReactComponent as LandPlot_60_1156 } from "./land_plots/60/land_plot_block_1156.svg";
import { ReactComponent as LandPlot_60_1157 } from "./land_plots/60/land_plot_block_1157.svg";
import { ReactComponent as LandPlot_60_1158 } from "./land_plots/60/land_plot_block_1158.svg";
import { ReactComponent as LandPlot_60_1159 } from "./land_plots/60/land_plot_block_1159.svg";
import { ReactComponent as LandPlot_60_1160 } from "./land_plots/60/land_plot_block_1160.svg";
import { ReactComponent as LandPlot_60_1161 } from "./land_plots/60/land_plot_block_1161.svg";
import { ReactComponent as LandPlot_60_1162 } from "./land_plots/60/land_plot_block_1162.svg";
import { ReactComponent as LandPlot_60_1163 } from "./land_plots/60/land_plot_block_1163.svg";

///////////////////////////////////////////////////////////////////////////////////////stop frame 90
import { ReactComponent as LandPlot_90_681 } from "./land_plots/90/land_plot_block_681.svg";
import { ReactComponent as LandPlot_90_682 } from "./land_plots/90/land_plot_block_682.svg";
import { ReactComponent as LandPlot_90_683 } from "./land_plots/90/land_plot_block_683.svg";
import { ReactComponent as LandPlot_90_684 } from "./land_plots/90/land_plot_block_684.svg";
import { ReactComponent as LandPlot_90_685 } from "./land_plots/90/land_plot_block_685.svg";
import { ReactComponent as LandPlot_90_686 } from "./land_plots/90/land_plot_block_686.svg";
import { ReactComponent as LandPlot_90_687 } from "./land_plots/90/land_plot_block_687.svg";
import { ReactComponent as LandPlot_90_688 } from "./land_plots/90/land_plot_block_688.svg";
import { ReactComponent as LandPlot_90_689 } from "./land_plots/90/land_plot_block_689.svg";
import { ReactComponent as LandPlot_90_690 } from "./land_plots/90/land_plot_block_690.svg";
import { ReactComponent as LandPlot_90_691 } from "./land_plots/90/land_plot_block_691.svg";
import { ReactComponent as LandPlot_90_692 } from "./land_plots/90/land_plot_block_692.svg";
import { ReactComponent as LandPlot_90_693 } from "./land_plots/90/land_plot_block_693.svg";
import { ReactComponent as LandPlot_90_694 } from "./land_plots/90/land_plot_block_694.svg";
import { ReactComponent as LandPlot_90_695 } from "./land_plots/90/land_plot_block_695.svg";
import { ReactComponent as LandPlot_90_696 } from "./land_plots/90/land_plot_block_696.svg";
import { ReactComponent as LandPlot_90_697 } from "./land_plots/90/land_plot_block_697.svg";
import { ReactComponent as LandPlot_90_698 } from "./land_plots/90/land_plot_block_698.svg";
import { ReactComponent as LandPlot_90_699 } from "./land_plots/90/land_plot_block_699.svg";
import { ReactComponent as LandPlot_90_700 } from "./land_plots/90/land_plot_block_700.svg";
import { ReactComponent as LandPlot_90_701 } from "./land_plots/90/land_plot_block_701.svg";
import { ReactComponent as LandPlot_90_702 } from "./land_plots/90/land_plot_block_702.svg";
import { ReactComponent as LandPlot_90_703 } from "./land_plots/90/land_plot_block_703.svg";
import { ReactComponent as LandPlot_90_704 } from "./land_plots/90/land_plot_block_704.svg";
import { ReactComponent as LandPlot_90_705 } from "./land_plots/90/land_plot_block_705.svg";
import { ReactComponent as LandPlot_90_706 } from "./land_plots/90/land_plot_block_706.svg";
import { ReactComponent as LandPlot_90_707 } from "./land_plots/90/land_plot_block_707.svg";
import { ReactComponent as LandPlot_90_708 } from "./land_plots/90/land_plot_block_708.svg";
import { ReactComponent as LandPlot_90_709 } from "./land_plots/90/land_plot_block_709.svg";
import { ReactComponent as LandPlot_90_710 } from "./land_plots/90/land_plot_block_710.svg";
import { ReactComponent as LandPlot_90_711 } from "./land_plots/90/land_plot_block_711.svg";
import { ReactComponent as LandPlot_90_712 } from "./land_plots/90/land_plot_block_712.svg";
import { ReactComponent as LandPlot_90_713 } from "./land_plots/90/land_plot_block_713.svg";
import { ReactComponent as LandPlot_90_714 } from "./land_plots/90/land_plot_block_714.svg";
import { ReactComponent as LandPlot_90_715 } from "./land_plots/90/land_plot_block_715.svg";
import { ReactComponent as LandPlot_90_716 } from "./land_plots/90/land_plot_block_716.svg";
import { ReactComponent as LandPlot_90_717 } from "./land_plots/90/land_plot_block_717.svg";
import { ReactComponent as LandPlot_90_718 } from "./land_plots/90/land_plot_block_718.svg";
import { ReactComponent as LandPlot_90_719 } from "./land_plots/90/land_plot_block_719.svg";
import { ReactComponent as LandPlot_90_720 } from "./land_plots/90/land_plot_block_720.svg";
import { ReactComponent as LandPlot_90_721 } from "./land_plots/90/land_plot_block_721.svg";
import { ReactComponent as LandPlot_90_722 } from "./land_plots/90/land_plot_block_722.svg";
import { ReactComponent as LandPlot_90_723 } from "./land_plots/90/land_plot_block_723.svg";
import { ReactComponent as LandPlot_90_724 } from "./land_plots/90/land_plot_block_724.svg";
import { ReactComponent as LandPlot_90_725 } from "./land_plots/90/land_plot_block_725.svg";
import { ReactComponent as LandPlot_90_726 } from "./land_plots/90/land_plot_block_726.svg";
import { ReactComponent as LandPlot_90_727 } from "./land_plots/90/land_plot_block_727.svg";
import { ReactComponent as LandPlot_90_728 } from "./land_plots/90/land_plot_block_728.svg";
import { ReactComponent as LandPlot_90_729 } from "./land_plots/90/land_plot_block_729.svg";
import { ReactComponent as LandPlot_90_730 } from "./land_plots/90/land_plot_block_730.svg";
import { ReactComponent as LandPlot_90_731 } from "./land_plots/90/land_plot_block_731.svg";
import { ReactComponent as LandPlot_90_732 } from "./land_plots/90/land_plot_block_732.svg";
import { ReactComponent as LandPlot_90_733 } from "./land_plots/90/land_plot_block_733.svg";
import { ReactComponent as LandPlot_90_734 } from "./land_plots/90/land_plot_block_734.svg";
import { ReactComponent as LandPlot_90_735 } from "./land_plots/90/land_plot_block_735.svg";
import { ReactComponent as LandPlot_90_736 } from "./land_plots/90/land_plot_block_736.svg";
import { ReactComponent as LandPlot_90_737 } from "./land_plots/90/land_plot_block_737.svg";
import { ReactComponent as LandPlot_90_738 } from "./land_plots/90/land_plot_block_738.svg";
import { ReactComponent as LandPlot_90_739 } from "./land_plots/90/land_plot_block_739.svg";
import { ReactComponent as LandPlot_90_740 } from "./land_plots/90/land_plot_block_740.svg";
import { ReactComponent as LandPlot_90_741 } from "./land_plots/90/land_plot_block_741.svg";
import { ReactComponent as LandPlot_90_742 } from "./land_plots/90/land_plot_block_742.svg";
import { ReactComponent as LandPlot_90_743 } from "./land_plots/90/land_plot_block_743.svg";
import { ReactComponent as LandPlot_90_744 } from "./land_plots/90/land_plot_block_744.svg";
import { ReactComponent as LandPlot_90_745 } from "./land_plots/90/land_plot_block_745.svg";
import { ReactComponent as LandPlot_90_746 } from "./land_plots/90/land_plot_block_746.svg";
import { ReactComponent as LandPlot_90_747 } from "./land_plots/90/land_plot_block_747.svg";
import { ReactComponent as LandPlot_90_748 } from "./land_plots/90/land_plot_block_748.svg";
import { ReactComponent as LandPlot_90_749 } from "./land_plots/90/land_plot_block_749.svg";
import { ReactComponent as LandPlot_90_750 } from "./land_plots/90/land_plot_block_750.svg";
import { ReactComponent as LandPlot_90_751 } from "./land_plots/90/land_plot_block_751.svg";
import { ReactComponent as LandPlot_90_752 } from "./land_plots/90/land_plot_block_752.svg";
import { ReactComponent as LandPlot_90_753 } from "./land_plots/90/land_plot_block_753.svg";
import { ReactComponent as LandPlot_90_754 } from "./land_plots/90/land_plot_block_754.svg";
import { ReactComponent as LandPlot_90_755 } from "./land_plots/90/land_plot_block_755.svg";
import { ReactComponent as LandPlot_90_756 } from "./land_plots/90/land_plot_block_756.svg";
import { ReactComponent as LandPlot_90_757 } from "./land_plots/90/land_plot_block_757.svg";
import { ReactComponent as LandPlot_90_758 } from "./land_plots/90/land_plot_block_758.svg";
import { ReactComponent as LandPlot_90_759 } from "./land_plots/90/land_plot_block_759.svg";
import { ReactComponent as LandPlot_90_760 } from "./land_plots/90/land_plot_block_760.svg";
import { ReactComponent as LandPlot_90_761 } from "./land_plots/90/land_plot_block_761.svg";
import { ReactComponent as LandPlot_90_762 } from "./land_plots/90/land_plot_block_762.svg";
import { ReactComponent as LandPlot_90_763 } from "./land_plots/90/land_plot_block_763.svg";
import { ReactComponent as LandPlot_90_764 } from "./land_plots/90/land_plot_block_764.svg";
import { ReactComponent as LandPlot_90_765 } from "./land_plots/90/land_plot_block_765.svg";
import { ReactComponent as LandPlot_90_766 } from "./land_plots/90/land_plot_block_766.svg";
import { ReactComponent as LandPlot_90_767 } from "./land_plots/90/land_plot_block_767.svg";
import { ReactComponent as LandPlot_90_768 } from "./land_plots/90/land_plot_block_768.svg";
import { ReactComponent as LandPlot_90_769 } from "./land_plots/90/land_plot_block_769.svg";
import { ReactComponent as LandPlot_90_770 } from "./land_plots/90/land_plot_block_770.svg";
import { ReactComponent as LandPlot_90_771 } from "./land_plots/90/land_plot_block_771.svg";
import { ReactComponent as LandPlot_90_772 } from "./land_plots/90/land_plot_block_772.svg";
import { ReactComponent as LandPlot_90_773 } from "./land_plots/90/land_plot_block_773.svg";
import { ReactComponent as LandPlot_90_774 } from "./land_plots/90/land_plot_block_774.svg";
import { ReactComponent as LandPlot_90_775 } from "./land_plots/90/land_plot_block_775.svg";
import { ReactComponent as LandPlot_90_776 } from "./land_plots/90/land_plot_block_776.svg";
import { ReactComponent as LandPlot_90_777 } from "./land_plots/90/land_plot_block_777.svg";
import { ReactComponent as LandPlot_90_778 } from "./land_plots/90/land_plot_block_778.svg";
import { ReactComponent as LandPlot_90_779 } from "./land_plots/90/land_plot_block_779.svg";
import { ReactComponent as LandPlot_90_780 } from "./land_plots/90/land_plot_block_780.svg";
import { ReactComponent as LandPlot_90_781 } from "./land_plots/90/land_plot_block_781.svg";
import { ReactComponent as LandPlot_90_782 } from "./land_plots/90/land_plot_block_782.svg";
import { ReactComponent as LandPlot_90_783 } from "./land_plots/90/land_plot_block_783.svg";
import { ReactComponent as LandPlot_90_784 } from "./land_plots/90/land_plot_block_784.svg";
import { ReactComponent as LandPlot_90_785 } from "./land_plots/90/land_plot_block_785.svg";
import { ReactComponent as LandPlot_90_786 } from "./land_plots/90/land_plot_block_786.svg";
import { ReactComponent as LandPlot_90_787 } from "./land_plots/90/land_plot_block_787.svg";
import { ReactComponent as LandPlot_90_788 } from "./land_plots/90/land_plot_block_788.svg";
import { ReactComponent as LandPlot_90_789 } from "./land_plots/90/land_plot_block_789.svg";
import { ReactComponent as LandPlot_90_790 } from "./land_plots/90/land_plot_block_790.svg";
import { ReactComponent as LandPlot_90_791 } from "./land_plots/90/land_plot_block_791.svg";
import { ReactComponent as LandPlot_90_792 } from "./land_plots/90/land_plot_block_792.svg";
import { ReactComponent as LandPlot_90_793 } from "./land_plots/90/land_plot_block_793.svg";
import { ReactComponent as LandPlot_90_794 } from "./land_plots/90/land_plot_block_794.svg";
import { ReactComponent as LandPlot_90_795 } from "./land_plots/90/land_plot_block_795.svg";
import { ReactComponent as LandPlot_90_796 } from "./land_plots/90/land_plot_block_796.svg";
import { ReactComponent as LandPlot_90_797 } from "./land_plots/90/land_plot_block_797.svg";
import { ReactComponent as LandPlot_90_798 } from "./land_plots/90/land_plot_block_798.svg";
import { ReactComponent as LandPlot_90_799 } from "./land_plots/90/land_plot_block_799.svg";
import { ReactComponent as LandPlot_90_800 } from "./land_plots/90/land_plot_block_800.svg";
import { ReactComponent as LandPlot_90_801 } from "./land_plots/90/land_plot_block_801.svg";
import { ReactComponent as LandPlot_90_802 } from "./land_plots/90/land_plot_block_802.svg";
import { ReactComponent as LandPlot_90_803 } from "./land_plots/90/land_plot_block_803.svg";
import { ReactComponent as LandPlot_90_804 } from "./land_plots/90/land_plot_block_804.svg";
import { ReactComponent as LandPlot_90_805 } from "./land_plots/90/land_plot_block_805.svg";
import { ReactComponent as LandPlot_90_806 } from "./land_plots/90/land_plot_block_806.svg";
import { ReactComponent as LandPlot_90_807 } from "./land_plots/90/land_plot_block_807.svg";
import { ReactComponent as LandPlot_90_808 } from "./land_plots/90/land_plot_block_808.svg";
import { ReactComponent as LandPlot_90_809 } from "./land_plots/90/land_plot_block_809.svg";
import { ReactComponent as LandPlot_90_810 } from "./land_plots/90/land_plot_block_810.svg";
import { ReactComponent as LandPlot_90_811 } from "./land_plots/90/land_plot_block_811.svg";
import { ReactComponent as LandPlot_90_812 } from "./land_plots/90/land_plot_block_812.svg";
import { ReactComponent as LandPlot_90_813 } from "./land_plots/90/land_plot_block_813.svg";
import { ReactComponent as LandPlot_90_814 } from "./land_plots/90/land_plot_block_814.svg";
import { ReactComponent as LandPlot_90_815 } from "./land_plots/90/land_plot_block_815.svg";
import { ReactComponent as LandPlot_90_816 } from "./land_plots/90/land_plot_block_816.svg";
import { ReactComponent as LandPlot_90_817 } from "./land_plots/90/land_plot_block_817.svg";
import { ReactComponent as LandPlot_90_818 } from "./land_plots/90/land_plot_block_818.svg";
import { ReactComponent as LandPlot_90_819 } from "./land_plots/90/land_plot_block_819.svg";
import { ReactComponent as LandPlot_90_820 } from "./land_plots/90/land_plot_block_820.svg";
import { ReactComponent as LandPlot_90_821 } from "./land_plots/90/land_plot_block_821.svg";
import { ReactComponent as LandPlot_90_822 } from "./land_plots/90/land_plot_block_822.svg";
import { ReactComponent as LandPlot_90_823 } from "./land_plots/90/land_plot_block_823.svg";
import { ReactComponent as LandPlot_90_824 } from "./land_plots/90/land_plot_block_824.svg";
import { ReactComponent as LandPlot_90_825 } from "./land_plots/90/land_plot_block_825.svg";
import { ReactComponent as LandPlot_90_826 } from "./land_plots/90/land_plot_block_826.svg";
import { ReactComponent as LandPlot_90_827 } from "./land_plots/90/land_plot_block_827.svg";
import { ReactComponent as LandPlot_90_828 } from "./land_plots/90/land_plot_block_828.svg";
import { ReactComponent as LandPlot_90_829 } from "./land_plots/90/land_plot_block_829.svg";
import { ReactComponent as LandPlot_90_830 } from "./land_plots/90/land_plot_block_830.svg";
import { ReactComponent as LandPlot_90_831 } from "./land_plots/90/land_plot_block_831.svg";
import { ReactComponent as LandPlot_90_832 } from "./land_plots/90/land_plot_block_832.svg";
import { ReactComponent as LandPlot_90_833 } from "./land_plots/90/land_plot_block_833.svg";
import { ReactComponent as LandPlot_90_834 } from "./land_plots/90/land_plot_block_834.svg";
import { ReactComponent as LandPlot_90_835 } from "./land_plots/90/land_plot_block_835.svg";
import { ReactComponent as LandPlot_90_836 } from "./land_plots/90/land_plot_block_836.svg";
import { ReactComponent as LandPlot_90_837 } from "./land_plots/90/land_plot_block_837.svg";
import { ReactComponent as LandPlot_90_838 } from "./land_plots/90/land_plot_block_838.svg";
import { ReactComponent as LandPlot_90_839 } from "./land_plots/90/land_plot_block_839.svg";
import { ReactComponent as LandPlot_90_840 } from "./land_plots/90/land_plot_block_840.svg";
import { ReactComponent as LandPlot_90_841 } from "./land_plots/90/land_plot_block_841.svg";
import { ReactComponent as LandPlot_90_842 } from "./land_plots/90/land_plot_block_842.svg";
import { ReactComponent as LandPlot_90_843 } from "./land_plots/90/land_plot_block_843.svg";
import { ReactComponent as LandPlot_90_844 } from "./land_plots/90/land_plot_block_844.svg";
import { ReactComponent as LandPlot_90_845 } from "./land_plots/90/land_plot_block_845.svg";
import { ReactComponent as LandPlot_90_846 } from "./land_plots/90/land_plot_block_846.svg";
import { ReactComponent as LandPlot_90_847 } from "./land_plots/90/land_plot_block_847.svg";
import { ReactComponent as LandPlot_90_848 } from "./land_plots/90/land_plot_block_848.svg";
import { ReactComponent as LandPlot_90_849 } from "./land_plots/90/land_plot_block_849.svg";
import { ReactComponent as LandPlot_90_850 } from "./land_plots/90/land_plot_block_850.svg";
import { ReactComponent as LandPlot_90_851 } from "./land_plots/90/land_plot_block_851.svg";
import { ReactComponent as LandPlot_90_852 } from "./land_plots/90/land_plot_block_852.svg";
import { ReactComponent as LandPlot_90_853 } from "./land_plots/90/land_plot_block_853.svg";
import { ReactComponent as LandPlot_90_854 } from "./land_plots/90/land_plot_block_854.svg";
import { ReactComponent as LandPlot_90_855 } from "./land_plots/90/land_plot_block_855.svg";
import { ReactComponent as LandPlot_90_856 } from "./land_plots/90/land_plot_block_856.svg";
import { ReactComponent as LandPlot_90_857 } from "./land_plots/90/land_plot_block_857.svg";
import { ReactComponent as LandPlot_90_858 } from "./land_plots/90/land_plot_block_858.svg";
import { ReactComponent as LandPlot_90_859 } from "./land_plots/90/land_plot_block_859.svg";
import { ReactComponent as LandPlot_90_860 } from "./land_plots/90/land_plot_block_860.svg";
import { ReactComponent as LandPlot_90_861 } from "./land_plots/90/land_plot_block_861.svg";
import { ReactComponent as LandPlot_90_862 } from "./land_plots/90/land_plot_block_862.svg";
import { ReactComponent as LandPlot_90_863 } from "./land_plots/90/land_plot_block_863.svg";
import { ReactComponent as LandPlot_90_864 } from "./land_plots/90/land_plot_block_864.svg";
import { ReactComponent as LandPlot_90_865 } from "./land_plots/90/land_plot_block_865.svg";
import { ReactComponent as LandPlot_90_866 } from "./land_plots/90/land_plot_block_866.svg";
import { ReactComponent as LandPlot_90_867 } from "./land_plots/90/land_plot_block_867.svg";
import { ReactComponent as LandPlot_90_868 } from "./land_plots/90/land_plot_block_868.svg";
import { ReactComponent as LandPlot_90_869 } from "./land_plots/90/land_plot_block_869.svg";
import { ReactComponent as LandPlot_90_870 } from "./land_plots/90/land_plot_block_870.svg";
import { ReactComponent as LandPlot_90_871 } from "./land_plots/90/land_plot_block_871.svg";
import { ReactComponent as LandPlot_90_872 } from "./land_plots/90/land_plot_block_872.svg";
import { ReactComponent as LandPlot_90_873 } from "./land_plots/90/land_plot_block_873.svg";
import { ReactComponent as LandPlot_90_874 } from "./land_plots/90/land_plot_block_874.svg";
import { ReactComponent as LandPlot_90_875 } from "./land_plots/90/land_plot_block_875.svg";
import { ReactComponent as LandPlot_90_876 } from "./land_plots/90/land_plot_block_876.svg";
import { ReactComponent as LandPlot_90_877 } from "./land_plots/90/land_plot_block_877.svg";
import { ReactComponent as LandPlot_90_878 } from "./land_plots/90/land_plot_block_878.svg";
import { ReactComponent as LandPlot_90_879 } from "./land_plots/90/land_plot_block_879.svg";
import { ReactComponent as LandPlot_90_880 } from "./land_plots/90/land_plot_block_880.svg";
import { ReactComponent as LandPlot_90_881 } from "./land_plots/90/land_plot_block_881.svg";
import { ReactComponent as LandPlot_90_882 } from "./land_plots/90/land_plot_block_882.svg";
import { ReactComponent as LandPlot_90_883 } from "./land_plots/90/land_plot_block_883.svg";
import { ReactComponent as LandPlot_90_884 } from "./land_plots/90/land_plot_block_884.svg";
import { ReactComponent as LandPlot_90_885 } from "./land_plots/90/land_plot_block_885.svg";
import { ReactComponent as LandPlot_90_886 } from "./land_plots/90/land_plot_block_886.svg";
import { ReactComponent as LandPlot_90_887 } from "./land_plots/90/land_plot_block_887.svg";
import { ReactComponent as LandPlot_90_888 } from "./land_plots/90/land_plot_block_888.svg";
import { ReactComponent as LandPlot_90_889 } from "./land_plots/90/land_plot_block_889.svg";
import { ReactComponent as LandPlot_90_890 } from "./land_plots/90/land_plot_block_890.svg";
import { ReactComponent as LandPlot_90_891 } from "./land_plots/90/land_plot_block_891.svg";
import { ReactComponent as LandPlot_90_892 } from "./land_plots/90/land_plot_block_892.svg";
import { ReactComponent as LandPlot_90_893 } from "./land_plots/90/land_plot_block_893.svg";
import { ReactComponent as LandPlot_90_894 } from "./land_plots/90/land_plot_block_894.svg";
import { ReactComponent as LandPlot_90_895 } from "./land_plots/90/land_plot_block_895.svg";
import { ReactComponent as LandPlot_90_896 } from "./land_plots/90/land_plot_block_896.svg";
import { ReactComponent as LandPlot_90_897 } from "./land_plots/90/land_plot_block_897.svg";
import { ReactComponent as LandPlot_90_898 } from "./land_plots/90/land_plot_block_898.svg";
import { ReactComponent as LandPlot_90_899 } from "./land_plots/90/land_plot_block_899.svg";
import { ReactComponent as LandPlot_90_900 } from "./land_plots/90/land_plot_block_900.svg";
import { ReactComponent as LandPlot_90_901 } from "./land_plots/90/land_plot_block_901.svg";
import { ReactComponent as LandPlot_90_902 } from "./land_plots/90/land_plot_block_902.svg";
import { ReactComponent as LandPlot_90_903 } from "./land_plots/90/land_plot_block_903.svg";
import { ReactComponent as LandPlot_90_904 } from "./land_plots/90/land_plot_block_904.svg";
import { ReactComponent as LandPlot_90_905 } from "./land_plots/90/land_plot_block_905.svg";
import { ReactComponent as LandPlot_90_906 } from "./land_plots/90/land_plot_block_906.svg";
import { ReactComponent as LandPlot_90_907 } from "./land_plots/90/land_plot_block_907.svg";
import { ReactComponent as LandPlot_90_908 } from "./land_plots/90/land_plot_block_908.svg";
import { ReactComponent as LandPlot_90_909 } from "./land_plots/90/land_plot_block_909.svg";
import { ReactComponent as LandPlot_90_910 } from "./land_plots/90/land_plot_block_910.svg";
import { ReactComponent as LandPlot_90_911 } from "./land_plots/90/land_plot_block_911.svg";
import { ReactComponent as LandPlot_90_912 } from "./land_plots/90/land_plot_block_912.svg";
import { ReactComponent as LandPlot_90_913 } from "./land_plots/90/land_plot_block_913.svg";
import { ReactComponent as LandPlot_90_914 } from "./land_plots/90/land_plot_block_914.svg";
import { ReactComponent as LandPlot_90_915 } from "./land_plots/90/land_plot_block_915.svg";
import { ReactComponent as LandPlot_90_916 } from "./land_plots/90/land_plot_block_916.svg";
import { ReactComponent as LandPlot_90_917 } from "./land_plots/90/land_plot_block_917.svg";
import { ReactComponent as LandPlot_90_918 } from "./land_plots/90/land_plot_block_918.svg";
import { ReactComponent as LandPlot_90_919 } from "./land_plots/90/land_plot_block_919.svg";
import { ReactComponent as LandPlot_90_920 } from "./land_plots/90/land_plot_block_920.svg";
import { ReactComponent as LandPlot_90_921 } from "./land_plots/90/land_plot_block_921.svg";
import { ReactComponent as LandPlot_90_922 } from "./land_plots/90/land_plot_block_922.svg";
import { ReactComponent as LandPlot_90_923 } from "./land_plots/90/land_plot_block_923.svg";
import { ReactComponent as LandPlot_90_924 } from "./land_plots/90/land_plot_block_924.svg";
import { ReactComponent as LandPlot_90_925 } from "./land_plots/90/land_plot_block_925.svg";
import { ReactComponent as LandPlot_90_926 } from "./land_plots/90/land_plot_block_926.svg";
import { ReactComponent as LandPlot_90_927 } from "./land_plots/90/land_plot_block_927.svg";
import { ReactComponent as LandPlot_90_928 } from "./land_plots/90/land_plot_block_928.svg";
import { ReactComponent as LandPlot_90_929 } from "./land_plots/90/land_plot_block_929.svg";
import { ReactComponent as LandPlot_90_930 } from "./land_plots/90/land_plot_block_930.svg";
import { ReactComponent as LandPlot_90_931 } from "./land_plots/90/land_plot_block_931.svg";
import { ReactComponent as LandPlot_90_932 } from "./land_plots/90/land_plot_block_932.svg";
import { ReactComponent as LandPlot_90_933 } from "./land_plots/90/land_plot_block_933.svg";
import { ReactComponent as LandPlot_90_934 } from "./land_plots/90/land_plot_block_934.svg";
import { ReactComponent as LandPlot_90_935 } from "./land_plots/90/land_plot_block_935.svg";
import { ReactComponent as LandPlot_90_936 } from "./land_plots/90/land_plot_block_936.svg";
import { ReactComponent as LandPlot_90_937 } from "./land_plots/90/land_plot_block_937.svg";
import { ReactComponent as LandPlot_90_938 } from "./land_plots/90/land_plot_block_938.svg";
import { ReactComponent as LandPlot_90_939 } from "./land_plots/90/land_plot_block_939.svg";
import { ReactComponent as LandPlot_90_940 } from "./land_plots/90/land_plot_block_940.svg";
import { ReactComponent as LandPlot_90_941 } from "./land_plots/90/land_plot_block_941.svg";
import { ReactComponent as LandPlot_90_942 } from "./land_plots/90/land_plot_block_942.svg";
import { ReactComponent as LandPlot_90_943 } from "./land_plots/90/land_plot_block_943.svg";
import { ReactComponent as LandPlot_90_944 } from "./land_plots/90/land_plot_block_944.svg";
import { ReactComponent as LandPlot_90_945 } from "./land_plots/90/land_plot_block_945.svg";
import { ReactComponent as LandPlot_90_946 } from "./land_plots/90/land_plot_block_946.svg";
import { ReactComponent as LandPlot_90_947 } from "./land_plots/90/land_plot_block_947.svg";
import { ReactComponent as LandPlot_90_948 } from "./land_plots/90/land_plot_block_948.svg";
import { ReactComponent as LandPlot_90_949 } from "./land_plots/90/land_plot_block_949.svg";
import { ReactComponent as LandPlot_90_950 } from "./land_plots/90/land_plot_block_950.svg";
import { ReactComponent as LandPlot_90_951 } from "./land_plots/90/land_plot_block_951.svg";
import { ReactComponent as LandPlot_90_952 } from "./land_plots/90/land_plot_block_952.svg";
import { ReactComponent as LandPlot_90_953 } from "./land_plots/90/land_plot_block_953.svg";
import { ReactComponent as LandPlot_90_954 } from "./land_plots/90/land_plot_block_954.svg";
import { ReactComponent as LandPlot_90_955 } from "./land_plots/90/land_plot_block_955.svg";
import { ReactComponent as LandPlot_90_956 } from "./land_plots/90/land_plot_block_956.svg";
import { ReactComponent as LandPlot_90_957 } from "./land_plots/90/land_plot_block_957.svg";
import { ReactComponent as LandPlot_90_958 } from "./land_plots/90/land_plot_block_958.svg";
import { ReactComponent as LandPlot_90_959 } from "./land_plots/90/land_plot_block_959.svg";
import { ReactComponent as LandPlot_90_960 } from "./land_plots/90/land_plot_block_960.svg";
import { ReactComponent as LandPlot_90_961 } from "./land_plots/90/land_plot_block_961.svg";
import { ReactComponent as LandPlot_90_962 } from "./land_plots/90/land_plot_block_962.svg";
import { ReactComponent as LandPlot_90_963 } from "./land_plots/90/land_plot_block_963.svg";
import { ReactComponent as LandPlot_90_964 } from "./land_plots/90/land_plot_block_964.svg";
import { ReactComponent as LandPlot_90_965 } from "./land_plots/90/land_plot_block_965.svg";
import { ReactComponent as LandPlot_90_966 } from "./land_plots/90/land_plot_block_966.svg";
import { ReactComponent as LandPlot_90_967 } from "./land_plots/90/land_plot_block_967.svg";
import { ReactComponent as LandPlot_90_968 } from "./land_plots/90/land_plot_block_968.svg";
import { ReactComponent as LandPlot_90_969 } from "./land_plots/90/land_plot_block_969.svg";
import { ReactComponent as LandPlot_90_970 } from "./land_plots/90/land_plot_block_970.svg";
import { ReactComponent as LandPlot_90_971 } from "./land_plots/90/land_plot_block_971.svg";
import { ReactComponent as LandPlot_90_972 } from "./land_plots/90/land_plot_block_972.svg";
import { ReactComponent as LandPlot_90_973 } from "./land_plots/90/land_plot_block_973.svg";
import { ReactComponent as LandPlot_90_974 } from "./land_plots/90/land_plot_block_974.svg";
import { ReactComponent as LandPlot_90_975 } from "./land_plots/90/land_plot_block_975.svg";
import { ReactComponent as LandPlot_90_976 } from "./land_plots/90/land_plot_block_976.svg";
import { ReactComponent as LandPlot_90_977 } from "./land_plots/90/land_plot_block_977.svg";
import { ReactComponent as LandPlot_90_978 } from "./land_plots/90/land_plot_block_978.svg";
import { ReactComponent as LandPlot_90_979 } from "./land_plots/90/land_plot_block_979.svg";
import { ReactComponent as LandPlot_90_980 } from "./land_plots/90/land_plot_block_980.svg";
import { ReactComponent as LandPlot_90_981 } from "./land_plots/90/land_plot_block_981.svg";
import { ReactComponent as LandPlot_90_982 } from "./land_plots/90/land_plot_block_982.svg";
import { ReactComponent as LandPlot_90_983 } from "./land_plots/90/land_plot_block_983.svg";
import { ReactComponent as LandPlot_90_984 } from "./land_plots/90/land_plot_block_984.svg";
import { ReactComponent as LandPlot_90_985 } from "./land_plots/90/land_plot_block_985.svg";
import { ReactComponent as LandPlot_90_986 } from "./land_plots/90/land_plot_block_986.svg";
import { ReactComponent as LandPlot_90_987 } from "./land_plots/90/land_plot_block_987.svg";
import { ReactComponent as LandPlot_90_988 } from "./land_plots/90/land_plot_block_988.svg";
import { ReactComponent as LandPlot_90_989 } from "./land_plots/90/land_plot_block_989.svg";
import { ReactComponent as LandPlot_90_990 } from "./land_plots/90/land_plot_block_990.svg";
import { ReactComponent as LandPlot_90_991 } from "./land_plots/90/land_plot_block_991.svg";
import { ReactComponent as LandPlot_90_992 } from "./land_plots/90/land_plot_block_992.svg";
import { ReactComponent as LandPlot_90_993 } from "./land_plots/90/land_plot_block_993.svg";
import { ReactComponent as LandPlot_90_994 } from "./land_plots/90/land_plot_block_994.svg";
import { ReactComponent as LandPlot_90_995 } from "./land_plots/90/land_plot_block_995.svg";
import { ReactComponent as LandPlot_90_996 } from "./land_plots/90/land_plot_block_996.svg";
import { ReactComponent as LandPlot_90_997 } from "./land_plots/90/land_plot_block_997.svg";
import { ReactComponent as LandPlot_90_998 } from "./land_plots/90/land_plot_block_998.svg";
import { ReactComponent as LandPlot_90_999 } from "./land_plots/90/land_plot_block_999.svg";
import { ReactComponent as LandPlot_90_1000 } from "./land_plots/90/land_plot_block_1000.svg";
import { ReactComponent as LandPlot_90_1001 } from "./land_plots/90/land_plot_block_1001.svg";
import { ReactComponent as LandPlot_90_1002 } from "./land_plots/90/land_plot_block_1002.svg";
import { ReactComponent as LandPlot_90_1003 } from "./land_plots/90/land_plot_block_1003.svg";
import { ReactComponent as LandPlot_90_1004 } from "./land_plots/90/land_plot_block_1004.svg";
import { ReactComponent as LandPlot_90_1005 } from "./land_plots/90/land_plot_block_1005.svg";
import { ReactComponent as LandPlot_90_1006 } from "./land_plots/90/land_plot_block_1006.svg";
import { ReactComponent as LandPlot_90_1007 } from "./land_plots/90/land_plot_block_1007.svg";
import { ReactComponent as LandPlot_90_1008 } from "./land_plots/90/land_plot_block_1008.svg";
import { ReactComponent as LandPlot_90_1009 } from "./land_plots/90/land_plot_block_1009.svg";
import { ReactComponent as LandPlot_90_1010 } from "./land_plots/90/land_plot_block_1010.svg";
import { ReactComponent as LandPlot_90_1011 } from "./land_plots/90/land_plot_block_1011.svg";
import { ReactComponent as LandPlot_90_1012 } from "./land_plots/90/land_plot_block_1012.svg";
import { ReactComponent as LandPlot_90_1013 } from "./land_plots/90/land_plot_block_1013.svg";
import { ReactComponent as LandPlot_90_1014 } from "./land_plots/90/land_plot_block_1014.svg";
import { ReactComponent as LandPlot_90_1015 } from "./land_plots/90/land_plot_block_1015.svg";
import { ReactComponent as LandPlot_90_1016 } from "./land_plots/90/land_plot_block_1016.svg";
import { ReactComponent as LandPlot_90_1017 } from "./land_plots/90/land_plot_block_1017.svg";
import { ReactComponent as LandPlot_90_1018 } from "./land_plots/90/land_plot_block_1018.svg";
import { ReactComponent as LandPlot_90_1019 } from "./land_plots/90/land_plot_block_1019.svg";
import { ReactComponent as LandPlot_90_1020 } from "./land_plots/90/land_plot_block_1020.svg";
import { ReactComponent as LandPlot_90_1021 } from "./land_plots/90/land_plot_block_1021.svg";
import { ReactComponent as LandPlot_90_1022 } from "./land_plots/90/land_plot_block_1022.svg";
import { ReactComponent as LandPlot_90_1023 } from "./land_plots/90/land_plot_block_1023.svg";
import { ReactComponent as LandPlot_90_1024 } from "./land_plots/90/land_plot_block_1024.svg";
import { ReactComponent as LandPlot_90_1025 } from "./land_plots/90/land_plot_block_1025.svg";
import { ReactComponent as LandPlot_90_1026 } from "./land_plots/90/land_plot_block_1026.svg";
import { ReactComponent as LandPlot_90_1027 } from "./land_plots/90/land_plot_block_1027.svg";
import { ReactComponent as LandPlot_90_1028 } from "./land_plots/90/land_plot_block_1028.svg";
import { ReactComponent as LandPlot_90_1029 } from "./land_plots/90/land_plot_block_1029.svg";
import { ReactComponent as LandPlot_90_1030 } from "./land_plots/90/land_plot_block_1030.svg";
import { ReactComponent as LandPlot_90_1031 } from "./land_plots/90/land_plot_block_1031.svg";
import { ReactComponent as LandPlot_90_1032 } from "./land_plots/90/land_plot_block_1032.svg";
import { ReactComponent as LandPlot_90_1033 } from "./land_plots/90/land_plot_block_1033.svg";
import { ReactComponent as LandPlot_90_1034 } from "./land_plots/90/land_plot_block_1034.svg";
import { ReactComponent as LandPlot_90_1035 } from "./land_plots/90/land_plot_block_1035.svg";
import { ReactComponent as LandPlot_90_1036 } from "./land_plots/90/land_plot_block_1036.svg";
import { ReactComponent as LandPlot_90_1037 } from "./land_plots/90/land_plot_block_1037.svg";
import { ReactComponent as LandPlot_90_1038 } from "./land_plots/90/land_plot_block_1038.svg";
import { ReactComponent as LandPlot_90_1039 } from "./land_plots/90/land_plot_block_1039.svg";
import { ReactComponent as LandPlot_90_1040 } from "./land_plots/90/land_plot_block_1040.svg";
import { ReactComponent as LandPlot_90_1041 } from "./land_plots/90/land_plot_block_1041.svg";
import { ReactComponent as LandPlot_90_1042 } from "./land_plots/90/land_plot_block_1042.svg";
import { ReactComponent as LandPlot_90_1043 } from "./land_plots/90/land_plot_block_1043.svg";
import { ReactComponent as LandPlot_90_1044 } from "./land_plots/90/land_plot_block_1044.svg";
import { ReactComponent as LandPlot_90_1045 } from "./land_plots/90/land_plot_block_1045.svg";
import { ReactComponent as LandPlot_90_1046 } from "./land_plots/90/land_plot_block_1046.svg";
import { ReactComponent as LandPlot_90_1047 } from "./land_plots/90/land_plot_block_1047.svg";
import { ReactComponent as LandPlot_90_1048 } from "./land_plots/90/land_plot_block_1048.svg";
import { ReactComponent as LandPlot_90_1049 } from "./land_plots/90/land_plot_block_1049.svg";
import { ReactComponent as LandPlot_90_1050 } from "./land_plots/90/land_plot_block_1050.svg";
import { ReactComponent as LandPlot_90_1051 } from "./land_plots/90/land_plot_block_1051.svg";
import { ReactComponent as LandPlot_90_1052 } from "./land_plots/90/land_plot_block_1052.svg";
import { ReactComponent as LandPlot_90_1053 } from "./land_plots/90/land_plot_block_1053.svg";
import { ReactComponent as LandPlot_90_1054 } from "./land_plots/90/land_plot_block_1054.svg";
import { ReactComponent as LandPlot_90_1055 } from "./land_plots/90/land_plot_block_1055.svg";
import { ReactComponent as LandPlot_90_1056 } from "./land_plots/90/land_plot_block_1056.svg";
import { ReactComponent as LandPlot_90_1057 } from "./land_plots/90/land_plot_block_1057.svg";
import { ReactComponent as LandPlot_90_1058 } from "./land_plots/90/land_plot_block_1058.svg";
import { ReactComponent as LandPlot_90_1059 } from "./land_plots/90/land_plot_block_1059.svg";
import { ReactComponent as LandPlot_90_1060 } from "./land_plots/90/land_plot_block_1060.svg";
import { ReactComponent as LandPlot_90_1061 } from "./land_plots/90/land_plot_block_1061.svg";
import { ReactComponent as LandPlot_90_1062 } from "./land_plots/90/land_plot_block_1062.svg";
import { ReactComponent as LandPlot_90_1063 } from "./land_plots/90/land_plot_block_1063.svg";
import { ReactComponent as LandPlot_90_1064 } from "./land_plots/90/land_plot_block_1064.svg";
import { ReactComponent as LandPlot_90_1065 } from "./land_plots/90/land_plot_block_1065.svg";
import { ReactComponent as LandPlot_90_1066 } from "./land_plots/90/land_plot_block_1066.svg";
import { ReactComponent as LandPlot_90_1067 } from "./land_plots/90/land_plot_block_1067.svg";
import { ReactComponent as LandPlot_90_1068 } from "./land_plots/90/land_plot_block_1068.svg";
import { ReactComponent as LandPlot_90_1069 } from "./land_plots/90/land_plot_block_1069.svg";
import { ReactComponent as LandPlot_90_1070 } from "./land_plots/90/land_plot_block_1070.svg";
import { ReactComponent as LandPlot_90_1071 } from "./land_plots/90/land_plot_block_1071.svg";
import { ReactComponent as LandPlot_90_1072 } from "./land_plots/90/land_plot_block_1072.svg";
import { ReactComponent as LandPlot_90_1073 } from "./land_plots/90/land_plot_block_1073.svg";
import { ReactComponent as LandPlot_90_1074 } from "./land_plots/90/land_plot_block_1074.svg";
import { ReactComponent as LandPlot_90_1075 } from "./land_plots/90/land_plot_block_1075.svg";
import { ReactComponent as LandPlot_90_1076 } from "./land_plots/90/land_plot_block_1076.svg";
import { ReactComponent as LandPlot_90_1077 } from "./land_plots/90/land_plot_block_1077.svg";
import { ReactComponent as LandPlot_90_1078 } from "./land_plots/90/land_plot_block_1078.svg";
import { ReactComponent as LandPlot_90_1079 } from "./land_plots/90/land_plot_block_1079.svg";
import { ReactComponent as LandPlot_90_1080 } from "./land_plots/90/land_plot_block_1080.svg";
import { ReactComponent as LandPlot_90_1081 } from "./land_plots/90/land_plot_block_1081.svg";
import { ReactComponent as LandPlot_90_1082 } from "./land_plots/90/land_plot_block_1082.svg";
import { ReactComponent as LandPlot_90_1083 } from "./land_plots/90/land_plot_block_1083.svg";
import { ReactComponent as LandPlot_90_1084 } from "./land_plots/90/land_plot_block_1084.svg";
import { ReactComponent as LandPlot_90_1085 } from "./land_plots/90/land_plot_block_1085.svg";
import { ReactComponent as LandPlot_90_1086 } from "./land_plots/90/land_plot_block_1086.svg";
import { ReactComponent as LandPlot_90_1087 } from "./land_plots/90/land_plot_block_1087.svg";
import { ReactComponent as LandPlot_90_1088 } from "./land_plots/90/land_plot_block_1088.svg";
import { ReactComponent as LandPlot_90_1089 } from "./land_plots/90/land_plot_block_1089.svg";
import { ReactComponent as LandPlot_90_1090 } from "./land_plots/90/land_plot_block_1090.svg";
import { ReactComponent as LandPlot_90_1091 } from "./land_plots/90/land_plot_block_1091.svg";
import { ReactComponent as LandPlot_90_1092 } from "./land_plots/90/land_plot_block_1092.svg";
import { ReactComponent as LandPlot_90_1093 } from "./land_plots/90/land_plot_block_1093.svg";
import { ReactComponent as LandPlot_90_1094 } from "./land_plots/90/land_plot_block_1094.svg";
import { ReactComponent as LandPlot_90_1095 } from "./land_plots/90/land_plot_block_1095.svg";
import { ReactComponent as LandPlot_90_1096 } from "./land_plots/90/land_plot_block_1096.svg";
import { ReactComponent as LandPlot_90_1097 } from "./land_plots/90/land_plot_block_1097.svg";
import { ReactComponent as LandPlot_90_1098 } from "./land_plots/90/land_plot_block_1098.svg";
import { ReactComponent as LandPlot_90_1099 } from "./land_plots/90/land_plot_block_1099.svg";
import { ReactComponent as LandPlot_90_1100 } from "./land_plots/90/land_plot_block_1100.svg";
import { ReactComponent as LandPlot_90_1101 } from "./land_plots/90/land_plot_block_1101.svg";
import { ReactComponent as LandPlot_90_1102 } from "./land_plots/90/land_plot_block_1102.svg";
import { ReactComponent as LandPlot_90_1103 } from "./land_plots/90/land_plot_block_1103.svg";
import { ReactComponent as LandPlot_90_1104 } from "./land_plots/90/land_plot_block_1104.svg";
import { ReactComponent as LandPlot_90_1105 } from "./land_plots/90/land_plot_block_1105.svg";
import { ReactComponent as LandPlot_90_1106 } from "./land_plots/90/land_plot_block_1106.svg";
import { ReactComponent as LandPlot_90_1107 } from "./land_plots/90/land_plot_block_1107.svg";
import { ReactComponent as LandPlot_90_1108 } from "./land_plots/90/land_plot_block_1108.svg";
import { ReactComponent as LandPlot_90_1109 } from "./land_plots/90/land_plot_block_1109.svg";
import { ReactComponent as LandPlot_90_1110 } from "./land_plots/90/land_plot_block_1110.svg";
import { ReactComponent as LandPlot_90_1111 } from "./land_plots/90/land_plot_block_1111.svg";
import { ReactComponent as LandPlot_90_1112 } from "./land_plots/90/land_plot_block_1112.svg";
import { ReactComponent as LandPlot_90_1113 } from "./land_plots/90/land_plot_block_1113.svg";
import { ReactComponent as LandPlot_90_1114 } from "./land_plots/90/land_plot_block_1114.svg";
import { ReactComponent as LandPlot_90_1115 } from "./land_plots/90/land_plot_block_1115.svg";
import { ReactComponent as LandPlot_90_1116 } from "./land_plots/90/land_plot_block_1116.svg";
import { ReactComponent as LandPlot_90_1117 } from "./land_plots/90/land_plot_block_1117.svg";
import { ReactComponent as LandPlot_90_1118 } from "./land_plots/90/land_plot_block_1118.svg";
import { ReactComponent as LandPlot_90_1119 } from "./land_plots/90/land_plot_block_1119.svg";
import { ReactComponent as LandPlot_90_1120 } from "./land_plots/90/land_plot_block_1120.svg";
import { ReactComponent as LandPlot_90_1121 } from "./land_plots/90/land_plot_block_1121.svg";
import { ReactComponent as LandPlot_90_1122 } from "./land_plots/90/land_plot_block_1122.svg";
import { ReactComponent as LandPlot_90_1123 } from "./land_plots/90/land_plot_block_1123.svg";
import { ReactComponent as LandPlot_90_1124 } from "./land_plots/90/land_plot_block_1124.svg";
import { ReactComponent as LandPlot_90_1125 } from "./land_plots/90/land_plot_block_1125.svg";
import { ReactComponent as LandPlot_90_1126 } from "./land_plots/90/land_plot_block_1126.svg";
import { ReactComponent as LandPlot_90_1127 } from "./land_plots/90/land_plot_block_1127.svg";
import { ReactComponent as LandPlot_90_1128 } from "./land_plots/90/land_plot_block_1128.svg";
import { ReactComponent as LandPlot_90_1129 } from "./land_plots/90/land_plot_block_1129.svg";
import { ReactComponent as LandPlot_90_1130 } from "./land_plots/90/land_plot_block_1130.svg";
import { ReactComponent as LandPlot_90_1131 } from "./land_plots/90/land_plot_block_1131.svg";
import { ReactComponent as LandPlot_90_1132 } from "./land_plots/90/land_plot_block_1132.svg";
import { ReactComponent as LandPlot_90_1133 } from "./land_plots/90/land_plot_block_1133.svg";
import { ReactComponent as LandPlot_90_1134 } from "./land_plots/90/land_plot_block_1134.svg";
import { ReactComponent as LandPlot_90_1135 } from "./land_plots/90/land_plot_block_1135.svg";
import { ReactComponent as LandPlot_90_1136 } from "./land_plots/90/land_plot_block_1136.svg";
import { ReactComponent as LandPlot_90_1137 } from "./land_plots/90/land_plot_block_1137.svg";
import { ReactComponent as LandPlot_90_1138 } from "./land_plots/90/land_plot_block_1138.svg";
import { ReactComponent as LandPlot_90_1139 } from "./land_plots/90/land_plot_block_1139.svg";
import { ReactComponent as LandPlot_90_1140 } from "./land_plots/90/land_plot_block_1140.svg";
import { ReactComponent as LandPlot_90_1141 } from "./land_plots/90/land_plot_block_1141.svg";
import { ReactComponent as LandPlot_90_1142 } from "./land_plots/90/land_plot_block_1142.svg";
import { ReactComponent as LandPlot_90_1143 } from "./land_plots/90/land_plot_block_1143.svg";
import { ReactComponent as LandPlot_90_1144 } from "./land_plots/90/land_plot_block_1144.svg";
import { ReactComponent as LandPlot_90_1145 } from "./land_plots/90/land_plot_block_1145.svg";
import { ReactComponent as LandPlot_90_1146 } from "./land_plots/90/land_plot_block_1146.svg";
import { ReactComponent as LandPlot_90_1147 } from "./land_plots/90/land_plot_block_1147.svg";
import { ReactComponent as LandPlot_90_1148 } from "./land_plots/90/land_plot_block_1148.svg";
import { ReactComponent as LandPlot_90_1149 } from "./land_plots/90/land_plot_block_1149.svg";
import { ReactComponent as LandPlot_90_1150 } from "./land_plots/90/land_plot_block_1150.svg";
import { ReactComponent as LandPlot_90_1151 } from "./land_plots/90/land_plot_block_1151.svg";
import { ReactComponent as LandPlot_90_1152 } from "./land_plots/90/land_plot_block_1152.svg";
import { ReactComponent as LandPlot_90_1153 } from "./land_plots/90/land_plot_block_1153.svg";
import { ReactComponent as LandPlot_90_1154 } from "./land_plots/90/land_plot_block_1154.svg";
import { ReactComponent as LandPlot_90_1155 } from "./land_plots/90/land_plot_block_1155.svg";
import { ReactComponent as LandPlot_90_1156 } from "./land_plots/90/land_plot_block_1156.svg";
import { ReactComponent as LandPlot_90_1157 } from "./land_plots/90/land_plot_block_1157.svg";
import { ReactComponent as LandPlot_90_1158 } from "./land_plots/90/land_plot_block_1158.svg";
import { ReactComponent as LandPlot_90_1159 } from "./land_plots/90/land_plot_block_1159.svg";
import { ReactComponent as LandPlot_90_1160 } from "./land_plots/90/land_plot_block_1160.svg";
import { ReactComponent as LandPlot_90_1161 } from "./land_plots/90/land_plot_block_1161.svg";
import { ReactComponent as LandPlot_90_1162 } from "./land_plots/90/land_plot_block_1162.svg";
import { ReactComponent as LandPlot_90_1163 } from "./land_plots/90/land_plot_block_1163.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-1/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-1/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-1/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-1/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-1/icons/2/church_5.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-1/icons/2/hospital_1.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-1/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-1/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-1/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-1/icons/2/school_4.svg";
import { ReactComponent as School_2_5 } from "../../../../static/images/contents/zone-1/icons/2/school_5.svg";
import { ReactComponent as School_2_6 } from "../../../../static/images/contents/zone-1/icons/2/school_6.svg";
import { ReactComponent as School_2_7 } from "../../../../static/images/contents/zone-1/icons/2/school_7.svg";
import { ReactComponent as Museum_2_1 } from "../../../../static/images/contents/zone-1/icons/2/museum_1.svg";
import { ReactComponent as Museum_2_2 } from "../../../../static/images/contents/zone-1/icons/2/museum_2.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-1/icons/2/shopping_1.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-1/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-1/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-1/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-1/icons/33/church_4.svg";
import { ReactComponent as Church_33_5 } from "../../../../static/images/contents/zone-1/icons/33/church_5.svg";
import { ReactComponent as Church_33_6 } from "../../../../static/images/contents/zone-1/icons/33/church_6.svg";
import { ReactComponent as Church_33_7 } from "../../../../static/images/contents/zone-1/icons/33/church_7.svg";
import { ReactComponent as Church_33_8 } from "../../../../static/images/contents/zone-1/icons/33/church_8.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-1/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-1/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-1/icons/33/school_3.svg";
import { ReactComponent as School_33_4 } from "../../../../static/images/contents/zone-1/icons/33/school_4.svg";
import { ReactComponent as School_33_5 } from "../../../../static/images/contents/zone-1/icons/33/school_5.svg";
import { ReactComponent as School_33_6 } from "../../../../static/images/contents/zone-1/icons/33/school_6.svg";
import { ReactComponent as School_33_7 } from "../../../../static/images/contents/zone-1/icons/33/school_7.svg";
import { ReactComponent as Museum_33_2 } from "../../../../static/images/contents/zone-1/icons/33/museum_2.svg";
import { ReactComponent as Shopping_33_1 } from "../../../../static/images/contents/zone-1/icons/33/shopping_1.svg";

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-1/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-1/icons/61/church_2.svg";
import { ReactComponent as Church_61_3 } from "../../../../static/images/contents/zone-1/icons/61/church_3.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-1/icons/61/church_4.svg";
import { ReactComponent as Church_61_5 } from "../../../../static/images/contents/zone-1/icons/61/church_5.svg";
import { ReactComponent as Church_61_6 } from "../../../../static/images/contents/zone-1/icons/61/church_6.svg";
import { ReactComponent as Church_61_7 } from "../../../../static/images/contents/zone-1/icons/61/church_7.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-1/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-1/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-1/icons/61/school_3.svg";
import { ReactComponent as School_61_4 } from "../../../../static/images/contents/zone-1/icons/61/school_4.svg";
import { ReactComponent as School_61_5 } from "../../../../static/images/contents/zone-1/icons/61/school_5.svg";
import { ReactComponent as School_61_6 } from "../../../../static/images/contents/zone-1/icons/61/school_6.svg";
import { ReactComponent as School_61_7 } from "../../../../static/images/contents/zone-1/icons/61/school_7.svg";
import { ReactComponent as School_61_8 } from "../../../../static/images/contents/zone-1/icons/61/school_8.svg";
import { ReactComponent as Museum_61_2 } from "../../../../static/images/contents/zone-1/icons/61/museum_2.svg";
import { ReactComponent as Shopping_61_1 } from "../../../../static/images/contents/zone-1/icons/61/shopping_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-1/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-1/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-1/icons/91/church_3.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-1/icons/91/church_4.svg";
import { ReactComponent as Church_91_5 } from "../../../../static/images/contents/zone-1/icons/91/church_5.svg";
import { ReactComponent as Church_91_6 } from "../../../../static/images/contents/zone-1/icons/91/church_6.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-1/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-1/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-1/icons/91/school_3.svg";
import { ReactComponent as School_91_4 } from "../../../../static/images/contents/zone-1/icons/91/school_4.svg";
import { ReactComponent as School_91_5 } from "../../../../static/images/contents/zone-1/icons/91/school_5.svg";
import { ReactComponent as Museum_91_2 } from "../../../../static/images/contents/zone-1/icons/91/museum_2.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-1/icons/91/shopping_1.svg";

/////////////////////////////////////////////////////////////////////////////////////
export const zone1_land_plots = [
    //index 1
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_1_681,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 681,
                                status: "available",
                                block: 38,
                                unitSize: 873.85,
                                north: "20.41",
                                east: "36.17",
                                south: "29.52",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_682,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 682,
                                status: "sold",
                                block: 38,
                                unitSize: 535.4,
                                north: "14.52",
                                east: "25.01",
                                south: "16.33",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_683,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 683,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_684,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 684,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_685,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 685,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_686,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 686,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_687,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 687,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_688,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 688,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_689,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 689,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_690,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 690,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_691,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 691,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_692,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 692,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_693,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 693,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_694,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 694,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_695,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 695,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_696,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 696,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_697,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 697,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_698,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 698,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_699,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 699,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_700,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 700,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_701,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 701,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_702,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 702,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_703,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 703,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_704,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 704,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_705,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 705,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_706,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 706,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_707,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 707,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_708,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 708,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_709,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 709,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_710,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 710,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_711,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 711,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_712,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 712,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_713,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 713,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_714,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 714,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_715,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 715,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_716,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 716,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_717,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 717,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_718,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 718,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_719,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 719,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_720,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 720,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_721,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 721,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_722,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 722,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_723,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 723,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_724,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 724,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_725,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 725,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_726,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 726,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_727,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 727,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_728,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 728,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_729,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 729,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_730,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 730,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_731,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 731,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_732,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 732,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_733,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 733,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_734,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 734,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_735,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 735,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_736,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 736,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_737,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 737,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_738,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 738,
                                status: "available",
                                block: 39,
                                unitSize: 526.58,
                                north: "14.99",
                                east: "35.0",
                                south: "15.1",
                                west: "34.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_739,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 739,
                                status: "available",
                                block: 39,
                                unitSize: 454.41,
                                north: "15.1",
                                east: "30.0",
                                south: "15.19",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_740,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 740,
                                status: "booked",
                                block: 40,
                                unitSize: 3089.99,
                                north: "62.05",
                                east: "50.0",
                                south: "62.05",
                                west: "50.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_741,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 741,
                                status: "available",
                                block: 41,
                                unitSize: 630.13,
                                north: "18.0",
                                east: "35.0",
                                south: "18.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_742,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 742,
                                status: "available",
                                block: 41,
                                unitSize: 540.11,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_743,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 743,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_744,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 744,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_745,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 745,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_746,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 746,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_747,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 747,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_748,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 748,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_749,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 749,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_750,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 750,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 751,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 752,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 753,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 754,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 755,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.08",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 756,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 757,
                                status: "available",
                                block: 41,
                                unitSize: 518.66,
                                north: "14.92",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 758,
                                status: "available",
                                block: 41,
                                unitSize: 449.92,
                                north: "15.0",
                                east: "30.0",
                                south: "14.51",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 759,
                                status: "available",
                                block: 41,
                                unitSize: 518.78,
                                north: "15.0",
                                east: "35.0",
                                south: "15.26",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 760,
                                status: "available",
                                block: 41,
                                unitSize: 450.02,
                                north: "15.26",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 761,
                                status: "available",
                                block: 41,
                                unitSize: 546.01,
                                north: "15.81",
                                east: "35.0",
                                south: "15.39",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 762,
                                status: "available",
                                block: 41,
                                unitSize: 456.43,
                                north: "15.39",
                                east: "30.0",
                                south: "15.04",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 763,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 764,
                                status: "sold",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 765,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 766,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 767,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 768,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 769,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 770,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 771,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 772,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 773,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 774,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 775,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 776,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 777,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 778,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 779,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 780,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 781,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 782,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 783,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 784,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 785,
                                status: "sold",
                                block: 42,
                                unitSize: 745.02,
                                north: "20.65",
                                east: "35.0",
                                south: "21.93",
                                west: "35.02",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 786,
                                status: "available",
                                block: 42,
                                unitSize: 674.3,
                                north: "21.93",
                                east: "30.0",
                                south: "23.03",
                                west: "30.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 787,
                                status: "available",
                                block: 43,
                                unitSize: 902.71,
                                north: "36.17",
                                east: "21.22",
                                south: "35.0",
                                west: "30.36",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 788,
                                status: "available",
                                block: 43,
                                unitSize: 560.9,
                                north: "25.01",
                                east: "15.36",
                                south: "17.19",
                                west: "7.34",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 789,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 790,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 791,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 792,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 793,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 794,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 795,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 796,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 797,
                                status: "available",
                                block: 43,
                                unitSize: 540.0,
                                north: "30.0",
                                east: "18.0",
                                south: "30.0",
                                west: "18.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 798,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 799,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 800,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 801,
                                status: "available",
                                block: 43,
                                unitSize: 1260.0,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "42.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 802,
                                status: "available",
                                block: 43,
                                unitSize: 759.0,
                                north: "23.0",
                                east: "33.0",
                                south: "23.0",
                                west: "33.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 803,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 804,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 805,
                                status: "available",
                                block: 44,
                                unitSize: 454.1,
                                north: "12.04",
                                east: "29.72",
                                south: "19.53",
                                west: "28.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 806,
                                status: "available",
                                block: 44,
                                unitSize: 658.23,
                                north: "19.53",
                                east: "23.97",
                                south: "21.06",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 807,
                                status: "available",
                                block: 44,
                                unitSize: 425.51,
                                north: "15.0",
                                east: "28.78",
                                south: "15.0",
                                west: "28.79",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 808,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 809,
                                status: "available",
                                block: 44,
                                unitSize: 425.69,
                                north: "15.0",
                                east: "28.79",
                                south: "15.0",
                                west: "28.8",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 810,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 811,
                                status: "available",
                                block: 44,
                                unitSize: 432.11,
                                north: "15.0",
                                east: "28.8",
                                south: "15.0",
                                west: "28.81",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 812,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 813,
                                status: "available",
                                block: 44,
                                unitSize: 432.29,
                                north: "15.0",
                                east: "28.81",
                                south: "15.0",
                                west: "28.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 814,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 815,
                                status: "available",
                                block: 44,
                                unitSize: 432.46,
                                north: "15.0",
                                east: "28.83",
                                south: "15.0",
                                west: "28.84",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 816,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 817,
                                status: "available",
                                block: 44,
                                unitSize: 432.64,
                                north: "15.0",
                                east: "28.84",
                                south: "15.0",
                                west: "28.85",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 818,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 819,
                                status: "available",
                                block: 44,
                                unitSize: 432.81,
                                north: "15.0",
                                east: "28.85",
                                south: "15.0",
                                west: "28.86",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 820,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 821,
                                status: "available",
                                block: 45,
                                unitSize: 433.06,
                                north: "15.0",
                                east: "28.86",
                                south: "15.0",
                                west: "28.88",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 822,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 823,
                                status: "available",
                                block: 45,
                                unitSize: 433.23,
                                north: "15.0",
                                east: "28.88",
                                south: "15.0",
                                west: "28.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 824,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 825,
                                status: "available",
                                block: 45,
                                unitSize: 433.41,
                                north: "15.0",
                                east: "28.89",
                                south: "15.0",
                                west: "28.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 826,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 827,
                                status: "available",
                                block: 45,
                                unitSize: 433.58,
                                north: "15.0",
                                east: "28.9",
                                south: "15.0",
                                west: "28.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 828,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 829,
                                status: "available",
                                block: 45,
                                unitSize: 433.76,
                                north: "15.0",
                                east: "28.91",
                                south: "15.0",
                                west: "28.92",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 830,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 831,
                                status: "available",
                                block: 45,
                                unitSize: 433.94,
                                north: "15.0",
                                east: "28.92",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 832,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 833,
                                status: "available",
                                block: 45,
                                unitSize: 434.11,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "28.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 834,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 835,
                                status: "available",
                                block: 45,
                                unitSize: 434.29,
                                north: "15.0",
                                east: "28.95",
                                south: "15.0",
                                west: "28.96",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 836,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 837,
                                status: "available",
                                block: 45,
                                unitSize: 434.46,
                                north: "15.0",
                                east: "28.96",
                                south: "15.0",
                                west: "28.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 838,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 839,
                                status: "available",
                                block: 45,
                                unitSize: 691.6,
                                north: "28.63",
                                east: "20.97",
                                south: "31.05",
                                west: "15.73",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 840,
                                status: "available",
                                block: 45,
                                unitSize: 490.24,
                                north: "31.05",
                                east: "17.0",
                                south: "26.63",
                                west: "17.57",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 841,
                                status: "available",
                                block: 45,
                                unitSize: 480.46,
                                north: "26.63",
                                east: "20.0",
                                south: "21.42",
                                west: "20.67",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 842,
                                status: "available",
                                block: 46,
                                unitSize: 463.69,
                                north: "28.93",
                                east: "12.92",
                                south: "28.0",
                                west: "20.2",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 843,
                                status: "available",
                                block: 46,
                                unitSize: 476.0,
                                north: "28.0",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 844,
                                status: "available",
                                block: 46,
                                unitSize: 351.83,
                                north: "18.6",
                                east: "17.2",
                                south: "18.0",
                                west: "21.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 845,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 846,
                                status: "available",
                                block: 46,
                                unitSize: 436.16,
                                north: "18.6",
                                east: "21.89",
                                south: "18.0",
                                west: "26.57",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 847,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 848,
                                status: "available",
                                block: 46,
                                unitSize: 352.88,
                                north: "15.5",
                                east: "21.57",
                                south: "15.0",
                                west: "25.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 849,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 850,
                                status: "available",
                                block: 46,
                                unitSize: 405.18,
                                north: "15.5",
                                east: "25.48",
                                south: "15.0",
                                west: "29.38",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 851,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 852,
                                status: "available",
                                block: 46,
                                unitSize: 388.74,
                                north: "15.5",
                                east: "24.38",
                                south: "15.0",
                                west: "28.28",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 853,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 854,
                                status: "available",
                                block: 46,
                                unitSize: 453.55,
                                north: "15.5",
                                east: "28.28",
                                south: "15.0",
                                west: "32.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 855,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 856,
                                status: "available",
                                block: 46,
                                unitSize: 512.11,
                                north: "15.5",
                                east: "32.19",
                                south: "15.0",
                                west: "36.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 857,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 858,
                                status: "available",
                                block: 46,
                                unitSize: 495.67,
                                north: "15.5",
                                east: "31.09",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 859,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 860,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 861,
                                status: "sold",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 862,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 863,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 864,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 865,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 866,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 867,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 868,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 869,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 870,
                                status: "available",
                                block: 47,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 871,
                                status: "available",
                                block: 47,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 872,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 873,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 874,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 875,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 876,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 877,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 878,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 879,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 880,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 881,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 882,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 883,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 884,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 885,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 886,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 887,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 888,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 889,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 890,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 891,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 892,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 893,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 894,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 895,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 896,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 897,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 898,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 899,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 900,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 901,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 902,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 903,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 904,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 905,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 906,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 907,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 908,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 909,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 910,
                                status: "available",
                                block: 50,
                                unitSize: 769.89,
                                north: "25.85",
                                east: "27.5",
                                south: "25.0",
                                west: "34.09",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 911,
                                status: "available",
                                block: 50,
                                unitSize: 673.83,
                                north: "25.02",
                                east: "19.09",
                                south: "30.0",
                                west: "20.99",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 912,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 913,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 914,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 915,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 916,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 917,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 918,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 919,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 920,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 921,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 922,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 923,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 924,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 925,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 926,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 927,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 928,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 929,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 930,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 931,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 932,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 933,
                                status: "available",
                                block: 51,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 934,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 935,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 936,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 937,
                                status: "available",
                                block: 51,
                                unitSize: 875.0,
                                north: "25.0",
                                east: "35.0",
                                south: "25.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 938,
                                status: "available",
                                block: 51,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 939,
                                status: "available",
                                block: 52,
                                unitSize: 698.78,
                                north: "31.02",
                                east: "19.34",
                                south: "30.0",
                                west: "27.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 940,
                                status: "available",
                                block: 52,
                                unitSize: 733.55,
                                north: "19.85",
                                east: "37.24",
                                south: "25.0",
                                west: "27.83",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 941,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 942,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 943,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 944,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 945,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 946,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 947,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 948,
                                status: "available",
                                block: 52,
                                unitSize: 625.0,
                                north: "25.0",
                                east: "25.0",
                                south: "25.0",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 949,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 950,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 951,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 952,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 953,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 954,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 955,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 956,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 957,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 958,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 959,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 960,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 961,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 962,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 963,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 964,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 965,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 966,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 967,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 968,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 969,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 970,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 971,
                                status: "available",
                                block: 53,
                                unitSize: 570.0,
                                north: "30.0",
                                east: "19.0",
                                south: "30.0",
                                west: "19.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 972,
                                status: "available",
                                block: 53,
                                unitSize: 475.0,
                                north: "25.0",
                                east: "19.0",
                                south: "25.0",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 973,
                                status: "sold",
                                block: 54,
                                unitSize: 507.6,
                                north: "14.25",
                                east: "29.31",
                                south: "21.38",
                                west: "28.6",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 974,
                                status: "available",
                                block: 54,
                                unitSize: 724.12,
                                north: "21.38",
                                east: "24.65",
                                south: "22.84",
                                west: "29.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 975,
                                status: "sold",
                                block: 54,
                                unitSize: 430.28,
                                north: "15.0",
                                east: "28.6",
                                south: "15.0",
                                west: "28.77",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 976,
                                status: "available",
                                block: 54,
                                unitSize: 439.44,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 977,
                                status: "available",
                                block: 54,
                                unitSize: 432.89,
                                north: "15.0",
                                east: "28.77",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 978,
                                status: "available",
                                block: 54,
                                unitSize: 436.77,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 979,
                                status: "available",
                                block: 54,
                                unitSize: 434.73,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "29.01",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 980,
                                status: "available",
                                block: 54,
                                unitSize: 435.04,
                                north: "15.0",
                                east: "29.03",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 981,
                                status: "available",
                                block: 54,
                                unitSize: 435.4,
                                north: "15.0",
                                east: "29.01",
                                south: "15.0",
                                west: "29.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 982,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 983,
                                status: "available",
                                block: 54,
                                unitSize: 435.76,
                                north: "15.0",
                                east: "29.04",
                                south: "15.0",
                                west: "29.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 984,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 985,
                                status: "available",
                                block: 54,
                                unitSize: 436.13,
                                north: "15.0",
                                east: "29.06",
                                south: "15.0",
                                west: "29.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 986,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 987,
                                status: "available",
                                block: 54,
                                unitSize: 436.5,
                                north: "15.0",
                                east: "29.09",
                                south: "15.0",
                                west: "29.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 988,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 989,
                                status: "available",
                                block: 54,
                                unitSize: 436.86,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 990,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 991,
                                status: "available",
                                block: 54,
                                unitSize: 437.23,
                                north: "15.0",
                                east: "29.14",
                                south: "15.0",
                                west: "29.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 992,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 993,
                                status: "available",
                                block: 54,
                                unitSize: 437.59,
                                north: "15.0",
                                east: "29.16",
                                south: "15.0",
                                west: "29.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 994,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 995,
                                status: "available",
                                block: 54,
                                unitSize: 431.71,
                                north: "15.0",
                                east: "29.19",
                                south: "15.0",
                                west: "29.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 996,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 997,
                                status: "available",
                                block: 54,
                                unitSize: 467.33,
                                north: "15.67",
                                east: "29.21",
                                south: "16.74",
                                west: "29.25",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 998,
                                status: "sold",
                                block: 54,
                                unitSize: 500.91,
                                north: "16.74",
                                east: "29.0",
                                south: "17.8",
                                west: "29.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 999,
                                status: "available",
                                block: 55,
                                unitSize: 629.06,
                                north: "33.27",
                                east: "15.31",
                                south: "32.18",
                                west: "23.79",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1000,
                                status: "available",
                                block: 55,
                                unitSize: 546.98,
                                north: "32.18",
                                east: "17.0",
                                south: "32.18",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1001,
                                status: "available",
                                block: 55,
                                unitSize: 428.56,
                                north: "20.68",
                                east: "18.79",
                                south: "20.0",
                                west: "24.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1002,
                                status: "available",
                                block: 55,
                                unitSize: 440.0,
                                north: "20.0",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1003,
                                status: "available",
                                block: 55,
                                unitSize: 345.51,
                                north: "15.5",
                                east: "21.06",
                                south: "15.0",
                                west: "24.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1004,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1005,
                                status: "available",
                                block: 55,
                                unitSize: 402.71,
                                north: "15.22",
                                east: "24.97",
                                south: "15.0",
                                west: "28.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1006,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1007,
                                status: "available",
                                block: 55,
                                unitSize: 416.78,
                                north: "9.94",
                                east: "26.73",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1008,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1009,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1010,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1011,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1012,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1013,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1014,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1015,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1016,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1017,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1018,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1019,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1020,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1021,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1022,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1023,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1024,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1025,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1026,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1027,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1028,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1029,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1030,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1031,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1032,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1033,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1034,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1035,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1036,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1037,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1038,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1038,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1039,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1039,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1040,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1040,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1041,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1041,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1042,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1042,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1043,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1043,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1044,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1044,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1045,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1045,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1046,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1046,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1047,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1047,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1048,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1049,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1050,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1051,
                                status: "available",
                                block: 57,
                                unitSize: 526.5,
                                north: "17.43",
                                east: "25.0",
                                south: "24.69",
                                west: "26.03",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1052,
                                status: "available",
                                block: 57,
                                unitSize: 707.91,
                                north: "24.69",
                                east: "25.0",
                                south: "31.94",
                                west: "26.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1053,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1053,
                                status: "available",
                                block: 58,
                                unitSize: 476.0,
                                north: "17.0",
                                east: "28.0",
                                south: "17.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1054,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1054,
                                status: "available",
                                block: 58,
                                unitSize: 459.0,
                                north: "17.0",
                                east: "27.0",
                                south: "17.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1055,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1055,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1056,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1056,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1057,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1057,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1058,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1058,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1059,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1059,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1060,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1060,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1061,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1061,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1062,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1062,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1063,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1063,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1064,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1064,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1065,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1065,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1066,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1066,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1067,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1067,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1068,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1068,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1069,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1069,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1070,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1070,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1071,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1071,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1072,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1072,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1073,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1073,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1074,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1074,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1075,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1075,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1076,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1076,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1077,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1077,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1078,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1078,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1079,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1079,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1080,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1080,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1081,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1081,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1082,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1082,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1083,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1083,
                                status: "available",
                                block: 59,
                                unitSize: 500.98,
                                north: "24.36",
                                east: "19.0",
                                south: "27.0",
                                west: "9.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1084,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1084,
                                status: "available",
                                block: 59,
                                unitSize: 459.0,
                                north: "27.0",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1085,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1085,
                                status: "available",
                                block: 59,
                                unitSize: 513.0,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1086,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1086,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1087,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1087,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1088,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1088,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1089,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1089,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1090,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1090,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1091,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1091,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1092,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1092,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1093,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1093,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1094,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1094,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1095,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1095,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1096,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1096,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1097,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1097,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1098,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1098,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1099,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1099,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1100,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1101,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1102,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1103,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1104,
                                status: "sold",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1105,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1106,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1107,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1108,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1109,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1110,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1111,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1112,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1113,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1114,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1115,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1116,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1117,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1118,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1119,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1120,
                                status: "available",
                                block: 62,
                                unitSize: 579.2,
                                north: "30.02",
                                east: "19.83",
                                south: "30.0",
                                west: "18.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1121,
                                status: "available",
                                block: 62,
                                unitSize: 723.26,
                                north: "40.02",
                                east: "18.78",
                                south: "40.0",
                                west: "17.38",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1122,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1123,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1124,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1125,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1126,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1127,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1128,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1129,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1130,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1131,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1132,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1133,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1134,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1135,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1136,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1137,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1138,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1139,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1140,
                                status: "available",
                                block: 63,
                                unitSize: 1620.0,
                                north: "30.0",
                                east: "54.0",
                                south: "30.0",
                                west: "54.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1141,
                                status: "available",
                                block: 63,
                                unitSize: 880.0,
                                north: "40.0",
                                east: "22.0",
                                south: "40.0",
                                west: "22.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1142,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1143,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1144,
                                status: "available",
                                block: 63,
                                unitSize: 1376.52,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "27.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1145,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1146,
                                status: "available",
                                block: 63,
                                unitSize: 640.64,
                                north: "40.0",
                                east: "16.0",
                                south: "40.25",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1147,
                                status: "available",
                                block: 63,
                                unitSize: 979.58,
                                north: "40.25",
                                east: "18.39",
                                south: "40.0",
                                west: "19.95",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1148,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1149,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1150,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1151,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1152,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1153,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1154,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1155,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1156,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1157,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1158,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1159,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1160,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1161,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1162,
                                status: "sold",
                                block: 63,
                                unitSize: 540.97,
                                north: "30.0",
                                east: "18.03",
                                south: "30.0",
                                west: "18.03",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1163,
                                status: "available",
                                block: 63,
                                unitSize: 721.29,
                                north: "40.0",
                                east: "18.03",
                                south: "40.0",
                                west: "18.03",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////
    //index 30
    {
        index: 31,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_30_681,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 681,
                                status: "available",
                                block: 38,
                                unitSize: 873.85,
                                north: "20.41",
                                east: "36.17",
                                south: "29.52",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_682,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 682,
                                status: "sold",
                                block: 38,
                                unitSize: 535.4,
                                north: "14.52",
                                east: "25.01",
                                south: "16.33",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_683,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 683,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_684,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 684,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_685,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 685,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_686,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 686,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_687,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 687,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_688,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 688,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_689,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 689,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_690,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 690,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_691,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 691,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_692,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 692,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_693,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 693,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_694,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 694,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_695,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 695,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_696,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 696,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_697,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 697,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_698,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 698,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_699,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 699,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_700,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 700,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_701,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 701,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_702,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 702,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_703,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 703,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_704,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 704,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_705,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 705,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_706,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 706,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_707,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 707,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_708,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 708,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_709,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 709,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_710,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 710,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_711,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 711,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_712,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 712,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_713,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 713,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_714,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 714,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_715,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 715,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_716,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 716,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_717,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 717,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_718,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 718,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_719,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 719,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_720,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 720,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_721,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 721,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_722,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 722,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_723,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 723,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_724,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 724,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_725,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 725,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_726,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 726,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_727,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 727,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_728,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 728,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_729,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 729,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_730,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 730,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_731,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 731,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_732,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 732,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_733,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 733,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_734,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 734,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_735,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 735,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_736,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 736,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_737,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 737,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_738,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 738,
                                status: "available",
                                block: 39,
                                unitSize: 526.58,
                                north: "14.99",
                                east: "35.0",
                                south: "15.1",
                                west: "34.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_739,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 739,
                                status: "available",
                                block: 39,
                                unitSize: 454.41,
                                north: "15.1",
                                east: "30.0",
                                south: "15.19",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_740,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 740,
                                status: "booked",
                                block: 40,
                                unitSize: 3089.99,
                                north: "62.05",
                                east: "50.0",
                                south: "62.05",
                                west: "50.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_741,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 741,
                                status: "available",
                                block: 41,
                                unitSize: 630.13,
                                north: "18.0",
                                east: "35.0",
                                south: "18.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_742,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 742,
                                status: "available",
                                block: 41,
                                unitSize: 540.11,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_743,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 743,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_744,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 744,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_745,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 745,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_746,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 746,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_747,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 747,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_748,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 748,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_749,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 749,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_750,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 750,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 751,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 752,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 753,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 754,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 755,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.08",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 756,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 757,
                                status: "available",
                                block: 41,
                                unitSize: 518.66,
                                north: "14.92",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 758,
                                status: "available",
                                block: 41,
                                unitSize: 449.92,
                                north: "15.0",
                                east: "30.0",
                                south: "14.51",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 759,
                                status: "available",
                                block: 41,
                                unitSize: 518.78,
                                north: "15.0",
                                east: "35.0",
                                south: "15.26",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 760,
                                status: "available",
                                block: 41,
                                unitSize: 450.02,
                                north: "15.26",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 761,
                                status: "available",
                                block: 41,
                                unitSize: 546.01,
                                north: "15.81",
                                east: "35.0",
                                south: "15.39",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 762,
                                status: "available",
                                block: 41,
                                unitSize: 456.43,
                                north: "15.39",
                                east: "30.0",
                                south: "15.04",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 763,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 764,
                                status: "sold",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 765,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 766,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 767,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 768,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 769,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 770,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 771,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 772,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 773,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 774,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 775,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 776,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 777,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 778,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 779,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 780,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 781,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 782,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 783,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 784,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 785,
                                status: "sold",
                                block: 42,
                                unitSize: 745.02,
                                north: "20.65",
                                east: "35.0",
                                south: "21.93",
                                west: "35.02",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 786,
                                status: "available",
                                block: 42,
                                unitSize: 674.3,
                                north: "21.93",
                                east: "30.0",
                                south: "23.03",
                                west: "30.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 787,
                                status: "available",
                                block: 43,
                                unitSize: 902.71,
                                north: "36.17",
                                east: "21.22",
                                south: "35.0",
                                west: "30.36",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 788,
                                status: "available",
                                block: 43,
                                unitSize: 560.9,
                                north: "25.01",
                                east: "15.36",
                                south: "17.19",
                                west: "7.34",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 789,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 790,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 791,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 792,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 793,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 794,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 795,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 796,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 797,
                                status: "available",
                                block: 43,
                                unitSize: 540.0,
                                north: "30.0",
                                east: "18.0",
                                south: "30.0",
                                west: "18.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 798,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 799,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 800,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 801,
                                status: "available",
                                block: 43,
                                unitSize: 1260.0,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "42.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 802,
                                status: "available",
                                block: 43,
                                unitSize: 759.0,
                                north: "23.0",
                                east: "33.0",
                                south: "23.0",
                                west: "33.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 803,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 804,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 805,
                                status: "available",
                                block: 44,
                                unitSize: 454.1,
                                north: "12.04",
                                east: "29.72",
                                south: "19.53",
                                west: "28.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 806,
                                status: "available",
                                block: 44,
                                unitSize: 658.23,
                                north: "19.53",
                                east: "23.97",
                                south: "21.06",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 807,
                                status: "available",
                                block: 44,
                                unitSize: 425.51,
                                north: "15.0",
                                east: "28.78",
                                south: "15.0",
                                west: "28.79",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 808,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 809,
                                status: "available",
                                block: 44,
                                unitSize: 425.69,
                                north: "15.0",
                                east: "28.79",
                                south: "15.0",
                                west: "28.8",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 810,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 811,
                                status: "available",
                                block: 44,
                                unitSize: 432.11,
                                north: "15.0",
                                east: "28.8",
                                south: "15.0",
                                west: "28.81",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 812,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 813,
                                status: "available",
                                block: 44,
                                unitSize: 432.29,
                                north: "15.0",
                                east: "28.81",
                                south: "15.0",
                                west: "28.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 814,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 815,
                                status: "available",
                                block: 44,
                                unitSize: 432.46,
                                north: "15.0",
                                east: "28.83",
                                south: "15.0",
                                west: "28.84",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 816,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 817,
                                status: "available",
                                block: 44,
                                unitSize: 432.64,
                                north: "15.0",
                                east: "28.84",
                                south: "15.0",
                                west: "28.85",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 818,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 819,
                                status: "available",
                                block: 44,
                                unitSize: 432.81,
                                north: "15.0",
                                east: "28.85",
                                south: "15.0",
                                west: "28.86",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 820,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 821,
                                status: "available",
                                block: 45,
                                unitSize: 433.06,
                                north: "15.0",
                                east: "28.86",
                                south: "15.0",
                                west: "28.88",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 822,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 823,
                                status: "available",
                                block: 45,
                                unitSize: 433.23,
                                north: "15.0",
                                east: "28.88",
                                south: "15.0",
                                west: "28.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 824,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 825,
                                status: "available",
                                block: 45,
                                unitSize: 433.41,
                                north: "15.0",
                                east: "28.89",
                                south: "15.0",
                                west: "28.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 826,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 827,
                                status: "available",
                                block: 45,
                                unitSize: 433.58,
                                north: "15.0",
                                east: "28.9",
                                south: "15.0",
                                west: "28.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 828,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 829,
                                status: "available",
                                block: 45,
                                unitSize: 433.76,
                                north: "15.0",
                                east: "28.91",
                                south: "15.0",
                                west: "28.92",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 830,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 831,
                                status: "available",
                                block: 45,
                                unitSize: 433.94,
                                north: "15.0",
                                east: "28.92",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 832,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 833,
                                status: "available",
                                block: 45,
                                unitSize: 434.11,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "28.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 834,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 835,
                                status: "available",
                                block: 45,
                                unitSize: 434.29,
                                north: "15.0",
                                east: "28.95",
                                south: "15.0",
                                west: "28.96",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 836,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 837,
                                status: "available",
                                block: 45,
                                unitSize: 434.46,
                                north: "15.0",
                                east: "28.96",
                                south: "15.0",
                                west: "28.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 838,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 839,
                                status: "available",
                                block: 45,
                                unitSize: 691.6,
                                north: "28.63",
                                east: "20.97",
                                south: "31.05",
                                west: "15.73",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 840,
                                status: "available",
                                block: 45,
                                unitSize: 490.24,
                                north: "31.05",
                                east: "17.0",
                                south: "26.63",
                                west: "17.57",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 841,
                                status: "available",
                                block: 45,
                                unitSize: 480.46,
                                north: "26.63",
                                east: "20.0",
                                south: "21.42",
                                west: "20.67",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 842,
                                status: "available",
                                block: 46,
                                unitSize: 463.69,
                                north: "28.93",
                                east: "12.92",
                                south: "28.0",
                                west: "20.2",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 843,
                                status: "available",
                                block: 46,
                                unitSize: 476.0,
                                north: "28.0",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 844,
                                status: "available",
                                block: 46,
                                unitSize: 351.83,
                                north: "18.6",
                                east: "17.2",
                                south: "18.0",
                                west: "21.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 845,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 846,
                                status: "available",
                                block: 46,
                                unitSize: 436.16,
                                north: "18.6",
                                east: "21.89",
                                south: "18.0",
                                west: "26.57",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 847,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 848,
                                status: "available",
                                block: 46,
                                unitSize: 352.88,
                                north: "15.5",
                                east: "21.57",
                                south: "15.0",
                                west: "25.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 849,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 850,
                                status: "available",
                                block: 46,
                                unitSize: 405.18,
                                north: "15.5",
                                east: "25.48",
                                south: "15.0",
                                west: "29.38",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 851,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 852,
                                status: "available",
                                block: 46,
                                unitSize: 388.74,
                                north: "15.5",
                                east: "24.38",
                                south: "15.0",
                                west: "28.28",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 853,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 854,
                                status: "available",
                                block: 46,
                                unitSize: 453.55,
                                north: "15.5",
                                east: "28.28",
                                south: "15.0",
                                west: "32.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 855,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 856,
                                status: "available",
                                block: 46,
                                unitSize: 512.11,
                                north: "15.5",
                                east: "32.19",
                                south: "15.0",
                                west: "36.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 857,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 858,
                                status: "available",
                                block: 46,
                                unitSize: 495.67,
                                north: "15.5",
                                east: "31.09",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 859,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 860,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 861,
                                status: "sold",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 862,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 863,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 864,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 865,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 866,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 867,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 868,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 869,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 870,
                                status: "available",
                                block: 47,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 871,
                                status: "available",
                                block: 47,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 872,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 873,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 874,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 875,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 876,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 877,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 878,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 879,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 880,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 881,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 882,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 883,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 884,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 885,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 886,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 887,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 888,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 889,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 890,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 891,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 892,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 893,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 894,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 895,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 896,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 897,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 898,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 899,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 900,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 901,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 902,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 903,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 904,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 905,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 906,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 907,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 908,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 909,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 910,
                                status: "available",
                                block: 50,
                                unitSize: 769.89,
                                north: "25.85",
                                east: "27.5",
                                south: "25.0",
                                west: "34.09",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 911,
                                status: "available",
                                block: 50,
                                unitSize: 673.83,
                                north: "25.02",
                                east: "19.09",
                                south: "30.0",
                                west: "20.99",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 912,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 913,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 914,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 915,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 916,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 917,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 918,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 919,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 920,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 921,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 922,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 923,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 924,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 925,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 926,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 927,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 928,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 929,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 930,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 931,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 932,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 933,
                                status: "available",
                                block: 51,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 934,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 935,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 936,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 937,
                                status: "available",
                                block: 51,
                                unitSize: 875.0,
                                north: "25.0",
                                east: "35.0",
                                south: "25.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 938,
                                status: "available",
                                block: 51,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 939,
                                status: "available",
                                block: 52,
                                unitSize: 698.78,
                                north: "31.02",
                                east: "19.34",
                                south: "30.0",
                                west: "27.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 940,
                                status: "available",
                                block: 52,
                                unitSize: 733.55,
                                north: "19.85",
                                east: "37.24",
                                south: "25.0",
                                west: "27.83",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 941,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 942,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 943,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 944,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 945,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 946,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 947,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 948,
                                status: "available",
                                block: 52,
                                unitSize: 625.0,
                                north: "25.0",
                                east: "25.0",
                                south: "25.0",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 949,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 950,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 951,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 952,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 953,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 954,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 955,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 956,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 957,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 958,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 959,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 960,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 961,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 962,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 963,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 964,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 965,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 966,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 967,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 968,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 969,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 970,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 971,
                                status: "available",
                                block: 53,
                                unitSize: 570.0,
                                north: "30.0",
                                east: "19.0",
                                south: "30.0",
                                west: "19.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 972,
                                status: "available",
                                block: 53,
                                unitSize: 475.0,
                                north: "25.0",
                                east: "19.0",
                                south: "25.0",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 973,
                                status: "sold",
                                block: 54,
                                unitSize: 507.6,
                                north: "14.25",
                                east: "29.31",
                                south: "21.38",
                                west: "28.6",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 974,
                                status: "available",
                                block: 54,
                                unitSize: 724.12,
                                north: "21.38",
                                east: "24.65",
                                south: "22.84",
                                west: "29.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 975,
                                status: "sold",
                                block: 54,
                                unitSize: 430.28,
                                north: "15.0",
                                east: "28.6",
                                south: "15.0",
                                west: "28.77",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 976,
                                status: "available",
                                block: 54,
                                unitSize: 439.44,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 977,
                                status: "available",
                                block: 54,
                                unitSize: 432.89,
                                north: "15.0",
                                east: "28.77",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 978,
                                status: "available",
                                block: 54,
                                unitSize: 436.77,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 979,
                                status: "available",
                                block: 54,
                                unitSize: 434.73,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "29.01",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 980,
                                status: "available",
                                block: 54,
                                unitSize: 435.04,
                                north: "15.0",
                                east: "29.03",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 981,
                                status: "available",
                                block: 54,
                                unitSize: 435.4,
                                north: "15.0",
                                east: "29.01",
                                south: "15.0",
                                west: "29.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 982,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 983,
                                status: "available",
                                block: 54,
                                unitSize: 435.76,
                                north: "15.0",
                                east: "29.04",
                                south: "15.0",
                                west: "29.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 984,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 985,
                                status: "available",
                                block: 54,
                                unitSize: 436.13,
                                north: "15.0",
                                east: "29.06",
                                south: "15.0",
                                west: "29.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 986,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 987,
                                status: "available",
                                block: 54,
                                unitSize: 436.5,
                                north: "15.0",
                                east: "29.09",
                                south: "15.0",
                                west: "29.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 988,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 989,
                                status: "available",
                                block: 54,
                                unitSize: 436.86,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 990,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 991,
                                status: "available",
                                block: 54,
                                unitSize: 437.23,
                                north: "15.0",
                                east: "29.14",
                                south: "15.0",
                                west: "29.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 992,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 993,
                                status: "available",
                                block: 54,
                                unitSize: 437.59,
                                north: "15.0",
                                east: "29.16",
                                south: "15.0",
                                west: "29.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 994,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 995,
                                status: "available",
                                block: 54,
                                unitSize: 431.71,
                                north: "15.0",
                                east: "29.19",
                                south: "15.0",
                                west: "29.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 996,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 997,
                                status: "available",
                                block: 54,
                                unitSize: 467.33,
                                north: "15.67",
                                east: "29.21",
                                south: "16.74",
                                west: "29.25",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 998,
                                status: "sold",
                                block: 54,
                                unitSize: 500.91,
                                north: "16.74",
                                east: "29.0",
                                south: "17.8",
                                west: "29.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 999,
                                status: "available",
                                block: 55,
                                unitSize: 629.06,
                                north: "33.27",
                                east: "15.31",
                                south: "32.18",
                                west: "23.79",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1000,
                                status: "available",
                                block: 55,
                                unitSize: 546.98,
                                north: "32.18",
                                east: "17.0",
                                south: "32.18",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1001,
                                status: "available",
                                block: 55,
                                unitSize: 428.56,
                                north: "20.68",
                                east: "18.79",
                                south: "20.0",
                                west: "24.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1002,
                                status: "available",
                                block: 55,
                                unitSize: 440.0,
                                north: "20.0",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1003,
                                status: "available",
                                block: 55,
                                unitSize: 345.51,
                                north: "15.5",
                                east: "21.06",
                                south: "15.0",
                                west: "24.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1004,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1005,
                                status: "available",
                                block: 55,
                                unitSize: 402.71,
                                north: "15.22",
                                east: "24.97",
                                south: "15.0",
                                west: "28.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1006,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1007,
                                status: "available",
                                block: 55,
                                unitSize: 416.78,
                                north: "9.94",
                                east: "26.73",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1008,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1009,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1010,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1011,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1012,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1013,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1014,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1015,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1016,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1017,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1018,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1019,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1020,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1021,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1022,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1023,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1024,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1025,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1026,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1027,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1028,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1029,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1030,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1031,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1032,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1033,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1034,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1035,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1036,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1037,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1038,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1038,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1039,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1039,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1040,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1040,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1041,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1041,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1042,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1042,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1043,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1043,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1044,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1044,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1045,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1045,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1046,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1046,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1047,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1047,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1048,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1049,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1050,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1051,
                                status: "available",
                                block: 57,
                                unitSize: 526.5,
                                north: "17.43",
                                east: "25.0",
                                south: "24.69",
                                west: "26.03",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1052,
                                status: "available",
                                block: 57,
                                unitSize: 707.91,
                                north: "24.69",
                                east: "25.0",
                                south: "31.94",
                                west: "26.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1053,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1053,
                                status: "available",
                                block: 58,
                                unitSize: 476.0,
                                north: "17.0",
                                east: "28.0",
                                south: "17.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1054,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1054,
                                status: "available",
                                block: 58,
                                unitSize: 459.0,
                                north: "17.0",
                                east: "27.0",
                                south: "17.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1055,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1055,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1056,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1056,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1057,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1057,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1058,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1058,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1059,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1059,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1060,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1060,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1061,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1061,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1062,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1062,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1063,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1063,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1064,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1064,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1065,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1065,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1066,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1066,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1067,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1067,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1068,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1068,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1069,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1069,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1070,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1070,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1071,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1071,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1072,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1072,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1073,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1073,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1074,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1074,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1075,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1075,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1076,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1076,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1077,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1077,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1078,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1078,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1079,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1079,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1080,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1080,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1081,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1081,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1082,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1082,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1083,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1083,
                                status: "available",
                                block: 59,
                                unitSize: 500.98,
                                north: "24.36",
                                east: "19.0",
                                south: "27.0",
                                west: "9.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1084,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1084,
                                status: "available",
                                block: 59,
                                unitSize: 459.0,
                                north: "27.0",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1085,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1085,
                                status: "available",
                                block: 59,
                                unitSize: 513.0,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1086,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1086,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1087,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1087,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1088,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1088,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1089,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1089,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1090,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1090,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1091,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1091,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1092,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1092,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1093,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1093,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1094,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1094,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1095,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1095,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1096,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1096,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1097,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1097,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1098,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1098,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1099,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1099,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1100,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1101,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1102,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1103,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1104,
                                status: "sold",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1105,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1106,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1107,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1108,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1109,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1110,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1111,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1112,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1113,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1114,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1115,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1116,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1117,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1118,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1119,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1120,
                                status: "available",
                                block: 62,
                                unitSize: 579.2,
                                north: "30.02",
                                east: "19.83",
                                south: "30.0",
                                west: "18.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1121,
                                status: "available",
                                block: 62,
                                unitSize: 723.26,
                                north: "40.02",
                                east: "18.78",
                                south: "40.0",
                                west: "17.38",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1122,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1123,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1124,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1125,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1126,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1127,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1128,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1129,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1130,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1131,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1132,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1133,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1134,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1135,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1136,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1137,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1138,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1139,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1140,
                                status: "available",
                                block: 63,
                                unitSize: 1620.0,
                                north: "30.0",
                                east: "54.0",
                                south: "30.0",
                                west: "54.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1141,
                                status: "available",
                                block: 63,
                                unitSize: 880.0,
                                north: "40.0",
                                east: "22.0",
                                south: "40.0",
                                west: "22.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1142,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1143,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1144,
                                status: "available",
                                block: 63,
                                unitSize: 1376.52,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "27.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1145,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1146,
                                status: "available",
                                block: 63,
                                unitSize: 640.64,
                                north: "40.0",
                                east: "16.0",
                                south: "40.25",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1147,
                                status: "available",
                                block: 63,
                                unitSize: 979.58,
                                north: "40.25",
                                east: "18.39",
                                south: "40.0",
                                west: "19.95",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1148,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1149,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1150,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1151,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1152,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1153,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1154,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1155,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1156,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1157,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1158,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1159,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1160,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1161,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1162,
                                status: "sold",
                                block: 63,
                                unitSize: 540.97,
                                north: "30.0",
                                east: "18.03",
                                south: "30.0",
                                west: "18.03",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_30_1163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1163,
                                status: "available",
                                block: 63,
                                unitSize: 721.29,
                                north: "40.0",
                                east: "18.03",
                                south: "40.0",
                                west: "18.03",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////
    //index 60
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_60_681,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 681,
                                status: "available",
                                block: 38,
                                unitSize: 873.85,
                                north: "20.41",
                                east: "36.17",
                                south: "29.52",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_682,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 682,
                                status: "sold",
                                block: 38,
                                unitSize: 535.4,
                                north: "14.52",
                                east: "25.01",
                                south: "16.33",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_683,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 683,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_684,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 684,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_685,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 685,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_686,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 686,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_687,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 687,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_688,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 688,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_689,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 689,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_690,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 690,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_691,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 691,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_692,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 692,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_693,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 693,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_694,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 694,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_695,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 695,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_696,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 696,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_697,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 697,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_698,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 698,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_699,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 699,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_700,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 700,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_701,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 701,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_702,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 702,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_703,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 703,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_704,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 704,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_705,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 705,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_706,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 706,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_707,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 707,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_708,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 708,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_709,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 709,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_710,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 710,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_711,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 711,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_712,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 712,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_713,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 713,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_714,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 714,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_715,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 715,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_716,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 716,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_717,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 717,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_718,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 718,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_719,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 719,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_720,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 720,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_721,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 721,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_722,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 722,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_723,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 723,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_724,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 724,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_725,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 725,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_726,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 726,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_727,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 727,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_728,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 728,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_729,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 729,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_730,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 730,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_731,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 731,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_732,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 732,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_733,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 733,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_734,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 734,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_735,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 735,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_736,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 736,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_737,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 737,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_738,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 738,
                                status: "available",
                                block: 39,
                                unitSize: 526.58,
                                north: "14.99",
                                east: "35.0",
                                south: "15.1",
                                west: "34.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_739,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 739,
                                status: "available",
                                block: 39,
                                unitSize: 454.41,
                                north: "15.1",
                                east: "30.0",
                                south: "15.19",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_740,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 740,
                                status: "booked",
                                block: 40,
                                unitSize: 3089.99,
                                north: "62.05",
                                east: "50.0",
                                south: "62.05",
                                west: "50.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_741,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 741,
                                status: "available",
                                block: 41,
                                unitSize: 630.13,
                                north: "18.0",
                                east: "35.0",
                                south: "18.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_742,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 742,
                                status: "available",
                                block: 41,
                                unitSize: 540.11,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_743,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 743,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_744,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 744,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_745,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 745,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_746,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 746,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_747,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 747,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_748,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 748,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_749,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 749,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_750,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 750,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 751,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 752,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 753,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 754,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 755,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.08",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 756,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 757,
                                status: "available",
                                block: 41,
                                unitSize: 518.66,
                                north: "14.92",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 758,
                                status: "available",
                                block: 41,
                                unitSize: 449.92,
                                north: "15.0",
                                east: "30.0",
                                south: "14.51",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 759,
                                status: "available",
                                block: 41,
                                unitSize: 518.78,
                                north: "15.0",
                                east: "35.0",
                                south: "15.26",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 760,
                                status: "available",
                                block: 41,
                                unitSize: 450.02,
                                north: "15.26",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 761,
                                status: "available",
                                block: 41,
                                unitSize: 546.01,
                                north: "15.81",
                                east: "35.0",
                                south: "15.39",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 762,
                                status: "available",
                                block: 41,
                                unitSize: 456.43,
                                north: "15.39",
                                east: "30.0",
                                south: "15.04",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 763,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 764,
                                status: "sold",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 765,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 766,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 767,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 768,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 769,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 770,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 771,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 772,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 773,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 774,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 775,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 776,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 777,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 778,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 779,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 780,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 781,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 782,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 783,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 784,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 785,
                                status: "sold",
                                block: 42,
                                unitSize: 745.02,
                                north: "20.65",
                                east: "35.0",
                                south: "21.93",
                                west: "35.02",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 786,
                                status: "available",
                                block: 42,
                                unitSize: 674.3,
                                north: "21.93",
                                east: "30.0",
                                south: "23.03",
                                west: "30.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 787,
                                status: "available",
                                block: 43,
                                unitSize: 902.71,
                                north: "36.17",
                                east: "21.22",
                                south: "35.0",
                                west: "30.36",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 788,
                                status: "available",
                                block: 43,
                                unitSize: 560.9,
                                north: "25.01",
                                east: "15.36",
                                south: "17.19",
                                west: "7.34",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 789,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 790,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 791,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 792,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 793,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 794,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 795,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 796,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 797,
                                status: "available",
                                block: 43,
                                unitSize: 540.0,
                                north: "30.0",
                                east: "18.0",
                                south: "30.0",
                                west: "18.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 798,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 799,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 800,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 801,
                                status: "available",
                                block: 43,
                                unitSize: 1260.0,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "42.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 802,
                                status: "available",
                                block: 43,
                                unitSize: 759.0,
                                north: "23.0",
                                east: "33.0",
                                south: "23.0",
                                west: "33.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 803,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 804,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 805,
                                status: "available",
                                block: 44,
                                unitSize: 454.1,
                                north: "12.04",
                                east: "29.72",
                                south: "19.53",
                                west: "28.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 806,
                                status: "available",
                                block: 44,
                                unitSize: 658.23,
                                north: "19.53",
                                east: "23.97",
                                south: "21.06",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 807,
                                status: "available",
                                block: 44,
                                unitSize: 425.51,
                                north: "15.0",
                                east: "28.78",
                                south: "15.0",
                                west: "28.79",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 808,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 809,
                                status: "available",
                                block: 44,
                                unitSize: 425.69,
                                north: "15.0",
                                east: "28.79",
                                south: "15.0",
                                west: "28.8",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 810,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 811,
                                status: "available",
                                block: 44,
                                unitSize: 432.11,
                                north: "15.0",
                                east: "28.8",
                                south: "15.0",
                                west: "28.81",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 812,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 813,
                                status: "available",
                                block: 44,
                                unitSize: 432.29,
                                north: "15.0",
                                east: "28.81",
                                south: "15.0",
                                west: "28.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 814,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 815,
                                status: "available",
                                block: 44,
                                unitSize: 432.46,
                                north: "15.0",
                                east: "28.83",
                                south: "15.0",
                                west: "28.84",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 816,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 817,
                                status: "available",
                                block: 44,
                                unitSize: 432.64,
                                north: "15.0",
                                east: "28.84",
                                south: "15.0",
                                west: "28.85",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 818,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 819,
                                status: "available",
                                block: 44,
                                unitSize: 432.81,
                                north: "15.0",
                                east: "28.85",
                                south: "15.0",
                                west: "28.86",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 820,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 821,
                                status: "available",
                                block: 45,
                                unitSize: 433.06,
                                north: "15.0",
                                east: "28.86",
                                south: "15.0",
                                west: "28.88",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 822,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 823,
                                status: "available",
                                block: 45,
                                unitSize: 433.23,
                                north: "15.0",
                                east: "28.88",
                                south: "15.0",
                                west: "28.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 824,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 825,
                                status: "available",
                                block: 45,
                                unitSize: 433.41,
                                north: "15.0",
                                east: "28.89",
                                south: "15.0",
                                west: "28.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 826,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 827,
                                status: "available",
                                block: 45,
                                unitSize: 433.58,
                                north: "15.0",
                                east: "28.9",
                                south: "15.0",
                                west: "28.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 828,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 829,
                                status: "available",
                                block: 45,
                                unitSize: 433.76,
                                north: "15.0",
                                east: "28.91",
                                south: "15.0",
                                west: "28.92",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 830,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 831,
                                status: "available",
                                block: 45,
                                unitSize: 433.94,
                                north: "15.0",
                                east: "28.92",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 832,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 833,
                                status: "available",
                                block: 45,
                                unitSize: 434.11,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "28.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 834,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 835,
                                status: "available",
                                block: 45,
                                unitSize: 434.29,
                                north: "15.0",
                                east: "28.95",
                                south: "15.0",
                                west: "28.96",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 836,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 837,
                                status: "available",
                                block: 45,
                                unitSize: 434.46,
                                north: "15.0",
                                east: "28.96",
                                south: "15.0",
                                west: "28.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 838,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 839,
                                status: "available",
                                block: 45,
                                unitSize: 691.6,
                                north: "28.63",
                                east: "20.97",
                                south: "31.05",
                                west: "15.73",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 840,
                                status: "available",
                                block: 45,
                                unitSize: 490.24,
                                north: "31.05",
                                east: "17.0",
                                south: "26.63",
                                west: "17.57",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 841,
                                status: "available",
                                block: 45,
                                unitSize: 480.46,
                                north: "26.63",
                                east: "20.0",
                                south: "21.42",
                                west: "20.67",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 842,
                                status: "available",
                                block: 46,
                                unitSize: 463.69,
                                north: "28.93",
                                east: "12.92",
                                south: "28.0",
                                west: "20.2",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 843,
                                status: "available",
                                block: 46,
                                unitSize: 476.0,
                                north: "28.0",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 844,
                                status: "available",
                                block: 46,
                                unitSize: 351.83,
                                north: "18.6",
                                east: "17.2",
                                south: "18.0",
                                west: "21.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 845,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 846,
                                status: "available",
                                block: 46,
                                unitSize: 436.16,
                                north: "18.6",
                                east: "21.89",
                                south: "18.0",
                                west: "26.57",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 847,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 848,
                                status: "available",
                                block: 46,
                                unitSize: 352.88,
                                north: "15.5",
                                east: "21.57",
                                south: "15.0",
                                west: "25.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 849,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 850,
                                status: "available",
                                block: 46,
                                unitSize: 405.18,
                                north: "15.5",
                                east: "25.48",
                                south: "15.0",
                                west: "29.38",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 851,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 852,
                                status: "available",
                                block: 46,
                                unitSize: 388.74,
                                north: "15.5",
                                east: "24.38",
                                south: "15.0",
                                west: "28.28",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 853,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 854,
                                status: "available",
                                block: 46,
                                unitSize: 453.55,
                                north: "15.5",
                                east: "28.28",
                                south: "15.0",
                                west: "32.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 855,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 856,
                                status: "available",
                                block: 46,
                                unitSize: 512.11,
                                north: "15.5",
                                east: "32.19",
                                south: "15.0",
                                west: "36.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 857,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 858,
                                status: "available",
                                block: 46,
                                unitSize: 495.67,
                                north: "15.5",
                                east: "31.09",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 859,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 860,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 861,
                                status: "sold",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 862,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 863,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 864,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 865,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 866,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 867,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 868,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 869,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 870,
                                status: "available",
                                block: 47,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 871,
                                status: "available",
                                block: 47,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 872,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 873,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 874,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 875,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 876,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 877,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 878,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 879,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 880,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 881,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 882,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 883,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 884,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 885,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 886,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 887,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 888,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 889,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 890,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 891,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 892,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 893,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 894,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 895,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 896,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 897,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 898,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 899,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 900,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 901,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 902,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 903,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 904,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 905,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 906,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 907,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 908,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 909,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 910,
                                status: "available",
                                block: 50,
                                unitSize: 769.89,
                                north: "25.85",
                                east: "27.5",
                                south: "25.0",
                                west: "34.09",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 911,
                                status: "available",
                                block: 50,
                                unitSize: 673.83,
                                north: "25.02",
                                east: "19.09",
                                south: "30.0",
                                west: "20.99",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 912,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 913,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 914,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 915,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 916,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 917,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 918,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 919,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 920,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 921,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 922,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 923,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 924,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 925,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 926,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 927,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 928,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 929,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 930,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 931,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 932,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 933,
                                status: "available",
                                block: 51,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 934,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 935,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 936,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 937,
                                status: "available",
                                block: 51,
                                unitSize: 875.0,
                                north: "25.0",
                                east: "35.0",
                                south: "25.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 938,
                                status: "available",
                                block: 51,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 939,
                                status: "available",
                                block: 52,
                                unitSize: 698.78,
                                north: "31.02",
                                east: "19.34",
                                south: "30.0",
                                west: "27.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 940,
                                status: "available",
                                block: 52,
                                unitSize: 733.55,
                                north: "19.85",
                                east: "37.24",
                                south: "25.0",
                                west: "27.83",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 941,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 942,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 943,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 944,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 945,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 946,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 947,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 948,
                                status: "available",
                                block: 52,
                                unitSize: 625.0,
                                north: "25.0",
                                east: "25.0",
                                south: "25.0",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 949,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 950,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 951,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 952,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 953,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 954,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 955,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 956,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 957,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 958,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 959,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 960,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 961,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 962,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 963,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 964,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 965,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 966,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 967,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 968,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 969,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 970,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 971,
                                status: "available",
                                block: 53,
                                unitSize: 570.0,
                                north: "30.0",
                                east: "19.0",
                                south: "30.0",
                                west: "19.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 972,
                                status: "available",
                                block: 53,
                                unitSize: 475.0,
                                north: "25.0",
                                east: "19.0",
                                south: "25.0",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 973,
                                status: "sold",
                                block: 54,
                                unitSize: 507.6,
                                north: "14.25",
                                east: "29.31",
                                south: "21.38",
                                west: "28.6",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 974,
                                status: "available",
                                block: 54,
                                unitSize: 724.12,
                                north: "21.38",
                                east: "24.65",
                                south: "22.84",
                                west: "29.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 975,
                                status: "sold",
                                block: 54,
                                unitSize: 430.28,
                                north: "15.0",
                                east: "28.6",
                                south: "15.0",
                                west: "28.77",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 976,
                                status: "available",
                                block: 54,
                                unitSize: 439.44,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 977,
                                status: "available",
                                block: 54,
                                unitSize: 432.89,
                                north: "15.0",
                                east: "28.77",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 978,
                                status: "available",
                                block: 54,
                                unitSize: 436.77,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 979,
                                status: "available",
                                block: 54,
                                unitSize: 434.73,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "29.01",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 980,
                                status: "available",
                                block: 54,
                                unitSize: 435.04,
                                north: "15.0",
                                east: "29.03",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 981,
                                status: "available",
                                block: 54,
                                unitSize: 435.4,
                                north: "15.0",
                                east: "29.01",
                                south: "15.0",
                                west: "29.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 982,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 983,
                                status: "available",
                                block: 54,
                                unitSize: 435.76,
                                north: "15.0",
                                east: "29.04",
                                south: "15.0",
                                west: "29.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 984,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 985,
                                status: "available",
                                block: 54,
                                unitSize: 436.13,
                                north: "15.0",
                                east: "29.06",
                                south: "15.0",
                                west: "29.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 986,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 987,
                                status: "available",
                                block: 54,
                                unitSize: 436.5,
                                north: "15.0",
                                east: "29.09",
                                south: "15.0",
                                west: "29.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 988,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 989,
                                status: "available",
                                block: 54,
                                unitSize: 436.86,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 990,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 991,
                                status: "available",
                                block: 54,
                                unitSize: 437.23,
                                north: "15.0",
                                east: "29.14",
                                south: "15.0",
                                west: "29.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 992,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 993,
                                status: "available",
                                block: 54,
                                unitSize: 437.59,
                                north: "15.0",
                                east: "29.16",
                                south: "15.0",
                                west: "29.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 994,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 995,
                                status: "available",
                                block: 54,
                                unitSize: 431.71,
                                north: "15.0",
                                east: "29.19",
                                south: "15.0",
                                west: "29.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 996,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 997,
                                status: "available",
                                block: 54,
                                unitSize: 467.33,
                                north: "15.67",
                                east: "29.21",
                                south: "16.74",
                                west: "29.25",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 998,
                                status: "sold",
                                block: 54,
                                unitSize: 500.91,
                                north: "16.74",
                                east: "29.0",
                                south: "17.8",
                                west: "29.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 999,
                                status: "available",
                                block: 55,
                                unitSize: 629.06,
                                north: "33.27",
                                east: "15.31",
                                south: "32.18",
                                west: "23.79",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1000,
                                status: "available",
                                block: 55,
                                unitSize: 546.98,
                                north: "32.18",
                                east: "17.0",
                                south: "32.18",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1001,
                                status: "available",
                                block: 55,
                                unitSize: 428.56,
                                north: "20.68",
                                east: "18.79",
                                south: "20.0",
                                west: "24.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1002,
                                status: "available",
                                block: 55,
                                unitSize: 440.0,
                                north: "20.0",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1003,
                                status: "available",
                                block: 55,
                                unitSize: 345.51,
                                north: "15.5",
                                east: "21.06",
                                south: "15.0",
                                west: "24.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1004,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1005,
                                status: "available",
                                block: 55,
                                unitSize: 402.71,
                                north: "15.22",
                                east: "24.97",
                                south: "15.0",
                                west: "28.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1006,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1007,
                                status: "available",
                                block: 55,
                                unitSize: 416.78,
                                north: "9.94",
                                east: "26.73",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1008,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1009,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1010,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1011,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1012,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1013,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1014,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1015,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1016,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1017,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1018,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1019,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1020,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1021,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1022,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1023,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1024,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1025,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1026,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1027,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1028,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1029,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1030,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1031,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1032,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1033,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1034,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1035,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1036,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1037,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1038,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1038,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1039,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1039,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1040,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1040,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1041,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1041,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1042,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1042,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1043,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1043,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1044,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1044,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1045,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1045,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1046,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1046,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1047,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1047,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1048,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1049,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1050,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1051,
                                status: "available",
                                block: 57,
                                unitSize: 526.5,
                                north: "17.43",
                                east: "25.0",
                                south: "24.69",
                                west: "26.03",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1052,
                                status: "available",
                                block: 57,
                                unitSize: 707.91,
                                north: "24.69",
                                east: "25.0",
                                south: "31.94",
                                west: "26.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1053,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1053,
                                status: "available",
                                block: 58,
                                unitSize: 476.0,
                                north: "17.0",
                                east: "28.0",
                                south: "17.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1054,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1054,
                                status: "available",
                                block: 58,
                                unitSize: 459.0,
                                north: "17.0",
                                east: "27.0",
                                south: "17.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1055,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1055,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1056,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1056,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1057,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1057,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1058,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1058,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1059,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1059,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1060,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1060,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1061,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1061,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1062,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1062,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1063,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1063,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1064,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1064,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1065,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1065,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1066,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1066,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1067,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1067,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1068,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1068,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1069,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1069,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1070,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1070,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1071,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1071,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1072,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1072,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1073,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1073,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1074,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1074,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1075,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1075,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1076,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1076,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1077,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1077,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1078,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1078,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1079,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1079,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1080,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1080,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1081,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1081,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1082,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1082,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1083,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1083,
                                status: "available",
                                block: 59,
                                unitSize: 500.98,
                                north: "24.36",
                                east: "19.0",
                                south: "27.0",
                                west: "9.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1084,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1084,
                                status: "available",
                                block: 59,
                                unitSize: 459.0,
                                north: "27.0",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1085,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1085,
                                status: "available",
                                block: 59,
                                unitSize: 513.0,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1086,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1086,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1087,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1087,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1088,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1088,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1089,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1089,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1090,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1090,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1091,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1091,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1092,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1092,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1093,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1093,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1094,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1094,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1095,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1095,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1096,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1096,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1097,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1097,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1098,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1098,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1099,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1099,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1100,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1101,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1102,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1103,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1104,
                                status: "sold",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1105,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1106,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1107,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1108,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1109,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1110,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1111,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1112,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1113,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1114,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1115,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1116,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1117,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1118,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1119,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1120,
                                status: "available",
                                block: 62,
                                unitSize: 579.2,
                                north: "30.02",
                                east: "19.83",
                                south: "30.0",
                                west: "18.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1121,
                                status: "available",
                                block: 62,
                                unitSize: 723.26,
                                north: "40.02",
                                east: "18.78",
                                south: "40.0",
                                west: "17.38",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1122,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1123,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1124,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1125,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1126,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1127,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1128,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1129,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1130,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1131,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1132,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1133,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1134,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1135,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1136,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1137,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1138,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1139,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1140,
                                status: "available",
                                block: 63,
                                unitSize: 1620.0,
                                north: "30.0",
                                east: "54.0",
                                south: "30.0",
                                west: "54.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1141,
                                status: "available",
                                block: 63,
                                unitSize: 880.0,
                                north: "40.0",
                                east: "22.0",
                                south: "40.0",
                                west: "22.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1142,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1143,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1144,
                                status: "available",
                                block: 63,
                                unitSize: 1376.52,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "27.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1145,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1146,
                                status: "available",
                                block: 63,
                                unitSize: 640.64,
                                north: "40.0",
                                east: "16.0",
                                south: "40.25",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1147,
                                status: "available",
                                block: 63,
                                unitSize: 979.58,
                                north: "40.25",
                                east: "18.39",
                                south: "40.0",
                                west: "19.95",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1148,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1149,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1150,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1151,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1152,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1153,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1154,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1155,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1156,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1157,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1158,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1159,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1160,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1161,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1162,
                                status: "sold",
                                block: 63,
                                unitSize: 540.97,
                                north: "30.0",
                                east: "18.03",
                                south: "30.0",
                                west: "18.03",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_60_1163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1163,
                                status: "available",
                                block: 63,
                                unitSize: 721.29,
                                north: "40.0",
                                east: "18.03",
                                south: "40.0",
                                west: "18.03",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////
    //index 90
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_90_681,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 681,
                                status: "available",
                                block: 38,
                                unitSize: 873.85,
                                north: "20.41",
                                east: "36.17",
                                south: "29.52",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_682,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 682,
                                status: "sold",
                                block: 38,
                                unitSize: 535.4,
                                north: "14.52",
                                east: "25.01",
                                south: "16.33",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_683,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 683,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_684,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 684,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_685,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 685,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_686,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 686,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_687,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 687,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_688,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 688,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_689,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 689,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_690,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 690,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_691,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 691,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_692,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 692,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_693,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 693,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_694,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 694,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_695,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 695,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_696,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 696,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_697,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 697,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_698,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 698,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_699,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 699,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_700,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 700,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_701,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 701,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_702,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 702,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_703,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 703,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_704,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 704,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_705,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 705,
                                status: "available",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_706,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 706,
                                status: "available",
                                block: 38,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_707,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 707,
                                status: "sold",
                                block: 38,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_708,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 708,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_709,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 709,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_710,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 710,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_711,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 711,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_712,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 712,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_713,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 713,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_714,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 714,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_715,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 715,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_716,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 716,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_717,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 717,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_718,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 718,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_719,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 719,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_720,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 720,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_721,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 721,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_722,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 722,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_723,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 723,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_724,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 724,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_725,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 725,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_726,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 726,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_727,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 727,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_728,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 728,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_729,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 729,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_730,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 730,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_731,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 731,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_732,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 732,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_733,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 733,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_734,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 734,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_735,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 735,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_736,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 736,
                                status: "available",
                                block: 39,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_737,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 737,
                                status: "available",
                                block: 39,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_738,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 738,
                                status: "available",
                                block: 39,
                                unitSize: 526.58,
                                north: "14.99",
                                east: "35.0",
                                south: "15.1",
                                west: "34.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_739,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 739,
                                status: "available",
                                block: 39,
                                unitSize: 454.41,
                                north: "15.1",
                                east: "30.0",
                                south: "15.19",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_740,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 740,
                                status: "booked",
                                block: 40,
                                unitSize: 3089.99,
                                north: "62.05",
                                east: "50.0",
                                south: "62.05",
                                west: "50.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_741,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 741,
                                status: "available",
                                block: 41,
                                unitSize: 630.13,
                                north: "18.0",
                                east: "35.0",
                                south: "18.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_742,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 742,
                                status: "available",
                                block: 41,
                                unitSize: 540.11,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_743,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 743,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_744,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 744,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_745,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 745,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_746,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 746,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_747,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 747,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_748,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 748,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_749,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 749,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_750,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 750,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 751,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 752,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 753,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 754,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 755,
                                status: "available",
                                block: 41,
                                unitSize: 525.0,
                                north: "15.08",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 756,
                                status: "available",
                                block: 41,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 757,
                                status: "available",
                                block: 41,
                                unitSize: 518.66,
                                north: "14.92",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 758,
                                status: "available",
                                block: 41,
                                unitSize: 449.92,
                                north: "15.0",
                                east: "30.0",
                                south: "14.51",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 759,
                                status: "available",
                                block: 41,
                                unitSize: 518.78,
                                north: "15.0",
                                east: "35.0",
                                south: "15.26",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 760,
                                status: "available",
                                block: 41,
                                unitSize: 450.02,
                                north: "15.26",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 761,
                                status: "available",
                                block: 41,
                                unitSize: 546.01,
                                north: "15.81",
                                east: "35.0",
                                south: "15.39",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 762,
                                status: "available",
                                block: 41,
                                unitSize: 456.43,
                                north: "15.39",
                                east: "30.0",
                                south: "15.04",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 763,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 764,
                                status: "sold",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 765,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 766,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 767,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 768,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 769,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 770,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 771,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 772,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 773,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 774,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 775,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 776,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 777,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 778,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 779,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 780,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 781,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 782,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 783,
                                status: "available",
                                block: 42,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 784,
                                status: "available",
                                block: 42,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 785,
                                status: "sold",
                                block: 42,
                                unitSize: 745.02,
                                north: "20.65",
                                east: "35.0",
                                south: "21.93",
                                west: "35.02",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 786,
                                status: "available",
                                block: 42,
                                unitSize: 674.3,
                                north: "21.93",
                                east: "30.0",
                                south: "23.03",
                                west: "30.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 787,
                                status: "available",
                                block: 43,
                                unitSize: 902.71,
                                north: "36.17",
                                east: "21.22",
                                south: "35.0",
                                west: "30.36",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 788,
                                status: "available",
                                block: 43,
                                unitSize: 560.9,
                                north: "25.01",
                                east: "15.36",
                                south: "17.19",
                                west: "7.34",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 789,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 790,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 791,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 792,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 793,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 794,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 795,
                                status: "available",
                                block: 43,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 796,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 797,
                                status: "available",
                                block: 43,
                                unitSize: 540.0,
                                north: "30.0",
                                east: "18.0",
                                south: "30.0",
                                west: "18.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 798,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 799,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 800,
                                status: "available",
                                block: 43,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 801,
                                status: "available",
                                block: 43,
                                unitSize: 1260.0,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "42.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 802,
                                status: "available",
                                block: 43,
                                unitSize: 759.0,
                                north: "23.0",
                                east: "33.0",
                                south: "23.0",
                                west: "33.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 803,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 804,
                                status: "available",
                                block: 43,
                                unitSize: 693.0,
                                north: "21.0",
                                east: "33.0",
                                south: "21.0",
                                west: "33.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 805,
                                status: "available",
                                block: 44,
                                unitSize: 454.1,
                                north: "12.04",
                                east: "29.72",
                                south: "19.53",
                                west: "28.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 806,
                                status: "available",
                                block: 44,
                                unitSize: 658.23,
                                north: "19.53",
                                east: "23.97",
                                south: "21.06",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 807,
                                status: "available",
                                block: 44,
                                unitSize: 425.51,
                                north: "15.0",
                                east: "28.78",
                                south: "15.0",
                                west: "28.79",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 808,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 809,
                                status: "available",
                                block: 44,
                                unitSize: 425.69,
                                north: "15.0",
                                east: "28.79",
                                south: "15.0",
                                west: "28.8",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 810,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 811,
                                status: "available",
                                block: 44,
                                unitSize: 432.11,
                                north: "15.0",
                                east: "28.8",
                                south: "15.0",
                                west: "28.81",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 812,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 813,
                                status: "available",
                                block: 44,
                                unitSize: 432.29,
                                north: "15.0",
                                east: "28.81",
                                south: "15.0",
                                west: "28.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 814,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 815,
                                status: "available",
                                block: 44,
                                unitSize: 432.46,
                                north: "15.0",
                                east: "28.83",
                                south: "15.0",
                                west: "28.84",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 816,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 817,
                                status: "available",
                                block: 44,
                                unitSize: 432.64,
                                north: "15.0",
                                east: "28.84",
                                south: "15.0",
                                west: "28.85",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 818,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 819,
                                status: "available",
                                block: 44,
                                unitSize: 432.81,
                                north: "15.0",
                                east: "28.85",
                                south: "15.0",
                                west: "28.86",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 820,
                                status: "available",
                                block: 44,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 821,
                                status: "available",
                                block: 45,
                                unitSize: 433.06,
                                north: "15.0",
                                east: "28.86",
                                south: "15.0",
                                west: "28.88",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 822,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 823,
                                status: "available",
                                block: 45,
                                unitSize: 433.23,
                                north: "15.0",
                                east: "28.88",
                                south: "15.0",
                                west: "28.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 824,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 825,
                                status: "available",
                                block: 45,
                                unitSize: 433.41,
                                north: "15.0",
                                east: "28.89",
                                south: "15.0",
                                west: "28.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 826,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 827,
                                status: "available",
                                block: 45,
                                unitSize: 433.58,
                                north: "15.0",
                                east: "28.9",
                                south: "15.0",
                                west: "28.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 828,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 829,
                                status: "available",
                                block: 45,
                                unitSize: 433.76,
                                north: "15.0",
                                east: "28.91",
                                south: "15.0",
                                west: "28.92",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 830,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 831,
                                status: "available",
                                block: 45,
                                unitSize: 433.94,
                                north: "15.0",
                                east: "28.92",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 832,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 833,
                                status: "available",
                                block: 45,
                                unitSize: 434.11,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "28.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 834,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 835,
                                status: "available",
                                block: 45,
                                unitSize: 434.29,
                                north: "15.0",
                                east: "28.95",
                                south: "15.0",
                                west: "28.96",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 836,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 837,
                                status: "available",
                                block: 45,
                                unitSize: 434.46,
                                north: "15.0",
                                east: "28.96",
                                south: "15.0",
                                west: "28.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 838,
                                status: "available",
                                block: 45,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 839,
                                status: "available",
                                block: 45,
                                unitSize: 691.6,
                                north: "28.63",
                                east: "20.97",
                                south: "31.05",
                                west: "15.73",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 840,
                                status: "available",
                                block: 45,
                                unitSize: 490.24,
                                north: "31.05",
                                east: "17.0",
                                south: "26.63",
                                west: "17.57",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 841,
                                status: "available",
                                block: 45,
                                unitSize: 480.46,
                                north: "26.63",
                                east: "20.0",
                                south: "21.42",
                                west: "20.67",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 842,
                                status: "available",
                                block: 46,
                                unitSize: 463.69,
                                north: "28.93",
                                east: "12.92",
                                south: "28.0",
                                west: "20.2",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 843,
                                status: "available",
                                block: 46,
                                unitSize: 476.0,
                                north: "28.0",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 844,
                                status: "available",
                                block: 46,
                                unitSize: 351.83,
                                north: "18.6",
                                east: "17.2",
                                south: "18.0",
                                west: "21.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 845,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 846,
                                status: "available",
                                block: 46,
                                unitSize: 436.16,
                                north: "18.6",
                                east: "21.89",
                                south: "18.0",
                                west: "26.57",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 847,
                                status: "sold",
                                block: 46,
                                unitSize: 360.0,
                                north: "18.0",
                                east: "20.0",
                                south: "18.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 848,
                                status: "available",
                                block: 46,
                                unitSize: 352.88,
                                north: "15.5",
                                east: "21.57",
                                south: "15.0",
                                west: "25.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 849,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 850,
                                status: "available",
                                block: 46,
                                unitSize: 405.18,
                                north: "15.5",
                                east: "25.48",
                                south: "15.0",
                                west: "29.38",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 851,
                                status: "sold",
                                block: 46,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 852,
                                status: "available",
                                block: 46,
                                unitSize: 388.74,
                                north: "15.5",
                                east: "24.38",
                                south: "15.0",
                                west: "28.28",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 853,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 854,
                                status: "available",
                                block: 46,
                                unitSize: 453.55,
                                north: "15.5",
                                east: "28.28",
                                south: "15.0",
                                west: "32.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 855,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 856,
                                status: "available",
                                block: 46,
                                unitSize: 512.11,
                                north: "15.5",
                                east: "32.19",
                                south: "15.0",
                                west: "36.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 857,
                                status: "available",
                                block: 46,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 858,
                                status: "available",
                                block: 46,
                                unitSize: 495.67,
                                north: "15.5",
                                east: "31.09",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 859,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 860,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 861,
                                status: "sold",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 862,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 863,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 864,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 865,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 866,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 867,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 868,
                                status: "available",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 869,
                                status: "booked",
                                block: 46,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 870,
                                status: "available",
                                block: 47,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 871,
                                status: "available",
                                block: 47,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 872,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 873,
                                status: "available",
                                block: 48,
                                unitSize: 600.0,
                                north: "20.0",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 874,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 875,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 876,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 877,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 878,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 879,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 880,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 881,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 882,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 883,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 884,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 885,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 886,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 887,
                                status: "available",
                                block: 48,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 888,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 889,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 890,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 891,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 892,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 893,
                                status: "available",
                                block: 48,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 894,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 895,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 896,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 897,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 898,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 899,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 900,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 901,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 902,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 903,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 904,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 905,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 906,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 907,
                                status: "available",
                                block: 49,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 908,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 909,
                                status: "available",
                                block: 49,
                                unitSize: 660.0,
                                north: "22.0",
                                east: "30.0",
                                south: "22.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 910,
                                status: "available",
                                block: 50,
                                unitSize: 769.89,
                                north: "25.85",
                                east: "27.5",
                                south: "25.0",
                                west: "34.09",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 911,
                                status: "available",
                                block: 50,
                                unitSize: 673.83,
                                north: "25.02",
                                east: "19.09",
                                south: "30.0",
                                west: "20.99",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 912,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 913,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 914,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 915,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 916,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 917,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 918,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 919,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 920,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 921,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 922,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 923,
                                status: "available",
                                block: 50,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 924,
                                status: "available",
                                block: 50,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 925,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 926,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 927,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 928,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 929,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 930,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 931,
                                status: "available",
                                block: 51,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 932,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 933,
                                status: "available",
                                block: 51,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 934,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 935,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 936,
                                status: "available",
                                block: 51,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 937,
                                status: "available",
                                block: 51,
                                unitSize: 875.0,
                                north: "25.0",
                                east: "35.0",
                                south: "25.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 938,
                                status: "available",
                                block: 51,
                                unitSize: 900.0,
                                north: "30.0",
                                east: "30.0",
                                south: "30.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 939,
                                status: "available",
                                block: 52,
                                unitSize: 698.78,
                                north: "31.02",
                                east: "19.34",
                                south: "30.0",
                                west: "27.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 940,
                                status: "available",
                                block: 52,
                                unitSize: 733.55,
                                north: "19.85",
                                east: "37.24",
                                south: "25.0",
                                west: "27.83",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 941,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 942,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 943,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 944,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 945,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 946,
                                status: "available",
                                block: 52,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 947,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 948,
                                status: "available",
                                block: 52,
                                unitSize: 625.0,
                                north: "25.0",
                                east: "25.0",
                                south: "25.0",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 949,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 950,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 951,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 952,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 953,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 954,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 955,
                                status: "available",
                                block: 52,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 956,
                                status: "available",
                                block: 52,
                                unitSize: 1000.0,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 957,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 958,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 959,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 960,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 961,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 962,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 963,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 964,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 965,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 966,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 967,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 968,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 969,
                                status: "available",
                                block: 53,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 970,
                                status: "available",
                                block: 53,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 971,
                                status: "available",
                                block: 53,
                                unitSize: 570.0,
                                north: "30.0",
                                east: "19.0",
                                south: "30.0",
                                west: "19.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 972,
                                status: "available",
                                block: 53,
                                unitSize: 475.0,
                                north: "25.0",
                                east: "19.0",
                                south: "25.0",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 973,
                                status: "sold",
                                block: 54,
                                unitSize: 507.6,
                                north: "14.25",
                                east: "29.31",
                                south: "21.38",
                                west: "28.6",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 974,
                                status: "available",
                                block: 54,
                                unitSize: 724.12,
                                north: "21.38",
                                east: "24.65",
                                south: "22.84",
                                west: "29.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 975,
                                status: "sold",
                                block: 54,
                                unitSize: 430.28,
                                north: "15.0",
                                east: "28.6",
                                south: "15.0",
                                west: "28.77",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 976,
                                status: "available",
                                block: 54,
                                unitSize: 439.44,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 977,
                                status: "available",
                                block: 54,
                                unitSize: 432.89,
                                north: "15.0",
                                east: "28.77",
                                south: "15.0",
                                west: "28.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 978,
                                status: "available",
                                block: 54,
                                unitSize: 436.77,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 979,
                                status: "available",
                                block: 54,
                                unitSize: 434.73,
                                north: "15.0",
                                east: "28.93",
                                south: "15.0",
                                west: "29.01",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 980,
                                status: "available",
                                block: 54,
                                unitSize: 435.04,
                                north: "15.0",
                                east: "29.03",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 981,
                                status: "available",
                                block: 54,
                                unitSize: 435.4,
                                north: "15.0",
                                east: "29.01",
                                south: "15.0",
                                west: "29.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 982,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 983,
                                status: "available",
                                block: 54,
                                unitSize: 435.76,
                                north: "15.0",
                                east: "29.04",
                                south: "15.0",
                                west: "29.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 984,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 985,
                                status: "available",
                                block: 54,
                                unitSize: 436.13,
                                north: "15.0",
                                east: "29.06",
                                south: "15.0",
                                west: "29.09",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 986,
                                status: "available",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 987,
                                status: "available",
                                block: 54,
                                unitSize: 436.5,
                                north: "15.0",
                                east: "29.09",
                                south: "15.0",
                                west: "29.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 988,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 989,
                                status: "available",
                                block: 54,
                                unitSize: 436.86,
                                north: "15.0",
                                east: "29.11",
                                south: "15.0",
                                west: "29.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 990,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 991,
                                status: "available",
                                block: 54,
                                unitSize: 437.23,
                                north: "15.0",
                                east: "29.14",
                                south: "15.0",
                                west: "29.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 992,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 993,
                                status: "available",
                                block: 54,
                                unitSize: 437.59,
                                north: "15.0",
                                east: "29.16",
                                south: "15.0",
                                west: "29.19",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 994,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 995,
                                status: "available",
                                block: 54,
                                unitSize: 431.71,
                                north: "15.0",
                                east: "29.19",
                                south: "15.0",
                                west: "29.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 996,
                                status: "sold",
                                block: 54,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 997,
                                status: "available",
                                block: 54,
                                unitSize: 467.33,
                                north: "15.67",
                                east: "29.21",
                                south: "16.74",
                                west: "29.25",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 998,
                                status: "sold",
                                block: 54,
                                unitSize: 500.91,
                                north: "16.74",
                                east: "29.0",
                                south: "17.8",
                                west: "29.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 999,
                                status: "available",
                                block: 55,
                                unitSize: 629.06,
                                north: "33.27",
                                east: "15.31",
                                south: "32.18",
                                west: "23.79",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1000,
                                status: "available",
                                block: 55,
                                unitSize: 546.98,
                                north: "32.18",
                                east: "17.0",
                                south: "32.18",
                                west: "17.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1001,
                                status: "available",
                                block: 55,
                                unitSize: 428.56,
                                north: "20.68",
                                east: "18.79",
                                south: "20.0",
                                west: "24.06",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1002,
                                status: "available",
                                block: 55,
                                unitSize: 440.0,
                                north: "20.0",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1003,
                                status: "available",
                                block: 55,
                                unitSize: 345.51,
                                north: "15.5",
                                east: "21.06",
                                south: "15.0",
                                west: "24.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1004,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1005,
                                status: "available",
                                block: 55,
                                unitSize: 402.71,
                                north: "15.22",
                                east: "24.97",
                                south: "15.0",
                                west: "28.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1006,
                                status: "available",
                                block: 55,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1007,
                                status: "available",
                                block: 55,
                                unitSize: 416.78,
                                north: "9.94",
                                east: "26.73",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1008,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1009,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1010,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1011,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1012,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1013,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1014,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1015,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1016,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1017,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1018,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1019,
                                status: "available",
                                block: 55,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1020,
                                status: "available",
                                block: 55,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1021,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1022,
                                status: "available",
                                block: 56,
                                unitSize: 510.8,
                                north: "20.43",
                                east: "25.0",
                                south: "20.43",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1023,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1024,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1025,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1026,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1027,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1028,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1029,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1030,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1031,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1032,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1033,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1034,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1035,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1036,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1037,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1038,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1038,
                                status: "available",
                                block: 56,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1039,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1039,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1040,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1040,
                                status: "available",
                                block: 57,
                                unitSize: 450.0,
                                north: "18.0",
                                east: "25.0",
                                south: "18.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1041,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1041,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1042,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1042,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1043,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1043,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1044,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1044,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1045,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1045,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1046,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1046,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1047,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1047,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1048,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1049,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1050,
                                status: "available",
                                block: 57,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1051,
                                status: "available",
                                block: 57,
                                unitSize: 526.5,
                                north: "17.43",
                                east: "25.0",
                                south: "24.69",
                                west: "26.03",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1052,
                                status: "available",
                                block: 57,
                                unitSize: 707.91,
                                north: "24.69",
                                east: "25.0",
                                south: "31.94",
                                west: "26.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1053,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1053,
                                status: "available",
                                block: 58,
                                unitSize: 476.0,
                                north: "17.0",
                                east: "28.0",
                                south: "17.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1054,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1054,
                                status: "available",
                                block: 58,
                                unitSize: 459.0,
                                north: "17.0",
                                east: "27.0",
                                south: "17.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1055,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1055,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1056,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1056,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1057,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1057,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1058,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1058,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1059,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1059,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1060,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1060,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1061,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1061,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1062,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1062,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1063,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1063,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1064,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1064,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1065,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1065,
                                status: "available",
                                block: 58,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1066,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1066,
                                status: "available",
                                block: 58,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1067,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1067,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1068,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1068,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1069,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1069,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1070,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1070,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1071,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1071,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1072,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1072,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1073,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1073,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1074,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1074,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1075,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1075,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1076,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1076,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1077,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1077,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1078,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1078,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1079,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1079,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1080,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1080,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1081,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1081,
                                status: "available",
                                block: 59,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1082,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1082,
                                status: "available",
                                block: 59,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1083,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1083,
                                status: "available",
                                block: 59,
                                unitSize: 500.98,
                                north: "24.36",
                                east: "19.0",
                                south: "27.0",
                                west: "9.9",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1084,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1084,
                                status: "available",
                                block: 59,
                                unitSize: 459.0,
                                north: "27.0",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1085,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1085,
                                status: "available",
                                block: 59,
                                unitSize: 513.0,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1086,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1086,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1087,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1087,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1088,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1088,
                                status: "available",
                                block: 60,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1089,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1089,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1090,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1090,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1091,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1091,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1092,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1092,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1093,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1093,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1094,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1094,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1095,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1095,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1096,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1096,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1097,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1097,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1098,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1098,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1099,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1099,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1100,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1101,
                                status: "available",
                                block: 60,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1102,
                                status: "available",
                                block: 60,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1103,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1104,
                                status: "sold",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1105,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1106,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1107,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1108,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1109,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1110,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1111,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1112,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1113,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1114,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1115,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1116,
                                status: "available",
                                block: 61,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1117,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1118,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1119,
                                status: "available",
                                block: 61,
                                unitSize: 440.0,
                                north: "22.0",
                                east: "20.0",
                                south: "22.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1120,
                                status: "available",
                                block: 62,
                                unitSize: 579.2,
                                north: "30.02",
                                east: "19.83",
                                south: "30.0",
                                west: "18.78",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1121,
                                status: "available",
                                block: 62,
                                unitSize: 723.26,
                                north: "40.02",
                                east: "18.78",
                                south: "40.0",
                                west: "17.38",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1122,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1123,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1124,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1125,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1126,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1127,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1128,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1129,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1130,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1131,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1132,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1133,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1134,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1135,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1136,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1137,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1138,
                                status: "available",
                                block: 62,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1139,
                                status: "available",
                                block: 62,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1140,
                                status: "available",
                                block: 63,
                                unitSize: 1620.0,
                                north: "30.0",
                                east: "54.0",
                                south: "30.0",
                                west: "54.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1141,
                                status: "available",
                                block: 63,
                                unitSize: 880.0,
                                north: "40.0",
                                east: "22.0",
                                south: "40.0",
                                west: "22.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1142,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1143,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1144,
                                status: "available",
                                block: 63,
                                unitSize: 1376.52,
                                north: "30.0",
                                east: "42.0",
                                south: "30.0",
                                west: "27.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1145,
                                status: "available",
                                block: 63,
                                unitSize: 640.0,
                                north: "40.0",
                                east: "16.0",
                                south: "40.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1146,
                                status: "available",
                                block: 63,
                                unitSize: 640.64,
                                north: "40.0",
                                east: "16.0",
                                south: "40.25",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1147,
                                status: "available",
                                block: 63,
                                unitSize: 979.58,
                                north: "40.25",
                                east: "18.39",
                                south: "40.0",
                                west: "19.95",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1148,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1149,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1150,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1151,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1152,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1153,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1154,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1155,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1156,
                                status: "available",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1157,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1158,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1159,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1160,
                                status: "sold",
                                block: 63,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1161,
                                status: "available",
                                block: 63,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1162,
                                status: "sold",
                                block: 63,
                                unitSize: 540.97,
                                north: "30.0",
                                east: "18.03",
                                south: "30.0",
                                west: "18.03",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25.0",
                            },
                        },
                        {
                            Polygon: LandPlot_90_1163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1163,
                                status: "available",
                                block: 63,
                                unitSize: 721.29,
                                north: "40.0",
                                east: "18.03",
                                south: "40.0",
                                west: "18.03",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25.0",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];
