import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import unitSizeJSON from "./unit_sizes.json";

import { ReactComponent as Block_6_2 } from "./blocks/6/land_plot_block_2.svg";
import { ReactComponent as Block_6_3 } from "./blocks/6/land_plot_block_3.svg";
import { ReactComponent as Block_6_4 } from "./blocks/6/land_plot_block_4.svg";
import { ReactComponent as Block_6_5 } from "./blocks/6/land_plot_block_5.svg";
import { ReactComponent as Block_6_7 } from "./blocks/6/land_plot_block_7.svg";
import { ReactComponent as Block_6_8 } from "./blocks/6/land_plot_block_8.svg";
import { ReactComponent as Block_6_9 } from "./blocks/6/land_plot_block_9.svg";
import { ReactComponent as Block_6_10 } from "./blocks/6/land_plot_block_10.svg";
import { ReactComponent as Block_6_11 } from "./blocks/6/land_plot_block_11.svg";
import { ReactComponent as Block_6_12 } from "./blocks/6/land_plot_block_12.svg";
import { ReactComponent as Block_6_13 } from "./blocks/6/land_plot_block_13.svg";
import { ReactComponent as Block_6_14 } from "./blocks/6/land_plot_block_14.svg";
import { ReactComponent as Block_6_15 } from "./blocks/6/land_plot_block_15.svg";
import { ReactComponent as Block_6_16 } from "./blocks/6/land_plot_block_16.svg";
import { ReactComponent as Block_6_17 } from "./blocks/6/land_plot_block_17.svg";
import { ReactComponent as Block_6_18 } from "./blocks/6/land_plot_block_18.svg";
import { ReactComponent as Block_6_19 } from "./blocks/6/land_plot_block_19.svg";
import { ReactComponent as Block_6_20 } from "./blocks/6/land_plot_block_20.svg";
import { ReactComponent as Block_6_21 } from "./blocks/6/land_plot_block_21.svg";
import { ReactComponent as Block_6_22 } from "./blocks/6/land_plot_block_22.svg";
import { ReactComponent as Block_6_23 } from "./blocks/6/land_plot_block_23.svg";
import { ReactComponent as Block_6_24 } from "./blocks/6/land_plot_block_24.svg";

import { ReactComponent as Block_27_2 } from "./blocks/27/land_plot_block_2.svg";
import { ReactComponent as Block_27_3 } from "./blocks/27/land_plot_block_3.svg";
import { ReactComponent as Block_27_4 } from "./blocks/27/land_plot_block_4.svg";
import { ReactComponent as Block_27_5 } from "./blocks/27/land_plot_block_5.svg";
import { ReactComponent as Block_27_7 } from "./blocks/27/land_plot_block_7.svg";
import { ReactComponent as Block_27_8 } from "./blocks/27/land_plot_block_8.svg";
import { ReactComponent as Block_27_9 } from "./blocks/27/land_plot_block_9.svg";
import { ReactComponent as Block_27_10 } from "./blocks/27/land_plot_block_10.svg";
import { ReactComponent as Block_27_11 } from "./blocks/27/land_plot_block_11.svg";
import { ReactComponent as Block_27_12 } from "./blocks/27/land_plot_block_12.svg";
import { ReactComponent as Block_27_13 } from "./blocks/27/land_plot_block_13.svg";
import { ReactComponent as Block_27_14 } from "./blocks/27/land_plot_block_14.svg";
import { ReactComponent as Block_27_15 } from "./blocks/27/land_plot_block_15.svg";
import { ReactComponent as Block_27_16 } from "./blocks/27/land_plot_block_16.svg";
import { ReactComponent as Block_27_17 } from "./blocks/27/land_plot_block_17.svg";
import { ReactComponent as Block_27_18 } from "./blocks/27/land_plot_block_18.svg";
import { ReactComponent as Block_27_19 } from "./blocks/27/land_plot_block_19.svg";
import { ReactComponent as Block_27_20 } from "./blocks/27/land_plot_block_20.svg";
import { ReactComponent as Block_27_21 } from "./blocks/27/land_plot_block_21.svg";
import { ReactComponent as Block_27_22 } from "./blocks/27/land_plot_block_22.svg";
import { ReactComponent as Block_27_23 } from "./blocks/27/land_plot_block_23.svg";
import { ReactComponent as Block_27_24 } from "./blocks/27/land_plot_block_24.svg";

import { ReactComponent as Block_62_2 } from "./blocks/62/land_plot_block_2.svg";
import { ReactComponent as Block_62_3 } from "./blocks/62/land_plot_block_3.svg";
import { ReactComponent as Block_62_4 } from "./blocks/62/land_plot_block_4.svg";
import { ReactComponent as Block_62_5 } from "./blocks/62/land_plot_block_5.svg";
import { ReactComponent as Block_62_7 } from "./blocks/62/land_plot_block_7.svg";
import { ReactComponent as Block_62_8 } from "./blocks/62/land_plot_block_8.svg";
import { ReactComponent as Block_62_9 } from "./blocks/62/land_plot_block_9.svg";
import { ReactComponent as Block_62_10 } from "./blocks/62/land_plot_block_10.svg";
import { ReactComponent as Block_62_11 } from "./blocks/62/land_plot_block_11.svg";
import { ReactComponent as Block_62_12 } from "./blocks/62/land_plot_block_12.svg";
import { ReactComponent as Block_62_13 } from "./blocks/62/land_plot_block_13.svg";
import { ReactComponent as Block_62_14 } from "./blocks/62/land_plot_block_14.svg";
import { ReactComponent as Block_62_15 } from "./blocks/62/land_plot_block_15.svg";
import { ReactComponent as Block_62_16 } from "./blocks/62/land_plot_block_16.svg";
import { ReactComponent as Block_62_17 } from "./blocks/62/land_plot_block_17.svg";
import { ReactComponent as Block_62_18 } from "./blocks/62/land_plot_block_18.svg";
import { ReactComponent as Block_62_19 } from "./blocks/62/land_plot_block_19.svg";
import { ReactComponent as Block_62_20 } from "./blocks/62/land_plot_block_20.svg";
import { ReactComponent as Block_62_21 } from "./blocks/62/land_plot_block_21.svg";
import { ReactComponent as Block_62_22 } from "./blocks/62/land_plot_block_22.svg";
import { ReactComponent as Block_62_23 } from "./blocks/62/land_plot_block_23.svg";
import { ReactComponent as Block_62_24 } from "./blocks/62/land_plot_block_24.svg";

import { ReactComponent as Block_89_2 } from "./blocks/89/land_plot_block_2.svg";
import { ReactComponent as Block_89_3 } from "./blocks/89/land_plot_block_3.svg";
import { ReactComponent as Block_89_4 } from "./blocks/89/land_plot_block_4.svg";
import { ReactComponent as Block_89_5 } from "./blocks/89/land_plot_block_5.svg";
import { ReactComponent as Block_89_7 } from "./blocks/89/land_plot_block_7.svg";
import { ReactComponent as Block_89_8 } from "./blocks/89/land_plot_block_8.svg";
import { ReactComponent as Block_89_9 } from "./blocks/89/land_plot_block_9.svg";
import { ReactComponent as Block_89_10 } from "./blocks/89/land_plot_block_10.svg";
import { ReactComponent as Block_89_11 } from "./blocks/89/land_plot_block_11.svg";
import { ReactComponent as Block_89_12 } from "./blocks/89/land_plot_block_12.svg";
import { ReactComponent as Block_89_13 } from "./blocks/89/land_plot_block_13.svg";
import { ReactComponent as Block_89_14 } from "./blocks/89/land_plot_block_14.svg";
import { ReactComponent as Block_89_15 } from "./blocks/89/land_plot_block_15.svg";
import { ReactComponent as Block_89_16 } from "./blocks/89/land_plot_block_16.svg";
import { ReactComponent as Block_89_17 } from "./blocks/89/land_plot_block_17.svg";
import { ReactComponent as Block_89_18 } from "./blocks/89/land_plot_block_18.svg";
import { ReactComponent as Block_89_19 } from "./blocks/89/land_plot_block_19.svg";
import { ReactComponent as Block_89_20 } from "./blocks/89/land_plot_block_20.svg";
import { ReactComponent as Block_89_21 } from "./blocks/89/land_plot_block_21.svg";
import { ReactComponent as Block_89_22 } from "./blocks/89/land_plot_block_22.svg";
import { ReactComponent as Block_89_23 } from "./blocks/89/land_plot_block_23.svg";
import { ReactComponent as Block_89_24 } from "./blocks/89/land_plot_block_24.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-5/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-5/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-5/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-5/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-5/icons/2/church_5.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-5/icons/2/hospital_1.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-5/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-5/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-5/icons/2/school_3.svg";
import { ReactComponent as Museum_2_1 } from "../../../../static/images/contents/zone-5/icons/2/museum_1.svg";
import { ReactComponent as Museum_2_2 } from "../../../../static/images/contents/zone-5/icons/2/museum_2.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-5/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-5/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-5/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-5/icons/33/church_4.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-5/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-5/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-5/icons/33/school_3.svg";
import { ReactComponent as Museum_33_2 } from "../../../../static/images/contents/zone-5/icons/33/museum_2.svg";

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-5/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-5/icons/61/church_2.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-5/icons/61/church_4.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-5/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-5/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-5/icons/61/school_3.svg";
import { ReactComponent as Museum_61_2 } from "../../../../static/images/contents/zone-5/icons/61/museum_2.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-5/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-5/icons/91/church_2.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-5/icons/91/church_4.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-5/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-5/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-5/icons/91/school_3.svg";
import { ReactComponent as Museum_91_2 } from "../../../../static/images/contents/zone-5/icons/91/museum_2.svg";

export const zone5_blocks = [
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3

                            Polygon: Block_6_2,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 2,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_6_3,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 3,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_4,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 4,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_7,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 7,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_8,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 8,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_9,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 9,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_10,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 10,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_11,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 11,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_12,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 12,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_13,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 13,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_14,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 14,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_6_15,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 15,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_16,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 16,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_17,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 17,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_18,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 18,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_6_19,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 19,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_20,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 20,
                                status: "booked",
                            },
                        },
                        {
                            //3

                            Polygon: Block_6_21,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 21,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_6_22,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 22,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_23,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 23,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_6_24,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 24,
                                status: "sold",
                            },
                            
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 33,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3

                            Polygon: Block_27_2,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 2,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_27_3,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 3,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_4,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 4,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_7,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 7,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_8,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 8,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_9,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 9,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_10,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 10,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_11,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 11,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_12,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 12,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_13,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 13,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_14,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 14,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_27_15,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 15,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_16,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 16,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_17,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 17,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_18,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 18,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_27_19,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 19,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_20,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 20,
                                status: "booked",
                            },
                        },
                        {
                            //3

                            Polygon: Block_27_21,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 21,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_27_22,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 22,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_23,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 23,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_27_24,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 24,
                                status: "sold",
                            },
                            
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3

                            Polygon: Block_62_2,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 2,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_62_3,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 3,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_4,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 4,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_7,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 7,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_8,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 8,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_9,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 9,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_10,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 10,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_11,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 11,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_12,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 12,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_13,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 13,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_14,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 14,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_62_15,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 15,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_16,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 16,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_17,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 17,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_18,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 18,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_62_19,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 19,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_20,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 20,
                                status: "booked",
                            },
                        },
                        {
                            //3

                            Polygon: Block_62_21,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 21,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_62_22,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 22,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_23,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 23,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_62_24,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 24,
                                status: "sold",
                            },
                            
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3

                            Polygon: Block_89_2,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 2,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_89_3,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 3,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_4,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 4,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_7,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 7,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_8,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 8,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_9,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 9,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_10,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 10,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_11,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 11,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_12,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 12,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_13,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 13,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_14,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 14,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_89_15,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 15,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_16,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 16,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_17,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 17,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_18,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 18,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_89_19,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 19,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_20,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 20,
                                status: "booked",
                            },
                        },
                        {
                            //3

                            Polygon: Block_89_21,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 21,
                                status: "sold",
                            },
                        },
                        {
                            //3

                            Polygon: Block_89_22,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 22,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_23,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 23,
                                status: "available",
                            },
                            
                        },
                        {
                            //3

                            Polygon: Block_89_24,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 24,
                                status: "sold",
                            },
                            
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];
