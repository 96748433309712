import React from "react";
import { useTranslation } from "react-i18next";

import ServicesModal from "./ServicesModal";

import "./ServicesButton.scss";
import "./Adaptations.scss";

export default function ServicesButton({ setModalContent }) {
    const [t] = useTranslation();

    return (
        <button
            className="services-button"
            onClick={() =>
                setModalContent(
                    <ServicesModal closdFunc={() => setModalContent(null)} />
                )
            }
        >
            {t("menu_services")}
        </button>
    );
}
