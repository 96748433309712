import "./BackButton.scss";
import "./Adaptations.scss";

import BackArrowImage from "../../../../static/images/icons/back_arrow.svg";
import BackArrowImageMobile from "../../../../static/images/icons/back_arrow_mobile.svg";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";

export default function BackButton({ onClick }) {
    const [t] = useTranslation();

    const isMobile = useMediaQuery("(max-width: 800px), (max-height: 400px)");

    return (
        <div
            className="back-button"
            onClick={onClick}
            style={{ fontFamily: t("font_inter") }}
        >
            {!isMobile ? (
                <>
                    <img
                        className="back-button-icon"
                        alt="back-arrow"
                        src={BackArrowImage}
                    />
                    <span className="back-button-text">{t("back_button")}</span>
                </>
            ) : (
                <img
                    className="back-button-icon"
                    alt="back-arrow"
                    src={BackArrowImageMobile}
                />
            )}
        </div>
    );
}
