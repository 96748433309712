import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Main";

import "./Zone5.scss";
import "./Adaptations.scss";
import { zone2_land_plots } from "./land_plots";
import { zone2_blocks } from "./blocks";

import Reel from "../../../../components/main/reel/Reel";
import MasterPlan from "../master-plan/MasterPlan";
import BackButton from "../../../../components/main/menu/back-button/BackButton";
import Switch from "../../../../components/switch/Switch";
import Switch2 from "../../../../components/switch/Switch2";

import startFrame from "../../../../static/images/contents/zone-2/temp.jpg";

import { TopLogoAlZumuruda } from "../../../../components/main/menu/top-logo/TopLogo";
import ServicesButton from "../../../../components/main/menu/services/ServicesButton";
import PopUp from "../../../../components/pop-up/PopUp";

export default function Zone5() {
    const [t] = useTranslation();

    const [changeContent, setModalContent] = useContext(MainContext);
    const [switchState, setSwitchState] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const onLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div className="zone-5" style={{ width: "100%", height: "100%" }}>
            <TopLogoAlZumuruda />
            {/* <ServicesButton setModalContent={setModalContent} /> */}
            {isLoaded && (
                <>
                    <div className="zone-5-switches">
                        <Switch2
                            switchChecked={switchState}
                            handleSwitchCheck={setSwitchState}
                            labelText={t("zone_5_switch_land_plot")}
                            labelText2={t("zone_5_switch_blocks")}
                        />
                        {/* <Switch
                            switchChecked={switchState}
                            handleSwitchCheck={setSwitchState}
                            labelText={t("zone_5_switch_land_plot")}
                        />
                        <Switch
                            switchChecked={!switchState}
                            handleSwitchCheck={setSwitchState}
                            labelText={t("zone_5_switch_blocks")}
                        /> */}
                    </div>
                    <PopUp title={t("tip_title")} desc={t("tip_desc")} />
                </>
            )}
            <Reel
                folder="static/contents/zone-2/renders"
                amount={120}
                qualitySteps={1}
                defaultControlsPart={0.075}
                stops={switchState ? zone2_land_plots : zone2_blocks}
                overlay_elements={null} // Soon
                wrapper_animation_key={switchState}
                startFrame={startFrame}
                onFullLoad={onLoad}
            />

            <BackButton
                onClick={() =>
                    changeContent(<MasterPlan />, {
                        button: "menu_master_plan",
                        element: null,
                        head_title: null,
                    })
                }
            />
        </div>
    );
}
