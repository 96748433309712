import ReactSwitch from "react-switch";

import "./Switch2.scss";
import { useTranslation } from "react-i18next";

export default function Switch({
    switchChecked,
    handleSwitchCheck,
    labelText,
    labelText2
}) {
    const { t, i18n } = useTranslation();
    const switchChange = () => handleSwitchCheck((state) => !state);
    

    return (
        <div
            className="switch"
            style={{
                flexDirection: i18n.language === "en" ? "row" : "row-reverse",
            }}
            onClick={switchChange}
        >
            <div className={`motionCon ${switchChecked ? '' : 'state2'}`}
            style={{
                width: i18n.language === "ar" ? "60px" : "",
             }}
             />
            <div
                className="switch-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="switch-text">{labelText}</span>
                <span className="switch-text">{labelText2}</span>
            </div>
        </div>
    );
}
