import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import unitSizeJSON from "./unit_sizes.json";

import { ReactComponent as Block_1_64 } from "./blocks/1/land_plot_block_64.svg";
import { ReactComponent as Block_1_65 } from "./blocks/1/land_plot_block_65.svg";
import { ReactComponent as Block_1_66 } from "./blocks/1/land_plot_block_66.svg";
import { ReactComponent as Block_1_67 } from "./blocks/1/land_plot_block_67.svg";
import { ReactComponent as Block_1_68 } from "./blocks/1/land_plot_block_68.svg";
import { ReactComponent as Block_1_69 } from "./blocks/1/land_plot_block_69.svg";
import { ReactComponent as Block_1_70 } from "./blocks/1/land_plot_block_70.svg";
import { ReactComponent as Block_1_71 } from "./blocks/1/land_plot_block_71.svg";
import { ReactComponent as Block_1_72 } from "./blocks/1/land_plot_block_72.svg";
import { ReactComponent as Block_1_73 } from "./blocks/1/land_plot_block_73.svg";
import { ReactComponent as Block_1_74 } from "./blocks/1/land_plot_block_74.svg";
import { ReactComponent as Block_1_75 } from "./blocks/1/land_plot_block_75.svg";
import { ReactComponent as Block_1_76 } from "./blocks/1/land_plot_block_76.svg";
import { ReactComponent as Block_1_77 } from "./blocks/1/land_plot_block_77.svg";
import { ReactComponent as Block_1_78 } from "./blocks/1/land_plot_block_78.svg";
import { ReactComponent as Block_1_79 } from "./blocks/1/land_plot_block_79.svg";
import { ReactComponent as Block_1_80 } from "./blocks/1/land_plot_block_80.svg";
import { ReactComponent as Block_1_81 } from "./blocks/1/land_plot_block_81.svg";
import { ReactComponent as Block_1_82 } from "./blocks/1/land_plot_block_82.svg";
import { ReactComponent as Block_1_83 } from "./blocks/1/land_plot_block_83.svg";
import { ReactComponent as Block_1_84 } from "./blocks/1/land_plot_block_84.svg";

import { ReactComponent as Block_31_64 } from "./blocks/31/land_plot_block_64.svg";
import { ReactComponent as Block_31_65 } from "./blocks/31/land_plot_block_65.svg";
import { ReactComponent as Block_31_66 } from "./blocks/31/land_plot_block_66.svg";
import { ReactComponent as Block_31_67 } from "./blocks/31/land_plot_block_67.svg";
import { ReactComponent as Block_31_68 } from "./blocks/31/land_plot_block_68.svg";
import { ReactComponent as Block_31_69 } from "./blocks/31/land_plot_block_69.svg";
import { ReactComponent as Block_31_70 } from "./blocks/31/land_plot_block_70.svg";
import { ReactComponent as Block_31_71 } from "./blocks/31/land_plot_block_71.svg";
import { ReactComponent as Block_31_72 } from "./blocks/31/land_plot_block_72.svg";
import { ReactComponent as Block_31_73 } from "./blocks/31/land_plot_block_73.svg";
import { ReactComponent as Block_31_74 } from "./blocks/31/land_plot_block_74.svg";
import { ReactComponent as Block_31_75 } from "./blocks/31/land_plot_block_75.svg";
import { ReactComponent as Block_31_76 } from "./blocks/31/land_plot_block_76.svg";
import { ReactComponent as Block_31_77 } from "./blocks/31/land_plot_block_77.svg";
import { ReactComponent as Block_31_78 } from "./blocks/31/land_plot_block_78.svg";
import { ReactComponent as Block_31_79 } from "./blocks/31/land_plot_block_79.svg";
import { ReactComponent as Block_31_80 } from "./blocks/31/land_plot_block_80.svg";
import { ReactComponent as Block_31_81 } from "./blocks/31/land_plot_block_81.svg";
import { ReactComponent as Block_31_82 } from "./blocks/31/land_plot_block_82.svg";
import { ReactComponent as Block_31_83 } from "./blocks/31/land_plot_block_83.svg";
import { ReactComponent as Block_31_84 } from "./blocks/31/land_plot_block_84.svg";

import { ReactComponent as Block_61_64 } from "./blocks/61/land_plot_block_64.svg";
import { ReactComponent as Block_61_65 } from "./blocks/61/land_plot_block_65.svg";
import { ReactComponent as Block_61_66 } from "./blocks/61/land_plot_block_66.svg";
import { ReactComponent as Block_61_67 } from "./blocks/61/land_plot_block_67.svg";
import { ReactComponent as Block_61_68 } from "./blocks/61/land_plot_block_68.svg";
import { ReactComponent as Block_61_69 } from "./blocks/61/land_plot_block_69.svg";
import { ReactComponent as Block_61_70 } from "./blocks/61/land_plot_block_70.svg";
import { ReactComponent as Block_61_71 } from "./blocks/61/land_plot_block_71.svg";
import { ReactComponent as Block_61_72 } from "./blocks/61/land_plot_block_72.svg";
import { ReactComponent as Block_61_73 } from "./blocks/61/land_plot_block_73.svg";
import { ReactComponent as Block_61_74 } from "./blocks/61/land_plot_block_74.svg";
import { ReactComponent as Block_61_75 } from "./blocks/61/land_plot_block_75.svg";
import { ReactComponent as Block_61_76 } from "./blocks/61/land_plot_block_76.svg";
import { ReactComponent as Block_61_77 } from "./blocks/61/land_plot_block_77.svg";
import { ReactComponent as Block_61_78 } from "./blocks/61/land_plot_block_78.svg";
import { ReactComponent as Block_61_79 } from "./blocks/61/land_plot_block_79.svg";
import { ReactComponent as Block_61_80 } from "./blocks/61/land_plot_block_80.svg";
import { ReactComponent as Block_61_81 } from "./blocks/61/land_plot_block_81.svg";
import { ReactComponent as Block_61_82 } from "./blocks/61/land_plot_block_82.svg";
import { ReactComponent as Block_61_83 } from "./blocks/61/land_plot_block_83.svg";
import { ReactComponent as Block_61_84 } from "./blocks/61/land_plot_block_84.svg";

import { ReactComponent as Block_91_64 } from "./blocks/91/land_plot_block_64.svg";
import { ReactComponent as Block_91_65 } from "./blocks/91/land_plot_block_65.svg";
import { ReactComponent as Block_91_66 } from "./blocks/91/land_plot_block_66.svg";
import { ReactComponent as Block_91_67 } from "./blocks/91/land_plot_block_67.svg";
import { ReactComponent as Block_91_68 } from "./blocks/91/land_plot_block_68.svg";
import { ReactComponent as Block_91_69 } from "./blocks/91/land_plot_block_69.svg";
import { ReactComponent as Block_91_70 } from "./blocks/91/land_plot_block_70.svg";
import { ReactComponent as Block_91_71 } from "./blocks/91/land_plot_block_71.svg";
import { ReactComponent as Block_91_72 } from "./blocks/91/land_plot_block_72.svg";
import { ReactComponent as Block_91_73 } from "./blocks/91/land_plot_block_73.svg";
import { ReactComponent as Block_91_74 } from "./blocks/91/land_plot_block_74.svg";
import { ReactComponent as Block_91_75 } from "./blocks/91/land_plot_block_75.svg";
import { ReactComponent as Block_91_76 } from "./blocks/91/land_plot_block_76.svg";
import { ReactComponent as Block_91_77 } from "./blocks/91/land_plot_block_77.svg";
import { ReactComponent as Block_91_78 } from "./blocks/91/land_plot_block_78.svg";
import { ReactComponent as Block_91_79 } from "./blocks/91/land_plot_block_79.svg";
import { ReactComponent as Block_91_80 } from "./blocks/91/land_plot_block_80.svg";
import { ReactComponent as Block_91_81 } from "./blocks/91/land_plot_block_81.svg";
import { ReactComponent as Block_91_82 } from "./blocks/91/land_plot_block_82.svg";
import { ReactComponent as Block_91_83 } from "./blocks/91/land_plot_block_83.svg";
import { ReactComponent as Block_91_84 } from "./blocks/91/land_plot_block_84.svg";

export const zone2_blocks = [
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_1_64,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 64,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_65,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 65,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_1_66,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 66,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_67,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 67,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_68,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 68,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_69,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 69,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_70,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 70,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_71,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 71,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_72,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 72,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_1_73,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 73,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_1_74,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 74,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_75,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 75,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_76,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 76,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_77,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 77,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_78,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 78,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_1_79,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 79,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_1_80,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 80,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_81,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 81,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_82,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 82,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_83,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_84,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "booked",
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 32,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_31_64,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 64,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_65,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 65,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_31_66,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 66,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_67,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 67,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_68,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 68,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_69,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 69,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_70,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 70,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_71,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 71,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_72,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 72,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_31_73,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 73,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_31_74,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 74,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_75,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 75,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_76,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 76,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_77,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 77,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_78,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 78,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_31_79,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 79,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_31_80,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 80,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_81,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 81,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_82,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 82,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_83,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_84,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "booked",
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 62,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_61_64,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 64,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_65,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 65,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_61_66,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 66,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_67,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 67,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_68,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 68,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_69,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 69,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_70,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 70,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_71,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 71,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_72,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 72,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_61_73,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 73,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_61_74,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 74,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_75,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 75,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_76,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 76,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_77,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 77,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_78,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 78,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_61_79,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 79,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_61_80,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 80,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_81,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 81,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_82,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 82,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_83,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_84,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "booked",
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 92,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_91_64,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 64,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_65,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 65,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_91_66,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 66,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_67,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 67,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_68,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 68,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_69,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 69,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_70,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 70,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_71,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 71,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_72,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 72,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_91_73,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 73,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_91_74,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 74,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_75,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 75,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_76,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 76,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_77,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 77,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_78,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 78,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_91_79,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 79,
                                status: "booked",
                            },
                        },
                        {
                            Polygon: Block_91_80,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 80,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_81,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 81,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_82,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 82,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_83,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_91_84,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 83,
                                status: "booked",
                            },
                        },
                    ]}
                />
            </>
        ),
    },
];
