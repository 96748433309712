import React from "react";
import { motion } from "framer-motion";

import "./Modal.scss";
import "./Adaptations.scss";
import { modal_animation } from "./animations";

import { ReactComponent as BackSVG } from "../../static/images/icons/back.svg";
import { useTranslation } from "react-i18next";

export function ModalExit({ closeFunc }) {
    const { t } = useTranslation();
    return (
        <button className="modal-exit" onClick={closeFunc}>
            <BackSVG />
            {t("back_button")}
        </button>
    );
}

export default function Modal({ children, closeFunc }) {
    return (
        <motion.div className="modal" {...modal_animation} onClick={closeFunc}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            closeFunc: closeFunc,
                        });
                    }

                    return child;
                })}
            </div>
        </motion.div>
    );
}
