import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import "./ContactForm.scss";
import "./Adaptations.scss";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";

import CrossThinImage from "../../../static/images/icons/cross-thin.svg";
import FullNameIcon from "../../../static/images/icons/user.svg";
import EmailIcon from "../../../static/images/icons/email.svg";

import SeparatingLineVertical from "../../../static/images/contact-form-line-vertical.svg";
import SeparatingLineHorizontal from "../../../static/images/contact-form-line-horizontal.svg";

import ElectricityImage from "../../../static/images/contact-form-electricity.svg";
import WaterImage from "../../../static/images/contact-form-water.svg";
import RoadsImage from "../../../static/images/contact-form-roads.svg";
import DrainageImage from "../../../static/images/contact-form-drainage.svg";
import i18next from "i18next";

function ContactFormStatus({ t }) {
    return (
        <div className="contact-form-status">
            <div
                className="contact-form-status-title"
                style={{ fontFamily: t("font_salvager") }}
            >
                <span className="contact-form-status-title-text">
                    {t("contact_form_sent_your_way")}
                </span>
            </div>
            <div
                className="contact-form-status-desc"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-status-desc-text">
                    {t("contact_form_would_be_appreciated")}
                </span>
            </div>
        </div>
    );
}

function ContactFormField({
    label,
    name,
    id,
    type,
    icon,
    placeholder,
    t,
    i18,
}) {
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                <img
                    src={icon}
                    alt={`${label} icon`}
                    className="contact-form-field-icon"
                />
                <input
                    // className="contact-form-field-input"
                    className="contact-form-field-input"
                    name={name}
                    id={id}
                    type={type}
                    required
                    placeholder={placeholder}
                    style={{
                        textAlign: i18.language === "ar" ? "right" : "left",
                    }}
                />
            </div>
        </div>
    );
}

function ContactFormPhoneField({
    label,
    placeholder,
    phoneInput,
    setPhoneInput,
    t,
}) {
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                <PhoneInput
                    placeholder={placeholder}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="SA"
                    value={phoneInput}
                    onChange={setPhoneInput}
                    withCountryCallingCode={true}
                />
            </div>
        </div>
    );
}

function ContactFormInfo({ label, value, unit }) {
    const [t, i18] = useTranslation();

    return (
        <div
            className="contact-form-info"
            style={{
                flexDirection: i18.language === "ar" ? "row-reverse" : "row",
                fontFamily: i18.language === "ar" ? "PNU" : "inter",
            }}
        >
            <span className="contact-form-info-label">{label}</span>
            {i18.language === "ar" ? (
                <span className="contact-form-info-value">
                    <div
                        style={{
                            marginRight: "5px",
                        }}
                    >
                        {unit}
                    </div>
                    {value}
                </span>
            ) : (
                <span className="contact-form-info-value">
                    {value}
                    <div style={{ marginLeft: "5px" }}>{unit}</div>
                </span>
            )}
            {/* <span className="contact-form-info-value">{value}</span> */}
        </div>
    );
}

function ContactFormText({ text, className }) {
    const [t, i18] = useTranslation();
    return (
        <div
            className={"contact-form-text " + (className ? className : "")}
            style={{ fontFamily: i18.language === "ar" ? "PNU" : "inter" }}
        >
            <span className="contact-form-text-label">{text}</span>
        </div>
    );
}

function ContactFormIcon({ src }) {
    return (
        <div className="contact-form-icon">
            <img src={src} alt="form-icon" />
        </div>
    );
}

export default function ContactForm({
    form_id,
    title,
    form_type,
    closeFunc,
    electricTransformer,
    unitSize,
    blockSize,
    block,
    north,
    east,
    south,
    west,
    front1,
    front2,
    st_width_1,
    st_width_2,
    numberOfUnits,
}) {
    const [t, i18] = useTranslation();
    const isMobile = useMediaQuery("(max-width: 600px)");

    const [status, setStatus] = useState("");

    const [phoneInput, setPhoneInput] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        formData.append("Info", t(title));
        formData.append("Phone", phoneInput);

        try {
            const response = await fetch(`https://formspree.io/f/${form_id}`, {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                },
            });

            await fetch(
                `https://alaqtar-demo5-api.visengine.app/spreadsheet/add_row/`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setStatus("Form submitted successfully!");
                form.reset();
            } else {
                setStatus("Failed to submit the form. Please try again.");
            }
        } catch (error) {
            setStatus("Failed to submit the form. Please try again.");
        }
    };

    return (
        <div className="contact-form">
            <div className="contact-form-close" onClick={closeFunc}>
                <img src={CrossThinImage} alt="cross-close" />
            </div>
            {status ? (
                <ContactFormStatus t={t} />
            ) : (
                <>
                    <div
                        className="contact-form-left"
                        style={{
                            textAlign: i18.language === "ar" ? "right" : "left",
                        }}
                    >
                        {form_type === "land" ? (
                            <>
                                <div className="contact-form-left-group">
                                    <ContactFormText
                                        text={
                                            t("contact_form_block") +
                                            "\u00A0\u00A0" +
                                            block
                                        }
                                    />
                                    <div
                                        className="contact-form-title"
                                        style={{
                                            // fontFamily: t("font_salvager"),
                                            fontFamily:
                                                i18.language === "ar"
                                                    ? "Etab"
                                                    : "SALVAGER",
                                        }}
                                    >
                                        <span className="contact-form-title-text">
                                            {title}
                                        </span>
                                    </div>
                                </div>
                                <ContactFormInfo
                                    label={t("unit_size")}
                                    // value={unitSize + " " + t("square_meters")}
                                    value={unitSize}
                                    unit={t("square_meters")}
                                />
                                <div className="contact-form-position-info contact-form-position-info-nsew">
                                    <ContactFormInfo
                                        label={t("contact_form_north")}
                                        value={north}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_east")}
                                        value={east}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_south")}
                                        value={south}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_west")}
                                        value={west}
                                    />
                                </div>
                                <div className="contact-form-left-group">
                                    <div className="contact-form-position-info">
                                        <ContactFormInfo
                                            label={t("contact_form_front1")}
                                            value={t(
                                                "contact_form_front_" +
                                                    front1.toLowerCase()
                                            )}
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_front2")}
                                            value={
                                                front2 === "nan"
                                                    ? "-"
                                                    : t(
                                                          "contact_form_front_" +
                                                              front2.toLowerCase()
                                                      )
                                            }
                                        />
                                    </div>
                                    <div className="contact-form-position-info">
                                        <ContactFormInfo
                                            label={t("contact_form_st_width_1")}
                                            value={
                                                // st_width_1 + " " + t("meters")
                                                st_width_1
                                            }
                                            unit={t("meters")}
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_st_width_2")}
                                            value={
                                                st_width_2 === "0"
                                                    ? "-"
                                                    : st_width_2
                                                // : st_width_2 +
                                                //   " " +
                                                //   t("meters")
                                            }
                                            unit={t("meters")}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            form_type === "block" && (
                                <>
                                    <div
                                        className="contact-form-title"
                                        style={{
                                            // fontFamily: t("font_salvager"),
                                            fontFamily:
                                                i18.language === "ar"
                                                    ? "Etab"
                                                    : "SALVAGER",
                                        }}
                                    >
                                        <span className="contact-form-title-text">
                                            {title}
                                        </span>
                                    </div>
                                    <ContactFormInfo
                                        label={t("number_of_units")}
                                        value={numberOfUnits}
                                    />
                                    <ContactFormInfo
                                        label={t("block_size")}
                                        value={blockSize}
                                        unit={t("square_meters")}
                                    />
                                </>
                            )
                        )}

                        <div className="contact-form-left-group">
                            {electricTransformer || form_type === "block" ? (
                                <ContactFormText
                                    text={t("contact_from_transformer_info")}
                                    className="contact-form-text-color contact-form-text-transformer"
                                />
                            ) : null}
                            <div className="contact-form-icons">
                                <ContactFormIcon src={ElectricityImage} />
                                <ContactFormIcon src={WaterImage} />
                                <ContactFormIcon src={RoadsImage} />
                                <ContactFormIcon src={DrainageImage} />
                            </div>
                        </div>
                    </div>
                    <div className="contact-form-separator">
                        <img
                            src={
                                isMobile
                                    ? SeparatingLineHorizontal
                                    : SeparatingLineVertical
                            }
                            alt="separating-line"
                        />
                    </div>
                    <div
                        className="contact-form-right"
                        style={{
                            textAlign: i18.language === "ar" ? "right" : "left",
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <ContactFormField
                                label={t("contact_form_full_name")}
                                name="Name"
                                id="name"
                                type="text"
                                icon={FullNameIcon}
                                placeholder="Olivia Dou"
                                t={t}
                                i18={i18}
                            />
                            <ContactFormField
                                label={t("contact_form_email")}
                                name="Email"
                                id="email"
                                type="email"
                                icon={EmailIcon}
                                placeholder="olivia@untitledui.com"
                                t={t}
                                i18={i18}
                            />
                            <ContactFormPhoneField
                                label={t("contact_form_phone_number")}
                                placeholder="+9 (660) 000-0000"
                                phoneInput={phoneInput}
                                setPhoneInput={setPhoneInput}
                                t={t}
                                i18={i18}
                            />
                            <button
                                className="contact-form-submit"
                                type="submit"
                                style={{
                                    fontFamily: t("font_salvager"),
                                    fontSize:
                                        i18.language === "ar" ? "32px" : "",
                                }}
                            >
                                {t("contact_form_send")}
                            </button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
}
