import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

//stop frame 6
import { ReactComponent as LandPlot_6_3 } from "./land_plots/6/land_plot_block_3.svg";
import { ReactComponent as LandPlot_6_4 } from "./land_plots/6/land_plot_block_4.svg";
import { ReactComponent as LandPlot_6_5 } from "./land_plots/6/land_plot_block_5.svg";
import { ReactComponent as LandPlot_6_6 } from "./land_plots/6/land_plot_block_6.svg";
import { ReactComponent as LandPlot_6_7 } from "./land_plots/6/land_plot_block_7.svg";
import { ReactComponent as LandPlot_6_8 } from "./land_plots/6/land_plot_block_8.svg";
import { ReactComponent as LandPlot_6_9 } from "./land_plots/6/land_plot_block_9.svg";
import { ReactComponent as LandPlot_6_10 } from "./land_plots/6/land_plot_block_10.svg";
import { ReactComponent as LandPlot_6_11 } from "./land_plots/6/land_plot_block_11.svg";
import { ReactComponent as LandPlot_6_12 } from "./land_plots/6/land_plot_block_12.svg";
import { ReactComponent as LandPlot_6_13 } from "./land_plots/6/land_plot_block_13.svg";
import { ReactComponent as LandPlot_6_14 } from "./land_plots/6/land_plot_block_14.svg";
import { ReactComponent as LandPlot_6_15 } from "./land_plots/6/land_plot_block_15.svg";
import { ReactComponent as LandPlot_6_16 } from "./land_plots/6/land_plot_block_16.svg";
import { ReactComponent as LandPlot_6_17 } from "./land_plots/6/land_plot_block_17.svg";
import { ReactComponent as LandPlot_6_18 } from "./land_plots/6/land_plot_block_18.svg";
import { ReactComponent as LandPlot_6_19 } from "./land_plots/6/land_plot_block_19.svg";
import { ReactComponent as LandPlot_6_20 } from "./land_plots/6/land_plot_block_20.svg";
import { ReactComponent as LandPlot_6_21 } from "./land_plots/6/land_plot_block_21.svg";
import { ReactComponent as LandPlot_6_22 } from "./land_plots/6/land_plot_block_22.svg";
import { ReactComponent as LandPlot_6_23 } from "./land_plots/6/land_plot_block_23.svg";
import { ReactComponent as LandPlot_6_24 } from "./land_plots/6/land_plot_block_24.svg";
import { ReactComponent as LandPlot_6_25 } from "./land_plots/6/land_plot_block_25.svg";
import { ReactComponent as LandPlot_6_26 } from "./land_plots/6/land_plot_block_26.svg";
import { ReactComponent as LandPlot_6_27 } from "./land_plots/6/land_plot_block_27.svg";
import { ReactComponent as LandPlot_6_28 } from "./land_plots/6/land_plot_block_28.svg";
import { ReactComponent as LandPlot_6_29 } from "./land_plots/6/land_plot_block_29.svg";
import { ReactComponent as LandPlot_6_30 } from "./land_plots/6/land_plot_block_30.svg";
import { ReactComponent as LandPlot_6_31 } from "./land_plots/6/land_plot_block_31.svg";
import { ReactComponent as LandPlot_6_32 } from "./land_plots/6/land_plot_block_32.svg";
import { ReactComponent as LandPlot_6_33 } from "./land_plots/6/land_plot_block_33.svg";
import { ReactComponent as LandPlot_6_34 } from "./land_plots/6/land_plot_block_34.svg";
import { ReactComponent as LandPlot_6_35 } from "./land_plots/6/land_plot_block_35.svg";
import { ReactComponent as LandPlot_6_36 } from "./land_plots/6/land_plot_block_36.svg";
import { ReactComponent as LandPlot_6_37 } from "./land_plots/6/land_plot_block_37.svg";
import { ReactComponent as LandPlot_6_38 } from "./land_plots/6/land_plot_block_38.svg";
import { ReactComponent as LandPlot_6_39 } from "./land_plots/6/land_plot_block_39.svg";
import { ReactComponent as LandPlot_6_40 } from "./land_plots/6/land_plot_block_40.svg";
import { ReactComponent as LandPlot_6_41 } from "./land_plots/6/land_plot_block_41.svg";
import { ReactComponent as LandPlot_6_42 } from "./land_plots/6/land_plot_block_42.svg";
import { ReactComponent as LandPlot_6_43 } from "./land_plots/6/land_plot_block_43.svg";
import { ReactComponent as LandPlot_6_44 } from "./land_plots/6/land_plot_block_44.svg";
import { ReactComponent as LandPlot_6_45 } from "./land_plots/6/land_plot_block_45.svg";
import { ReactComponent as LandPlot_6_46 } from "./land_plots/6/land_plot_block_46.svg";
import { ReactComponent as LandPlot_6_47 } from "./land_plots/6/land_plot_block_47.svg";
import { ReactComponent as LandPlot_6_48 } from "./land_plots/6/land_plot_block_48.svg";
import { ReactComponent as LandPlot_6_49 } from "./land_plots/6/land_plot_block_49.svg";
import { ReactComponent as LandPlot_6_50 } from "./land_plots/6/land_plot_block_50.svg";
import { ReactComponent as LandPlot_6_51 } from "./land_plots/6/land_plot_block_51.svg";
import { ReactComponent as LandPlot_6_52 } from "./land_plots/6/land_plot_block_52.svg";
import { ReactComponent as LandPlot_6_53 } from "./land_plots/6/land_plot_block_53.svg";
import { ReactComponent as LandPlot_6_54 } from "./land_plots/6/land_plot_block_54.svg";
import { ReactComponent as LandPlot_6_62 } from "./land_plots/6/land_plot_block_62.svg";
import { ReactComponent as LandPlot_6_63 } from "./land_plots/6/land_plot_block_63.svg";
import { ReactComponent as LandPlot_6_64 } from "./land_plots/6/land_plot_block_64.svg";
import { ReactComponent as LandPlot_6_65 } from "./land_plots/6/land_plot_block_65.svg";
import { ReactComponent as LandPlot_6_66 } from "./land_plots/6/land_plot_block_66.svg";
import { ReactComponent as LandPlot_6_67 } from "./land_plots/6/land_plot_block_67.svg";
import { ReactComponent as LandPlot_6_68 } from "./land_plots/6/land_plot_block_68.svg";
import { ReactComponent as LandPlot_6_69 } from "./land_plots/6/land_plot_block_69.svg";
import { ReactComponent as LandPlot_6_70 } from "./land_plots/6/land_plot_block_70.svg";
import { ReactComponent as LandPlot_6_71 } from "./land_plots/6/land_plot_block_71.svg";
import { ReactComponent as LandPlot_6_72 } from "./land_plots/6/land_plot_block_72.svg";
import { ReactComponent as LandPlot_6_73 } from "./land_plots/6/land_plot_block_73.svg";
import { ReactComponent as LandPlot_6_74 } from "./land_plots/6/land_plot_block_74.svg";
import { ReactComponent as LandPlot_6_75 } from "./land_plots/6/land_plot_block_75.svg";
import { ReactComponent as LandPlot_6_76 } from "./land_plots/6/land_plot_block_76.svg";
import { ReactComponent as LandPlot_6_77 } from "./land_plots/6/land_plot_block_77.svg";
import { ReactComponent as LandPlot_6_78 } from "./land_plots/6/land_plot_block_78.svg";
import { ReactComponent as LandPlot_6_79 } from "./land_plots/6/land_plot_block_79.svg";
import { ReactComponent as LandPlot_6_80 } from "./land_plots/6/land_plot_block_80.svg";
import { ReactComponent as LandPlot_6_81 } from "./land_plots/6/land_plot_block_81.svg";
import { ReactComponent as LandPlot_6_82 } from "./land_plots/6/land_plot_block_82.svg";
import { ReactComponent as LandPlot_6_83 } from "./land_plots/6/land_plot_block_83.svg";
import { ReactComponent as LandPlot_6_84 } from "./land_plots/6/land_plot_block_84.svg";
import { ReactComponent as LandPlot_6_85 } from "./land_plots/6/land_plot_block_85.svg";
import { ReactComponent as LandPlot_6_86 } from "./land_plots/6/land_plot_block_86.svg";
import { ReactComponent as LandPlot_6_87 } from "./land_plots/6/land_plot_block_87.svg";
import { ReactComponent as LandPlot_6_88 } from "./land_plots/6/land_plot_block_88.svg";
import { ReactComponent as LandPlot_6_89 } from "./land_plots/6/land_plot_block_89.svg";
import { ReactComponent as LandPlot_6_90 } from "./land_plots/6/land_plot_block_90.svg";
import { ReactComponent as LandPlot_6_91 } from "./land_plots/6/land_plot_block_91.svg";
import { ReactComponent as LandPlot_6_92 } from "./land_plots/6/land_plot_block_92.svg";
import { ReactComponent as LandPlot_6_93 } from "./land_plots/6/land_plot_block_93.svg";
import { ReactComponent as LandPlot_6_94 } from "./land_plots/6/land_plot_block_94.svg";
import { ReactComponent as LandPlot_6_95 } from "./land_plots/6/land_plot_block_95.svg";
import { ReactComponent as LandPlot_6_96 } from "./land_plots/6/land_plot_block_96.svg";
import { ReactComponent as LandPlot_6_97 } from "./land_plots/6/land_plot_block_97.svg";
import { ReactComponent as LandPlot_6_98 } from "./land_plots/6/land_plot_block_98.svg";
import { ReactComponent as LandPlot_6_99 } from "./land_plots/6/land_plot_block_99.svg";
import { ReactComponent as LandPlot_6_100 } from "./land_plots/6/land_plot_block_100.svg";
import { ReactComponent as LandPlot_6_101 } from "./land_plots/6/land_plot_block_101.svg";
import { ReactComponent as LandPlot_6_102 } from "./land_plots/6/land_plot_block_102.svg";
import { ReactComponent as LandPlot_6_103 } from "./land_plots/6/land_plot_block_103.svg";
import { ReactComponent as LandPlot_6_104 } from "./land_plots/6/land_plot_block_104.svg";
import { ReactComponent as LandPlot_6_105 } from "./land_plots/6/land_plot_block_105.svg";
import { ReactComponent as LandPlot_6_106 } from "./land_plots/6/land_plot_block_106.svg";
import { ReactComponent as LandPlot_6_107 } from "./land_plots/6/land_plot_block_107.svg";
import { ReactComponent as LandPlot_6_108 } from "./land_plots/6/land_plot_block_108.svg";
import { ReactComponent as LandPlot_6_109 } from "./land_plots/6/land_plot_block_109.svg";
import { ReactComponent as LandPlot_6_110 } from "./land_plots/6/land_plot_block_110.svg";
import { ReactComponent as LandPlot_6_111 } from "./land_plots/6/land_plot_block_111.svg";
import { ReactComponent as LandPlot_6_112 } from "./land_plots/6/land_plot_block_112.svg";
import { ReactComponent as LandPlot_6_113 } from "./land_plots/6/land_plot_block_113.svg";
import { ReactComponent as LandPlot_6_114 } from "./land_plots/6/land_plot_block_114.svg";
import { ReactComponent as LandPlot_6_115 } from "./land_plots/6/land_plot_block_115.svg";
import { ReactComponent as LandPlot_6_116 } from "./land_plots/6/land_plot_block_116.svg";
import { ReactComponent as LandPlot_6_117 } from "./land_plots/6/land_plot_block_117.svg";
import { ReactComponent as LandPlot_6_118 } from "./land_plots/6/land_plot_block_118.svg";
import { ReactComponent as LandPlot_6_119 } from "./land_plots/6/land_plot_block_119.svg";
import { ReactComponent as LandPlot_6_120 } from "./land_plots/6/land_plot_block_120.svg";
import { ReactComponent as LandPlot_6_121 } from "./land_plots/6/land_plot_block_121.svg";
import { ReactComponent as LandPlot_6_122 } from "./land_plots/6/land_plot_block_122.svg";
import { ReactComponent as LandPlot_6_123 } from "./land_plots/6/land_plot_block_123.svg";
import { ReactComponent as LandPlot_6_124 } from "./land_plots/6/land_plot_block_124.svg";
import { ReactComponent as LandPlot_6_125 } from "./land_plots/6/land_plot_block_125.svg";
import { ReactComponent as LandPlot_6_126 } from "./land_plots/6/land_plot_block_126.svg";
import { ReactComponent as LandPlot_6_127 } from "./land_plots/6/land_plot_block_127.svg";
import { ReactComponent as LandPlot_6_128 } from "./land_plots/6/land_plot_block_128.svg";
import { ReactComponent as LandPlot_6_129 } from "./land_plots/6/land_plot_block_129.svg";
import { ReactComponent as LandPlot_6_130 } from "./land_plots/6/land_plot_block_130.svg";
import { ReactComponent as LandPlot_6_131 } from "./land_plots/6/land_plot_block_131.svg";
import { ReactComponent as LandPlot_6_132 } from "./land_plots/6/land_plot_block_132.svg";
import { ReactComponent as LandPlot_6_133 } from "./land_plots/6/land_plot_block_133.svg";
import { ReactComponent as LandPlot_6_134 } from "./land_plots/6/land_plot_block_134.svg";
import { ReactComponent as LandPlot_6_135 } from "./land_plots/6/land_plot_block_135.svg";
import { ReactComponent as LandPlot_6_136 } from "./land_plots/6/land_plot_block_136.svg";
import { ReactComponent as LandPlot_6_137 } from "./land_plots/6/land_plot_block_137.svg";
import { ReactComponent as LandPlot_6_138 } from "./land_plots/6/land_plot_block_138.svg";
import { ReactComponent as LandPlot_6_139 } from "./land_plots/6/land_plot_block_139.svg";
import { ReactComponent as LandPlot_6_140 } from "./land_plots/6/land_plot_block_140.svg";
import { ReactComponent as LandPlot_6_141 } from "./land_plots/6/land_plot_block_141.svg";
import { ReactComponent as LandPlot_6_142 } from "./land_plots/6/land_plot_block_142.svg";
import { ReactComponent as LandPlot_6_143 } from "./land_plots/6/land_plot_block_143.svg";
import { ReactComponent as LandPlot_6_144 } from "./land_plots/6/land_plot_block_144.svg";
import { ReactComponent as LandPlot_6_145 } from "./land_plots/6/land_plot_block_145.svg";
import { ReactComponent as LandPlot_6_146 } from "./land_plots/6/land_plot_block_146.svg";
import { ReactComponent as LandPlot_6_147 } from "./land_plots/6/land_plot_block_147.svg";
import { ReactComponent as LandPlot_6_148 } from "./land_plots/6/land_plot_block_148.svg";
import { ReactComponent as LandPlot_6_149 } from "./land_plots/6/land_plot_block_149.svg";
import { ReactComponent as LandPlot_6_150 } from "./land_plots/6/land_plot_block_150.svg";
import { ReactComponent as LandPlot_6_151 } from "./land_plots/6/land_plot_block_151.svg";
import { ReactComponent as LandPlot_6_152 } from "./land_plots/6/land_plot_block_152.svg";
import { ReactComponent as LandPlot_6_153 } from "./land_plots/6/land_plot_block_153.svg";
import { ReactComponent as LandPlot_6_154 } from "./land_plots/6/land_plot_block_154.svg";
import { ReactComponent as LandPlot_6_155 } from "./land_plots/6/land_plot_block_155.svg";
import { ReactComponent as LandPlot_6_156 } from "./land_plots/6/land_plot_block_156.svg";
import { ReactComponent as LandPlot_6_157 } from "./land_plots/6/land_plot_block_157.svg";
import { ReactComponent as LandPlot_6_158 } from "./land_plots/6/land_plot_block_158.svg";
import { ReactComponent as LandPlot_6_159 } from "./land_plots/6/land_plot_block_159.svg";
import { ReactComponent as LandPlot_6_160 } from "./land_plots/6/land_plot_block_160.svg";
import { ReactComponent as LandPlot_6_161 } from "./land_plots/6/land_plot_block_161.svg";
import { ReactComponent as LandPlot_6_162 } from "./land_plots/6/land_plot_block_162.svg";
import { ReactComponent as LandPlot_6_163 } from "./land_plots/6/land_plot_block_163.svg";
import { ReactComponent as LandPlot_6_164 } from "./land_plots/6/land_plot_block_164.svg";
import { ReactComponent as LandPlot_6_165 } from "./land_plots/6/land_plot_block_165.svg";
import { ReactComponent as LandPlot_6_166 } from "./land_plots/6/land_plot_block_166.svg";
import { ReactComponent as LandPlot_6_167 } from "./land_plots/6/land_plot_block_167.svg";
import { ReactComponent as LandPlot_6_168 } from "./land_plots/6/land_plot_block_168.svg";
import { ReactComponent as LandPlot_6_169 } from "./land_plots/6/land_plot_block_169.svg";
import { ReactComponent as LandPlot_6_170 } from "./land_plots/6/land_plot_block_170.svg";
import { ReactComponent as LandPlot_6_171 } from "./land_plots/6/land_plot_block_171.svg";
import { ReactComponent as LandPlot_6_172 } from "./land_plots/6/land_plot_block_172.svg";
import { ReactComponent as LandPlot_6_173 } from "./land_plots/6/land_plot_block_173.svg";
import { ReactComponent as LandPlot_6_174 } from "./land_plots/6/land_plot_block_174.svg";
import { ReactComponent as LandPlot_6_175 } from "./land_plots/6/land_plot_block_175.svg";
import { ReactComponent as LandPlot_6_176 } from "./land_plots/6/land_plot_block_176.svg";
import { ReactComponent as LandPlot_6_177 } from "./land_plots/6/land_plot_block_177.svg";
import { ReactComponent as LandPlot_6_178 } from "./land_plots/6/land_plot_block_178.svg";
import { ReactComponent as LandPlot_6_179 } from "./land_plots/6/land_plot_block_179.svg";
import { ReactComponent as LandPlot_6_180 } from "./land_plots/6/land_plot_block_180.svg";
import { ReactComponent as LandPlot_6_181 } from "./land_plots/6/land_plot_block_181.svg";
import { ReactComponent as LandPlot_6_182 } from "./land_plots/6/land_plot_block_182.svg";
import { ReactComponent as LandPlot_6_183 } from "./land_plots/6/land_plot_block_183.svg";
import { ReactComponent as LandPlot_6_184 } from "./land_plots/6/land_plot_block_184.svg";
import { ReactComponent as LandPlot_6_185 } from "./land_plots/6/land_plot_block_185.svg";
import { ReactComponent as LandPlot_6_186 } from "./land_plots/6/land_plot_block_186.svg";
import { ReactComponent as LandPlot_6_187 } from "./land_plots/6/land_plot_block_187.svg";
import { ReactComponent as LandPlot_6_188 } from "./land_plots/6/land_plot_block_188.svg";
import { ReactComponent as LandPlot_6_189 } from "./land_plots/6/land_plot_block_189.svg";
import { ReactComponent as LandPlot_6_190 } from "./land_plots/6/land_plot_block_190.svg";
import { ReactComponent as LandPlot_6_191 } from "./land_plots/6/land_plot_block_191.svg";
import { ReactComponent as LandPlot_6_192 } from "./land_plots/6/land_plot_block_192.svg";
import { ReactComponent as LandPlot_6_193 } from "./land_plots/6/land_plot_block_193.svg";
import { ReactComponent as LandPlot_6_194 } from "./land_plots/6/land_plot_block_194.svg";
import { ReactComponent as LandPlot_6_195 } from "./land_plots/6/land_plot_block_195.svg";
import { ReactComponent as LandPlot_6_196 } from "./land_plots/6/land_plot_block_196.svg";
import { ReactComponent as LandPlot_6_197 } from "./land_plots/6/land_plot_block_197.svg";
import { ReactComponent as LandPlot_6_198 } from "./land_plots/6/land_plot_block_198.svg";
import { ReactComponent as LandPlot_6_199 } from "./land_plots/6/land_plot_block_199.svg";
import { ReactComponent as LandPlot_6_200 } from "./land_plots/6/land_plot_block_200.svg";
import { ReactComponent as LandPlot_6_201 } from "./land_plots/6/land_plot_block_201.svg";
import { ReactComponent as LandPlot_6_202 } from "./land_plots/6/land_plot_block_202.svg";
import { ReactComponent as LandPlot_6_203 } from "./land_plots/6/land_plot_block_203.svg";
import { ReactComponent as LandPlot_6_204 } from "./land_plots/6/land_plot_block_204.svg";
import { ReactComponent as LandPlot_6_205 } from "./land_plots/6/land_plot_block_205.svg";
import { ReactComponent as LandPlot_6_206 } from "./land_plots/6/land_plot_block_206.svg";
import { ReactComponent as LandPlot_6_207 } from "./land_plots/6/land_plot_block_207.svg";
import { ReactComponent as LandPlot_6_208 } from "./land_plots/6/land_plot_block_208.svg";
import { ReactComponent as LandPlot_6_209 } from "./land_plots/6/land_plot_block_209.svg";
import { ReactComponent as LandPlot_6_210 } from "./land_plots/6/land_plot_block_210.svg";
import { ReactComponent as LandPlot_6_211 } from "./land_plots/6/land_plot_block_211.svg";
import { ReactComponent as LandPlot_6_212 } from "./land_plots/6/land_plot_block_212.svg";
import { ReactComponent as LandPlot_6_213 } from "./land_plots/6/land_plot_block_213.svg";
import { ReactComponent as LandPlot_6_214 } from "./land_plots/6/land_plot_block_214.svg";
import { ReactComponent as LandPlot_6_215 } from "./land_plots/6/land_plot_block_215.svg";
import { ReactComponent as LandPlot_6_216 } from "./land_plots/6/land_plot_block_216.svg";
import { ReactComponent as LandPlot_6_217 } from "./land_plots/6/land_plot_block_217.svg";
import { ReactComponent as LandPlot_6_218 } from "./land_plots/6/land_plot_block_218.svg";
import { ReactComponent as LandPlot_6_219 } from "./land_plots/6/land_plot_block_219.svg";
import { ReactComponent as LandPlot_6_220 } from "./land_plots/6/land_plot_block_220.svg";
import { ReactComponent as LandPlot_6_221 } from "./land_plots/6/land_plot_block_221.svg";
import { ReactComponent as LandPlot_6_222 } from "./land_plots/6/land_plot_block_222.svg";
import { ReactComponent as LandPlot_6_223 } from "./land_plots/6/land_plot_block_223.svg";
import { ReactComponent as LandPlot_6_224 } from "./land_plots/6/land_plot_block_224.svg";
import { ReactComponent as LandPlot_6_225 } from "./land_plots/6/land_plot_block_225.svg";
import { ReactComponent as LandPlot_6_226 } from "./land_plots/6/land_plot_block_226.svg";
import { ReactComponent as LandPlot_6_227 } from "./land_plots/6/land_plot_block_227.svg";
import { ReactComponent as LandPlot_6_228 } from "./land_plots/6/land_plot_block_228.svg";
import { ReactComponent as LandPlot_6_229 } from "./land_plots/6/land_plot_block_229.svg";
import { ReactComponent as LandPlot_6_230 } from "./land_plots/6/land_plot_block_230.svg";
import { ReactComponent as LandPlot_6_231 } from "./land_plots/6/land_plot_block_231.svg";
import { ReactComponent as LandPlot_6_232 } from "./land_plots/6/land_plot_block_232.svg";
import { ReactComponent as LandPlot_6_233 } from "./land_plots/6/land_plot_block_233.svg";
import { ReactComponent as LandPlot_6_234 } from "./land_plots/6/land_plot_block_234.svg";
import { ReactComponent as LandPlot_6_235 } from "./land_plots/6/land_plot_block_235.svg";
import { ReactComponent as LandPlot_6_236 } from "./land_plots/6/land_plot_block_236.svg";
import { ReactComponent as LandPlot_6_237 } from "./land_plots/6/land_plot_block_237.svg";
import { ReactComponent as LandPlot_6_238 } from "./land_plots/6/land_plot_block_238.svg";
import { ReactComponent as LandPlot_6_239 } from "./land_plots/6/land_plot_block_239.svg";
import { ReactComponent as LandPlot_6_240 } from "./land_plots/6/land_plot_block_240.svg";
import { ReactComponent as LandPlot_6_241 } from "./land_plots/6/land_plot_block_241.svg";
import { ReactComponent as LandPlot_6_242 } from "./land_plots/6/land_plot_block_242.svg";
import { ReactComponent as LandPlot_6_243 } from "./land_plots/6/land_plot_block_243.svg";
import { ReactComponent as LandPlot_6_244 } from "./land_plots/6/land_plot_block_244.svg";
import { ReactComponent as LandPlot_6_245 } from "./land_plots/6/land_plot_block_245.svg";
import { ReactComponent as LandPlot_6_246 } from "./land_plots/6/land_plot_block_246.svg";
import { ReactComponent as LandPlot_6_247 } from "./land_plots/6/land_plot_block_247.svg";
import { ReactComponent as LandPlot_6_248 } from "./land_plots/6/land_plot_block_248.svg";
import { ReactComponent as LandPlot_6_249 } from "./land_plots/6/land_plot_block_249.svg";
import { ReactComponent as LandPlot_6_250 } from "./land_plots/6/land_plot_block_250.svg";
import { ReactComponent as LandPlot_6_251 } from "./land_plots/6/land_plot_block_251.svg";
import { ReactComponent as LandPlot_6_252 } from "./land_plots/6/land_plot_block_252.svg";
import { ReactComponent as LandPlot_6_253 } from "./land_plots/6/land_plot_block_253.svg";
import { ReactComponent as LandPlot_6_254 } from "./land_plots/6/land_plot_block_254.svg";
import { ReactComponent as LandPlot_6_255 } from "./land_plots/6/land_plot_block_255.svg";
import { ReactComponent as LandPlot_6_256 } from "./land_plots/6/land_plot_block_256.svg";
import { ReactComponent as LandPlot_6_257 } from "./land_plots/6/land_plot_block_257.svg";
import { ReactComponent as LandPlot_6_258 } from "./land_plots/6/land_plot_block_258.svg";
import { ReactComponent as LandPlot_6_259 } from "./land_plots/6/land_plot_block_259.svg";
import { ReactComponent as LandPlot_6_260 } from "./land_plots/6/land_plot_block_260.svg";
import { ReactComponent as LandPlot_6_261 } from "./land_plots/6/land_plot_block_261.svg";
import { ReactComponent as LandPlot_6_262 } from "./land_plots/6/land_plot_block_262.svg";
import { ReactComponent as LandPlot_6_263 } from "./land_plots/6/land_plot_block_263.svg";
import { ReactComponent as LandPlot_6_264 } from "./land_plots/6/land_plot_block_264.svg";
import { ReactComponent as LandPlot_6_265 } from "./land_plots/6/land_plot_block_265.svg";
import { ReactComponent as LandPlot_6_266 } from "./land_plots/6/land_plot_block_266.svg";
import { ReactComponent as LandPlot_6_267 } from "./land_plots/6/land_plot_block_267.svg";
import { ReactComponent as LandPlot_6_268 } from "./land_plots/6/land_plot_block_268.svg";
import { ReactComponent as LandPlot_6_269 } from "./land_plots/6/land_plot_block_269.svg";
import { ReactComponent as LandPlot_6_270 } from "./land_plots/6/land_plot_block_270.svg";
import { ReactComponent as LandPlot_6_271 } from "./land_plots/6/land_plot_block_271.svg";
import { ReactComponent as LandPlot_6_272 } from "./land_plots/6/land_plot_block_272.svg";
import { ReactComponent as LandPlot_6_273 } from "./land_plots/6/land_plot_block_273.svg";
import { ReactComponent as LandPlot_6_274 } from "./land_plots/6/land_plot_block_274.svg";
import { ReactComponent as LandPlot_6_275 } from "./land_plots/6/land_plot_block_275.svg";
import { ReactComponent as LandPlot_6_276 } from "./land_plots/6/land_plot_block_276.svg";
import { ReactComponent as LandPlot_6_277 } from "./land_plots/6/land_plot_block_277.svg";
import { ReactComponent as LandPlot_6_278 } from "./land_plots/6/land_plot_block_278.svg";
import { ReactComponent as LandPlot_6_279 } from "./land_plots/6/land_plot_block_279.svg";
import { ReactComponent as LandPlot_6_280 } from "./land_plots/6/land_plot_block_280.svg";
import { ReactComponent as LandPlot_6_281 } from "./land_plots/6/land_plot_block_281.svg";
import { ReactComponent as LandPlot_6_282 } from "./land_plots/6/land_plot_block_282.svg";
import { ReactComponent as LandPlot_6_283 } from "./land_plots/6/land_plot_block_283.svg";
import { ReactComponent as LandPlot_6_284 } from "./land_plots/6/land_plot_block_284.svg";
import { ReactComponent as LandPlot_6_285 } from "./land_plots/6/land_plot_block_285.svg";
import { ReactComponent as LandPlot_6_286 } from "./land_plots/6/land_plot_block_286.svg";
import { ReactComponent as LandPlot_6_287 } from "./land_plots/6/land_plot_block_287.svg";
import { ReactComponent as LandPlot_6_288 } from "./land_plots/6/land_plot_block_288.svg";
import { ReactComponent as LandPlot_6_289 } from "./land_plots/6/land_plot_block_289.svg";
import { ReactComponent as LandPlot_6_290 } from "./land_plots/6/land_plot_block_290.svg";
import { ReactComponent as LandPlot_6_291 } from "./land_plots/6/land_plot_block_291.svg";
import { ReactComponent as LandPlot_6_292 } from "./land_plots/6/land_plot_block_292.svg";
import { ReactComponent as LandPlot_6_293 } from "./land_plots/6/land_plot_block_293.svg";
import { ReactComponent as LandPlot_6_294 } from "./land_plots/6/land_plot_block_294.svg";
import { ReactComponent as LandPlot_6_295 } from "./land_plots/6/land_plot_block_295.svg";
import { ReactComponent as LandPlot_6_296 } from "./land_plots/6/land_plot_block_296.svg";
import { ReactComponent as LandPlot_6_297 } from "./land_plots/6/land_plot_block_297.svg";
import { ReactComponent as LandPlot_6_298 } from "./land_plots/6/land_plot_block_298.svg";
import { ReactComponent as LandPlot_6_299 } from "./land_plots/6/land_plot_block_299.svg";
import { ReactComponent as LandPlot_6_300 } from "./land_plots/6/land_plot_block_300.svg";
import { ReactComponent as LandPlot_6_301 } from "./land_plots/6/land_plot_block_301.svg";
import { ReactComponent as LandPlot_6_302 } from "./land_plots/6/land_plot_block_302.svg";
import { ReactComponent as LandPlot_6_303 } from "./land_plots/6/land_plot_block_303.svg";
import { ReactComponent as LandPlot_6_304 } from "./land_plots/6/land_plot_block_304.svg";
import { ReactComponent as LandPlot_6_305 } from "./land_plots/6/land_plot_block_305.svg";
import { ReactComponent as LandPlot_6_306 } from "./land_plots/6/land_plot_block_306.svg";
import { ReactComponent as LandPlot_6_307 } from "./land_plots/6/land_plot_block_307.svg";
import { ReactComponent as LandPlot_6_308 } from "./land_plots/6/land_plot_block_308.svg";
import { ReactComponent as LandPlot_6_309 } from "./land_plots/6/land_plot_block_309.svg";
import { ReactComponent as LandPlot_6_310 } from "./land_plots/6/land_plot_block_310.svg";
import { ReactComponent as LandPlot_6_311 } from "./land_plots/6/land_plot_block_311.svg";
import { ReactComponent as LandPlot_6_312 } from "./land_plots/6/land_plot_block_312.svg";
import { ReactComponent as LandPlot_6_313 } from "./land_plots/6/land_plot_block_313.svg";
import { ReactComponent as LandPlot_6_314 } from "./land_plots/6/land_plot_block_314.svg";
import { ReactComponent as LandPlot_6_315 } from "./land_plots/6/land_plot_block_315.svg";
import { ReactComponent as LandPlot_6_316 } from "./land_plots/6/land_plot_block_316.svg";
import { ReactComponent as LandPlot_6_317 } from "./land_plots/6/land_plot_block_317.svg";
import { ReactComponent as LandPlot_6_318 } from "./land_plots/6/land_plot_block_318.svg";
import { ReactComponent as LandPlot_6_319 } from "./land_plots/6/land_plot_block_319.svg";
import { ReactComponent as LandPlot_6_320 } from "./land_plots/6/land_plot_block_320.svg";
import { ReactComponent as LandPlot_6_321 } from "./land_plots/6/land_plot_block_321.svg";
import { ReactComponent as LandPlot_6_322 } from "./land_plots/6/land_plot_block_322.svg";
import { ReactComponent as LandPlot_6_323 } from "./land_plots/6/land_plot_block_323.svg";
import { ReactComponent as LandPlot_6_324 } from "./land_plots/6/land_plot_block_324.svg";
import { ReactComponent as LandPlot_6_325 } from "./land_plots/6/land_plot_block_325.svg";
import { ReactComponent as LandPlot_6_326 } from "./land_plots/6/land_plot_block_326.svg";
import { ReactComponent as LandPlot_6_327 } from "./land_plots/6/land_plot_block_327.svg";
import { ReactComponent as LandPlot_6_328 } from "./land_plots/6/land_plot_block_328.svg";
import { ReactComponent as LandPlot_6_329 } from "./land_plots/6/land_plot_block_329.svg";
import { ReactComponent as LandPlot_6_330 } from "./land_plots/6/land_plot_block_330.svg";
import { ReactComponent as LandPlot_6_331 } from "./land_plots/6/land_plot_block_331.svg";
import { ReactComponent as LandPlot_6_332 } from "./land_plots/6/land_plot_block_332.svg";
import { ReactComponent as LandPlot_6_333 } from "./land_plots/6/land_plot_block_333.svg";
import { ReactComponent as LandPlot_6_334 } from "./land_plots/6/land_plot_block_334.svg";
import { ReactComponent as LandPlot_6_335 } from "./land_plots/6/land_plot_block_335.svg";
import { ReactComponent as LandPlot_6_336 } from "./land_plots/6/land_plot_block_336.svg";
import { ReactComponent as LandPlot_6_337 } from "./land_plots/6/land_plot_block_337.svg";
import { ReactComponent as LandPlot_6_338 } from "./land_plots/6/land_plot_block_338.svg";
import { ReactComponent as LandPlot_6_339 } from "./land_plots/6/land_plot_block_339.svg";
import { ReactComponent as LandPlot_6_340 } from "./land_plots/6/land_plot_block_340.svg";
import { ReactComponent as LandPlot_6_341 } from "./land_plots/6/land_plot_block_341.svg";
import { ReactComponent as LandPlot_6_342 } from "./land_plots/6/land_plot_block_342.svg";
import { ReactComponent as LandPlot_6_343 } from "./land_plots/6/land_plot_block_343.svg";
import { ReactComponent as LandPlot_6_344 } from "./land_plots/6/land_plot_block_344.svg";
import { ReactComponent as LandPlot_6_345 } from "./land_plots/6/land_plot_block_345.svg";
import { ReactComponent as LandPlot_6_346 } from "./land_plots/6/land_plot_block_346.svg";
import { ReactComponent as LandPlot_6_347 } from "./land_plots/6/land_plot_block_347.svg";
import { ReactComponent as LandPlot_6_348 } from "./land_plots/6/land_plot_block_348.svg";
import { ReactComponent as LandPlot_6_349 } from "./land_plots/6/land_plot_block_349.svg";
import { ReactComponent as LandPlot_6_350 } from "./land_plots/6/land_plot_block_350.svg";
import { ReactComponent as LandPlot_6_351 } from "./land_plots/6/land_plot_block_351.svg";
import { ReactComponent as LandPlot_6_352 } from "./land_plots/6/land_plot_block_352.svg";
import { ReactComponent as LandPlot_6_353 } from "./land_plots/6/land_plot_block_353.svg";
import { ReactComponent as LandPlot_6_354 } from "./land_plots/6/land_plot_block_354.svg";
import { ReactComponent as LandPlot_6_355 } from "./land_plots/6/land_plot_block_355.svg";
import { ReactComponent as LandPlot_6_356 } from "./land_plots/6/land_plot_block_356.svg";
import { ReactComponent as LandPlot_6_357 } from "./land_plots/6/land_plot_block_357.svg";
import { ReactComponent as LandPlot_6_358 } from "./land_plots/6/land_plot_block_358.svg";
import { ReactComponent as LandPlot_6_359 } from "./land_plots/6/land_plot_block_359.svg";
import { ReactComponent as LandPlot_6_360 } from "./land_plots/6/land_plot_block_360.svg";
import { ReactComponent as LandPlot_6_361 } from "./land_plots/6/land_plot_block_361.svg";
import { ReactComponent as LandPlot_6_362 } from "./land_plots/6/land_plot_block_362.svg";
import { ReactComponent as LandPlot_6_363 } from "./land_plots/6/land_plot_block_363.svg";
import { ReactComponent as LandPlot_6_364 } from "./land_plots/6/land_plot_block_364.svg";
import { ReactComponent as LandPlot_6_365 } from "./land_plots/6/land_plot_block_365.svg";
import { ReactComponent as LandPlot_6_366 } from "./land_plots/6/land_plot_block_366.svg";
import { ReactComponent as LandPlot_6_367 } from "./land_plots/6/land_plot_block_367.svg";
import { ReactComponent as LandPlot_6_368 } from "./land_plots/6/land_plot_block_368.svg";
import { ReactComponent as LandPlot_6_369 } from "./land_plots/6/land_plot_block_369.svg";
import { ReactComponent as LandPlot_6_370 } from "./land_plots/6/land_plot_block_370.svg";
import { ReactComponent as LandPlot_6_371 } from "./land_plots/6/land_plot_block_371.svg";
import { ReactComponent as LandPlot_6_372 } from "./land_plots/6/land_plot_block_372.svg";
import { ReactComponent as LandPlot_6_373 } from "./land_plots/6/land_plot_block_373.svg";
import { ReactComponent as LandPlot_6_374 } from "./land_plots/6/land_plot_block_374.svg";
import { ReactComponent as LandPlot_6_375 } from "./land_plots/6/land_plot_block_375.svg";
import { ReactComponent as LandPlot_6_376 } from "./land_plots/6/land_plot_block_376.svg";
import { ReactComponent as LandPlot_6_377 } from "./land_plots/6/land_plot_block_377.svg";
import { ReactComponent as LandPlot_6_378 } from "./land_plots/6/land_plot_block_378.svg";
import { ReactComponent as LandPlot_6_379 } from "./land_plots/6/land_plot_block_379.svg";
import { ReactComponent as LandPlot_6_380 } from "./land_plots/6/land_plot_block_380.svg";
import { ReactComponent as LandPlot_6_381 } from "./land_plots/6/land_plot_block_381.svg";
import { ReactComponent as LandPlot_6_382 } from "./land_plots/6/land_plot_block_382.svg";
import { ReactComponent as LandPlot_6_383 } from "./land_plots/6/land_plot_block_383.svg";
import { ReactComponent as LandPlot_6_384 } from "./land_plots/6/land_plot_block_384.svg";
import { ReactComponent as LandPlot_6_385 } from "./land_plots/6/land_plot_block_385.svg";
import { ReactComponent as LandPlot_6_386 } from "./land_plots/6/land_plot_block_386.svg";
import { ReactComponent as LandPlot_6_387 } from "./land_plots/6/land_plot_block_387.svg";
import { ReactComponent as LandPlot_6_388 } from "./land_plots/6/land_plot_block_388.svg";
import { ReactComponent as LandPlot_6_389 } from "./land_plots/6/land_plot_block_389.svg";
import { ReactComponent as LandPlot_6_390 } from "./land_plots/6/land_plot_block_390.svg";
import { ReactComponent as LandPlot_6_391 } from "./land_plots/6/land_plot_block_391.svg";
import { ReactComponent as LandPlot_6_392 } from "./land_plots/6/land_plot_block_392.svg";
import { ReactComponent as LandPlot_6_393 } from "./land_plots/6/land_plot_block_393.svg";
import { ReactComponent as LandPlot_6_394 } from "./land_plots/6/land_plot_block_394.svg";
import { ReactComponent as LandPlot_6_395 } from "./land_plots/6/land_plot_block_395.svg";
import { ReactComponent as LandPlot_6_396 } from "./land_plots/6/land_plot_block_396.svg";
import { ReactComponent as LandPlot_6_397 } from "./land_plots/6/land_plot_block_397.svg";
import { ReactComponent as LandPlot_6_398 } from "./land_plots/6/land_plot_block_398.svg";
import { ReactComponent as LandPlot_6_399 } from "./land_plots/6/land_plot_block_399.svg";
import { ReactComponent as LandPlot_6_400 } from "./land_plots/6/land_plot_block_400.svg";
import { ReactComponent as LandPlot_6_401 } from "./land_plots/6/land_plot_block_401.svg";
import { ReactComponent as LandPlot_6_402 } from "./land_plots/6/land_plot_block_402.svg";
import { ReactComponent as LandPlot_6_403 } from "./land_plots/6/land_plot_block_403.svg";
import { ReactComponent as LandPlot_6_404 } from "./land_plots/6/land_plot_block_404.svg";
import { ReactComponent as LandPlot_6_405 } from "./land_plots/6/land_plot_block_405.svg";
import { ReactComponent as LandPlot_6_406 } from "./land_plots/6/land_plot_block_406.svg";
import { ReactComponent as LandPlot_6_407 } from "./land_plots/6/land_plot_block_407.svg";
import { ReactComponent as LandPlot_6_408 } from "./land_plots/6/land_plot_block_408.svg";
import { ReactComponent as LandPlot_6_409 } from "./land_plots/6/land_plot_block_409.svg";
import { ReactComponent as LandPlot_6_410 } from "./land_plots/6/land_plot_block_410.svg";
import { ReactComponent as LandPlot_6_411 } from "./land_plots/6/land_plot_block_411.svg";
import { ReactComponent as LandPlot_6_412 } from "./land_plots/6/land_plot_block_412.svg";
import { ReactComponent as LandPlot_6_413 } from "./land_plots/6/land_plot_block_413.svg";
import { ReactComponent as LandPlot_6_414 } from "./land_plots/6/land_plot_block_414.svg";
import { ReactComponent as LandPlot_6_415 } from "./land_plots/6/land_plot_block_415.svg";
import { ReactComponent as LandPlot_6_416 } from "./land_plots/6/land_plot_block_416.svg";
import { ReactComponent as LandPlot_6_417 } from "./land_plots/6/land_plot_block_417.svg";
import { ReactComponent as LandPlot_6_418 } from "./land_plots/6/land_plot_block_418.svg";
import { ReactComponent as LandPlot_6_419 } from "./land_plots/6/land_plot_block_419.svg";
import { ReactComponent as LandPlot_6_420 } from "./land_plots/6/land_plot_block_420.svg";
import { ReactComponent as LandPlot_6_421 } from "./land_plots/6/land_plot_block_421.svg";
import { ReactComponent as LandPlot_6_422 } from "./land_plots/6/land_plot_block_422.svg";
import { ReactComponent as LandPlot_6_423 } from "./land_plots/6/land_plot_block_423.svg";
import { ReactComponent as LandPlot_6_424 } from "./land_plots/6/land_plot_block_424.svg";
import { ReactComponent as LandPlot_6_425 } from "./land_plots/6/land_plot_block_425.svg";
import { ReactComponent as LandPlot_6_426 } from "./land_plots/6/land_plot_block_426.svg";
import { ReactComponent as LandPlot_6_427 } from "./land_plots/6/land_plot_block_427.svg";
import { ReactComponent as LandPlot_6_428 } from "./land_plots/6/land_plot_block_428.svg";
import { ReactComponent as LandPlot_6_429 } from "./land_plots/6/land_plot_block_429.svg";
import { ReactComponent as LandPlot_6_430 } from "./land_plots/6/land_plot_block_430.svg";
import { ReactComponent as LandPlot_6_431 } from "./land_plots/6/land_plot_block_431.svg";
import { ReactComponent as LandPlot_6_432 } from "./land_plots/6/land_plot_block_432.svg";
import { ReactComponent as LandPlot_6_433 } from "./land_plots/6/land_plot_block_433.svg";
import { ReactComponent as LandPlot_6_434 } from "./land_plots/6/land_plot_block_434.svg";
import { ReactComponent as LandPlot_6_435 } from "./land_plots/6/land_plot_block_435.svg";
import { ReactComponent as LandPlot_6_436 } from "./land_plots/6/land_plot_block_436.svg";
import { ReactComponent as LandPlot_6_437 } from "./land_plots/6/land_plot_block_437.svg";
import { ReactComponent as LandPlot_6_438 } from "./land_plots/6/land_plot_block_438.svg";
import { ReactComponent as LandPlot_6_439 } from "./land_plots/6/land_plot_block_439.svg";
import { ReactComponent as LandPlot_6_440 } from "./land_plots/6/land_plot_block_440.svg";
import { ReactComponent as LandPlot_6_441 } from "./land_plots/6/land_plot_block_441.svg";
import { ReactComponent as LandPlot_6_442 } from "./land_plots/6/land_plot_block_442.svg";
import { ReactComponent as LandPlot_6_443 } from "./land_plots/6/land_plot_block_443.svg";
import { ReactComponent as LandPlot_6_444 } from "./land_plots/6/land_plot_block_444.svg";
import { ReactComponent as LandPlot_6_445 } from "./land_plots/6/land_plot_block_445.svg";
import { ReactComponent as LandPlot_6_446 } from "./land_plots/6/land_plot_block_446.svg";
import { ReactComponent as LandPlot_6_447 } from "./land_plots/6/land_plot_block_447.svg";
import { ReactComponent as LandPlot_6_448 } from "./land_plots/6/land_plot_block_448.svg";
import { ReactComponent as LandPlot_6_449 } from "./land_plots/6/land_plot_block_449.svg";

//stop frame 27
import { ReactComponent as LandPlot_27_3 } from "./land_plots/27/land_plot_block_3.svg";
import { ReactComponent as LandPlot_27_4 } from "./land_plots/27/land_plot_block_4.svg";
import { ReactComponent as LandPlot_27_5 } from "./land_plots/27/land_plot_block_5.svg";
import { ReactComponent as LandPlot_27_6 } from "./land_plots/27/land_plot_block_6.svg";
import { ReactComponent as LandPlot_27_7 } from "./land_plots/27/land_plot_block_7.svg";
import { ReactComponent as LandPlot_27_8 } from "./land_plots/27/land_plot_block_8.svg";
import { ReactComponent as LandPlot_27_9 } from "./land_plots/27/land_plot_block_9.svg";
import { ReactComponent as LandPlot_27_10 } from "./land_plots/27/land_plot_block_10.svg";
import { ReactComponent as LandPlot_27_11 } from "./land_plots/27/land_plot_block_11.svg";
import { ReactComponent as LandPlot_27_12 } from "./land_plots/27/land_plot_block_12.svg";
import { ReactComponent as LandPlot_27_13 } from "./land_plots/27/land_plot_block_13.svg";
import { ReactComponent as LandPlot_27_14 } from "./land_plots/27/land_plot_block_14.svg";
import { ReactComponent as LandPlot_27_15 } from "./land_plots/27/land_plot_block_15.svg";
import { ReactComponent as LandPlot_27_16 } from "./land_plots/27/land_plot_block_16.svg";
import { ReactComponent as LandPlot_27_17 } from "./land_plots/27/land_plot_block_17.svg";
import { ReactComponent as LandPlot_27_18 } from "./land_plots/27/land_plot_block_18.svg";
import { ReactComponent as LandPlot_27_19 } from "./land_plots/27/land_plot_block_19.svg";
import { ReactComponent as LandPlot_27_20 } from "./land_plots/27/land_plot_block_20.svg";
import { ReactComponent as LandPlot_27_21 } from "./land_plots/27/land_plot_block_21.svg";
import { ReactComponent as LandPlot_27_22 } from "./land_plots/27/land_plot_block_22.svg";
import { ReactComponent as LandPlot_27_23 } from "./land_plots/27/land_plot_block_23.svg";
import { ReactComponent as LandPlot_27_24 } from "./land_plots/27/land_plot_block_24.svg";
import { ReactComponent as LandPlot_27_25 } from "./land_plots/27/land_plot_block_25.svg";
import { ReactComponent as LandPlot_27_26 } from "./land_plots/27/land_plot_block_26.svg";
import { ReactComponent as LandPlot_27_27 } from "./land_plots/27/land_plot_block_27.svg";
import { ReactComponent as LandPlot_27_28 } from "./land_plots/27/land_plot_block_28.svg";
import { ReactComponent as LandPlot_27_29 } from "./land_plots/27/land_plot_block_29.svg";
import { ReactComponent as LandPlot_27_30 } from "./land_plots/27/land_plot_block_30.svg";
import { ReactComponent as LandPlot_27_31 } from "./land_plots/27/land_plot_block_31.svg";
import { ReactComponent as LandPlot_27_32 } from "./land_plots/27/land_plot_block_32.svg";
import { ReactComponent as LandPlot_27_33 } from "./land_plots/27/land_plot_block_33.svg";
import { ReactComponent as LandPlot_27_34 } from "./land_plots/27/land_plot_block_34.svg";
import { ReactComponent as LandPlot_27_35 } from "./land_plots/27/land_plot_block_35.svg";
import { ReactComponent as LandPlot_27_36 } from "./land_plots/27/land_plot_block_36.svg";
import { ReactComponent as LandPlot_27_37 } from "./land_plots/27/land_plot_block_37.svg";
import { ReactComponent as LandPlot_27_38 } from "./land_plots/27/land_plot_block_38.svg";
import { ReactComponent as LandPlot_27_39 } from "./land_plots/27/land_plot_block_39.svg";
import { ReactComponent as LandPlot_27_40 } from "./land_plots/27/land_plot_block_40.svg";
import { ReactComponent as LandPlot_27_41 } from "./land_plots/27/land_plot_block_41.svg";
import { ReactComponent as LandPlot_27_42 } from "./land_plots/27/land_plot_block_42.svg";
import { ReactComponent as LandPlot_27_43 } from "./land_plots/27/land_plot_block_43.svg";
import { ReactComponent as LandPlot_27_44 } from "./land_plots/27/land_plot_block_44.svg";
import { ReactComponent as LandPlot_27_45 } from "./land_plots/27/land_plot_block_45.svg";
import { ReactComponent as LandPlot_27_46 } from "./land_plots/27/land_plot_block_46.svg";
import { ReactComponent as LandPlot_27_47 } from "./land_plots/27/land_plot_block_47.svg";
import { ReactComponent as LandPlot_27_48 } from "./land_plots/27/land_plot_block_48.svg";
import { ReactComponent as LandPlot_27_49 } from "./land_plots/27/land_plot_block_49.svg";
import { ReactComponent as LandPlot_27_50 } from "./land_plots/27/land_plot_block_50.svg";
import { ReactComponent as LandPlot_27_51 } from "./land_plots/27/land_plot_block_51.svg";
import { ReactComponent as LandPlot_27_52 } from "./land_plots/27/land_plot_block_52.svg";
import { ReactComponent as LandPlot_27_53 } from "./land_plots/27/land_plot_block_53.svg";
import { ReactComponent as LandPlot_27_54 } from "./land_plots/27/land_plot_block_54.svg";
import { ReactComponent as LandPlot_27_62 } from "./land_plots/27/land_plot_block_62.svg";
import { ReactComponent as LandPlot_27_63 } from "./land_plots/27/land_plot_block_63.svg";
import { ReactComponent as LandPlot_27_64 } from "./land_plots/27/land_plot_block_64.svg";
import { ReactComponent as LandPlot_27_65 } from "./land_plots/27/land_plot_block_65.svg";
import { ReactComponent as LandPlot_27_66 } from "./land_plots/27/land_plot_block_66.svg";
import { ReactComponent as LandPlot_27_67 } from "./land_plots/27/land_plot_block_67.svg";
import { ReactComponent as LandPlot_27_68 } from "./land_plots/27/land_plot_block_68.svg";
import { ReactComponent as LandPlot_27_69 } from "./land_plots/27/land_plot_block_69.svg";
import { ReactComponent as LandPlot_27_70 } from "./land_plots/27/land_plot_block_70.svg";
import { ReactComponent as LandPlot_27_71 } from "./land_plots/27/land_plot_block_71.svg";
import { ReactComponent as LandPlot_27_72 } from "./land_plots/27/land_plot_block_72.svg";
import { ReactComponent as LandPlot_27_73 } from "./land_plots/27/land_plot_block_73.svg";
import { ReactComponent as LandPlot_27_74 } from "./land_plots/27/land_plot_block_74.svg";
import { ReactComponent as LandPlot_27_75 } from "./land_plots/27/land_plot_block_75.svg";
import { ReactComponent as LandPlot_27_76 } from "./land_plots/27/land_plot_block_76.svg";
import { ReactComponent as LandPlot_27_77 } from "./land_plots/27/land_plot_block_77.svg";
import { ReactComponent as LandPlot_27_78 } from "./land_plots/27/land_plot_block_78.svg";
import { ReactComponent as LandPlot_27_79 } from "./land_plots/27/land_plot_block_79.svg";
import { ReactComponent as LandPlot_27_80 } from "./land_plots/27/land_plot_block_80.svg";
import { ReactComponent as LandPlot_27_81 } from "./land_plots/27/land_plot_block_81.svg";
import { ReactComponent as LandPlot_27_82 } from "./land_plots/27/land_plot_block_82.svg";
import { ReactComponent as LandPlot_27_83 } from "./land_plots/27/land_plot_block_83.svg";
import { ReactComponent as LandPlot_27_84 } from "./land_plots/27/land_plot_block_84.svg";
import { ReactComponent as LandPlot_27_85 } from "./land_plots/27/land_plot_block_85.svg";
import { ReactComponent as LandPlot_27_86 } from "./land_plots/27/land_plot_block_86.svg";
import { ReactComponent as LandPlot_27_87 } from "./land_plots/27/land_plot_block_87.svg";
import { ReactComponent as LandPlot_27_88 } from "./land_plots/27/land_plot_block_88.svg";
import { ReactComponent as LandPlot_27_89 } from "./land_plots/27/land_plot_block_89.svg";
import { ReactComponent as LandPlot_27_90 } from "./land_plots/27/land_plot_block_90.svg";
import { ReactComponent as LandPlot_27_91 } from "./land_plots/27/land_plot_block_91.svg";
import { ReactComponent as LandPlot_27_92 } from "./land_plots/27/land_plot_block_92.svg";
import { ReactComponent as LandPlot_27_93 } from "./land_plots/27/land_plot_block_93.svg";
import { ReactComponent as LandPlot_27_94 } from "./land_plots/27/land_plot_block_94.svg";
import { ReactComponent as LandPlot_27_95 } from "./land_plots/27/land_plot_block_95.svg";
import { ReactComponent as LandPlot_27_96 } from "./land_plots/27/land_plot_block_96.svg";
import { ReactComponent as LandPlot_27_97 } from "./land_plots/27/land_plot_block_97.svg";
import { ReactComponent as LandPlot_27_98 } from "./land_plots/27/land_plot_block_98.svg";
import { ReactComponent as LandPlot_27_99 } from "./land_plots/27/land_plot_block_99.svg";
import { ReactComponent as LandPlot_27_100 } from "./land_plots/27/land_plot_block_100.svg";
import { ReactComponent as LandPlot_27_101 } from "./land_plots/27/land_plot_block_101.svg";
import { ReactComponent as LandPlot_27_102 } from "./land_plots/27/land_plot_block_102.svg";
import { ReactComponent as LandPlot_27_103 } from "./land_plots/27/land_plot_block_103.svg";
import { ReactComponent as LandPlot_27_104 } from "./land_plots/27/land_plot_block_104.svg";
import { ReactComponent as LandPlot_27_105 } from "./land_plots/27/land_plot_block_105.svg";
import { ReactComponent as LandPlot_27_106 } from "./land_plots/27/land_plot_block_106.svg";
import { ReactComponent as LandPlot_27_107 } from "./land_plots/27/land_plot_block_107.svg";
import { ReactComponent as LandPlot_27_108 } from "./land_plots/27/land_plot_block_108.svg";
import { ReactComponent as LandPlot_27_109 } from "./land_plots/27/land_plot_block_109.svg";
import { ReactComponent as LandPlot_27_110 } from "./land_plots/27/land_plot_block_110.svg";
import { ReactComponent as LandPlot_27_111 } from "./land_plots/27/land_plot_block_111.svg";
import { ReactComponent as LandPlot_27_112 } from "./land_plots/27/land_plot_block_112.svg";
import { ReactComponent as LandPlot_27_113 } from "./land_plots/27/land_plot_block_113.svg";
import { ReactComponent as LandPlot_27_114 } from "./land_plots/27/land_plot_block_114.svg";
import { ReactComponent as LandPlot_27_115 } from "./land_plots/27/land_plot_block_115.svg";
import { ReactComponent as LandPlot_27_116 } from "./land_plots/27/land_plot_block_116.svg";
import { ReactComponent as LandPlot_27_117 } from "./land_plots/27/land_plot_block_117.svg";
import { ReactComponent as LandPlot_27_118 } from "./land_plots/27/land_plot_block_118.svg";
import { ReactComponent as LandPlot_27_119 } from "./land_plots/27/land_plot_block_119.svg";
import { ReactComponent as LandPlot_27_120 } from "./land_plots/27/land_plot_block_120.svg";
import { ReactComponent as LandPlot_27_121 } from "./land_plots/27/land_plot_block_121.svg";
import { ReactComponent as LandPlot_27_122 } from "./land_plots/27/land_plot_block_122.svg";
import { ReactComponent as LandPlot_27_123 } from "./land_plots/27/land_plot_block_123.svg";
import { ReactComponent as LandPlot_27_124 } from "./land_plots/27/land_plot_block_124.svg";
import { ReactComponent as LandPlot_27_125 } from "./land_plots/27/land_plot_block_125.svg";
import { ReactComponent as LandPlot_27_126 } from "./land_plots/27/land_plot_block_126.svg";
import { ReactComponent as LandPlot_27_127 } from "./land_plots/27/land_plot_block_127.svg";
import { ReactComponent as LandPlot_27_128 } from "./land_plots/27/land_plot_block_128.svg";
import { ReactComponent as LandPlot_27_129 } from "./land_plots/27/land_plot_block_129.svg";
import { ReactComponent as LandPlot_27_130 } from "./land_plots/27/land_plot_block_130.svg";
import { ReactComponent as LandPlot_27_131 } from "./land_plots/27/land_plot_block_131.svg";
import { ReactComponent as LandPlot_27_132 } from "./land_plots/27/land_plot_block_132.svg";
import { ReactComponent as LandPlot_27_133 } from "./land_plots/27/land_plot_block_133.svg";
import { ReactComponent as LandPlot_27_134 } from "./land_plots/27/land_plot_block_134.svg";
import { ReactComponent as LandPlot_27_135 } from "./land_plots/27/land_plot_block_135.svg";
import { ReactComponent as LandPlot_27_136 } from "./land_plots/27/land_plot_block_136.svg";
import { ReactComponent as LandPlot_27_137 } from "./land_plots/27/land_plot_block_137.svg";
import { ReactComponent as LandPlot_27_138 } from "./land_plots/27/land_plot_block_138.svg";
import { ReactComponent as LandPlot_27_139 } from "./land_plots/27/land_plot_block_139.svg";
import { ReactComponent as LandPlot_27_140 } from "./land_plots/27/land_plot_block_140.svg";
import { ReactComponent as LandPlot_27_141 } from "./land_plots/27/land_plot_block_141.svg";
import { ReactComponent as LandPlot_27_142 } from "./land_plots/27/land_plot_block_142.svg";
import { ReactComponent as LandPlot_27_143 } from "./land_plots/27/land_plot_block_143.svg";
import { ReactComponent as LandPlot_27_144 } from "./land_plots/27/land_plot_block_144.svg";
import { ReactComponent as LandPlot_27_145 } from "./land_plots/27/land_plot_block_145.svg";
import { ReactComponent as LandPlot_27_146 } from "./land_plots/27/land_plot_block_146.svg";
import { ReactComponent as LandPlot_27_147 } from "./land_plots/27/land_plot_block_147.svg";
import { ReactComponent as LandPlot_27_148 } from "./land_plots/27/land_plot_block_148.svg";
import { ReactComponent as LandPlot_27_149 } from "./land_plots/27/land_plot_block_149.svg";
import { ReactComponent as LandPlot_27_150 } from "./land_plots/27/land_plot_block_150.svg";
import { ReactComponent as LandPlot_27_151 } from "./land_plots/27/land_plot_block_151.svg";
import { ReactComponent as LandPlot_27_152 } from "./land_plots/27/land_plot_block_152.svg";
import { ReactComponent as LandPlot_27_153 } from "./land_plots/27/land_plot_block_153.svg";
import { ReactComponent as LandPlot_27_154 } from "./land_plots/27/land_plot_block_154.svg";
import { ReactComponent as LandPlot_27_155 } from "./land_plots/27/land_plot_block_155.svg";
import { ReactComponent as LandPlot_27_156 } from "./land_plots/27/land_plot_block_156.svg";
import { ReactComponent as LandPlot_27_157 } from "./land_plots/27/land_plot_block_157.svg";
import { ReactComponent as LandPlot_27_158 } from "./land_plots/27/land_plot_block_158.svg";
import { ReactComponent as LandPlot_27_159 } from "./land_plots/27/land_plot_block_159.svg";
import { ReactComponent as LandPlot_27_160 } from "./land_plots/27/land_plot_block_160.svg";
import { ReactComponent as LandPlot_27_161 } from "./land_plots/27/land_plot_block_161.svg";
import { ReactComponent as LandPlot_27_162 } from "./land_plots/27/land_plot_block_162.svg";
import { ReactComponent as LandPlot_27_163 } from "./land_plots/27/land_plot_block_163.svg";
import { ReactComponent as LandPlot_27_164 } from "./land_plots/27/land_plot_block_164.svg";
import { ReactComponent as LandPlot_27_165 } from "./land_plots/27/land_plot_block_165.svg";
import { ReactComponent as LandPlot_27_166 } from "./land_plots/27/land_plot_block_166.svg";
import { ReactComponent as LandPlot_27_167 } from "./land_plots/27/land_plot_block_167.svg";
import { ReactComponent as LandPlot_27_168 } from "./land_plots/27/land_plot_block_168.svg";
import { ReactComponent as LandPlot_27_169 } from "./land_plots/27/land_plot_block_169.svg";
import { ReactComponent as LandPlot_27_170 } from "./land_plots/27/land_plot_block_170.svg";
import { ReactComponent as LandPlot_27_171 } from "./land_plots/27/land_plot_block_171.svg";
import { ReactComponent as LandPlot_27_172 } from "./land_plots/27/land_plot_block_172.svg";
import { ReactComponent as LandPlot_27_173 } from "./land_plots/27/land_plot_block_173.svg";
import { ReactComponent as LandPlot_27_174 } from "./land_plots/27/land_plot_block_174.svg";
import { ReactComponent as LandPlot_27_175 } from "./land_plots/27/land_plot_block_175.svg";
import { ReactComponent as LandPlot_27_176 } from "./land_plots/27/land_plot_block_176.svg";
import { ReactComponent as LandPlot_27_177 } from "./land_plots/27/land_plot_block_177.svg";
import { ReactComponent as LandPlot_27_178 } from "./land_plots/27/land_plot_block_178.svg";
import { ReactComponent as LandPlot_27_179 } from "./land_plots/27/land_plot_block_179.svg";
import { ReactComponent as LandPlot_27_180 } from "./land_plots/27/land_plot_block_180.svg";
import { ReactComponent as LandPlot_27_181 } from "./land_plots/27/land_plot_block_181.svg";
import { ReactComponent as LandPlot_27_182 } from "./land_plots/27/land_plot_block_182.svg";
import { ReactComponent as LandPlot_27_183 } from "./land_plots/27/land_plot_block_183.svg";
import { ReactComponent as LandPlot_27_184 } from "./land_plots/27/land_plot_block_184.svg";
import { ReactComponent as LandPlot_27_185 } from "./land_plots/27/land_plot_block_185.svg";
import { ReactComponent as LandPlot_27_186 } from "./land_plots/27/land_plot_block_186.svg";
import { ReactComponent as LandPlot_27_187 } from "./land_plots/27/land_plot_block_187.svg";
import { ReactComponent as LandPlot_27_188 } from "./land_plots/27/land_plot_block_188.svg";
import { ReactComponent as LandPlot_27_189 } from "./land_plots/27/land_plot_block_189.svg";
import { ReactComponent as LandPlot_27_190 } from "./land_plots/27/land_plot_block_190.svg";
import { ReactComponent as LandPlot_27_191 } from "./land_plots/27/land_plot_block_191.svg";
import { ReactComponent as LandPlot_27_192 } from "./land_plots/27/land_plot_block_192.svg";
import { ReactComponent as LandPlot_27_193 } from "./land_plots/27/land_plot_block_193.svg";
import { ReactComponent as LandPlot_27_194 } from "./land_plots/27/land_plot_block_194.svg";
import { ReactComponent as LandPlot_27_195 } from "./land_plots/27/land_plot_block_195.svg";
import { ReactComponent as LandPlot_27_196 } from "./land_plots/27/land_plot_block_196.svg";
import { ReactComponent as LandPlot_27_197 } from "./land_plots/27/land_plot_block_197.svg";
import { ReactComponent as LandPlot_27_198 } from "./land_plots/27/land_plot_block_198.svg";
import { ReactComponent as LandPlot_27_199 } from "./land_plots/27/land_plot_block_199.svg";
import { ReactComponent as LandPlot_27_200 } from "./land_plots/27/land_plot_block_200.svg";
import { ReactComponent as LandPlot_27_201 } from "./land_plots/27/land_plot_block_201.svg";
import { ReactComponent as LandPlot_27_202 } from "./land_plots/27/land_plot_block_202.svg";
import { ReactComponent as LandPlot_27_203 } from "./land_plots/27/land_plot_block_203.svg";
import { ReactComponent as LandPlot_27_204 } from "./land_plots/27/land_plot_block_204.svg";
import { ReactComponent as LandPlot_27_205 } from "./land_plots/27/land_plot_block_205.svg";
import { ReactComponent as LandPlot_27_206 } from "./land_plots/27/land_plot_block_206.svg";
import { ReactComponent as LandPlot_27_207 } from "./land_plots/27/land_plot_block_207.svg";
import { ReactComponent as LandPlot_27_208 } from "./land_plots/27/land_plot_block_208.svg";
import { ReactComponent as LandPlot_27_209 } from "./land_plots/27/land_plot_block_209.svg";
import { ReactComponent as LandPlot_27_210 } from "./land_plots/27/land_plot_block_210.svg";
import { ReactComponent as LandPlot_27_211 } from "./land_plots/27/land_plot_block_211.svg";
import { ReactComponent as LandPlot_27_212 } from "./land_plots/27/land_plot_block_212.svg";
import { ReactComponent as LandPlot_27_213 } from "./land_plots/27/land_plot_block_213.svg";
import { ReactComponent as LandPlot_27_214 } from "./land_plots/27/land_plot_block_214.svg";
import { ReactComponent as LandPlot_27_215 } from "./land_plots/27/land_plot_block_215.svg";
import { ReactComponent as LandPlot_27_216 } from "./land_plots/27/land_plot_block_216.svg";
import { ReactComponent as LandPlot_27_217 } from "./land_plots/27/land_plot_block_217.svg";
import { ReactComponent as LandPlot_27_218 } from "./land_plots/27/land_plot_block_218.svg";
import { ReactComponent as LandPlot_27_219 } from "./land_plots/27/land_plot_block_219.svg";
import { ReactComponent as LandPlot_27_220 } from "./land_plots/27/land_plot_block_220.svg";
import { ReactComponent as LandPlot_27_221 } from "./land_plots/27/land_plot_block_221.svg";
import { ReactComponent as LandPlot_27_222 } from "./land_plots/27/land_plot_block_222.svg";
import { ReactComponent as LandPlot_27_223 } from "./land_plots/27/land_plot_block_223.svg";
import { ReactComponent as LandPlot_27_224 } from "./land_plots/27/land_plot_block_224.svg";
import { ReactComponent as LandPlot_27_225 } from "./land_plots/27/land_plot_block_225.svg";
import { ReactComponent as LandPlot_27_226 } from "./land_plots/27/land_plot_block_226.svg";
import { ReactComponent as LandPlot_27_227 } from "./land_plots/27/land_plot_block_227.svg";
import { ReactComponent as LandPlot_27_228 } from "./land_plots/27/land_plot_block_228.svg";
import { ReactComponent as LandPlot_27_229 } from "./land_plots/27/land_plot_block_229.svg";
import { ReactComponent as LandPlot_27_230 } from "./land_plots/27/land_plot_block_230.svg";
import { ReactComponent as LandPlot_27_231 } from "./land_plots/27/land_plot_block_231.svg";
import { ReactComponent as LandPlot_27_232 } from "./land_plots/27/land_plot_block_232.svg";
import { ReactComponent as LandPlot_27_233 } from "./land_plots/27/land_plot_block_233.svg";
import { ReactComponent as LandPlot_27_234 } from "./land_plots/27/land_plot_block_234.svg";
import { ReactComponent as LandPlot_27_235 } from "./land_plots/27/land_plot_block_235.svg";
import { ReactComponent as LandPlot_27_236 } from "./land_plots/27/land_plot_block_236.svg";
import { ReactComponent as LandPlot_27_237 } from "./land_plots/27/land_plot_block_237.svg";
import { ReactComponent as LandPlot_27_238 } from "./land_plots/27/land_plot_block_238.svg";
import { ReactComponent as LandPlot_27_239 } from "./land_plots/27/land_plot_block_239.svg";
import { ReactComponent as LandPlot_27_240 } from "./land_plots/27/land_plot_block_240.svg";
import { ReactComponent as LandPlot_27_241 } from "./land_plots/27/land_plot_block_241.svg";
import { ReactComponent as LandPlot_27_242 } from "./land_plots/27/land_plot_block_242.svg";
import { ReactComponent as LandPlot_27_243 } from "./land_plots/27/land_plot_block_243.svg";
import { ReactComponent as LandPlot_27_244 } from "./land_plots/27/land_plot_block_244.svg";
import { ReactComponent as LandPlot_27_245 } from "./land_plots/27/land_plot_block_245.svg";
import { ReactComponent as LandPlot_27_246 } from "./land_plots/27/land_plot_block_246.svg";
import { ReactComponent as LandPlot_27_247 } from "./land_plots/27/land_plot_block_247.svg";
import { ReactComponent as LandPlot_27_248 } from "./land_plots/27/land_plot_block_248.svg";
import { ReactComponent as LandPlot_27_249 } from "./land_plots/27/land_plot_block_249.svg";
import { ReactComponent as LandPlot_27_250 } from "./land_plots/27/land_plot_block_250.svg";
import { ReactComponent as LandPlot_27_251 } from "./land_plots/27/land_plot_block_251.svg";
import { ReactComponent as LandPlot_27_252 } from "./land_plots/27/land_plot_block_252.svg";
import { ReactComponent as LandPlot_27_253 } from "./land_plots/27/land_plot_block_253.svg";
import { ReactComponent as LandPlot_27_254 } from "./land_plots/27/land_plot_block_254.svg";
import { ReactComponent as LandPlot_27_255 } from "./land_plots/27/land_plot_block_255.svg";
import { ReactComponent as LandPlot_27_256 } from "./land_plots/27/land_plot_block_256.svg";
import { ReactComponent as LandPlot_27_257 } from "./land_plots/27/land_plot_block_257.svg";
import { ReactComponent as LandPlot_27_258 } from "./land_plots/27/land_plot_block_258.svg";
import { ReactComponent as LandPlot_27_259 } from "./land_plots/27/land_plot_block_259.svg";
import { ReactComponent as LandPlot_27_260 } from "./land_plots/27/land_plot_block_260.svg";
import { ReactComponent as LandPlot_27_261 } from "./land_plots/27/land_plot_block_261.svg";
import { ReactComponent as LandPlot_27_262 } from "./land_plots/27/land_plot_block_262.svg";
import { ReactComponent as LandPlot_27_263 } from "./land_plots/27/land_plot_block_263.svg";
import { ReactComponent as LandPlot_27_264 } from "./land_plots/27/land_plot_block_264.svg";
import { ReactComponent as LandPlot_27_265 } from "./land_plots/27/land_plot_block_265.svg";
import { ReactComponent as LandPlot_27_266 } from "./land_plots/27/land_plot_block_266.svg";
import { ReactComponent as LandPlot_27_267 } from "./land_plots/27/land_plot_block_267.svg";
import { ReactComponent as LandPlot_27_268 } from "./land_plots/27/land_plot_block_268.svg";
import { ReactComponent as LandPlot_27_269 } from "./land_plots/27/land_plot_block_269.svg";
import { ReactComponent as LandPlot_27_270 } from "./land_plots/27/land_plot_block_270.svg";
import { ReactComponent as LandPlot_27_271 } from "./land_plots/27/land_plot_block_271.svg";
import { ReactComponent as LandPlot_27_272 } from "./land_plots/27/land_plot_block_272.svg";
import { ReactComponent as LandPlot_27_273 } from "./land_plots/27/land_plot_block_273.svg";
import { ReactComponent as LandPlot_27_274 } from "./land_plots/27/land_plot_block_274.svg";
import { ReactComponent as LandPlot_27_275 } from "./land_plots/27/land_plot_block_275.svg";
import { ReactComponent as LandPlot_27_276 } from "./land_plots/27/land_plot_block_276.svg";
import { ReactComponent as LandPlot_27_277 } from "./land_plots/27/land_plot_block_277.svg";
import { ReactComponent as LandPlot_27_278 } from "./land_plots/27/land_plot_block_278.svg";
import { ReactComponent as LandPlot_27_279 } from "./land_plots/27/land_plot_block_279.svg";
import { ReactComponent as LandPlot_27_280 } from "./land_plots/27/land_plot_block_280.svg";
import { ReactComponent as LandPlot_27_281 } from "./land_plots/27/land_plot_block_281.svg";
import { ReactComponent as LandPlot_27_282 } from "./land_plots/27/land_plot_block_282.svg";
import { ReactComponent as LandPlot_27_283 } from "./land_plots/27/land_plot_block_283.svg";
import { ReactComponent as LandPlot_27_284 } from "./land_plots/27/land_plot_block_284.svg";
import { ReactComponent as LandPlot_27_285 } from "./land_plots/27/land_plot_block_285.svg";
import { ReactComponent as LandPlot_27_286 } from "./land_plots/27/land_plot_block_286.svg";
import { ReactComponent as LandPlot_27_287 } from "./land_plots/27/land_plot_block_287.svg";
import { ReactComponent as LandPlot_27_288 } from "./land_plots/27/land_plot_block_288.svg";
import { ReactComponent as LandPlot_27_289 } from "./land_plots/27/land_plot_block_289.svg";
import { ReactComponent as LandPlot_27_290 } from "./land_plots/27/land_plot_block_290.svg";
import { ReactComponent as LandPlot_27_291 } from "./land_plots/27/land_plot_block_291.svg";
import { ReactComponent as LandPlot_27_292 } from "./land_plots/27/land_plot_block_292.svg";
import { ReactComponent as LandPlot_27_293 } from "./land_plots/27/land_plot_block_293.svg";
import { ReactComponent as LandPlot_27_294 } from "./land_plots/27/land_plot_block_294.svg";
import { ReactComponent as LandPlot_27_295 } from "./land_plots/27/land_plot_block_295.svg";
import { ReactComponent as LandPlot_27_296 } from "./land_plots/27/land_plot_block_296.svg";
import { ReactComponent as LandPlot_27_297 } from "./land_plots/27/land_plot_block_297.svg";
import { ReactComponent as LandPlot_27_298 } from "./land_plots/27/land_plot_block_298.svg";
import { ReactComponent as LandPlot_27_299 } from "./land_plots/27/land_plot_block_299.svg";
import { ReactComponent as LandPlot_27_300 } from "./land_plots/27/land_plot_block_300.svg";
import { ReactComponent as LandPlot_27_301 } from "./land_plots/27/land_plot_block_301.svg";
import { ReactComponent as LandPlot_27_302 } from "./land_plots/27/land_plot_block_302.svg";
import { ReactComponent as LandPlot_27_303 } from "./land_plots/27/land_plot_block_303.svg";
import { ReactComponent as LandPlot_27_304 } from "./land_plots/27/land_plot_block_304.svg";
import { ReactComponent as LandPlot_27_305 } from "./land_plots/27/land_plot_block_305.svg";
import { ReactComponent as LandPlot_27_306 } from "./land_plots/27/land_plot_block_306.svg";
import { ReactComponent as LandPlot_27_307 } from "./land_plots/27/land_plot_block_307.svg";
import { ReactComponent as LandPlot_27_308 } from "./land_plots/27/land_plot_block_308.svg";
import { ReactComponent as LandPlot_27_309 } from "./land_plots/27/land_plot_block_309.svg";
import { ReactComponent as LandPlot_27_310 } from "./land_plots/27/land_plot_block_310.svg";
import { ReactComponent as LandPlot_27_311 } from "./land_plots/27/land_plot_block_311.svg";
import { ReactComponent as LandPlot_27_312 } from "./land_plots/27/land_plot_block_312.svg";
import { ReactComponent as LandPlot_27_313 } from "./land_plots/27/land_plot_block_313.svg";
import { ReactComponent as LandPlot_27_314 } from "./land_plots/27/land_plot_block_314.svg";
import { ReactComponent as LandPlot_27_315 } from "./land_plots/27/land_plot_block_315.svg";
import { ReactComponent as LandPlot_27_316 } from "./land_plots/27/land_plot_block_316.svg";
import { ReactComponent as LandPlot_27_317 } from "./land_plots/27/land_plot_block_317.svg";
import { ReactComponent as LandPlot_27_318 } from "./land_plots/27/land_plot_block_318.svg";
import { ReactComponent as LandPlot_27_319 } from "./land_plots/27/land_plot_block_319.svg";
import { ReactComponent as LandPlot_27_320 } from "./land_plots/27/land_plot_block_320.svg";
import { ReactComponent as LandPlot_27_321 } from "./land_plots/27/land_plot_block_321.svg";
import { ReactComponent as LandPlot_27_322 } from "./land_plots/27/land_plot_block_322.svg";
import { ReactComponent as LandPlot_27_323 } from "./land_plots/27/land_plot_block_323.svg";
import { ReactComponent as LandPlot_27_324 } from "./land_plots/27/land_plot_block_324.svg";
import { ReactComponent as LandPlot_27_325 } from "./land_plots/27/land_plot_block_325.svg";
import { ReactComponent as LandPlot_27_326 } from "./land_plots/27/land_plot_block_326.svg";
import { ReactComponent as LandPlot_27_327 } from "./land_plots/27/land_plot_block_327.svg";
import { ReactComponent as LandPlot_27_328 } from "./land_plots/27/land_plot_block_328.svg";
import { ReactComponent as LandPlot_27_329 } from "./land_plots/27/land_plot_block_329.svg";
import { ReactComponent as LandPlot_27_330 } from "./land_plots/27/land_plot_block_330.svg";
import { ReactComponent as LandPlot_27_331 } from "./land_plots/27/land_plot_block_331.svg";
import { ReactComponent as LandPlot_27_332 } from "./land_plots/27/land_plot_block_332.svg";
import { ReactComponent as LandPlot_27_333 } from "./land_plots/27/land_plot_block_333.svg";
import { ReactComponent as LandPlot_27_334 } from "./land_plots/27/land_plot_block_334.svg";
import { ReactComponent as LandPlot_27_335 } from "./land_plots/27/land_plot_block_335.svg";
import { ReactComponent as LandPlot_27_336 } from "./land_plots/27/land_plot_block_336.svg";
import { ReactComponent as LandPlot_27_337 } from "./land_plots/27/land_plot_block_337.svg";
import { ReactComponent as LandPlot_27_338 } from "./land_plots/27/land_plot_block_338.svg";
import { ReactComponent as LandPlot_27_339 } from "./land_plots/27/land_plot_block_339.svg";
import { ReactComponent as LandPlot_27_340 } from "./land_plots/27/land_plot_block_340.svg";
import { ReactComponent as LandPlot_27_341 } from "./land_plots/27/land_plot_block_341.svg";
import { ReactComponent as LandPlot_27_342 } from "./land_plots/27/land_plot_block_342.svg";
import { ReactComponent as LandPlot_27_343 } from "./land_plots/27/land_plot_block_343.svg";
import { ReactComponent as LandPlot_27_344 } from "./land_plots/27/land_plot_block_344.svg";
import { ReactComponent as LandPlot_27_345 } from "./land_plots/27/land_plot_block_345.svg";
import { ReactComponent as LandPlot_27_346 } from "./land_plots/27/land_plot_block_346.svg";
import { ReactComponent as LandPlot_27_347 } from "./land_plots/27/land_plot_block_347.svg";
import { ReactComponent as LandPlot_27_348 } from "./land_plots/27/land_plot_block_348.svg";
import { ReactComponent as LandPlot_27_349 } from "./land_plots/27/land_plot_block_349.svg";
import { ReactComponent as LandPlot_27_350 } from "./land_plots/27/land_plot_block_350.svg";
import { ReactComponent as LandPlot_27_351 } from "./land_plots/27/land_plot_block_351.svg";
import { ReactComponent as LandPlot_27_352 } from "./land_plots/27/land_plot_block_352.svg";
import { ReactComponent as LandPlot_27_353 } from "./land_plots/27/land_plot_block_353.svg";
import { ReactComponent as LandPlot_27_354 } from "./land_plots/27/land_plot_block_354.svg";
import { ReactComponent as LandPlot_27_355 } from "./land_plots/27/land_plot_block_355.svg";
import { ReactComponent as LandPlot_27_356 } from "./land_plots/27/land_plot_block_356.svg";
import { ReactComponent as LandPlot_27_357 } from "./land_plots/27/land_plot_block_357.svg";
import { ReactComponent as LandPlot_27_358 } from "./land_plots/27/land_plot_block_358.svg";
import { ReactComponent as LandPlot_27_359 } from "./land_plots/27/land_plot_block_359.svg";
import { ReactComponent as LandPlot_27_360 } from "./land_plots/27/land_plot_block_360.svg";
import { ReactComponent as LandPlot_27_361 } from "./land_plots/27/land_plot_block_361.svg";
import { ReactComponent as LandPlot_27_362 } from "./land_plots/27/land_plot_block_362.svg";
import { ReactComponent as LandPlot_27_363 } from "./land_plots/27/land_plot_block_363.svg";
import { ReactComponent as LandPlot_27_364 } from "./land_plots/27/land_plot_block_364.svg";
import { ReactComponent as LandPlot_27_365 } from "./land_plots/27/land_plot_block_365.svg";
import { ReactComponent as LandPlot_27_366 } from "./land_plots/27/land_plot_block_366.svg";
import { ReactComponent as LandPlot_27_367 } from "./land_plots/27/land_plot_block_367.svg";
import { ReactComponent as LandPlot_27_368 } from "./land_plots/27/land_plot_block_368.svg";
import { ReactComponent as LandPlot_27_369 } from "./land_plots/27/land_plot_block_369.svg";
import { ReactComponent as LandPlot_27_370 } from "./land_plots/27/land_plot_block_370.svg";
import { ReactComponent as LandPlot_27_371 } from "./land_plots/27/land_plot_block_371.svg";
import { ReactComponent as LandPlot_27_372 } from "./land_plots/27/land_plot_block_372.svg";
import { ReactComponent as LandPlot_27_373 } from "./land_plots/27/land_plot_block_373.svg";
import { ReactComponent as LandPlot_27_374 } from "./land_plots/27/land_plot_block_374.svg";
import { ReactComponent as LandPlot_27_375 } from "./land_plots/27/land_plot_block_375.svg";
import { ReactComponent as LandPlot_27_376 } from "./land_plots/27/land_plot_block_376.svg";
import { ReactComponent as LandPlot_27_377 } from "./land_plots/27/land_plot_block_377.svg";
import { ReactComponent as LandPlot_27_378 } from "./land_plots/27/land_plot_block_378.svg";
import { ReactComponent as LandPlot_27_379 } from "./land_plots/27/land_plot_block_379.svg";
import { ReactComponent as LandPlot_27_380 } from "./land_plots/27/land_plot_block_380.svg";
import { ReactComponent as LandPlot_27_381 } from "./land_plots/27/land_plot_block_381.svg";
import { ReactComponent as LandPlot_27_382 } from "./land_plots/27/land_plot_block_382.svg";
import { ReactComponent as LandPlot_27_383 } from "./land_plots/27/land_plot_block_383.svg";
import { ReactComponent as LandPlot_27_384 } from "./land_plots/27/land_plot_block_384.svg";
import { ReactComponent as LandPlot_27_385 } from "./land_plots/27/land_plot_block_385.svg";
import { ReactComponent as LandPlot_27_386 } from "./land_plots/27/land_plot_block_386.svg";
import { ReactComponent as LandPlot_27_387 } from "./land_plots/27/land_plot_block_387.svg";
import { ReactComponent as LandPlot_27_388 } from "./land_plots/27/land_plot_block_388.svg";
import { ReactComponent as LandPlot_27_389 } from "./land_plots/27/land_plot_block_389.svg";
import { ReactComponent as LandPlot_27_390 } from "./land_plots/27/land_plot_block_390.svg";
import { ReactComponent as LandPlot_27_391 } from "./land_plots/27/land_plot_block_391.svg";
import { ReactComponent as LandPlot_27_392 } from "./land_plots/27/land_plot_block_392.svg";
import { ReactComponent as LandPlot_27_393 } from "./land_plots/27/land_plot_block_393.svg";
import { ReactComponent as LandPlot_27_394 } from "./land_plots/27/land_plot_block_394.svg";
import { ReactComponent as LandPlot_27_395 } from "./land_plots/27/land_plot_block_395.svg";
import { ReactComponent as LandPlot_27_396 } from "./land_plots/27/land_plot_block_396.svg";
import { ReactComponent as LandPlot_27_397 } from "./land_plots/27/land_plot_block_397.svg";
import { ReactComponent as LandPlot_27_398 } from "./land_plots/27/land_plot_block_398.svg";
import { ReactComponent as LandPlot_27_399 } from "./land_plots/27/land_plot_block_399.svg";
import { ReactComponent as LandPlot_27_400 } from "./land_plots/27/land_plot_block_400.svg";
import { ReactComponent as LandPlot_27_401 } from "./land_plots/27/land_plot_block_401.svg";
import { ReactComponent as LandPlot_27_402 } from "./land_plots/27/land_plot_block_402.svg";
import { ReactComponent as LandPlot_27_403 } from "./land_plots/27/land_plot_block_403.svg";
import { ReactComponent as LandPlot_27_404 } from "./land_plots/27/land_plot_block_404.svg";
import { ReactComponent as LandPlot_27_405 } from "./land_plots/27/land_plot_block_405.svg";
import { ReactComponent as LandPlot_27_406 } from "./land_plots/27/land_plot_block_406.svg";
import { ReactComponent as LandPlot_27_407 } from "./land_plots/27/land_plot_block_407.svg";
import { ReactComponent as LandPlot_27_408 } from "./land_plots/27/land_plot_block_408.svg";
import { ReactComponent as LandPlot_27_409 } from "./land_plots/27/land_plot_block_409.svg";
import { ReactComponent as LandPlot_27_410 } from "./land_plots/27/land_plot_block_410.svg";
import { ReactComponent as LandPlot_27_411 } from "./land_plots/27/land_plot_block_411.svg";
import { ReactComponent as LandPlot_27_412 } from "./land_plots/27/land_plot_block_412.svg";
import { ReactComponent as LandPlot_27_413 } from "./land_plots/27/land_plot_block_413.svg";
import { ReactComponent as LandPlot_27_414 } from "./land_plots/27/land_plot_block_414.svg";
import { ReactComponent as LandPlot_27_415 } from "./land_plots/27/land_plot_block_415.svg";
import { ReactComponent as LandPlot_27_416 } from "./land_plots/27/land_plot_block_416.svg";
import { ReactComponent as LandPlot_27_417 } from "./land_plots/27/land_plot_block_417.svg";
import { ReactComponent as LandPlot_27_418 } from "./land_plots/27/land_plot_block_418.svg";
import { ReactComponent as LandPlot_27_419 } from "./land_plots/27/land_plot_block_419.svg";
import { ReactComponent as LandPlot_27_420 } from "./land_plots/27/land_plot_block_420.svg";
import { ReactComponent as LandPlot_27_421 } from "./land_plots/27/land_plot_block_421.svg";
import { ReactComponent as LandPlot_27_422 } from "./land_plots/27/land_plot_block_422.svg";
import { ReactComponent as LandPlot_27_423 } from "./land_plots/27/land_plot_block_423.svg";
import { ReactComponent as LandPlot_27_424 } from "./land_plots/27/land_plot_block_424.svg";
import { ReactComponent as LandPlot_27_425 } from "./land_plots/27/land_plot_block_425.svg";
import { ReactComponent as LandPlot_27_426 } from "./land_plots/27/land_plot_block_426.svg";
import { ReactComponent as LandPlot_27_427 } from "./land_plots/27/land_plot_block_427.svg";
import { ReactComponent as LandPlot_27_428 } from "./land_plots/27/land_plot_block_428.svg";
import { ReactComponent as LandPlot_27_429 } from "./land_plots/27/land_plot_block_429.svg";
import { ReactComponent as LandPlot_27_430 } from "./land_plots/27/land_plot_block_430.svg";
import { ReactComponent as LandPlot_27_431 } from "./land_plots/27/land_plot_block_431.svg";
import { ReactComponent as LandPlot_27_432 } from "./land_plots/27/land_plot_block_432.svg";
import { ReactComponent as LandPlot_27_433 } from "./land_plots/27/land_plot_block_433.svg";
import { ReactComponent as LandPlot_27_434 } from "./land_plots/27/land_plot_block_434.svg";
import { ReactComponent as LandPlot_27_435 } from "./land_plots/27/land_plot_block_435.svg";
import { ReactComponent as LandPlot_27_436 } from "./land_plots/27/land_plot_block_436.svg";
import { ReactComponent as LandPlot_27_437 } from "./land_plots/27/land_plot_block_437.svg";
import { ReactComponent as LandPlot_27_438 } from "./land_plots/27/land_plot_block_438.svg";
import { ReactComponent as LandPlot_27_439 } from "./land_plots/27/land_plot_block_439.svg";
import { ReactComponent as LandPlot_27_440 } from "./land_plots/27/land_plot_block_440.svg";
import { ReactComponent as LandPlot_27_441 } from "./land_plots/27/land_plot_block_441.svg";
import { ReactComponent as LandPlot_27_442 } from "./land_plots/27/land_plot_block_442.svg";
import { ReactComponent as LandPlot_27_443 } from "./land_plots/27/land_plot_block_443.svg";
import { ReactComponent as LandPlot_27_444 } from "./land_plots/27/land_plot_block_444.svg";
import { ReactComponent as LandPlot_27_445 } from "./land_plots/27/land_plot_block_445.svg";
import { ReactComponent as LandPlot_27_446 } from "./land_plots/27/land_plot_block_446.svg";
import { ReactComponent as LandPlot_27_447 } from "./land_plots/27/land_plot_block_447.svg";
import { ReactComponent as LandPlot_27_448 } from "./land_plots/27/land_plot_block_448.svg";
import { ReactComponent as LandPlot_27_449 } from "./land_plots/27/land_plot_block_449.svg";

//stop frame 62
import { ReactComponent as LandPlot_62_3 } from "./land_plots/62/land_plot_block_3.svg";
import { ReactComponent as LandPlot_62_4 } from "./land_plots/62/land_plot_block_4.svg";
import { ReactComponent as LandPlot_62_5 } from "./land_plots/62/land_plot_block_5.svg";
import { ReactComponent as LandPlot_62_6 } from "./land_plots/62/land_plot_block_6.svg";
import { ReactComponent as LandPlot_62_7 } from "./land_plots/62/land_plot_block_7.svg";
import { ReactComponent as LandPlot_62_8 } from "./land_plots/62/land_plot_block_8.svg";
import { ReactComponent as LandPlot_62_9 } from "./land_plots/62/land_plot_block_9.svg";
import { ReactComponent as LandPlot_62_10 } from "./land_plots/62/land_plot_block_10.svg";
import { ReactComponent as LandPlot_62_11 } from "./land_plots/62/land_plot_block_11.svg";
import { ReactComponent as LandPlot_62_12 } from "./land_plots/62/land_plot_block_12.svg";
import { ReactComponent as LandPlot_62_13 } from "./land_plots/62/land_plot_block_13.svg";
import { ReactComponent as LandPlot_62_14 } from "./land_plots/62/land_plot_block_14.svg";
import { ReactComponent as LandPlot_62_15 } from "./land_plots/62/land_plot_block_15.svg";
import { ReactComponent as LandPlot_62_16 } from "./land_plots/62/land_plot_block_16.svg";
import { ReactComponent as LandPlot_62_17 } from "./land_plots/62/land_plot_block_17.svg";
import { ReactComponent as LandPlot_62_18 } from "./land_plots/62/land_plot_block_18.svg";
import { ReactComponent as LandPlot_62_19 } from "./land_plots/62/land_plot_block_19.svg";
import { ReactComponent as LandPlot_62_20 } from "./land_plots/62/land_plot_block_20.svg";
import { ReactComponent as LandPlot_62_21 } from "./land_plots/62/land_plot_block_21.svg";
import { ReactComponent as LandPlot_62_22 } from "./land_plots/62/land_plot_block_22.svg";
import { ReactComponent as LandPlot_62_23 } from "./land_plots/62/land_plot_block_23.svg";
import { ReactComponent as LandPlot_62_24 } from "./land_plots/62/land_plot_block_24.svg";
import { ReactComponent as LandPlot_62_25 } from "./land_plots/62/land_plot_block_25.svg";
import { ReactComponent as LandPlot_62_26 } from "./land_plots/62/land_plot_block_26.svg";
import { ReactComponent as LandPlot_62_27 } from "./land_plots/62/land_plot_block_27.svg";
import { ReactComponent as LandPlot_62_28 } from "./land_plots/62/land_plot_block_28.svg";
import { ReactComponent as LandPlot_62_29 } from "./land_plots/62/land_plot_block_29.svg";
import { ReactComponent as LandPlot_62_30 } from "./land_plots/62/land_plot_block_30.svg";
import { ReactComponent as LandPlot_62_31 } from "./land_plots/62/land_plot_block_31.svg";
import { ReactComponent as LandPlot_62_32 } from "./land_plots/62/land_plot_block_32.svg";
import { ReactComponent as LandPlot_62_33 } from "./land_plots/62/land_plot_block_33.svg";
import { ReactComponent as LandPlot_62_34 } from "./land_plots/62/land_plot_block_34.svg";
import { ReactComponent as LandPlot_62_35 } from "./land_plots/62/land_plot_block_35.svg";
import { ReactComponent as LandPlot_62_36 } from "./land_plots/62/land_plot_block_36.svg";
import { ReactComponent as LandPlot_62_37 } from "./land_plots/62/land_plot_block_37.svg";
import { ReactComponent as LandPlot_62_38 } from "./land_plots/62/land_plot_block_38.svg";
import { ReactComponent as LandPlot_62_39 } from "./land_plots/62/land_plot_block_39.svg";
import { ReactComponent as LandPlot_62_40 } from "./land_plots/62/land_plot_block_40.svg";
import { ReactComponent as LandPlot_62_41 } from "./land_plots/62/land_plot_block_41.svg";
import { ReactComponent as LandPlot_62_42 } from "./land_plots/62/land_plot_block_42.svg";
import { ReactComponent as LandPlot_62_43 } from "./land_plots/62/land_plot_block_43.svg";
import { ReactComponent as LandPlot_62_44 } from "./land_plots/62/land_plot_block_44.svg";
import { ReactComponent as LandPlot_62_45 } from "./land_plots/62/land_plot_block_45.svg";
import { ReactComponent as LandPlot_62_46 } from "./land_plots/62/land_plot_block_46.svg";
import { ReactComponent as LandPlot_62_47 } from "./land_plots/62/land_plot_block_47.svg";
import { ReactComponent as LandPlot_62_48 } from "./land_plots/62/land_plot_block_48.svg";
import { ReactComponent as LandPlot_62_49 } from "./land_plots/62/land_plot_block_49.svg";
import { ReactComponent as LandPlot_62_50 } from "./land_plots/62/land_plot_block_50.svg";
import { ReactComponent as LandPlot_62_51 } from "./land_plots/62/land_plot_block_51.svg";
import { ReactComponent as LandPlot_62_52 } from "./land_plots/62/land_plot_block_52.svg";
import { ReactComponent as LandPlot_62_53 } from "./land_plots/62/land_plot_block_53.svg";
import { ReactComponent as LandPlot_62_54 } from "./land_plots/62/land_plot_block_54.svg";
import { ReactComponent as LandPlot_62_62 } from "./land_plots/62/land_plot_block_62.svg";
import { ReactComponent as LandPlot_62_63 } from "./land_plots/62/land_plot_block_63.svg";
import { ReactComponent as LandPlot_62_64 } from "./land_plots/62/land_plot_block_64.svg";
import { ReactComponent as LandPlot_62_65 } from "./land_plots/62/land_plot_block_65.svg";
import { ReactComponent as LandPlot_62_66 } from "./land_plots/62/land_plot_block_66.svg";
import { ReactComponent as LandPlot_62_67 } from "./land_plots/62/land_plot_block_67.svg";
import { ReactComponent as LandPlot_62_68 } from "./land_plots/62/land_plot_block_68.svg";
import { ReactComponent as LandPlot_62_69 } from "./land_plots/62/land_plot_block_69.svg";
import { ReactComponent as LandPlot_62_70 } from "./land_plots/62/land_plot_block_70.svg";
import { ReactComponent as LandPlot_62_71 } from "./land_plots/62/land_plot_block_71.svg";
import { ReactComponent as LandPlot_62_72 } from "./land_plots/62/land_plot_block_72.svg";
import { ReactComponent as LandPlot_62_73 } from "./land_plots/62/land_plot_block_73.svg";
import { ReactComponent as LandPlot_62_74 } from "./land_plots/62/land_plot_block_74.svg";
import { ReactComponent as LandPlot_62_75 } from "./land_plots/62/land_plot_block_75.svg";
import { ReactComponent as LandPlot_62_76 } from "./land_plots/62/land_plot_block_76.svg";
import { ReactComponent as LandPlot_62_77 } from "./land_plots/62/land_plot_block_77.svg";
import { ReactComponent as LandPlot_62_78 } from "./land_plots/62/land_plot_block_78.svg";
import { ReactComponent as LandPlot_62_79 } from "./land_plots/62/land_plot_block_79.svg";
import { ReactComponent as LandPlot_62_80 } from "./land_plots/62/land_plot_block_80.svg";
import { ReactComponent as LandPlot_62_81 } from "./land_plots/62/land_plot_block_81.svg";
import { ReactComponent as LandPlot_62_82 } from "./land_plots/62/land_plot_block_82.svg";
import { ReactComponent as LandPlot_62_83 } from "./land_plots/62/land_plot_block_83.svg";
import { ReactComponent as LandPlot_62_84 } from "./land_plots/62/land_plot_block_84.svg";
import { ReactComponent as LandPlot_62_85 } from "./land_plots/62/land_plot_block_85.svg";
import { ReactComponent as LandPlot_62_86 } from "./land_plots/62/land_plot_block_86.svg";
import { ReactComponent as LandPlot_62_87 } from "./land_plots/62/land_plot_block_87.svg";
import { ReactComponent as LandPlot_62_88 } from "./land_plots/62/land_plot_block_88.svg";
import { ReactComponent as LandPlot_62_89 } from "./land_plots/62/land_plot_block_89.svg";
import { ReactComponent as LandPlot_62_90 } from "./land_plots/62/land_plot_block_90.svg";
import { ReactComponent as LandPlot_62_91 } from "./land_plots/62/land_plot_block_91.svg";
import { ReactComponent as LandPlot_62_92 } from "./land_plots/62/land_plot_block_92.svg";
import { ReactComponent as LandPlot_62_93 } from "./land_plots/62/land_plot_block_93.svg";
import { ReactComponent as LandPlot_62_94 } from "./land_plots/62/land_plot_block_94.svg";
import { ReactComponent as LandPlot_62_95 } from "./land_plots/62/land_plot_block_95.svg";
import { ReactComponent as LandPlot_62_96 } from "./land_plots/62/land_plot_block_96.svg";
import { ReactComponent as LandPlot_62_97 } from "./land_plots/62/land_plot_block_97.svg";
import { ReactComponent as LandPlot_62_98 } from "./land_plots/62/land_plot_block_98.svg";
import { ReactComponent as LandPlot_62_99 } from "./land_plots/62/land_plot_block_99.svg";
import { ReactComponent as LandPlot_62_100 } from "./land_plots/62/land_plot_block_100.svg";
import { ReactComponent as LandPlot_62_101 } from "./land_plots/62/land_plot_block_101.svg";
import { ReactComponent as LandPlot_62_102 } from "./land_plots/62/land_plot_block_102.svg";
import { ReactComponent as LandPlot_62_103 } from "./land_plots/62/land_plot_block_103.svg";
import { ReactComponent as LandPlot_62_104 } from "./land_plots/62/land_plot_block_104.svg";
import { ReactComponent as LandPlot_62_105 } from "./land_plots/62/land_plot_block_105.svg";
import { ReactComponent as LandPlot_62_106 } from "./land_plots/62/land_plot_block_106.svg";
import { ReactComponent as LandPlot_62_107 } from "./land_plots/62/land_plot_block_107.svg";
import { ReactComponent as LandPlot_62_108 } from "./land_plots/62/land_plot_block_108.svg";
import { ReactComponent as LandPlot_62_109 } from "./land_plots/62/land_plot_block_109.svg";
import { ReactComponent as LandPlot_62_110 } from "./land_plots/62/land_plot_block_110.svg";
import { ReactComponent as LandPlot_62_111 } from "./land_plots/62/land_plot_block_111.svg";
import { ReactComponent as LandPlot_62_112 } from "./land_plots/62/land_plot_block_112.svg";
import { ReactComponent as LandPlot_62_113 } from "./land_plots/62/land_plot_block_113.svg";
import { ReactComponent as LandPlot_62_114 } from "./land_plots/62/land_plot_block_114.svg";
import { ReactComponent as LandPlot_62_115 } from "./land_plots/62/land_plot_block_115.svg";
import { ReactComponent as LandPlot_62_116 } from "./land_plots/62/land_plot_block_116.svg";
import { ReactComponent as LandPlot_62_117 } from "./land_plots/62/land_plot_block_117.svg";
import { ReactComponent as LandPlot_62_118 } from "./land_plots/62/land_plot_block_118.svg";
import { ReactComponent as LandPlot_62_119 } from "./land_plots/62/land_plot_block_119.svg";
import { ReactComponent as LandPlot_62_120 } from "./land_plots/62/land_plot_block_120.svg";
import { ReactComponent as LandPlot_62_121 } from "./land_plots/62/land_plot_block_121.svg";
import { ReactComponent as LandPlot_62_122 } from "./land_plots/62/land_plot_block_122.svg";
import { ReactComponent as LandPlot_62_123 } from "./land_plots/62/land_plot_block_123.svg";
import { ReactComponent as LandPlot_62_124 } from "./land_plots/62/land_plot_block_124.svg";
import { ReactComponent as LandPlot_62_125 } from "./land_plots/62/land_plot_block_125.svg";
import { ReactComponent as LandPlot_62_126 } from "./land_plots/62/land_plot_block_126.svg";
import { ReactComponent as LandPlot_62_127 } from "./land_plots/62/land_plot_block_127.svg";
import { ReactComponent as LandPlot_62_128 } from "./land_plots/62/land_plot_block_128.svg";
import { ReactComponent as LandPlot_62_129 } from "./land_plots/62/land_plot_block_129.svg";
import { ReactComponent as LandPlot_62_130 } from "./land_plots/62/land_plot_block_130.svg";
import { ReactComponent as LandPlot_62_131 } from "./land_plots/62/land_plot_block_131.svg";
import { ReactComponent as LandPlot_62_132 } from "./land_plots/62/land_plot_block_132.svg";
import { ReactComponent as LandPlot_62_133 } from "./land_plots/62/land_plot_block_133.svg";
import { ReactComponent as LandPlot_62_134 } from "./land_plots/62/land_plot_block_134.svg";
import { ReactComponent as LandPlot_62_135 } from "./land_plots/62/land_plot_block_135.svg";
import { ReactComponent as LandPlot_62_136 } from "./land_plots/62/land_plot_block_136.svg";
import { ReactComponent as LandPlot_62_137 } from "./land_plots/62/land_plot_block_137.svg";
import { ReactComponent as LandPlot_62_138 } from "./land_plots/62/land_plot_block_138.svg";
import { ReactComponent as LandPlot_62_139 } from "./land_plots/62/land_plot_block_139.svg";
import { ReactComponent as LandPlot_62_140 } from "./land_plots/62/land_plot_block_140.svg";
import { ReactComponent as LandPlot_62_141 } from "./land_plots/62/land_plot_block_141.svg";
import { ReactComponent as LandPlot_62_142 } from "./land_plots/62/land_plot_block_142.svg";
import { ReactComponent as LandPlot_62_143 } from "./land_plots/62/land_plot_block_143.svg";
import { ReactComponent as LandPlot_62_144 } from "./land_plots/62/land_plot_block_144.svg";
import { ReactComponent as LandPlot_62_145 } from "./land_plots/62/land_plot_block_145.svg";
import { ReactComponent as LandPlot_62_146 } from "./land_plots/62/land_plot_block_146.svg";
import { ReactComponent as LandPlot_62_147 } from "./land_plots/62/land_plot_block_147.svg";
import { ReactComponent as LandPlot_62_148 } from "./land_plots/62/land_plot_block_148.svg";
import { ReactComponent as LandPlot_62_149 } from "./land_plots/62/land_plot_block_149.svg";
import { ReactComponent as LandPlot_62_150 } from "./land_plots/62/land_plot_block_150.svg";
import { ReactComponent as LandPlot_62_151 } from "./land_plots/62/land_plot_block_151.svg";
import { ReactComponent as LandPlot_62_152 } from "./land_plots/62/land_plot_block_152.svg";
import { ReactComponent as LandPlot_62_153 } from "./land_plots/62/land_plot_block_153.svg";
import { ReactComponent as LandPlot_62_154 } from "./land_plots/62/land_plot_block_154.svg";
import { ReactComponent as LandPlot_62_155 } from "./land_plots/62/land_plot_block_155.svg";
import { ReactComponent as LandPlot_62_156 } from "./land_plots/62/land_plot_block_156.svg";
import { ReactComponent as LandPlot_62_157 } from "./land_plots/62/land_plot_block_157.svg";
import { ReactComponent as LandPlot_62_158 } from "./land_plots/62/land_plot_block_158.svg";
import { ReactComponent as LandPlot_62_159 } from "./land_plots/62/land_plot_block_159.svg";
import { ReactComponent as LandPlot_62_160 } from "./land_plots/62/land_plot_block_160.svg";
import { ReactComponent as LandPlot_62_161 } from "./land_plots/62/land_plot_block_161.svg";
import { ReactComponent as LandPlot_62_162 } from "./land_plots/62/land_plot_block_162.svg";
import { ReactComponent as LandPlot_62_163 } from "./land_plots/62/land_plot_block_163.svg";
import { ReactComponent as LandPlot_62_164 } from "./land_plots/62/land_plot_block_164.svg";
import { ReactComponent as LandPlot_62_165 } from "./land_plots/62/land_plot_block_165.svg";
import { ReactComponent as LandPlot_62_166 } from "./land_plots/62/land_plot_block_166.svg";
import { ReactComponent as LandPlot_62_167 } from "./land_plots/62/land_plot_block_167.svg";
import { ReactComponent as LandPlot_62_168 } from "./land_plots/62/land_plot_block_168.svg";
import { ReactComponent as LandPlot_62_169 } from "./land_plots/62/land_plot_block_169.svg";
import { ReactComponent as LandPlot_62_170 } from "./land_plots/62/land_plot_block_170.svg";
import { ReactComponent as LandPlot_62_171 } from "./land_plots/62/land_plot_block_171.svg";
import { ReactComponent as LandPlot_62_172 } from "./land_plots/62/land_plot_block_172.svg";
import { ReactComponent as LandPlot_62_173 } from "./land_plots/62/land_plot_block_173.svg";
import { ReactComponent as LandPlot_62_174 } from "./land_plots/62/land_plot_block_174.svg";
import { ReactComponent as LandPlot_62_175 } from "./land_plots/62/land_plot_block_175.svg";
import { ReactComponent as LandPlot_62_176 } from "./land_plots/62/land_plot_block_176.svg";
import { ReactComponent as LandPlot_62_177 } from "./land_plots/62/land_plot_block_177.svg";
import { ReactComponent as LandPlot_62_178 } from "./land_plots/62/land_plot_block_178.svg";
import { ReactComponent as LandPlot_62_179 } from "./land_plots/62/land_plot_block_179.svg";
import { ReactComponent as LandPlot_62_180 } from "./land_plots/62/land_plot_block_180.svg";
import { ReactComponent as LandPlot_62_181 } from "./land_plots/62/land_plot_block_181.svg";
import { ReactComponent as LandPlot_62_182 } from "./land_plots/62/land_plot_block_182.svg";
import { ReactComponent as LandPlot_62_183 } from "./land_plots/62/land_plot_block_183.svg";
import { ReactComponent as LandPlot_62_184 } from "./land_plots/62/land_plot_block_184.svg";
import { ReactComponent as LandPlot_62_185 } from "./land_plots/62/land_plot_block_185.svg";
import { ReactComponent as LandPlot_62_186 } from "./land_plots/62/land_plot_block_186.svg";
import { ReactComponent as LandPlot_62_187 } from "./land_plots/62/land_plot_block_187.svg";
import { ReactComponent as LandPlot_62_188 } from "./land_plots/62/land_plot_block_188.svg";
import { ReactComponent as LandPlot_62_189 } from "./land_plots/62/land_plot_block_189.svg";
import { ReactComponent as LandPlot_62_190 } from "./land_plots/62/land_plot_block_190.svg";
import { ReactComponent as LandPlot_62_191 } from "./land_plots/62/land_plot_block_191.svg";
import { ReactComponent as LandPlot_62_192 } from "./land_plots/62/land_plot_block_192.svg";
import { ReactComponent as LandPlot_62_193 } from "./land_plots/62/land_plot_block_193.svg";
import { ReactComponent as LandPlot_62_194 } from "./land_plots/62/land_plot_block_194.svg";
import { ReactComponent as LandPlot_62_195 } from "./land_plots/62/land_plot_block_195.svg";
import { ReactComponent as LandPlot_62_196 } from "./land_plots/62/land_plot_block_196.svg";
import { ReactComponent as LandPlot_62_197 } from "./land_plots/62/land_plot_block_197.svg";
import { ReactComponent as LandPlot_62_198 } from "./land_plots/62/land_plot_block_198.svg";
import { ReactComponent as LandPlot_62_199 } from "./land_plots/62/land_plot_block_199.svg";
import { ReactComponent as LandPlot_62_200 } from "./land_plots/62/land_plot_block_200.svg";
import { ReactComponent as LandPlot_62_201 } from "./land_plots/62/land_plot_block_201.svg";
import { ReactComponent as LandPlot_62_202 } from "./land_plots/62/land_plot_block_202.svg";
import { ReactComponent as LandPlot_62_203 } from "./land_plots/62/land_plot_block_203.svg";
import { ReactComponent as LandPlot_62_204 } from "./land_plots/62/land_plot_block_204.svg";
import { ReactComponent as LandPlot_62_205 } from "./land_plots/62/land_plot_block_205.svg";
import { ReactComponent as LandPlot_62_206 } from "./land_plots/62/land_plot_block_206.svg";
import { ReactComponent as LandPlot_62_207 } from "./land_plots/62/land_plot_block_207.svg";
import { ReactComponent as LandPlot_62_208 } from "./land_plots/62/land_plot_block_208.svg";
import { ReactComponent as LandPlot_62_209 } from "./land_plots/62/land_plot_block_209.svg";
import { ReactComponent as LandPlot_62_210 } from "./land_plots/62/land_plot_block_210.svg";
import { ReactComponent as LandPlot_62_211 } from "./land_plots/62/land_plot_block_211.svg";
import { ReactComponent as LandPlot_62_212 } from "./land_plots/62/land_plot_block_212.svg";
import { ReactComponent as LandPlot_62_213 } from "./land_plots/62/land_plot_block_213.svg";
import { ReactComponent as LandPlot_62_214 } from "./land_plots/62/land_plot_block_214.svg";
import { ReactComponent as LandPlot_62_215 } from "./land_plots/62/land_plot_block_215.svg";
import { ReactComponent as LandPlot_62_216 } from "./land_plots/62/land_plot_block_216.svg";
import { ReactComponent as LandPlot_62_217 } from "./land_plots/62/land_plot_block_217.svg";
import { ReactComponent as LandPlot_62_218 } from "./land_plots/62/land_plot_block_218.svg";
import { ReactComponent as LandPlot_62_219 } from "./land_plots/62/land_plot_block_219.svg";
import { ReactComponent as LandPlot_62_220 } from "./land_plots/62/land_plot_block_220.svg";
import { ReactComponent as LandPlot_62_221 } from "./land_plots/62/land_plot_block_221.svg";
import { ReactComponent as LandPlot_62_222 } from "./land_plots/62/land_plot_block_222.svg";
import { ReactComponent as LandPlot_62_223 } from "./land_plots/62/land_plot_block_223.svg";
import { ReactComponent as LandPlot_62_224 } from "./land_plots/62/land_plot_block_224.svg";
import { ReactComponent as LandPlot_62_225 } from "./land_plots/62/land_plot_block_225.svg";
import { ReactComponent as LandPlot_62_226 } from "./land_plots/62/land_plot_block_226.svg";
import { ReactComponent as LandPlot_62_227 } from "./land_plots/62/land_plot_block_227.svg";
import { ReactComponent as LandPlot_62_228 } from "./land_plots/62/land_plot_block_228.svg";
import { ReactComponent as LandPlot_62_229 } from "./land_plots/62/land_plot_block_229.svg";
import { ReactComponent as LandPlot_62_230 } from "./land_plots/62/land_plot_block_230.svg";
import { ReactComponent as LandPlot_62_231 } from "./land_plots/62/land_plot_block_231.svg";
import { ReactComponent as LandPlot_62_232 } from "./land_plots/62/land_plot_block_232.svg";
import { ReactComponent as LandPlot_62_233 } from "./land_plots/62/land_plot_block_233.svg";
import { ReactComponent as LandPlot_62_234 } from "./land_plots/62/land_plot_block_234.svg";
import { ReactComponent as LandPlot_62_235 } from "./land_plots/62/land_plot_block_235.svg";
import { ReactComponent as LandPlot_62_236 } from "./land_plots/62/land_plot_block_236.svg";
import { ReactComponent as LandPlot_62_237 } from "./land_plots/62/land_plot_block_237.svg";
import { ReactComponent as LandPlot_62_238 } from "./land_plots/62/land_plot_block_238.svg";
import { ReactComponent as LandPlot_62_239 } from "./land_plots/62/land_plot_block_239.svg";
import { ReactComponent as LandPlot_62_240 } from "./land_plots/62/land_plot_block_240.svg";
import { ReactComponent as LandPlot_62_241 } from "./land_plots/62/land_plot_block_241.svg";
import { ReactComponent as LandPlot_62_242 } from "./land_plots/62/land_plot_block_242.svg";
import { ReactComponent as LandPlot_62_243 } from "./land_plots/62/land_plot_block_243.svg";
import { ReactComponent as LandPlot_62_244 } from "./land_plots/62/land_plot_block_244.svg";
import { ReactComponent as LandPlot_62_245 } from "./land_plots/62/land_plot_block_245.svg";
import { ReactComponent as LandPlot_62_246 } from "./land_plots/62/land_plot_block_246.svg";
import { ReactComponent as LandPlot_62_247 } from "./land_plots/62/land_plot_block_247.svg";
import { ReactComponent as LandPlot_62_248 } from "./land_plots/62/land_plot_block_248.svg";
import { ReactComponent as LandPlot_62_249 } from "./land_plots/62/land_plot_block_249.svg";
import { ReactComponent as LandPlot_62_250 } from "./land_plots/62/land_plot_block_250.svg";
import { ReactComponent as LandPlot_62_251 } from "./land_plots/62/land_plot_block_251.svg";
import { ReactComponent as LandPlot_62_252 } from "./land_plots/62/land_plot_block_252.svg";
import { ReactComponent as LandPlot_62_253 } from "./land_plots/62/land_plot_block_253.svg";
import { ReactComponent as LandPlot_62_254 } from "./land_plots/62/land_plot_block_254.svg";
import { ReactComponent as LandPlot_62_255 } from "./land_plots/62/land_plot_block_255.svg";
import { ReactComponent as LandPlot_62_256 } from "./land_plots/62/land_plot_block_256.svg";
import { ReactComponent as LandPlot_62_257 } from "./land_plots/62/land_plot_block_257.svg";
import { ReactComponent as LandPlot_62_258 } from "./land_plots/62/land_plot_block_258.svg";
import { ReactComponent as LandPlot_62_259 } from "./land_plots/62/land_plot_block_259.svg";
import { ReactComponent as LandPlot_62_260 } from "./land_plots/62/land_plot_block_260.svg";
import { ReactComponent as LandPlot_62_261 } from "./land_plots/62/land_plot_block_261.svg";
import { ReactComponent as LandPlot_62_262 } from "./land_plots/62/land_plot_block_262.svg";
import { ReactComponent as LandPlot_62_263 } from "./land_plots/62/land_plot_block_263.svg";
import { ReactComponent as LandPlot_62_264 } from "./land_plots/62/land_plot_block_264.svg";
import { ReactComponent as LandPlot_62_265 } from "./land_plots/62/land_plot_block_265.svg";
import { ReactComponent as LandPlot_62_266 } from "./land_plots/62/land_plot_block_266.svg";
import { ReactComponent as LandPlot_62_267 } from "./land_plots/62/land_plot_block_267.svg";
import { ReactComponent as LandPlot_62_268 } from "./land_plots/62/land_plot_block_268.svg";
import { ReactComponent as LandPlot_62_269 } from "./land_plots/62/land_plot_block_269.svg";
import { ReactComponent as LandPlot_62_270 } from "./land_plots/62/land_plot_block_270.svg";
import { ReactComponent as LandPlot_62_271 } from "./land_plots/62/land_plot_block_271.svg";
import { ReactComponent as LandPlot_62_272 } from "./land_plots/62/land_plot_block_272.svg";
import { ReactComponent as LandPlot_62_273 } from "./land_plots/62/land_plot_block_273.svg";
import { ReactComponent as LandPlot_62_274 } from "./land_plots/62/land_plot_block_274.svg";
import { ReactComponent as LandPlot_62_275 } from "./land_plots/62/land_plot_block_275.svg";
import { ReactComponent as LandPlot_62_276 } from "./land_plots/62/land_plot_block_276.svg";
import { ReactComponent as LandPlot_62_277 } from "./land_plots/62/land_plot_block_277.svg";
import { ReactComponent as LandPlot_62_278 } from "./land_plots/62/land_plot_block_278.svg";
import { ReactComponent as LandPlot_62_279 } from "./land_plots/62/land_plot_block_279.svg";
import { ReactComponent as LandPlot_62_280 } from "./land_plots/62/land_plot_block_280.svg";
import { ReactComponent as LandPlot_62_281 } from "./land_plots/62/land_plot_block_281.svg";
import { ReactComponent as LandPlot_62_282 } from "./land_plots/62/land_plot_block_282.svg";
import { ReactComponent as LandPlot_62_283 } from "./land_plots/62/land_plot_block_283.svg";
import { ReactComponent as LandPlot_62_284 } from "./land_plots/62/land_plot_block_284.svg";
import { ReactComponent as LandPlot_62_285 } from "./land_plots/62/land_plot_block_285.svg";
import { ReactComponent as LandPlot_62_286 } from "./land_plots/62/land_plot_block_286.svg";
import { ReactComponent as LandPlot_62_287 } from "./land_plots/62/land_plot_block_287.svg";
import { ReactComponent as LandPlot_62_288 } from "./land_plots/62/land_plot_block_288.svg";
import { ReactComponent as LandPlot_62_289 } from "./land_plots/62/land_plot_block_289.svg";
import { ReactComponent as LandPlot_62_290 } from "./land_plots/62/land_plot_block_290.svg";
import { ReactComponent as LandPlot_62_291 } from "./land_plots/62/land_plot_block_291.svg";
import { ReactComponent as LandPlot_62_292 } from "./land_plots/62/land_plot_block_292.svg";
import { ReactComponent as LandPlot_62_293 } from "./land_plots/62/land_plot_block_293.svg";
import { ReactComponent as LandPlot_62_294 } from "./land_plots/62/land_plot_block_294.svg";
import { ReactComponent as LandPlot_62_295 } from "./land_plots/62/land_plot_block_295.svg";
import { ReactComponent as LandPlot_62_296 } from "./land_plots/62/land_plot_block_296.svg";
import { ReactComponent as LandPlot_62_297 } from "./land_plots/62/land_plot_block_297.svg";
import { ReactComponent as LandPlot_62_298 } from "./land_plots/62/land_plot_block_298.svg";
import { ReactComponent as LandPlot_62_299 } from "./land_plots/62/land_plot_block_299.svg";
import { ReactComponent as LandPlot_62_300 } from "./land_plots/62/land_plot_block_300.svg";
import { ReactComponent as LandPlot_62_301 } from "./land_plots/62/land_plot_block_301.svg";
import { ReactComponent as LandPlot_62_302 } from "./land_plots/62/land_plot_block_302.svg";
import { ReactComponent as LandPlot_62_303 } from "./land_plots/62/land_plot_block_303.svg";
import { ReactComponent as LandPlot_62_304 } from "./land_plots/62/land_plot_block_304.svg";
import { ReactComponent as LandPlot_62_305 } from "./land_plots/62/land_plot_block_305.svg";
import { ReactComponent as LandPlot_62_306 } from "./land_plots/62/land_plot_block_306.svg";
import { ReactComponent as LandPlot_62_307 } from "./land_plots/62/land_plot_block_307.svg";
import { ReactComponent as LandPlot_62_308 } from "./land_plots/62/land_plot_block_308.svg";
import { ReactComponent as LandPlot_62_309 } from "./land_plots/62/land_plot_block_309.svg";
import { ReactComponent as LandPlot_62_310 } from "./land_plots/62/land_plot_block_310.svg";
import { ReactComponent as LandPlot_62_311 } from "./land_plots/62/land_plot_block_311.svg";
import { ReactComponent as LandPlot_62_312 } from "./land_plots/62/land_plot_block_312.svg";
import { ReactComponent as LandPlot_62_313 } from "./land_plots/62/land_plot_block_313.svg";
import { ReactComponent as LandPlot_62_314 } from "./land_plots/62/land_plot_block_314.svg";
import { ReactComponent as LandPlot_62_315 } from "./land_plots/62/land_plot_block_315.svg";
import { ReactComponent as LandPlot_62_316 } from "./land_plots/62/land_plot_block_316.svg";
import { ReactComponent as LandPlot_62_317 } from "./land_plots/62/land_plot_block_317.svg";
import { ReactComponent as LandPlot_62_318 } from "./land_plots/62/land_plot_block_318.svg";
import { ReactComponent as LandPlot_62_319 } from "./land_plots/62/land_plot_block_319.svg";
import { ReactComponent as LandPlot_62_320 } from "./land_plots/62/land_plot_block_320.svg";
import { ReactComponent as LandPlot_62_321 } from "./land_plots/62/land_plot_block_321.svg";
import { ReactComponent as LandPlot_62_322 } from "./land_plots/62/land_plot_block_322.svg";
import { ReactComponent as LandPlot_62_323 } from "./land_plots/62/land_plot_block_323.svg";
import { ReactComponent as LandPlot_62_324 } from "./land_plots/62/land_plot_block_324.svg";
import { ReactComponent as LandPlot_62_325 } from "./land_plots/62/land_plot_block_325.svg";
import { ReactComponent as LandPlot_62_326 } from "./land_plots/62/land_plot_block_326.svg";
import { ReactComponent as LandPlot_62_327 } from "./land_plots/62/land_plot_block_327.svg";
import { ReactComponent as LandPlot_62_328 } from "./land_plots/62/land_plot_block_328.svg";
import { ReactComponent as LandPlot_62_329 } from "./land_plots/62/land_plot_block_329.svg";
import { ReactComponent as LandPlot_62_330 } from "./land_plots/62/land_plot_block_330.svg";
import { ReactComponent as LandPlot_62_331 } from "./land_plots/62/land_plot_block_331.svg";
import { ReactComponent as LandPlot_62_332 } from "./land_plots/62/land_plot_block_332.svg";
import { ReactComponent as LandPlot_62_333 } from "./land_plots/62/land_plot_block_333.svg";
import { ReactComponent as LandPlot_62_334 } from "./land_plots/62/land_plot_block_334.svg";
import { ReactComponent as LandPlot_62_335 } from "./land_plots/62/land_plot_block_335.svg";
import { ReactComponent as LandPlot_62_336 } from "./land_plots/62/land_plot_block_336.svg";
import { ReactComponent as LandPlot_62_337 } from "./land_plots/62/land_plot_block_337.svg";
import { ReactComponent as LandPlot_62_338 } from "./land_plots/62/land_plot_block_338.svg";
import { ReactComponent as LandPlot_62_339 } from "./land_plots/62/land_plot_block_339.svg";
import { ReactComponent as LandPlot_62_340 } from "./land_plots/62/land_plot_block_340.svg";
import { ReactComponent as LandPlot_62_341 } from "./land_plots/62/land_plot_block_341.svg";
import { ReactComponent as LandPlot_62_342 } from "./land_plots/62/land_plot_block_342.svg";
import { ReactComponent as LandPlot_62_343 } from "./land_plots/62/land_plot_block_343.svg";
import { ReactComponent as LandPlot_62_344 } from "./land_plots/62/land_plot_block_344.svg";
import { ReactComponent as LandPlot_62_345 } from "./land_plots/62/land_plot_block_345.svg";
import { ReactComponent as LandPlot_62_346 } from "./land_plots/62/land_plot_block_346.svg";
import { ReactComponent as LandPlot_62_347 } from "./land_plots/62/land_plot_block_347.svg";
import { ReactComponent as LandPlot_62_348 } from "./land_plots/62/land_plot_block_348.svg";
import { ReactComponent as LandPlot_62_349 } from "./land_plots/62/land_plot_block_349.svg";
import { ReactComponent as LandPlot_62_350 } from "./land_plots/62/land_plot_block_350.svg";
import { ReactComponent as LandPlot_62_351 } from "./land_plots/62/land_plot_block_351.svg";
import { ReactComponent as LandPlot_62_352 } from "./land_plots/62/land_plot_block_352.svg";
import { ReactComponent as LandPlot_62_353 } from "./land_plots/62/land_plot_block_353.svg";
import { ReactComponent as LandPlot_62_354 } from "./land_plots/62/land_plot_block_354.svg";
import { ReactComponent as LandPlot_62_355 } from "./land_plots/62/land_plot_block_355.svg";
import { ReactComponent as LandPlot_62_356 } from "./land_plots/62/land_plot_block_356.svg";
import { ReactComponent as LandPlot_62_357 } from "./land_plots/62/land_plot_block_357.svg";
import { ReactComponent as LandPlot_62_358 } from "./land_plots/62/land_plot_block_358.svg";
import { ReactComponent as LandPlot_62_359 } from "./land_plots/62/land_plot_block_359.svg";
import { ReactComponent as LandPlot_62_360 } from "./land_plots/62/land_plot_block_360.svg";
import { ReactComponent as LandPlot_62_361 } from "./land_plots/62/land_plot_block_361.svg";
import { ReactComponent as LandPlot_62_362 } from "./land_plots/62/land_plot_block_362.svg";
import { ReactComponent as LandPlot_62_363 } from "./land_plots/62/land_plot_block_363.svg";
import { ReactComponent as LandPlot_62_364 } from "./land_plots/62/land_plot_block_364.svg";
import { ReactComponent as LandPlot_62_365 } from "./land_plots/62/land_plot_block_365.svg";
import { ReactComponent as LandPlot_62_366 } from "./land_plots/62/land_plot_block_366.svg";
import { ReactComponent as LandPlot_62_367 } from "./land_plots/62/land_plot_block_367.svg";
import { ReactComponent as LandPlot_62_368 } from "./land_plots/62/land_plot_block_368.svg";
import { ReactComponent as LandPlot_62_369 } from "./land_plots/62/land_plot_block_369.svg";
import { ReactComponent as LandPlot_62_370 } from "./land_plots/62/land_plot_block_370.svg";
import { ReactComponent as LandPlot_62_371 } from "./land_plots/62/land_plot_block_371.svg";
import { ReactComponent as LandPlot_62_372 } from "./land_plots/62/land_plot_block_372.svg";
import { ReactComponent as LandPlot_62_373 } from "./land_plots/62/land_plot_block_373.svg";
import { ReactComponent as LandPlot_62_374 } from "./land_plots/62/land_plot_block_374.svg";
import { ReactComponent as LandPlot_62_375 } from "./land_plots/62/land_plot_block_375.svg";
import { ReactComponent as LandPlot_62_376 } from "./land_plots/62/land_plot_block_376.svg";
import { ReactComponent as LandPlot_62_377 } from "./land_plots/62/land_plot_block_377.svg";
import { ReactComponent as LandPlot_62_378 } from "./land_plots/62/land_plot_block_378.svg";
import { ReactComponent as LandPlot_62_379 } from "./land_plots/62/land_plot_block_379.svg";
import { ReactComponent as LandPlot_62_380 } from "./land_plots/62/land_plot_block_380.svg";
import { ReactComponent as LandPlot_62_381 } from "./land_plots/62/land_plot_block_381.svg";
import { ReactComponent as LandPlot_62_382 } from "./land_plots/62/land_plot_block_382.svg";
import { ReactComponent as LandPlot_62_383 } from "./land_plots/62/land_plot_block_383.svg";
import { ReactComponent as LandPlot_62_384 } from "./land_plots/62/land_plot_block_384.svg";
import { ReactComponent as LandPlot_62_385 } from "./land_plots/62/land_plot_block_385.svg";
import { ReactComponent as LandPlot_62_386 } from "./land_plots/62/land_plot_block_386.svg";
import { ReactComponent as LandPlot_62_387 } from "./land_plots/62/land_plot_block_387.svg";
import { ReactComponent as LandPlot_62_388 } from "./land_plots/62/land_plot_block_388.svg";
import { ReactComponent as LandPlot_62_389 } from "./land_plots/62/land_plot_block_389.svg";
import { ReactComponent as LandPlot_62_390 } from "./land_plots/62/land_plot_block_390.svg";
import { ReactComponent as LandPlot_62_391 } from "./land_plots/62/land_plot_block_391.svg";
import { ReactComponent as LandPlot_62_392 } from "./land_plots/62/land_plot_block_392.svg";
import { ReactComponent as LandPlot_62_393 } from "./land_plots/62/land_plot_block_393.svg";
import { ReactComponent as LandPlot_62_394 } from "./land_plots/62/land_plot_block_394.svg";
import { ReactComponent as LandPlot_62_395 } from "./land_plots/62/land_plot_block_395.svg";
import { ReactComponent as LandPlot_62_396 } from "./land_plots/62/land_plot_block_396.svg";
import { ReactComponent as LandPlot_62_397 } from "./land_plots/62/land_plot_block_397.svg";
import { ReactComponent as LandPlot_62_398 } from "./land_plots/62/land_plot_block_398.svg";
import { ReactComponent as LandPlot_62_399 } from "./land_plots/62/land_plot_block_399.svg";
import { ReactComponent as LandPlot_62_400 } from "./land_plots/62/land_plot_block_400.svg";
import { ReactComponent as LandPlot_62_401 } from "./land_plots/62/land_plot_block_401.svg";
import { ReactComponent as LandPlot_62_402 } from "./land_plots/62/land_plot_block_402.svg";
import { ReactComponent as LandPlot_62_403 } from "./land_plots/62/land_plot_block_403.svg";
import { ReactComponent as LandPlot_62_404 } from "./land_plots/62/land_plot_block_404.svg";
import { ReactComponent as LandPlot_62_405 } from "./land_plots/62/land_plot_block_405.svg";
import { ReactComponent as LandPlot_62_406 } from "./land_plots/62/land_plot_block_406.svg";
import { ReactComponent as LandPlot_62_407 } from "./land_plots/62/land_plot_block_407.svg";
import { ReactComponent as LandPlot_62_408 } from "./land_plots/62/land_plot_block_408.svg";
import { ReactComponent as LandPlot_62_409 } from "./land_plots/62/land_plot_block_409.svg";
import { ReactComponent as LandPlot_62_410 } from "./land_plots/62/land_plot_block_410.svg";
import { ReactComponent as LandPlot_62_411 } from "./land_plots/62/land_plot_block_411.svg";
import { ReactComponent as LandPlot_62_412 } from "./land_plots/62/land_plot_block_412.svg";
import { ReactComponent as LandPlot_62_413 } from "./land_plots/62/land_plot_block_413.svg";
import { ReactComponent as LandPlot_62_414 } from "./land_plots/62/land_plot_block_414.svg";
import { ReactComponent as LandPlot_62_415 } from "./land_plots/62/land_plot_block_415.svg";
import { ReactComponent as LandPlot_62_416 } from "./land_plots/62/land_plot_block_416.svg";
import { ReactComponent as LandPlot_62_417 } from "./land_plots/62/land_plot_block_417.svg";
import { ReactComponent as LandPlot_62_418 } from "./land_plots/62/land_plot_block_418.svg";
import { ReactComponent as LandPlot_62_419 } from "./land_plots/62/land_plot_block_419.svg";
import { ReactComponent as LandPlot_62_420 } from "./land_plots/62/land_plot_block_420.svg";
import { ReactComponent as LandPlot_62_421 } from "./land_plots/62/land_plot_block_421.svg";
import { ReactComponent as LandPlot_62_422 } from "./land_plots/62/land_plot_block_422.svg";
import { ReactComponent as LandPlot_62_423 } from "./land_plots/62/land_plot_block_423.svg";
import { ReactComponent as LandPlot_62_424 } from "./land_plots/62/land_plot_block_424.svg";
import { ReactComponent as LandPlot_62_425 } from "./land_plots/62/land_plot_block_425.svg";
import { ReactComponent as LandPlot_62_426 } from "./land_plots/62/land_plot_block_426.svg";
import { ReactComponent as LandPlot_62_427 } from "./land_plots/62/land_plot_block_427.svg";
import { ReactComponent as LandPlot_62_428 } from "./land_plots/62/land_plot_block_428.svg";
import { ReactComponent as LandPlot_62_429 } from "./land_plots/62/land_plot_block_429.svg";
import { ReactComponent as LandPlot_62_430 } from "./land_plots/62/land_plot_block_430.svg";
import { ReactComponent as LandPlot_62_431 } from "./land_plots/62/land_plot_block_431.svg";
import { ReactComponent as LandPlot_62_432 } from "./land_plots/62/land_plot_block_432.svg";
import { ReactComponent as LandPlot_62_433 } from "./land_plots/62/land_plot_block_433.svg";
import { ReactComponent as LandPlot_62_434 } from "./land_plots/62/land_plot_block_434.svg";
import { ReactComponent as LandPlot_62_435 } from "./land_plots/62/land_plot_block_435.svg";
import { ReactComponent as LandPlot_62_436 } from "./land_plots/62/land_plot_block_436.svg";
import { ReactComponent as LandPlot_62_437 } from "./land_plots/62/land_plot_block_437.svg";
import { ReactComponent as LandPlot_62_438 } from "./land_plots/62/land_plot_block_438.svg";
import { ReactComponent as LandPlot_62_439 } from "./land_plots/62/land_plot_block_439.svg";
import { ReactComponent as LandPlot_62_440 } from "./land_plots/62/land_plot_block_440.svg";
import { ReactComponent as LandPlot_62_441 } from "./land_plots/62/land_plot_block_441.svg";
import { ReactComponent as LandPlot_62_442 } from "./land_plots/62/land_plot_block_442.svg";
import { ReactComponent as LandPlot_62_443 } from "./land_plots/62/land_plot_block_443.svg";
import { ReactComponent as LandPlot_62_444 } from "./land_plots/62/land_plot_block_444.svg";
import { ReactComponent as LandPlot_62_445 } from "./land_plots/62/land_plot_block_445.svg";
import { ReactComponent as LandPlot_62_446 } from "./land_plots/62/land_plot_block_446.svg";
import { ReactComponent as LandPlot_62_447 } from "./land_plots/62/land_plot_block_447.svg";
import { ReactComponent as LandPlot_62_448 } from "./land_plots/62/land_plot_block_448.svg";
import { ReactComponent as LandPlot_62_449 } from "./land_plots/62/land_plot_block_449.svg";

//stop frame 89
import { ReactComponent as LandPlot_89_3 } from "./land_plots/89/land_plot_block_3.svg";
import { ReactComponent as LandPlot_89_4 } from "./land_plots/89/land_plot_block_4.svg";
import { ReactComponent as LandPlot_89_5 } from "./land_plots/89/land_plot_block_5.svg";
import { ReactComponent as LandPlot_89_6 } from "./land_plots/89/land_plot_block_6.svg";
import { ReactComponent as LandPlot_89_7 } from "./land_plots/89/land_plot_block_7.svg";
import { ReactComponent as LandPlot_89_8 } from "./land_plots/89/land_plot_block_8.svg";
import { ReactComponent as LandPlot_89_9 } from "./land_plots/89/land_plot_block_9.svg";
import { ReactComponent as LandPlot_89_10 } from "./land_plots/89/land_plot_block_10.svg";
import { ReactComponent as LandPlot_89_11 } from "./land_plots/89/land_plot_block_11.svg";
import { ReactComponent as LandPlot_89_12 } from "./land_plots/89/land_plot_block_12.svg";
import { ReactComponent as LandPlot_89_13 } from "./land_plots/89/land_plot_block_13.svg";
import { ReactComponent as LandPlot_89_14 } from "./land_plots/89/land_plot_block_14.svg";
import { ReactComponent as LandPlot_89_15 } from "./land_plots/89/land_plot_block_15.svg";
import { ReactComponent as LandPlot_89_16 } from "./land_plots/89/land_plot_block_16.svg";
import { ReactComponent as LandPlot_89_17 } from "./land_plots/89/land_plot_block_17.svg";
import { ReactComponent as LandPlot_89_18 } from "./land_plots/89/land_plot_block_18.svg";
import { ReactComponent as LandPlot_89_19 } from "./land_plots/89/land_plot_block_19.svg";
import { ReactComponent as LandPlot_89_20 } from "./land_plots/89/land_plot_block_20.svg";
import { ReactComponent as LandPlot_89_21 } from "./land_plots/89/land_plot_block_21.svg";
import { ReactComponent as LandPlot_89_22 } from "./land_plots/89/land_plot_block_22.svg";
import { ReactComponent as LandPlot_89_23 } from "./land_plots/89/land_plot_block_23.svg";
import { ReactComponent as LandPlot_89_24 } from "./land_plots/89/land_plot_block_24.svg";
import { ReactComponent as LandPlot_89_25 } from "./land_plots/89/land_plot_block_25.svg";
import { ReactComponent as LandPlot_89_26 } from "./land_plots/89/land_plot_block_26.svg";
import { ReactComponent as LandPlot_89_27 } from "./land_plots/89/land_plot_block_27.svg";
import { ReactComponent as LandPlot_89_28 } from "./land_plots/89/land_plot_block_28.svg";
import { ReactComponent as LandPlot_89_29 } from "./land_plots/89/land_plot_block_29.svg";
import { ReactComponent as LandPlot_89_30 } from "./land_plots/89/land_plot_block_30.svg";
import { ReactComponent as LandPlot_89_31 } from "./land_plots/89/land_plot_block_31.svg";
import { ReactComponent as LandPlot_89_32 } from "./land_plots/89/land_plot_block_32.svg";
import { ReactComponent as LandPlot_89_33 } from "./land_plots/89/land_plot_block_33.svg";
import { ReactComponent as LandPlot_89_34 } from "./land_plots/89/land_plot_block_34.svg";
import { ReactComponent as LandPlot_89_35 } from "./land_plots/89/land_plot_block_35.svg";
import { ReactComponent as LandPlot_89_36 } from "./land_plots/89/land_plot_block_36.svg";
import { ReactComponent as LandPlot_89_37 } from "./land_plots/89/land_plot_block_37.svg";
import { ReactComponent as LandPlot_89_38 } from "./land_plots/89/land_plot_block_38.svg";
import { ReactComponent as LandPlot_89_39 } from "./land_plots/89/land_plot_block_39.svg";
import { ReactComponent as LandPlot_89_40 } from "./land_plots/89/land_plot_block_40.svg";
import { ReactComponent as LandPlot_89_41 } from "./land_plots/89/land_plot_block_41.svg";
import { ReactComponent as LandPlot_89_42 } from "./land_plots/89/land_plot_block_42.svg";
import { ReactComponent as LandPlot_89_43 } from "./land_plots/89/land_plot_block_43.svg";
import { ReactComponent as LandPlot_89_44 } from "./land_plots/89/land_plot_block_44.svg";
import { ReactComponent as LandPlot_89_45 } from "./land_plots/89/land_plot_block_45.svg";
import { ReactComponent as LandPlot_89_46 } from "./land_plots/89/land_plot_block_46.svg";
import { ReactComponent as LandPlot_89_47 } from "./land_plots/89/land_plot_block_47.svg";
import { ReactComponent as LandPlot_89_48 } from "./land_plots/89/land_plot_block_48.svg";
import { ReactComponent as LandPlot_89_49 } from "./land_plots/89/land_plot_block_49.svg";
import { ReactComponent as LandPlot_89_50 } from "./land_plots/89/land_plot_block_50.svg";
import { ReactComponent as LandPlot_89_51 } from "./land_plots/89/land_plot_block_51.svg";
import { ReactComponent as LandPlot_89_52 } from "./land_plots/89/land_plot_block_52.svg";
import { ReactComponent as LandPlot_89_53 } from "./land_plots/89/land_plot_block_53.svg";
import { ReactComponent as LandPlot_89_54 } from "./land_plots/89/land_plot_block_54.svg";
import { ReactComponent as LandPlot_89_62 } from "./land_plots/89/land_plot_block_62.svg";
import { ReactComponent as LandPlot_89_63 } from "./land_plots/89/land_plot_block_63.svg";
import { ReactComponent as LandPlot_89_64 } from "./land_plots/89/land_plot_block_64.svg";
import { ReactComponent as LandPlot_89_65 } from "./land_plots/89/land_plot_block_65.svg";
import { ReactComponent as LandPlot_89_66 } from "./land_plots/89/land_plot_block_66.svg";
import { ReactComponent as LandPlot_89_67 } from "./land_plots/89/land_plot_block_67.svg";
import { ReactComponent as LandPlot_89_68 } from "./land_plots/89/land_plot_block_68.svg";
import { ReactComponent as LandPlot_89_69 } from "./land_plots/89/land_plot_block_69.svg";
import { ReactComponent as LandPlot_89_70 } from "./land_plots/89/land_plot_block_70.svg";
import { ReactComponent as LandPlot_89_71 } from "./land_plots/89/land_plot_block_71.svg";
import { ReactComponent as LandPlot_89_72 } from "./land_plots/89/land_plot_block_72.svg";
import { ReactComponent as LandPlot_89_73 } from "./land_plots/89/land_plot_block_73.svg";
import { ReactComponent as LandPlot_89_74 } from "./land_plots/89/land_plot_block_74.svg";
import { ReactComponent as LandPlot_89_75 } from "./land_plots/89/land_plot_block_75.svg";
import { ReactComponent as LandPlot_89_76 } from "./land_plots/89/land_plot_block_76.svg";
import { ReactComponent as LandPlot_89_77 } from "./land_plots/89/land_plot_block_77.svg";
import { ReactComponent as LandPlot_89_78 } from "./land_plots/89/land_plot_block_78.svg";
import { ReactComponent as LandPlot_89_79 } from "./land_plots/89/land_plot_block_79.svg";
import { ReactComponent as LandPlot_89_80 } from "./land_plots/89/land_plot_block_80.svg";
import { ReactComponent as LandPlot_89_81 } from "./land_plots/89/land_plot_block_81.svg";
import { ReactComponent as LandPlot_89_82 } from "./land_plots/89/land_plot_block_82.svg";
import { ReactComponent as LandPlot_89_83 } from "./land_plots/89/land_plot_block_83.svg";
import { ReactComponent as LandPlot_89_84 } from "./land_plots/89/land_plot_block_84.svg";
import { ReactComponent as LandPlot_89_85 } from "./land_plots/89/land_plot_block_85.svg";
import { ReactComponent as LandPlot_89_86 } from "./land_plots/89/land_plot_block_86.svg";
import { ReactComponent as LandPlot_89_87 } from "./land_plots/89/land_plot_block_87.svg";
import { ReactComponent as LandPlot_89_88 } from "./land_plots/89/land_plot_block_88.svg";
import { ReactComponent as LandPlot_89_89 } from "./land_plots/89/land_plot_block_89.svg";
import { ReactComponent as LandPlot_89_90 } from "./land_plots/89/land_plot_block_90.svg";
import { ReactComponent as LandPlot_89_91 } from "./land_plots/89/land_plot_block_91.svg";
import { ReactComponent as LandPlot_89_92 } from "./land_plots/89/land_plot_block_92.svg";
import { ReactComponent as LandPlot_89_93 } from "./land_plots/89/land_plot_block_93.svg";
import { ReactComponent as LandPlot_89_94 } from "./land_plots/89/land_plot_block_94.svg";
import { ReactComponent as LandPlot_89_95 } from "./land_plots/89/land_plot_block_95.svg";
import { ReactComponent as LandPlot_89_96 } from "./land_plots/89/land_plot_block_96.svg";
import { ReactComponent as LandPlot_89_97 } from "./land_plots/89/land_plot_block_97.svg";
import { ReactComponent as LandPlot_89_98 } from "./land_plots/89/land_plot_block_98.svg";
import { ReactComponent as LandPlot_89_99 } from "./land_plots/89/land_plot_block_99.svg";
import { ReactComponent as LandPlot_89_100 } from "./land_plots/89/land_plot_block_100.svg";
import { ReactComponent as LandPlot_89_101 } from "./land_plots/89/land_plot_block_101.svg";
import { ReactComponent as LandPlot_89_102 } from "./land_plots/89/land_plot_block_102.svg";
import { ReactComponent as LandPlot_89_103 } from "./land_plots/89/land_plot_block_103.svg";
import { ReactComponent as LandPlot_89_104 } from "./land_plots/89/land_plot_block_104.svg";
import { ReactComponent as LandPlot_89_105 } from "./land_plots/89/land_plot_block_105.svg";
import { ReactComponent as LandPlot_89_106 } from "./land_plots/89/land_plot_block_106.svg";
import { ReactComponent as LandPlot_89_107 } from "./land_plots/89/land_plot_block_107.svg";
import { ReactComponent as LandPlot_89_108 } from "./land_plots/89/land_plot_block_108.svg";
import { ReactComponent as LandPlot_89_109 } from "./land_plots/89/land_plot_block_109.svg";
import { ReactComponent as LandPlot_89_110 } from "./land_plots/89/land_plot_block_110.svg";
import { ReactComponent as LandPlot_89_111 } from "./land_plots/89/land_plot_block_111.svg";
import { ReactComponent as LandPlot_89_112 } from "./land_plots/89/land_plot_block_112.svg";
import { ReactComponent as LandPlot_89_113 } from "./land_plots/89/land_plot_block_113.svg";
import { ReactComponent as LandPlot_89_114 } from "./land_plots/89/land_plot_block_114.svg";
import { ReactComponent as LandPlot_89_115 } from "./land_plots/89/land_plot_block_115.svg";
import { ReactComponent as LandPlot_89_116 } from "./land_plots/89/land_plot_block_116.svg";
import { ReactComponent as LandPlot_89_117 } from "./land_plots/89/land_plot_block_117.svg";
import { ReactComponent as LandPlot_89_118 } from "./land_plots/89/land_plot_block_118.svg";
import { ReactComponent as LandPlot_89_119 } from "./land_plots/89/land_plot_block_119.svg";
import { ReactComponent as LandPlot_89_120 } from "./land_plots/89/land_plot_block_120.svg";
import { ReactComponent as LandPlot_89_121 } from "./land_plots/89/land_plot_block_121.svg";
import { ReactComponent as LandPlot_89_122 } from "./land_plots/89/land_plot_block_122.svg";
import { ReactComponent as LandPlot_89_123 } from "./land_plots/89/land_plot_block_123.svg";
import { ReactComponent as LandPlot_89_124 } from "./land_plots/89/land_plot_block_124.svg";
import { ReactComponent as LandPlot_89_125 } from "./land_plots/89/land_plot_block_125.svg";
import { ReactComponent as LandPlot_89_126 } from "./land_plots/89/land_plot_block_126.svg";
import { ReactComponent as LandPlot_89_127 } from "./land_plots/89/land_plot_block_127.svg";
import { ReactComponent as LandPlot_89_128 } from "./land_plots/89/land_plot_block_128.svg";
import { ReactComponent as LandPlot_89_129 } from "./land_plots/89/land_plot_block_129.svg";
import { ReactComponent as LandPlot_89_130 } from "./land_plots/89/land_plot_block_130.svg";
import { ReactComponent as LandPlot_89_131 } from "./land_plots/89/land_plot_block_131.svg";
import { ReactComponent as LandPlot_89_132 } from "./land_plots/89/land_plot_block_132.svg";
import { ReactComponent as LandPlot_89_133 } from "./land_plots/89/land_plot_block_133.svg";
import { ReactComponent as LandPlot_89_134 } from "./land_plots/89/land_plot_block_134.svg";
import { ReactComponent as LandPlot_89_135 } from "./land_plots/89/land_plot_block_135.svg";
import { ReactComponent as LandPlot_89_136 } from "./land_plots/89/land_plot_block_136.svg";
import { ReactComponent as LandPlot_89_137 } from "./land_plots/89/land_plot_block_137.svg";
import { ReactComponent as LandPlot_89_138 } from "./land_plots/89/land_plot_block_138.svg";
import { ReactComponent as LandPlot_89_139 } from "./land_plots/89/land_plot_block_139.svg";
import { ReactComponent as LandPlot_89_140 } from "./land_plots/89/land_plot_block_140.svg";
import { ReactComponent as LandPlot_89_141 } from "./land_plots/89/land_plot_block_141.svg";
import { ReactComponent as LandPlot_89_142 } from "./land_plots/89/land_plot_block_142.svg";
import { ReactComponent as LandPlot_89_143 } from "./land_plots/89/land_plot_block_143.svg";
import { ReactComponent as LandPlot_89_144 } from "./land_plots/89/land_plot_block_144.svg";
import { ReactComponent as LandPlot_89_145 } from "./land_plots/89/land_plot_block_145.svg";
import { ReactComponent as LandPlot_89_146 } from "./land_plots/89/land_plot_block_146.svg";
import { ReactComponent as LandPlot_89_147 } from "./land_plots/89/land_plot_block_147.svg";
import { ReactComponent as LandPlot_89_148 } from "./land_plots/89/land_plot_block_148.svg";
import { ReactComponent as LandPlot_89_149 } from "./land_plots/89/land_plot_block_149.svg";
import { ReactComponent as LandPlot_89_150 } from "./land_plots/89/land_plot_block_150.svg";
import { ReactComponent as LandPlot_89_151 } from "./land_plots/89/land_plot_block_151.svg";
import { ReactComponent as LandPlot_89_152 } from "./land_plots/89/land_plot_block_152.svg";
import { ReactComponent as LandPlot_89_153 } from "./land_plots/89/land_plot_block_153.svg";
import { ReactComponent as LandPlot_89_154 } from "./land_plots/89/land_plot_block_154.svg";
import { ReactComponent as LandPlot_89_155 } from "./land_plots/89/land_plot_block_155.svg";
import { ReactComponent as LandPlot_89_156 } from "./land_plots/89/land_plot_block_156.svg";
import { ReactComponent as LandPlot_89_157 } from "./land_plots/89/land_plot_block_157.svg";
import { ReactComponent as LandPlot_89_158 } from "./land_plots/89/land_plot_block_158.svg";
import { ReactComponent as LandPlot_89_159 } from "./land_plots/89/land_plot_block_159.svg";
import { ReactComponent as LandPlot_89_160 } from "./land_plots/89/land_plot_block_160.svg";
import { ReactComponent as LandPlot_89_161 } from "./land_plots/89/land_plot_block_161.svg";
import { ReactComponent as LandPlot_89_162 } from "./land_plots/89/land_plot_block_162.svg";
import { ReactComponent as LandPlot_89_163 } from "./land_plots/89/land_plot_block_163.svg";
import { ReactComponent as LandPlot_89_164 } from "./land_plots/89/land_plot_block_164.svg";
import { ReactComponent as LandPlot_89_165 } from "./land_plots/89/land_plot_block_165.svg";
import { ReactComponent as LandPlot_89_166 } from "./land_plots/89/land_plot_block_166.svg";
import { ReactComponent as LandPlot_89_167 } from "./land_plots/89/land_plot_block_167.svg";
import { ReactComponent as LandPlot_89_168 } from "./land_plots/89/land_plot_block_168.svg";
import { ReactComponent as LandPlot_89_169 } from "./land_plots/89/land_plot_block_169.svg";
import { ReactComponent as LandPlot_89_170 } from "./land_plots/89/land_plot_block_170.svg";
import { ReactComponent as LandPlot_89_171 } from "./land_plots/89/land_plot_block_171.svg";
import { ReactComponent as LandPlot_89_172 } from "./land_plots/89/land_plot_block_172.svg";
import { ReactComponent as LandPlot_89_173 } from "./land_plots/89/land_plot_block_173.svg";
import { ReactComponent as LandPlot_89_174 } from "./land_plots/89/land_plot_block_174.svg";
import { ReactComponent as LandPlot_89_175 } from "./land_plots/89/land_plot_block_175.svg";
import { ReactComponent as LandPlot_89_176 } from "./land_plots/89/land_plot_block_176.svg";
import { ReactComponent as LandPlot_89_177 } from "./land_plots/89/land_plot_block_177.svg";
import { ReactComponent as LandPlot_89_178 } from "./land_plots/89/land_plot_block_178.svg";
import { ReactComponent as LandPlot_89_179 } from "./land_plots/89/land_plot_block_179.svg";
import { ReactComponent as LandPlot_89_180 } from "./land_plots/89/land_plot_block_180.svg";
import { ReactComponent as LandPlot_89_181 } from "./land_plots/89/land_plot_block_181.svg";
import { ReactComponent as LandPlot_89_182 } from "./land_plots/89/land_plot_block_182.svg";
import { ReactComponent as LandPlot_89_183 } from "./land_plots/89/land_plot_block_183.svg";
import { ReactComponent as LandPlot_89_184 } from "./land_plots/89/land_plot_block_184.svg";
import { ReactComponent as LandPlot_89_185 } from "./land_plots/89/land_plot_block_185.svg";
import { ReactComponent as LandPlot_89_186 } from "./land_plots/89/land_plot_block_186.svg";
import { ReactComponent as LandPlot_89_187 } from "./land_plots/89/land_plot_block_187.svg";
import { ReactComponent as LandPlot_89_188 } from "./land_plots/89/land_plot_block_188.svg";
import { ReactComponent as LandPlot_89_189 } from "./land_plots/89/land_plot_block_189.svg";
import { ReactComponent as LandPlot_89_190 } from "./land_plots/89/land_plot_block_190.svg";
import { ReactComponent as LandPlot_89_191 } from "./land_plots/89/land_plot_block_191.svg";
import { ReactComponent as LandPlot_89_192 } from "./land_plots/89/land_plot_block_192.svg";
import { ReactComponent as LandPlot_89_193 } from "./land_plots/89/land_plot_block_193.svg";
import { ReactComponent as LandPlot_89_194 } from "./land_plots/89/land_plot_block_194.svg";
import { ReactComponent as LandPlot_89_195 } from "./land_plots/89/land_plot_block_195.svg";
import { ReactComponent as LandPlot_89_196 } from "./land_plots/89/land_plot_block_196.svg";
import { ReactComponent as LandPlot_89_197 } from "./land_plots/89/land_plot_block_197.svg";
import { ReactComponent as LandPlot_89_198 } from "./land_plots/89/land_plot_block_198.svg";
import { ReactComponent as LandPlot_89_199 } from "./land_plots/89/land_plot_block_199.svg";
import { ReactComponent as LandPlot_89_200 } from "./land_plots/89/land_plot_block_200.svg";
import { ReactComponent as LandPlot_89_201 } from "./land_plots/89/land_plot_block_201.svg";
import { ReactComponent as LandPlot_89_202 } from "./land_plots/89/land_plot_block_202.svg";
import { ReactComponent as LandPlot_89_203 } from "./land_plots/89/land_plot_block_203.svg";
import { ReactComponent as LandPlot_89_204 } from "./land_plots/89/land_plot_block_204.svg";
import { ReactComponent as LandPlot_89_205 } from "./land_plots/89/land_plot_block_205.svg";
import { ReactComponent as LandPlot_89_206 } from "./land_plots/89/land_plot_block_206.svg";
import { ReactComponent as LandPlot_89_207 } from "./land_plots/89/land_plot_block_207.svg";
import { ReactComponent as LandPlot_89_208 } from "./land_plots/89/land_plot_block_208.svg";
import { ReactComponent as LandPlot_89_209 } from "./land_plots/89/land_plot_block_209.svg";
import { ReactComponent as LandPlot_89_210 } from "./land_plots/89/land_plot_block_210.svg";
import { ReactComponent as LandPlot_89_211 } from "./land_plots/89/land_plot_block_211.svg";
import { ReactComponent as LandPlot_89_212 } from "./land_plots/89/land_plot_block_212.svg";
import { ReactComponent as LandPlot_89_213 } from "./land_plots/89/land_plot_block_213.svg";
import { ReactComponent as LandPlot_89_214 } from "./land_plots/89/land_plot_block_214.svg";
import { ReactComponent as LandPlot_89_215 } from "./land_plots/89/land_plot_block_215.svg";
import { ReactComponent as LandPlot_89_216 } from "./land_plots/89/land_plot_block_216.svg";
import { ReactComponent as LandPlot_89_217 } from "./land_plots/89/land_plot_block_217.svg";
import { ReactComponent as LandPlot_89_218 } from "./land_plots/89/land_plot_block_218.svg";
import { ReactComponent as LandPlot_89_219 } from "./land_plots/89/land_plot_block_219.svg";
import { ReactComponent as LandPlot_89_220 } from "./land_plots/89/land_plot_block_220.svg";
import { ReactComponent as LandPlot_89_221 } from "./land_plots/89/land_plot_block_221.svg";
import { ReactComponent as LandPlot_89_222 } from "./land_plots/89/land_plot_block_222.svg";
import { ReactComponent as LandPlot_89_223 } from "./land_plots/89/land_plot_block_223.svg";
import { ReactComponent as LandPlot_89_224 } from "./land_plots/89/land_plot_block_224.svg";
import { ReactComponent as LandPlot_89_225 } from "./land_plots/89/land_plot_block_225.svg";
import { ReactComponent as LandPlot_89_226 } from "./land_plots/89/land_plot_block_226.svg";
import { ReactComponent as LandPlot_89_227 } from "./land_plots/89/land_plot_block_227.svg";
import { ReactComponent as LandPlot_89_228 } from "./land_plots/89/land_plot_block_228.svg";
import { ReactComponent as LandPlot_89_229 } from "./land_plots/89/land_plot_block_229.svg";
import { ReactComponent as LandPlot_89_230 } from "./land_plots/89/land_plot_block_230.svg";
import { ReactComponent as LandPlot_89_231 } from "./land_plots/89/land_plot_block_231.svg";
import { ReactComponent as LandPlot_89_232 } from "./land_plots/89/land_plot_block_232.svg";
import { ReactComponent as LandPlot_89_233 } from "./land_plots/89/land_plot_block_233.svg";
import { ReactComponent as LandPlot_89_234 } from "./land_plots/89/land_plot_block_234.svg";
import { ReactComponent as LandPlot_89_235 } from "./land_plots/89/land_plot_block_235.svg";
import { ReactComponent as LandPlot_89_236 } from "./land_plots/89/land_plot_block_236.svg";
import { ReactComponent as LandPlot_89_237 } from "./land_plots/89/land_plot_block_237.svg";
import { ReactComponent as LandPlot_89_238 } from "./land_plots/89/land_plot_block_238.svg";
import { ReactComponent as LandPlot_89_239 } from "./land_plots/89/land_plot_block_239.svg";
import { ReactComponent as LandPlot_89_240 } from "./land_plots/89/land_plot_block_240.svg";
import { ReactComponent as LandPlot_89_241 } from "./land_plots/89/land_plot_block_241.svg";
import { ReactComponent as LandPlot_89_242 } from "./land_plots/89/land_plot_block_242.svg";
import { ReactComponent as LandPlot_89_243 } from "./land_plots/89/land_plot_block_243.svg";
import { ReactComponent as LandPlot_89_244 } from "./land_plots/89/land_plot_block_244.svg";
import { ReactComponent as LandPlot_89_245 } from "./land_plots/89/land_plot_block_245.svg";
import { ReactComponent as LandPlot_89_246 } from "./land_plots/89/land_plot_block_246.svg";
import { ReactComponent as LandPlot_89_247 } from "./land_plots/89/land_plot_block_247.svg";
import { ReactComponent as LandPlot_89_248 } from "./land_plots/89/land_plot_block_248.svg";
import { ReactComponent as LandPlot_89_249 } from "./land_plots/89/land_plot_block_249.svg";
import { ReactComponent as LandPlot_89_250 } from "./land_plots/89/land_plot_block_250.svg";
import { ReactComponent as LandPlot_89_251 } from "./land_plots/89/land_plot_block_251.svg";
import { ReactComponent as LandPlot_89_252 } from "./land_plots/89/land_plot_block_252.svg";
import { ReactComponent as LandPlot_89_253 } from "./land_plots/89/land_plot_block_253.svg";
import { ReactComponent as LandPlot_89_254 } from "./land_plots/89/land_plot_block_254.svg";
import { ReactComponent as LandPlot_89_255 } from "./land_plots/89/land_plot_block_255.svg";
import { ReactComponent as LandPlot_89_256 } from "./land_plots/89/land_plot_block_256.svg";
import { ReactComponent as LandPlot_89_257 } from "./land_plots/89/land_plot_block_257.svg";
import { ReactComponent as LandPlot_89_258 } from "./land_plots/89/land_plot_block_258.svg";
import { ReactComponent as LandPlot_89_259 } from "./land_plots/89/land_plot_block_259.svg";
import { ReactComponent as LandPlot_89_260 } from "./land_plots/89/land_plot_block_260.svg";
import { ReactComponent as LandPlot_89_261 } from "./land_plots/89/land_plot_block_261.svg";
import { ReactComponent as LandPlot_89_262 } from "./land_plots/89/land_plot_block_262.svg";
import { ReactComponent as LandPlot_89_263 } from "./land_plots/89/land_plot_block_263.svg";
import { ReactComponent as LandPlot_89_264 } from "./land_plots/89/land_plot_block_264.svg";
import { ReactComponent as LandPlot_89_265 } from "./land_plots/89/land_plot_block_265.svg";
import { ReactComponent as LandPlot_89_266 } from "./land_plots/89/land_plot_block_266.svg";
import { ReactComponent as LandPlot_89_267 } from "./land_plots/89/land_plot_block_267.svg";
import { ReactComponent as LandPlot_89_268 } from "./land_plots/89/land_plot_block_268.svg";
import { ReactComponent as LandPlot_89_269 } from "./land_plots/89/land_plot_block_269.svg";
import { ReactComponent as LandPlot_89_270 } from "./land_plots/89/land_plot_block_270.svg";
import { ReactComponent as LandPlot_89_271 } from "./land_plots/89/land_plot_block_271.svg";
import { ReactComponent as LandPlot_89_272 } from "./land_plots/89/land_plot_block_272.svg";
import { ReactComponent as LandPlot_89_273 } from "./land_plots/89/land_plot_block_273.svg";
import { ReactComponent as LandPlot_89_274 } from "./land_plots/89/land_plot_block_274.svg";
import { ReactComponent as LandPlot_89_275 } from "./land_plots/89/land_plot_block_275.svg";
import { ReactComponent as LandPlot_89_276 } from "./land_plots/89/land_plot_block_276.svg";
import { ReactComponent as LandPlot_89_277 } from "./land_plots/89/land_plot_block_277.svg";
import { ReactComponent as LandPlot_89_278 } from "./land_plots/89/land_plot_block_278.svg";
import { ReactComponent as LandPlot_89_279 } from "./land_plots/89/land_plot_block_279.svg";
import { ReactComponent as LandPlot_89_280 } from "./land_plots/89/land_plot_block_280.svg";
import { ReactComponent as LandPlot_89_281 } from "./land_plots/89/land_plot_block_281.svg";
import { ReactComponent as LandPlot_89_282 } from "./land_plots/89/land_plot_block_282.svg";
import { ReactComponent as LandPlot_89_283 } from "./land_plots/89/land_plot_block_283.svg";
import { ReactComponent as LandPlot_89_284 } from "./land_plots/89/land_plot_block_284.svg";
import { ReactComponent as LandPlot_89_285 } from "./land_plots/89/land_plot_block_285.svg";
import { ReactComponent as LandPlot_89_286 } from "./land_plots/89/land_plot_block_286.svg";
import { ReactComponent as LandPlot_89_287 } from "./land_plots/89/land_plot_block_287.svg";
import { ReactComponent as LandPlot_89_288 } from "./land_plots/89/land_plot_block_288.svg";
import { ReactComponent as LandPlot_89_289 } from "./land_plots/89/land_plot_block_289.svg";
import { ReactComponent as LandPlot_89_290 } from "./land_plots/89/land_plot_block_290.svg";
import { ReactComponent as LandPlot_89_291 } from "./land_plots/89/land_plot_block_291.svg";
import { ReactComponent as LandPlot_89_292 } from "./land_plots/89/land_plot_block_292.svg";
import { ReactComponent as LandPlot_89_293 } from "./land_plots/89/land_plot_block_293.svg";
import { ReactComponent as LandPlot_89_294 } from "./land_plots/89/land_plot_block_294.svg";
import { ReactComponent as LandPlot_89_295 } from "./land_plots/89/land_plot_block_295.svg";
import { ReactComponent as LandPlot_89_296 } from "./land_plots/89/land_plot_block_296.svg";
import { ReactComponent as LandPlot_89_297 } from "./land_plots/89/land_plot_block_297.svg";
import { ReactComponent as LandPlot_89_298 } from "./land_plots/89/land_plot_block_298.svg";
import { ReactComponent as LandPlot_89_299 } from "./land_plots/89/land_plot_block_299.svg";
import { ReactComponent as LandPlot_89_300 } from "./land_plots/89/land_plot_block_300.svg";
import { ReactComponent as LandPlot_89_301 } from "./land_plots/89/land_plot_block_301.svg";
import { ReactComponent as LandPlot_89_302 } from "./land_plots/89/land_plot_block_302.svg";
import { ReactComponent as LandPlot_89_303 } from "./land_plots/89/land_plot_block_303.svg";
import { ReactComponent as LandPlot_89_304 } from "./land_plots/89/land_plot_block_304.svg";
import { ReactComponent as LandPlot_89_305 } from "./land_plots/89/land_plot_block_305.svg";
import { ReactComponent as LandPlot_89_306 } from "./land_plots/89/land_plot_block_306.svg";
import { ReactComponent as LandPlot_89_307 } from "./land_plots/89/land_plot_block_307.svg";
import { ReactComponent as LandPlot_89_308 } from "./land_plots/89/land_plot_block_308.svg";
import { ReactComponent as LandPlot_89_309 } from "./land_plots/89/land_plot_block_309.svg";
import { ReactComponent as LandPlot_89_310 } from "./land_plots/89/land_plot_block_310.svg";
import { ReactComponent as LandPlot_89_311 } from "./land_plots/89/land_plot_block_311.svg";
import { ReactComponent as LandPlot_89_312 } from "./land_plots/89/land_plot_block_312.svg";
import { ReactComponent as LandPlot_89_313 } from "./land_plots/89/land_plot_block_313.svg";
import { ReactComponent as LandPlot_89_314 } from "./land_plots/89/land_plot_block_314.svg";
import { ReactComponent as LandPlot_89_315 } from "./land_plots/89/land_plot_block_315.svg";
import { ReactComponent as LandPlot_89_316 } from "./land_plots/89/land_plot_block_316.svg";
import { ReactComponent as LandPlot_89_317 } from "./land_plots/89/land_plot_block_317.svg";
import { ReactComponent as LandPlot_89_318 } from "./land_plots/89/land_plot_block_318.svg";
import { ReactComponent as LandPlot_89_319 } from "./land_plots/89/land_plot_block_319.svg";
import { ReactComponent as LandPlot_89_320 } from "./land_plots/89/land_plot_block_320.svg";
import { ReactComponent as LandPlot_89_321 } from "./land_plots/89/land_plot_block_321.svg";
import { ReactComponent as LandPlot_89_322 } from "./land_plots/89/land_plot_block_322.svg";
import { ReactComponent as LandPlot_89_323 } from "./land_plots/89/land_plot_block_323.svg";
import { ReactComponent as LandPlot_89_324 } from "./land_plots/89/land_plot_block_324.svg";
import { ReactComponent as LandPlot_89_325 } from "./land_plots/89/land_plot_block_325.svg";
import { ReactComponent as LandPlot_89_326 } from "./land_plots/89/land_plot_block_326.svg";
import { ReactComponent as LandPlot_89_327 } from "./land_plots/89/land_plot_block_327.svg";
import { ReactComponent as LandPlot_89_328 } from "./land_plots/89/land_plot_block_328.svg";
import { ReactComponent as LandPlot_89_329 } from "./land_plots/89/land_plot_block_329.svg";
import { ReactComponent as LandPlot_89_330 } from "./land_plots/89/land_plot_block_330.svg";
import { ReactComponent as LandPlot_89_331 } from "./land_plots/89/land_plot_block_331.svg";
import { ReactComponent as LandPlot_89_332 } from "./land_plots/89/land_plot_block_332.svg";
import { ReactComponent as LandPlot_89_333 } from "./land_plots/89/land_plot_block_333.svg";
import { ReactComponent as LandPlot_89_334 } from "./land_plots/89/land_plot_block_334.svg";
import { ReactComponent as LandPlot_89_335 } from "./land_plots/89/land_plot_block_335.svg";
import { ReactComponent as LandPlot_89_336 } from "./land_plots/89/land_plot_block_336.svg";
import { ReactComponent as LandPlot_89_337 } from "./land_plots/89/land_plot_block_337.svg";
import { ReactComponent as LandPlot_89_338 } from "./land_plots/89/land_plot_block_338.svg";
import { ReactComponent as LandPlot_89_339 } from "./land_plots/89/land_plot_block_339.svg";
import { ReactComponent as LandPlot_89_340 } from "./land_plots/89/land_plot_block_340.svg";
import { ReactComponent as LandPlot_89_341 } from "./land_plots/89/land_plot_block_341.svg";
import { ReactComponent as LandPlot_89_342 } from "./land_plots/89/land_plot_block_342.svg";
import { ReactComponent as LandPlot_89_343 } from "./land_plots/89/land_plot_block_343.svg";
import { ReactComponent as LandPlot_89_344 } from "./land_plots/89/land_plot_block_344.svg";
import { ReactComponent as LandPlot_89_345 } from "./land_plots/89/land_plot_block_345.svg";
import { ReactComponent as LandPlot_89_346 } from "./land_plots/89/land_plot_block_346.svg";
import { ReactComponent as LandPlot_89_347 } from "./land_plots/89/land_plot_block_347.svg";
import { ReactComponent as LandPlot_89_348 } from "./land_plots/89/land_plot_block_348.svg";
import { ReactComponent as LandPlot_89_349 } from "./land_plots/89/land_plot_block_349.svg";
import { ReactComponent as LandPlot_89_350 } from "./land_plots/89/land_plot_block_350.svg";
import { ReactComponent as LandPlot_89_351 } from "./land_plots/89/land_plot_block_351.svg";
import { ReactComponent as LandPlot_89_352 } from "./land_plots/89/land_plot_block_352.svg";
import { ReactComponent as LandPlot_89_353 } from "./land_plots/89/land_plot_block_353.svg";
import { ReactComponent as LandPlot_89_354 } from "./land_plots/89/land_plot_block_354.svg";
import { ReactComponent as LandPlot_89_355 } from "./land_plots/89/land_plot_block_355.svg";
import { ReactComponent as LandPlot_89_356 } from "./land_plots/89/land_plot_block_356.svg";
import { ReactComponent as LandPlot_89_357 } from "./land_plots/89/land_plot_block_357.svg";
import { ReactComponent as LandPlot_89_358 } from "./land_plots/89/land_plot_block_358.svg";
import { ReactComponent as LandPlot_89_359 } from "./land_plots/89/land_plot_block_359.svg";
import { ReactComponent as LandPlot_89_360 } from "./land_plots/89/land_plot_block_360.svg";
import { ReactComponent as LandPlot_89_361 } from "./land_plots/89/land_plot_block_361.svg";
import { ReactComponent as LandPlot_89_362 } from "./land_plots/89/land_plot_block_362.svg";
import { ReactComponent as LandPlot_89_363 } from "./land_plots/89/land_plot_block_363.svg";
import { ReactComponent as LandPlot_89_364 } from "./land_plots/89/land_plot_block_364.svg";
import { ReactComponent as LandPlot_89_365 } from "./land_plots/89/land_plot_block_365.svg";
import { ReactComponent as LandPlot_89_366 } from "./land_plots/89/land_plot_block_366.svg";
import { ReactComponent as LandPlot_89_367 } from "./land_plots/89/land_plot_block_367.svg";
import { ReactComponent as LandPlot_89_368 } from "./land_plots/89/land_plot_block_368.svg";
import { ReactComponent as LandPlot_89_369 } from "./land_plots/89/land_plot_block_369.svg";
import { ReactComponent as LandPlot_89_370 } from "./land_plots/89/land_plot_block_370.svg";
import { ReactComponent as LandPlot_89_371 } from "./land_plots/89/land_plot_block_371.svg";
import { ReactComponent as LandPlot_89_372 } from "./land_plots/89/land_plot_block_372.svg";
import { ReactComponent as LandPlot_89_373 } from "./land_plots/89/land_plot_block_373.svg";
import { ReactComponent as LandPlot_89_374 } from "./land_plots/89/land_plot_block_374.svg";
import { ReactComponent as LandPlot_89_375 } from "./land_plots/89/land_plot_block_375.svg";
import { ReactComponent as LandPlot_89_376 } from "./land_plots/89/land_plot_block_376.svg";
import { ReactComponent as LandPlot_89_377 } from "./land_plots/89/land_plot_block_377.svg";
import { ReactComponent as LandPlot_89_378 } from "./land_plots/89/land_plot_block_378.svg";
import { ReactComponent as LandPlot_89_379 } from "./land_plots/89/land_plot_block_379.svg";
import { ReactComponent as LandPlot_89_380 } from "./land_plots/89/land_plot_block_380.svg";
import { ReactComponent as LandPlot_89_381 } from "./land_plots/89/land_plot_block_381.svg";
import { ReactComponent as LandPlot_89_382 } from "./land_plots/89/land_plot_block_382.svg";
import { ReactComponent as LandPlot_89_383 } from "./land_plots/89/land_plot_block_383.svg";
import { ReactComponent as LandPlot_89_384 } from "./land_plots/89/land_plot_block_384.svg";
import { ReactComponent as LandPlot_89_385 } from "./land_plots/89/land_plot_block_385.svg";
import { ReactComponent as LandPlot_89_386 } from "./land_plots/89/land_plot_block_386.svg";
import { ReactComponent as LandPlot_89_387 } from "./land_plots/89/land_plot_block_387.svg";
import { ReactComponent as LandPlot_89_388 } from "./land_plots/89/land_plot_block_388.svg";
import { ReactComponent as LandPlot_89_389 } from "./land_plots/89/land_plot_block_389.svg";
import { ReactComponent as LandPlot_89_390 } from "./land_plots/89/land_plot_block_390.svg";
import { ReactComponent as LandPlot_89_391 } from "./land_plots/89/land_plot_block_391.svg";
import { ReactComponent as LandPlot_89_392 } from "./land_plots/89/land_plot_block_392.svg";
import { ReactComponent as LandPlot_89_393 } from "./land_plots/89/land_plot_block_393.svg";
import { ReactComponent as LandPlot_89_394 } from "./land_plots/89/land_plot_block_394.svg";
import { ReactComponent as LandPlot_89_395 } from "./land_plots/89/land_plot_block_395.svg";
import { ReactComponent as LandPlot_89_396 } from "./land_plots/89/land_plot_block_396.svg";
import { ReactComponent as LandPlot_89_397 } from "./land_plots/89/land_plot_block_397.svg";
import { ReactComponent as LandPlot_89_398 } from "./land_plots/89/land_plot_block_398.svg";
import { ReactComponent as LandPlot_89_399 } from "./land_plots/89/land_plot_block_399.svg";
import { ReactComponent as LandPlot_89_400 } from "./land_plots/89/land_plot_block_400.svg";
import { ReactComponent as LandPlot_89_401 } from "./land_plots/89/land_plot_block_401.svg";
import { ReactComponent as LandPlot_89_402 } from "./land_plots/89/land_plot_block_402.svg";
import { ReactComponent as LandPlot_89_403 } from "./land_plots/89/land_plot_block_403.svg";
import { ReactComponent as LandPlot_89_404 } from "./land_plots/89/land_plot_block_404.svg";
import { ReactComponent as LandPlot_89_405 } from "./land_plots/89/land_plot_block_405.svg";
import { ReactComponent as LandPlot_89_406 } from "./land_plots/89/land_plot_block_406.svg";
import { ReactComponent as LandPlot_89_407 } from "./land_plots/89/land_plot_block_407.svg";
import { ReactComponent as LandPlot_89_408 } from "./land_plots/89/land_plot_block_408.svg";
import { ReactComponent as LandPlot_89_409 } from "./land_plots/89/land_plot_block_409.svg";
import { ReactComponent as LandPlot_89_410 } from "./land_plots/89/land_plot_block_410.svg";
import { ReactComponent as LandPlot_89_411 } from "./land_plots/89/land_plot_block_411.svg";
import { ReactComponent as LandPlot_89_412 } from "./land_plots/89/land_plot_block_412.svg";
import { ReactComponent as LandPlot_89_413 } from "./land_plots/89/land_plot_block_413.svg";
import { ReactComponent as LandPlot_89_414 } from "./land_plots/89/land_plot_block_414.svg";
import { ReactComponent as LandPlot_89_415 } from "./land_plots/89/land_plot_block_415.svg";
import { ReactComponent as LandPlot_89_416 } from "./land_plots/89/land_plot_block_416.svg";
import { ReactComponent as LandPlot_89_417 } from "./land_plots/89/land_plot_block_417.svg";
import { ReactComponent as LandPlot_89_418 } from "./land_plots/89/land_plot_block_418.svg";
import { ReactComponent as LandPlot_89_419 } from "./land_plots/89/land_plot_block_419.svg";
import { ReactComponent as LandPlot_89_420 } from "./land_plots/89/land_plot_block_420.svg";
import { ReactComponent as LandPlot_89_421 } from "./land_plots/89/land_plot_block_421.svg";
import { ReactComponent as LandPlot_89_422 } from "./land_plots/89/land_plot_block_422.svg";
import { ReactComponent as LandPlot_89_423 } from "./land_plots/89/land_plot_block_423.svg";
import { ReactComponent as LandPlot_89_424 } from "./land_plots/89/land_plot_block_424.svg";
import { ReactComponent as LandPlot_89_425 } from "./land_plots/89/land_plot_block_425.svg";
import { ReactComponent as LandPlot_89_426 } from "./land_plots/89/land_plot_block_426.svg";
import { ReactComponent as LandPlot_89_427 } from "./land_plots/89/land_plot_block_427.svg";
import { ReactComponent as LandPlot_89_428 } from "./land_plots/89/land_plot_block_428.svg";
import { ReactComponent as LandPlot_89_429 } from "./land_plots/89/land_plot_block_429.svg";
import { ReactComponent as LandPlot_89_430 } from "./land_plots/89/land_plot_block_430.svg";
import { ReactComponent as LandPlot_89_431 } from "./land_plots/89/land_plot_block_431.svg";
import { ReactComponent as LandPlot_89_432 } from "./land_plots/89/land_plot_block_432.svg";
import { ReactComponent as LandPlot_89_433 } from "./land_plots/89/land_plot_block_433.svg";
import { ReactComponent as LandPlot_89_434 } from "./land_plots/89/land_plot_block_434.svg";
import { ReactComponent as LandPlot_89_435 } from "./land_plots/89/land_plot_block_435.svg";
import { ReactComponent as LandPlot_89_436 } from "./land_plots/89/land_plot_block_436.svg";
import { ReactComponent as LandPlot_89_437 } from "./land_plots/89/land_plot_block_437.svg";
import { ReactComponent as LandPlot_89_438 } from "./land_plots/89/land_plot_block_438.svg";
import { ReactComponent as LandPlot_89_439 } from "./land_plots/89/land_plot_block_439.svg";
import { ReactComponent as LandPlot_89_440 } from "./land_plots/89/land_plot_block_440.svg";
import { ReactComponent as LandPlot_89_441 } from "./land_plots/89/land_plot_block_441.svg";
import { ReactComponent as LandPlot_89_442 } from "./land_plots/89/land_plot_block_442.svg";
import { ReactComponent as LandPlot_89_443 } from "./land_plots/89/land_plot_block_443.svg";
import { ReactComponent as LandPlot_89_444 } from "./land_plots/89/land_plot_block_444.svg";
import { ReactComponent as LandPlot_89_445 } from "./land_plots/89/land_plot_block_445.svg";
import { ReactComponent as LandPlot_89_446 } from "./land_plots/89/land_plot_block_446.svg";
import { ReactComponent as LandPlot_89_447 } from "./land_plots/89/land_plot_block_447.svg";
import { ReactComponent as LandPlot_89_448 } from "./land_plots/89/land_plot_block_448.svg";
import { ReactComponent as LandPlot_89_449 } from "./land_plots/89/land_plot_block_449.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-5/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-5/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-5/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-5/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-5/icons/2/church_5.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-5/icons/2/hospital_1.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-5/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-5/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-5/icons/2/school_3.svg";
import { ReactComponent as Museum_2_1 } from "../../../../static/images/contents/zone-5/icons/2/museum_1.svg";
import { ReactComponent as Museum_2_2 } from "../../../../static/images/contents/zone-5/icons/2/museum_2.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-5/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-5/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-5/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-5/icons/33/church_4.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-5/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-5/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-5/icons/33/school_3.svg";
import { ReactComponent as Museum_33_2 } from "../../../../static/images/contents/zone-5/icons/33/museum_2.svg";

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-5/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-5/icons/61/church_2.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-5/icons/61/church_4.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-5/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-5/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-5/icons/61/school_3.svg";
import { ReactComponent as Museum_61_2 } from "../../../../static/images/contents/zone-5/icons/61/museum_2.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-5/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-5/icons/91/church_2.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-5/icons/91/church_4.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-5/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-5/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-5/icons/91/school_3.svg";
import { ReactComponent as Museum_91_2 } from "../../../../static/images/contents/zone-5/icons/91/museum_2.svg";

export const zone5_land_plots = [
    //index 6
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_6_3,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 3,
                                status: "sold",
                                block: 2,
                                unitSize: 564.04,
                                north: "32.47",
                                east: "17.91",
                                south: "30.76",
                                west: "17.85",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_4,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 4,
                                status: "sold",
                                block: 2,
                                unitSize: 450.51,
                                north: "30.76",
                                east: "15.0",
                                south: "29.31",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_5,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 5,
                                status: "sold",
                                block: 2,
                                unitSize: 428.78,
                                north: "29.31",
                                east: "15.0",
                                south: "27.86",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_6,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 6,
                                status: "sold",
                                block: 2,
                                unitSize: 407.05,
                                north: "27.86",
                                east: "15.0",
                                south: "26.41",
                                west: "15.07",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_7,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 7,
                                status: "sold",
                                block: 2,
                                unitSize: 494.09,
                                north: "15.0",
                                east: "32.97",
                                south: "15.0",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_8,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 8,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_9,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 9,
                                status: "sold",
                                block: 2,
                                unitSize: 495.05,
                                north: "15.0",
                                east: "33.04",
                                south: "15.0",
                                west: "32.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_10,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 10,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_11,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 11,
                                status: "sold",
                                block: 2,
                                unitSize: 496.01,
                                north: "15.0",
                                east: "33.1",
                                south: "15.0",
                                west: "33.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_12,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 12,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_13,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 13,
                                status: "sold",
                                block: 2,
                                unitSize: 496.97,
                                north: "15.0",
                                east: "33.16",
                                south: "15.0",
                                west: "33.1",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_14,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 14,
                                status: "sold",
                                block: 2,
                                unitSize: 676.9,
                                north: "27.43",
                                east: "31.54",
                                south: "17.7",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_15,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 15,
                                status: "sold",
                                block: 2,
                                unitSize: 591.7,
                                north: "23.22",
                                east: "34.97",
                                south: "12.43",
                                west: "33.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_16,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 16,
                                status: "available",
                                block: 3,
                                unitSize: 1505.24,
                                north: "41.34",
                                east: "40.21",
                                south: "41.44",
                                west: "28.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "40",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_17,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 17,
                                status: "available",
                                block: 3,
                                unitSize: 564.55,
                                north: "26.99",
                                east: "28.16",
                                south: "25.0",
                                west: "18.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_18,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 18,
                                status: "available",
                                block: 3,
                                unitSize: 1245.11,
                                north: "41.44",
                                east: "29.65",
                                south: "41.56",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_19,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 19,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_20,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 20,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_21,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 21,
                                status: "available",
                                block: 3,
                                unitSize: 1249.02,
                                north: "41.56",
                                east: "35.0",
                                south: "41.7",
                                west: "30.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_22,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 22,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_23,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 23,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_24,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 24,
                                status: "available",
                                block: 3,
                                unitSize: 1732.36,
                                north: "41.7",
                                east: "47.22",
                                south: "43.48",
                                west: "35.67",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_25,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 25,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_26,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 26,
                                status: "available",
                                block: 3,
                                unitSize: 430.75,
                                north: "25.0",
                                east: "20.67",
                                south: "25.93",
                                west: "13.79",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_27,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 27,
                                status: "sold",
                                block: 4,
                                unitSize: 410.76,
                                north: "20.26",
                                east: "19.02",
                                south: "17.7",
                                west: "32.38",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_28,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 28,
                                status: "available",
                                block: 4,
                                unitSize: 929.99,
                                north: "29.58",
                                east: "32.38",
                                south: "22.0",
                                west: "52.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_29,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 29,
                                status: "available",
                                block: 4,
                                unitSize: 1054.93,
                                north: "36.08",
                                east: "12.51",
                                south: "45.62",
                                west: "31.63",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_30,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 30,
                                status: "available",
                                block: 4,
                                unitSize: 946.77,
                                north: "45.62",
                                east: "19.64",
                                south: "45.41",
                                west: "21.99",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_31,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 31,
                                status: "available",
                                block: 4,
                                unitSize: 906.86,
                                north: "45.41",
                                east: "20.0",
                                south: "45.27",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_32,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 32,
                                status: "available",
                                block: 4,
                                unitSize: 821.97,
                                north: "39.7",
                                east: "20.19",
                                south: "42.5",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_33,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 33,
                                status: "available",
                                block: 4,
                                unitSize: 904.12,
                                north: "45.27",
                                east: "20.0",
                                south: "45.14",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_34,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 34,
                                status: "available",
                                block: 4,
                                unitSize: 877.85,
                                north: "42.5",
                                east: "20.19",
                                south: "45.29",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_35,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 35,
                                status: "available",
                                block: 4,
                                unitSize: 901.37,
                                north: "45.14",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_36,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 36,
                                status: "available",
                                block: 4,
                                unitSize: 695.31,
                                north: "15.29",
                                east: "40.41",
                                south: "20.06",
                                west: "37.29",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_37,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 37,
                                status: "available",
                                block: 4,
                                unitSize: 544.69,
                                north: "15.0",
                                east: "37.29",
                                south: "15.13",
                                west: "35.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_38,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 38,
                                status: "available",
                                block: 4,
                                unitSize: 515.29,
                                north: "15.0",
                                east: "35.33",
                                south: "15.13",
                                west: "33.37",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_39,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 39,
                                status: "sold",
                                block: 4,
                                unitSize: 485.88,
                                north: "15.0",
                                east: "33.37",
                                south: "15.13",
                                west: "31.41",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_40,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 40,
                                status: "sold",
                                block: 4,
                                unitSize: 456.48,
                                north: "15.0",
                                east: "31.41",
                                south: "15.13",
                                west: "29.45",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_41,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 41,
                                status: "sold",
                                block: 4,
                                unitSize: 424.48,
                                north: "15.0",
                                east: "29.45",
                                south: "14.94",
                                west: "27.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_42,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 42,
                                status: "sold",
                                block: 5,
                                unitSize: 437.58,
                                north: "15.32",
                                east: "28.7",
                                south: "15.17",
                                west: "28.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_43,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 43,
                                status: "sold",
                                block: 5,
                                unitSize: 430.6,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_44,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 44,
                                status: "sold",
                                block: 5,
                                unitSize: 430.62,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_45,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 45,
                                status: "sold",
                                block: 5,
                                unitSize: 430.65,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_46,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 46,
                                status: "available",
                                block: 5,
                                unitSize: 504.49,
                                north: "20.03",
                                east: "28.71",
                                south: "15.98",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_47,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 47,
                                status: "available",
                                block: 5,
                                unitSize: 597.19,
                                north: "35.17",
                                east: "17.0",
                                south: "35.09",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_48,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 48,
                                status: "available",
                                block: 5,
                                unitSize: 676.21,
                                north: "40.98",
                                east: "17.0",
                                south: "38.58",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_49,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 49,
                                status: "available",
                                block: 5,
                                unitSize: 595.73,
                                north: "35.09",
                                east: "17.0",
                                south: "35.0",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_50,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 50,
                                status: "available",
                                block: 5,
                                unitSize: 635.38,
                                north: "38.58",
                                east: "17.0",
                                south: "36.17",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_51,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 51,
                                status: "sold",
                                block: 5,
                                unitSize: 364.26,
                                north: "16.0",
                                east: "22.66",
                                south: "15.89",
                                west: "23.04",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_52,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 52,
                                status: "sold",
                                block: 5,
                                unitSize: 371.71,
                                north: "16.0",
                                east: "23.04",
                                south: "16.0",
                                west: "23.42",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_53,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 53,
                                status: "sold",
                                block: 5,
                                unitSize: 377.86,
                                north: "16.0",
                                east: "23.42",
                                south: "16.0",
                                west: "23.81",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_54,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 54,
                                status: "available",
                                block: 5,
                                unitSize: 516.4,
                                north: "23.17",
                                east: "23.81",
                                south: "19.75",
                                west: "24.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_62,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 62,
                                status: "sold",
                                block: 7,
                                unitSize: 543.18,
                                north: "19.11",
                                east: "26.89",
                                south: "21.69",
                                west: "26.58",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_63,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 63,
                                status: "sold",
                                block: 7,
                                unitSize: 406.04,
                                north: "21.69",
                                east: "18.08",
                                south: "23.43",
                                west: "18.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_64,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 64,
                                status: "sold",
                                block: 7,
                                unitSize: 437.33,
                                north: "23.43",
                                east: "18.08",
                                south: "25.16",
                                west: "18.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_65,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 65,
                                status: "sold",
                                block: 7,
                                unitSize: 488.24,
                                north: "15.0",
                                east: "32.58",
                                south: "15.0",
                                west: "32.52",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_66,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 66,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_67,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 67,
                                status: "sold",
                                block: 7,
                                unitSize: 752.78,
                                north: "26.06",
                                east: "32.52",
                                south: "20.31",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_68,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 68,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.0",
                                south: "15.0",
                                west: "30.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_69,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 69,
                                status: "available",
                                block: 7,
                                unitSize: 847.8,
                                north: "15.11",
                                east: "32.91",
                                south: "20.31",
                                west: "38.33",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_70,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 70,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.47",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_71,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 71,
                                status: "available",
                                block: 7,
                                unitSize: 589.54,
                                north: "15.12",
                                east: "38.33",
                                south: "15.0",
                                west: "40.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_72,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 72,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_73,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 73,
                                status: "available",
                                block: 7,
                                unitSize: 618.58,
                                north: "15.12",
                                east: "40.27",
                                south: "15.0",
                                west: "42.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_74,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 74,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_75,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 75,
                                status: "available",
                                block: 7,
                                unitSize: 647.61,
                                north: "15.12",
                                east: "42.21",
                                south: "15.0",
                                west: "44.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_76,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 76,
                                status: "available",
                                block: 7,
                                unitSize: 707.99,
                                north: "29.89",
                                east: "22.14",
                                south: "24.65",
                                west: "22.89",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_77,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 77,
                                status: "available",
                                block: 7,
                                unitSize: 443.39,
                                north: "24.65",
                                east: "22.0",
                                south: "15.65",
                                west: "23.77",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_78,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 78,
                                status: "available",
                                block: 7,
                                unitSize: 413.77,
                                north: "30.65",
                                east: "15.0",
                                south: "24.52",
                                west: "16.21",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_79,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 79,
                                status: "sold",
                                block: 7,
                                unitSize: 321.7,
                                north: "24.52",
                                east: "15.0",
                                south: "18.38",
                                west: "16.21",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_80,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 80,
                                status: "sold",
                                block: 8,
                                unitSize: 330.17,
                                north: "15.7",
                                east: "20.29",
                                south: "15.87",
                                west: "21.63",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_81,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 81,
                                status: "sold",
                                block: 8,
                                unitSize: 334.03,
                                north: "15.05",
                                east: "21.63",
                                south: "15.0",
                                west: "22.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_82,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 82,
                                status: "available",
                                block: 8,
                                unitSize: 401.64,
                                north: "23.2",
                                east: "22.91",
                                south: "11.0",
                                west: "27.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_83,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 83,
                                status: "available",
                                block: 8,
                                unitSize: 828.76,
                                north: "41.87",
                                east: "18.35",
                                south: "22.23",
                                west: "40.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_84,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 84,
                                status: "available",
                                block: 8,
                                unitSize: 462.59,
                                north: "18.92",
                                east: "27.68",
                                south: "12.0",
                                west: "34.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_85,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 85,
                                status: "available",
                                block: 8,
                                unitSize: 358.81,
                                north: "17.29",
                                east: "19.21",
                                south: "16.0",
                                west: "25.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_86,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 86,
                                status: "sold",
                                block: 8,
                                unitSize: 494.08,
                                north: "18.37",
                                east: "25.64",
                                south: "17.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_87,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 87,
                                status: "available",
                                block: 8,
                                unitSize: 495.01,
                                north: "33.0",
                                east: "15.0",
                                south: "33.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_88,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 88,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_89,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 89,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_90,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 90,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_91,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 91,
                                status: "available",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_92,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 92,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_93,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 93,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_94,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 94,
                                status: "sold",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_95,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 95,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_96,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 96,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_97,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 97,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_98,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 98,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_99,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 99,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 100,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 101,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 102,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 103,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 104,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 105,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 106,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 107,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 108,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 109,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 110,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 111,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 112,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 113,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 114,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 115,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 116,
                                status: "sold",
                                block: 9,
                                unitSize: 774.58,
                                north: "22.0",
                                east: "36.66",
                                south: "22.21",
                                west: "33.75",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 117,
                                status: "sold",
                                block: 9,
                                unitSize: 679.6,
                                north: "21.0",
                                east: "33.75",
                                south: "21.2",
                                west: "30.97",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 118,
                                status: "sold",
                                block: 9,
                                unitSize: 649.33,
                                north: "22.0",
                                east: "30.97",
                                south: "22.21",
                                west: "28.06",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 119,
                                status: "sold",
                                block: 10,
                                unitSize: 529.13,
                                north: "21.58",
                                east: "22.47",
                                south: "20.0",
                                west: "30.44",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 120,
                                status: "available",
                                block: 10,
                                unitSize: 575.11,
                                north: "18.34",
                                east: "30.44",
                                south: "17.0",
                                west: "37.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 121,
                                status: "available",
                                block: 10,
                                unitSize: 734.49,
                                north: "19.42",
                                east: "37.22",
                                south: "18.0",
                                west: "44.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 122,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 123,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 124,
                                status: "available",
                                block: 10,
                                unitSize: 1107.03,
                                north: "27.0",
                                east: "41.0",
                                south: "27.0",
                                west: "41.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 125,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 126,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 127,
                                status: "available",
                                block: 10,
                                unitSize: 513.01,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 128,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 129,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 130,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 131,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 132,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 133,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 134,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 135,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 136,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 137,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 138,
                                status: "sold",
                                block: 10,
                                unitSize: 596.35,
                                north: "28.0",
                                east: "23.15",
                                south: "28.27",
                                west: "19.44",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 139,
                                status: "sold",
                                block: 10,
                                unitSize: 476.74,
                                north: "27.0",
                                east: "19.44",
                                south: "27.26",
                                west: "15.87",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 140,
                                status: "available",
                                block: 11,
                                unitSize: 868.22,
                                north: "17.66",
                                east: "37.77",
                                south: "31.96",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 141,
                                status: "available",
                                block: 11,
                                unitSize: 692.6,
                                north: "16.96",
                                east: "32.37",
                                south: "29.22",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 142,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 143,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 144,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 145,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 146,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 147,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 148,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 149,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 150,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 151,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 152,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 153,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 154,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 155,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 156,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 157,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 158,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 159,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 160,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 161,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 162,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 163,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 164,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 165,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 166,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 167,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 168,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 169,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 170,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 171,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 172,
                                status: "available",
                                block: 11,
                                unitSize: 776.21,
                                north: "26.65",
                                east: "35.0",
                                south: "17.7",
                                west: "36.17",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 173,
                                status: "available",
                                block: 11,
                                unitSize: 866.01,
                                north: "32.7",
                                east: "30.0",
                                south: "25.03",
                                west: "31.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 174,
                                status: "available",
                                block: 12,
                                unitSize: 450.1,
                                north: "26.38",
                                east: "13.99",
                                south: "27.51",
                                west: "20.3",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 175,
                                status: "sold",
                                block: 12,
                                unitSize: 669.5,
                                north: "27.51",
                                east: "23.22",
                                south: "30.71",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 176,
                                status: "available",
                                block: 12,
                                unitSize: 406.16,
                                north: "18.56",
                                east: "20.3",
                                south: "18.0",
                                west: "24.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 177,
                                status: "sold",
                                block: 12,
                                unitSize: 414.0,
                                north: "18.0",
                                east: "23.0",
                                south: "18.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 178,
                                status: "sold",
                                block: 12,
                                unitSize: 310.82,
                                north: "15.47",
                                east: "18.83",
                                south: "15.0",
                                west: "22.61",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 179,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 180,
                                status: "available",
                                block: 12,
                                unitSize: 367.5,
                                north: "15.47",
                                east: "22.61",
                                south: "15.0",
                                west: "26.39",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 181,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 182,
                                status: "available",
                                block: 12,
                                unitSize: 424.19,
                                north: "15.47",
                                east: "26.39",
                                south: "15.0",
                                west: "30.17",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 183,
                                status: "available",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 184,
                                status: "available",
                                block: 12,
                                unitSize: 390.87,
                                north: "15.47",
                                east: "24.17",
                                south: "15.0",
                                west: "27.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 185,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 186,
                                status: "available",
                                block: 12,
                                unitSize: 447.56,
                                north: "15.47",
                                east: "27.95",
                                south: "15.0",
                                west: "31.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 187,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 188,
                                status: "available",
                                block: 12,
                                unitSize: 504.25,
                                north: "15.47",
                                east: "31.73",
                                south: "15.0",
                                west: "35.51",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 189,
                                status: "sold",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 190,
                                status: "available",
                                block: 12,
                                unitSize: 545.02,
                                north: "11.24",
                                east: "35.51",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 191,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 192,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 193,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 194,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 195,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 196,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 197,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 198,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "10",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 199,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 200,
                                status: "sold",
                                block: 13,
                                unitSize: 312.66,
                                north: "19.8",
                                east: "15.14",
                                south: "21.89",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 201,
                                status: "sold",
                                block: 13,
                                unitSize: 343.99,
                                north: "21.89",
                                east: "15.14",
                                south: "23.98",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 202,
                                status: "sold",
                                block: 13,
                                unitSize: 375.33,
                                north: "23.98",
                                east: "15.14",
                                south: "26.07",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 203,
                                status: "sold",
                                block: 13,
                                unitSize: 406.66,
                                north: "26.07",
                                east: "15.14",
                                south: "28.16",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 204,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 205,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 206,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 207,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 208,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 209,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 210,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 211,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 212,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 213,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 214,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 215,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 216,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 217,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 218,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 219,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 220,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 221,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 222,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 223,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 224,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 225,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 226,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 227,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 228,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 229,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 230,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 231,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 232,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 233,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 234,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 235,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 236,
                                status: "sold",
                                block: 14,
                                unitSize: 821.93,
                                north: "20.67",
                                east: "38.49",
                                south: "20.0",
                                west: "43.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 237,
                                status: "available",
                                block: 14,
                                unitSize: 926.37,
                                north: "20.67",
                                east: "43.71",
                                south: "20.0",
                                west: "48.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 238,
                                status: "available",
                                block: 14,
                                unitSize: 554.82,
                                north: "25.84",
                                east: "18.93",
                                south: "25.0",
                                west: "25.46",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 239,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 240,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 241,
                                status: "booked",
                                block: 14,
                                unitSize: 1350.0,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 242,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 243,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 244,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 245,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 246,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 247,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 248,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 249,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 250,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 251,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 252,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 253,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 254,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 255,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 256,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 257,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 258,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 259,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 260,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 261,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 262,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 263,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 264,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 265,
                                status: "available",
                                block: 15,
                                unitSize: 692.65,
                                north: "30.0",
                                east: "23.09",
                                south: "30.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 266,
                                status: "sold",
                                block: 15,
                                unitSize: 808.1,
                                north: "35.0",
                                east: "23.09",
                                south: "35.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 267,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 268,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 269,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 270,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 271,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 272,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 273,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 274,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 275,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 276,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 277,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 278,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 279,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 280,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 281,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 282,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 283,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 284,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 285,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 286,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 287,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 288,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 289,
                                status: "sold",
                                block: 17,
                                unitSize: 549.56,
                                north: "19.18",
                                east: "27.67",
                                south: "19.0",
                                west: "30.18",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 290,
                                status: "sold",
                                block: 17,
                                unitSize: 532.22,
                                north: "17.16",
                                east: "30.18",
                                south: "17.0",
                                west: "32.43",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 291,
                                status: "sold",
                                block: 17,
                                unitSize: 640.12,
                                north: "19.18",
                                east: "32.43",
                                south: "19.0",
                                west: "34.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 292,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 293,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 294,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 295,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 296,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 297,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 298,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 299,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 300,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 301,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 302,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 303,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 304,
                                status: "available",
                                block: 17,
                                unitSize: 500.01,
                                north: "25.0",
                                east: "20.0",
                                south: "25.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 305,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 306,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 307,
                                status: "available",
                                block: 17,
                                unitSize: 1000.02,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 308,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 309,
                                status: "sold",
                                block: 17,
                                unitSize: 832.14,
                                north: "30.0",
                                east: "24.32",
                                south: "24.29",
                                west: "26.35",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_6_310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 310,
                                status: "sold",
                                block: 17,
                                unitSize: 992.29,
                                north: "25.0",
                                east: "41.35",
                                south: "25.24",
                                west: "38.04",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 311,
                                status: "sold",
                                block: 18,
                                unitSize: 477.46,
                                north: "24.46",
                                east: "19.18",
                                south: "27.11",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 312,
                                status: "sold",
                                block: 18,
                                unitSize: 630.13,
                                north: "27.11",
                                east: "22.21",
                                south: "30.17",
                                west: "22.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 313,
                                status: "sold",
                                block: 18,
                                unitSize: 580.62,
                                north: "30.17",
                                east: "13.18",
                                south: "26.82",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 314,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 315,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 316,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 317,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 318,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 319,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 320,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 321,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 322,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 323,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 324,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 325,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 326,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 327,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 328,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 329,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 330,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 331,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 332,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 333,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 334,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 335,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 336,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 337,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 338,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 339,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 340,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 341,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 342,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 343,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 344,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 345,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 346,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 347,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 348,
                                status: "booked",
                                block: 20,
                                unitSize: 555.56,
                                north: "27.92",
                                east: "19.18",
                                south: "30.56",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 349,
                                status: "booked",
                                block: 20,
                                unitSize: 439.7,
                                north: "30.56",
                                east: "17.16",
                                south: "32.93",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 350,
                                status: "booked",
                                block: 20,
                                unitSize: 632.99,
                                north: "32.93",
                                east: "13.18",
                                south: "29.58",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 351,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 352,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 353,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 354,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 355,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 356,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 357,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 358,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 359,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 360,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 361,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 362,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 363,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 364,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 365,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 366,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 367,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 368,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_6_369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 369,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 370,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 371,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 372,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 373,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 374,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 375,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 376,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 377,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 378,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 379,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 380,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 381,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 382,
                                status: "sold",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 383,
                                status: "booked",
                                block: 21,
                                unitSize: 577.06,
                                north: "20.61",
                                east: "28.0",
                                south: "20.61",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 384,
                                status: "booked",
                                block: 21,
                                unitSize: 556.45,
                                north: "20.61",
                                east: "27.0",
                                south: "20.61",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 385,
                                status: "available",
                                block: 22,
                                unitSize: 913.53,
                                north: "28.36",
                                east: "30.29",
                                south: "32.54",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 386,
                                status: "available",
                                block: 22,
                                unitSize: 610.65,
                                north: "17.54",
                                east: "31.3",
                                south: "21.86",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 387,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 388,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 389,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 390,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 391,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 392,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 393,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 394,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 395,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 396,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 397,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 398,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 399,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 400,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 401,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 402,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 403,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 404,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 405,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 406,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 407,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 408,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 409,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 410,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 411,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 412,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 413,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 414,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 415,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 416,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 417,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 418,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 419,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 420,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 421,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 422,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 423,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 424,
                                status: "available",
                                block: 23,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 425,
                                status: "available",
                                block: 23,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 426,
                                status: "booked",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_6_427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 427,
                                status: "booked",
                                block: 24,
                                unitSize: 687.5,
                                north: "35.0",
                                east: "20.0",
                                south: "35.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_6_428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 428,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 429,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 430,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 431,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 432,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 433,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 434,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 435,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 436,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 437,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 438,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 439,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 440,
                                status: "available",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 441,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 442,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 443,
                                status: "available",
                                block: 24,
                                unitSize: 1200.0,
                                north: "30.0",
                                east: "40.0",
                                south: "30.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 444,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 445,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 446,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 447,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_6_448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 448,
                                status: "sold",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_6_449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 449,
                                status: "sold",
                                block: 24,
                                unitSize: 720.0,
                                north: "20.0",
                                east: "36.0",
                                south: "20.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    //index 27
    {
        index: 33,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_27_3,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 3,
                                status: "sold",
                                block: 2,
                                unitSize: 564.04,
                                north: "32.47",
                                east: "17.91",
                                south: "30.76",
                                west: "17.85",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_4,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 4,
                                status: "sold",
                                block: 2,
                                unitSize: 450.51,
                                north: "30.76",
                                east: "15.0",
                                south: "29.31",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_5,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 5,
                                status: "sold",
                                block: 2,
                                unitSize: 428.78,
                                north: "29.31",
                                east: "15.0",
                                south: "27.86",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_6,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 6,
                                status: "sold",
                                block: 2,
                                unitSize: 407.05,
                                north: "27.86",
                                east: "15.0",
                                south: "26.41",
                                west: "15.07",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_7,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 7,
                                status: "sold",
                                block: 2,
                                unitSize: 494.09,
                                north: "15.0",
                                east: "32.97",
                                south: "15.0",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_8,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 8,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_9,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 9,
                                status: "sold",
                                block: 2,
                                unitSize: 495.05,
                                north: "15.0",
                                east: "33.04",
                                south: "15.0",
                                west: "32.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_10,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 10,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_11,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 11,
                                status: "sold",
                                block: 2,
                                unitSize: 496.01,
                                north: "15.0",
                                east: "33.1",
                                south: "15.0",
                                west: "33.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_12,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 12,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_13,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 13,
                                status: "sold",
                                block: 2,
                                unitSize: 496.97,
                                north: "15.0",
                                east: "33.16",
                                south: "15.0",
                                west: "33.1",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_14,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 14,
                                status: "sold",
                                block: 2,
                                unitSize: 676.9,
                                north: "27.43",
                                east: "31.54",
                                south: "17.7",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_15,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 15,
                                status: "sold",
                                block: 2,
                                unitSize: 591.7,
                                north: "23.22",
                                east: "34.97",
                                south: "12.43",
                                west: "33.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_16,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 16,
                                status: "available",
                                block: 3,
                                unitSize: 1505.24,
                                north: "41.34",
                                east: "40.21",
                                south: "41.44",
                                west: "28.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "40",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_17,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 17,
                                status: "available",
                                block: 3,
                                unitSize: 564.55,
                                north: "26.99",
                                east: "28.16",
                                south: "25.0",
                                west: "18.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_18,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 18,
                                status: "available",
                                block: 3,
                                unitSize: 1245.11,
                                north: "41.44",
                                east: "29.65",
                                south: "41.56",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_19,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 19,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_20,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 20,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_21,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 21,
                                status: "available",
                                block: 3,
                                unitSize: 1249.02,
                                north: "41.56",
                                east: "35.0",
                                south: "41.7",
                                west: "30.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_22,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 22,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_23,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 23,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_24,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 24,
                                status: "available",
                                block: 3,
                                unitSize: 1732.36,
                                north: "41.7",
                                east: "47.22",
                                south: "43.48",
                                west: "35.67",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_25,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 25,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_26,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 26,
                                status: "available",
                                block: 3,
                                unitSize: 430.75,
                                north: "25.0",
                                east: "20.67",
                                south: "25.93",
                                west: "13.79",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_27,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 27,
                                status: "sold",
                                block: 4,
                                unitSize: 410.76,
                                north: "20.26",
                                east: "19.02",
                                south: "17.7",
                                west: "32.38",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_28,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 28,
                                status: "available",
                                block: 4,
                                unitSize: 929.99,
                                north: "29.58",
                                east: "32.38",
                                south: "22.0",
                                west: "52.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_29,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 29,
                                status: "available",
                                block: 4,
                                unitSize: 1054.93,
                                north: "36.08",
                                east: "12.51",
                                south: "45.62",
                                west: "31.63",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_30,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 30,
                                status: "available",
                                block: 4,
                                unitSize: 946.77,
                                north: "45.62",
                                east: "19.64",
                                south: "45.41",
                                west: "21.99",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_31,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 31,
                                status: "available",
                                block: 4,
                                unitSize: 906.86,
                                north: "45.41",
                                east: "20.0",
                                south: "45.27",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_32,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 32,
                                status: "available",
                                block: 4,
                                unitSize: 821.97,
                                north: "39.7",
                                east: "20.19",
                                south: "42.5",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_33,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 33,
                                status: "available",
                                block: 4,
                                unitSize: 904.12,
                                north: "45.27",
                                east: "20.0",
                                south: "45.14",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_34,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 34,
                                status: "available",
                                block: 4,
                                unitSize: 877.85,
                                north: "42.5",
                                east: "20.19",
                                south: "45.29",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_35,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 35,
                                status: "available",
                                block: 4,
                                unitSize: 901.37,
                                north: "45.14",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_36,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 36,
                                status: "available",
                                block: 4,
                                unitSize: 695.31,
                                north: "15.29",
                                east: "40.41",
                                south: "20.06",
                                west: "37.29",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_37,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 37,
                                status: "available",
                                block: 4,
                                unitSize: 544.69,
                                north: "15.0",
                                east: "37.29",
                                south: "15.13",
                                west: "35.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_38,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 38,
                                status: "available",
                                block: 4,
                                unitSize: 515.29,
                                north: "15.0",
                                east: "35.33",
                                south: "15.13",
                                west: "33.37",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_39,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 39,
                                status: "sold",
                                block: 4,
                                unitSize: 485.88,
                                north: "15.0",
                                east: "33.37",
                                south: "15.13",
                                west: "31.41",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_40,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 40,
                                status: "sold",
                                block: 4,
                                unitSize: 456.48,
                                north: "15.0",
                                east: "31.41",
                                south: "15.13",
                                west: "29.45",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_41,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 41,
                                status: "sold",
                                block: 4,
                                unitSize: 424.48,
                                north: "15.0",
                                east: "29.45",
                                south: "14.94",
                                west: "27.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_42,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 42,
                                status: "sold",
                                block: 5,
                                unitSize: 437.58,
                                north: "15.32",
                                east: "28.7",
                                south: "15.17",
                                west: "28.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_43,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 43,
                                status: "sold",
                                block: 5,
                                unitSize: 430.6,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_44,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 44,
                                status: "sold",
                                block: 5,
                                unitSize: 430.62,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_45,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 45,
                                status: "sold",
                                block: 5,
                                unitSize: 430.65,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_46,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 46,
                                status: "available",
                                block: 5,
                                unitSize: 504.49,
                                north: "20.03",
                                east: "28.71",
                                south: "15.98",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_47,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 47,
                                status: "available",
                                block: 5,
                                unitSize: 597.19,
                                north: "35.17",
                                east: "17.0",
                                south: "35.09",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_48,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 48,
                                status: "available",
                                block: 5,
                                unitSize: 676.21,
                                north: "40.98",
                                east: "17.0",
                                south: "38.58",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_49,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 49,
                                status: "available",
                                block: 5,
                                unitSize: 595.73,
                                north: "35.09",
                                east: "17.0",
                                south: "35.0",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_50,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 50,
                                status: "available",
                                block: 5,
                                unitSize: 635.38,
                                north: "38.58",
                                east: "17.0",
                                south: "36.17",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_51,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 51,
                                status: "sold",
                                block: 5,
                                unitSize: 364.26,
                                north: "16.0",
                                east: "22.66",
                                south: "15.89",
                                west: "23.04",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_52,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 52,
                                status: "sold",
                                block: 5,
                                unitSize: 371.71,
                                north: "16.0",
                                east: "23.04",
                                south: "16.0",
                                west: "23.42",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_53,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 53,
                                status: "sold",
                                block: 5,
                                unitSize: 377.86,
                                north: "16.0",
                                east: "23.42",
                                south: "16.0",
                                west: "23.81",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_54,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 54,
                                status: "available",
                                block: 5,
                                unitSize: 516.4,
                                north: "23.17",
                                east: "23.81",
                                south: "19.75",
                                west: "24.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_62,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 62,
                                status: "sold",
                                block: 7,
                                unitSize: 543.18,
                                north: "19.11",
                                east: "26.89",
                                south: "21.69",
                                west: "26.58",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_63,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 63,
                                status: "sold",
                                block: 7,
                                unitSize: 406.04,
                                north: "21.69",
                                east: "18.08",
                                south: "23.43",
                                west: "18.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_64,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 64,
                                status: "sold",
                                block: 7,
                                unitSize: 437.33,
                                north: "23.43",
                                east: "18.08",
                                south: "25.16",
                                west: "18.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_65,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 65,
                                status: "sold",
                                block: 7,
                                unitSize: 488.24,
                                north: "15.0",
                                east: "32.58",
                                south: "15.0",
                                west: "32.52",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_66,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 66,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_67,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 67,
                                status: "sold",
                                block: 7,
                                unitSize: 752.78,
                                north: "26.06",
                                east: "32.52",
                                south: "20.31",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_68,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 68,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.0",
                                south: "15.0",
                                west: "30.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_69,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 69,
                                status: "available",
                                block: 7,
                                unitSize: 847.8,
                                north: "15.11",
                                east: "32.91",
                                south: "20.31",
                                west: "38.33",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_70,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 70,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.47",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_71,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 71,
                                status: "available",
                                block: 7,
                                unitSize: 589.54,
                                north: "15.12",
                                east: "38.33",
                                south: "15.0",
                                west: "40.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_72,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 72,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_73,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 73,
                                status: "available",
                                block: 7,
                                unitSize: 618.58,
                                north: "15.12",
                                east: "40.27",
                                south: "15.0",
                                west: "42.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_74,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 74,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_75,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 75,
                                status: "available",
                                block: 7,
                                unitSize: 647.61,
                                north: "15.12",
                                east: "42.21",
                                south: "15.0",
                                west: "44.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_76,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 76,
                                status: "available",
                                block: 7,
                                unitSize: 707.99,
                                north: "29.89",
                                east: "22.14",
                                south: "24.65",
                                west: "22.89",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_77,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 77,
                                status: "available",
                                block: 7,
                                unitSize: 443.39,
                                north: "24.65",
                                east: "22.0",
                                south: "15.65",
                                west: "23.77",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_78,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 78,
                                status: "available",
                                block: 7,
                                unitSize: 413.77,
                                north: "30.65",
                                east: "15.0",
                                south: "24.52",
                                west: "16.21",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_79,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 79,
                                status: "sold",
                                block: 7,
                                unitSize: 321.7,
                                north: "24.52",
                                east: "15.0",
                                south: "18.38",
                                west: "16.21",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_80,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 80,
                                status: "sold",
                                block: 8,
                                unitSize: 330.17,
                                north: "15.7",
                                east: "20.29",
                                south: "15.87",
                                west: "21.63",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_81,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 81,
                                status: "sold",
                                block: 8,
                                unitSize: 334.03,
                                north: "15.05",
                                east: "21.63",
                                south: "15.0",
                                west: "22.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_82,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 82,
                                status: "available",
                                block: 8,
                                unitSize: 401.64,
                                north: "23.2",
                                east: "22.91",
                                south: "11.0",
                                west: "27.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_83,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 83,
                                status: "available",
                                block: 8,
                                unitSize: 828.76,
                                north: "41.87",
                                east: "18.35",
                                south: "22.23",
                                west: "40.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_84,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 84,
                                status: "available",
                                block: 8,
                                unitSize: 462.59,
                                north: "18.92",
                                east: "27.68",
                                south: "12.0",
                                west: "34.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_85,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 85,
                                status: "available",
                                block: 8,
                                unitSize: 358.81,
                                north: "17.29",
                                east: "19.21",
                                south: "16.0",
                                west: "25.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_86,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 86,
                                status: "sold",
                                block: 8,
                                unitSize: 494.08,
                                north: "18.37",
                                east: "25.64",
                                south: "17.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_87,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 87,
                                status: "available",
                                block: 8,
                                unitSize: 495.01,
                                north: "33.0",
                                east: "15.0",
                                south: "33.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_88,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 88,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_89,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 89,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_90,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 90,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_91,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 91,
                                status: "available",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_92,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 92,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_93,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 93,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_94,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 94,
                                status: "sold",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_95,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 95,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_96,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 96,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_97,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 97,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_98,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 98,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_99,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 99,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 100,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 101,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 102,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 103,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 104,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 105,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 106,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 107,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 108,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 109,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 110,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 111,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 112,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 113,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 114,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 115,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 116,
                                status: "sold",
                                block: 9,
                                unitSize: 774.58,
                                north: "22.0",
                                east: "36.66",
                                south: "22.21",
                                west: "33.75",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 117,
                                status: "sold",
                                block: 9,
                                unitSize: 679.6,
                                north: "21.0",
                                east: "33.75",
                                south: "21.2",
                                west: "30.97",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 118,
                                status: "sold",
                                block: 9,
                                unitSize: 649.33,
                                north: "22.0",
                                east: "30.97",
                                south: "22.21",
                                west: "28.06",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 119,
                                status: "sold",
                                block: 10,
                                unitSize: 529.13,
                                north: "21.58",
                                east: "22.47",
                                south: "20.0",
                                west: "30.44",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 120,
                                status: "available",
                                block: 10,
                                unitSize: 575.11,
                                north: "18.34",
                                east: "30.44",
                                south: "17.0",
                                west: "37.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 121,
                                status: "available",
                                block: 10,
                                unitSize: 734.49,
                                north: "19.42",
                                east: "37.22",
                                south: "18.0",
                                west: "44.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 122,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 123,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 124,
                                status: "available",
                                block: 10,
                                unitSize: 1107.03,
                                north: "27.0",
                                east: "41.0",
                                south: "27.0",
                                west: "41.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 125,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 126,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 127,
                                status: "available",
                                block: 10,
                                unitSize: 513.01,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 128,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 129,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 130,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 131,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 132,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 133,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 134,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 135,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 136,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 137,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 138,
                                status: "sold",
                                block: 10,
                                unitSize: 596.35,
                                north: "28.0",
                                east: "23.15",
                                south: "28.27",
                                west: "19.44",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 139,
                                status: "sold",
                                block: 10,
                                unitSize: 476.74,
                                north: "27.0",
                                east: "19.44",
                                south: "27.26",
                                west: "15.87",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 140,
                                status: "available",
                                block: 11,
                                unitSize: 868.22,
                                north: "17.66",
                                east: "37.77",
                                south: "31.96",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 141,
                                status: "available",
                                block: 11,
                                unitSize: 692.6,
                                north: "16.96",
                                east: "32.37",
                                south: "29.22",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 142,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 143,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 144,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 145,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 146,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 147,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 148,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 149,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 150,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 151,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 152,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 153,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 154,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 155,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 156,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 157,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 158,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 159,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 160,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 161,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 162,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 163,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 164,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 165,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 166,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 167,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 168,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 169,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 170,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 171,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 172,
                                status: "available",
                                block: 11,
                                unitSize: 776.21,
                                north: "26.65",
                                east: "35.0",
                                south: "17.7",
                                west: "36.17",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 173,
                                status: "available",
                                block: 11,
                                unitSize: 866.01,
                                north: "32.7",
                                east: "30.0",
                                south: "25.03",
                                west: "31.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 174,
                                status: "available",
                                block: 12,
                                unitSize: 450.1,
                                north: "26.38",
                                east: "13.99",
                                south: "27.51",
                                west: "20.3",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 175,
                                status: "sold",
                                block: 12,
                                unitSize: 669.5,
                                north: "27.51",
                                east: "23.22",
                                south: "30.71",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 176,
                                status: "available",
                                block: 12,
                                unitSize: 406.16,
                                north: "18.56",
                                east: "20.3",
                                south: "18.0",
                                west: "24.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 177,
                                status: "sold",
                                block: 12,
                                unitSize: 414.0,
                                north: "18.0",
                                east: "23.0",
                                south: "18.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 178,
                                status: "sold",
                                block: 12,
                                unitSize: 310.82,
                                north: "15.47",
                                east: "18.83",
                                south: "15.0",
                                west: "22.61",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 179,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 180,
                                status: "available",
                                block: 12,
                                unitSize: 367.5,
                                north: "15.47",
                                east: "22.61",
                                south: "15.0",
                                west: "26.39",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 181,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 182,
                                status: "available",
                                block: 12,
                                unitSize: 424.19,
                                north: "15.47",
                                east: "26.39",
                                south: "15.0",
                                west: "30.17",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 183,
                                status: "available",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 184,
                                status: "available",
                                block: 12,
                                unitSize: 390.87,
                                north: "15.47",
                                east: "24.17",
                                south: "15.0",
                                west: "27.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 185,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 186,
                                status: "available",
                                block: 12,
                                unitSize: 447.56,
                                north: "15.47",
                                east: "27.95",
                                south: "15.0",
                                west: "31.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 187,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 188,
                                status: "available",
                                block: 12,
                                unitSize: 504.25,
                                north: "15.47",
                                east: "31.73",
                                south: "15.0",
                                west: "35.51",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 189,
                                status: "sold",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 190,
                                status: "available",
                                block: 12,
                                unitSize: 545.02,
                                north: "11.24",
                                east: "35.51",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 191,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 192,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 193,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 194,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 195,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 196,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 197,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 198,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "10",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 199,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 200,
                                status: "sold",
                                block: 13,
                                unitSize: 312.66,
                                north: "19.8",
                                east: "15.14",
                                south: "21.89",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 201,
                                status: "sold",
                                block: 13,
                                unitSize: 343.99,
                                north: "21.89",
                                east: "15.14",
                                south: "23.98",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 202,
                                status: "sold",
                                block: 13,
                                unitSize: 375.33,
                                north: "23.98",
                                east: "15.14",
                                south: "26.07",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 203,
                                status: "sold",
                                block: 13,
                                unitSize: 406.66,
                                north: "26.07",
                                east: "15.14",
                                south: "28.16",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 204,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 205,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 206,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 207,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 208,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 209,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 210,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 211,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 212,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 213,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 214,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 215,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 216,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 217,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 218,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 219,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 220,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 221,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 222,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 223,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 224,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 225,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 226,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 227,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 228,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 229,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 230,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 231,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 232,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 233,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 234,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 235,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 236,
                                status: "sold",
                                block: 14,
                                unitSize: 821.93,
                                north: "20.67",
                                east: "38.49",
                                south: "20.0",
                                west: "43.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 237,
                                status: "available",
                                block: 14,
                                unitSize: 926.37,
                                north: "20.67",
                                east: "43.71",
                                south: "20.0",
                                west: "48.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 238,
                                status: "available",
                                block: 14,
                                unitSize: 554.82,
                                north: "25.84",
                                east: "18.93",
                                south: "25.0",
                                west: "25.46",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 239,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 240,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 241,
                                status: "booked",
                                block: 14,
                                unitSize: 1350.0,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 242,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 243,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 244,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 245,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 246,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 247,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 248,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 249,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 250,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 251,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 252,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 253,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 254,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 255,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 256,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 257,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 258,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 259,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 260,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 261,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 262,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 263,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 264,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 265,
                                status: "available",
                                block: 15,
                                unitSize: 692.65,
                                north: "30.0",
                                east: "23.09",
                                south: "30.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 266,
                                status: "sold",
                                block: 15,
                                unitSize: 808.1,
                                north: "35.0",
                                east: "23.09",
                                south: "35.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 267,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 268,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 269,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 270,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 271,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 272,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 273,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 274,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 275,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 276,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 277,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 278,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 279,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 280,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 281,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 282,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 283,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 284,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 285,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 286,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 287,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 288,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 289,
                                status: "sold",
                                block: 17,
                                unitSize: 549.56,
                                north: "19.18",
                                east: "27.67",
                                south: "19.0",
                                west: "30.18",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 290,
                                status: "sold",
                                block: 17,
                                unitSize: 532.22,
                                north: "17.16",
                                east: "30.18",
                                south: "17.0",
                                west: "32.43",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 291,
                                status: "sold",
                                block: 17,
                                unitSize: 640.12,
                                north: "19.18",
                                east: "32.43",
                                south: "19.0",
                                west: "34.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 292,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 293,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 294,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 295,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 296,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 297,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 298,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 299,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 300,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 301,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 302,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 303,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 304,
                                status: "available",
                                block: 17,
                                unitSize: 500.01,
                                north: "25.0",
                                east: "20.0",
                                south: "25.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 305,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 306,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 307,
                                status: "available",
                                block: 17,
                                unitSize: 1000.02,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 308,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 309,
                                status: "sold",
                                block: 17,
                                unitSize: 832.14,
                                north: "30.0",
                                east: "24.32",
                                south: "24.29",
                                west: "26.35",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_27_310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 310,
                                status: "sold",
                                block: 17,
                                unitSize: 992.29,
                                north: "25.0",
                                east: "41.35",
                                south: "25.24",
                                west: "38.04",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 311,
                                status: "sold",
                                block: 18,
                                unitSize: 477.46,
                                north: "24.46",
                                east: "19.18",
                                south: "27.11",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 312,
                                status: "sold",
                                block: 18,
                                unitSize: 630.13,
                                north: "27.11",
                                east: "22.21",
                                south: "30.17",
                                west: "22.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 313,
                                status: "sold",
                                block: 18,
                                unitSize: 580.62,
                                north: "30.17",
                                east: "13.18",
                                south: "26.82",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 314,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 315,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 316,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 317,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 318,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 319,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 320,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 321,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 322,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 323,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 324,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 325,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 326,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 327,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 328,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 329,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 330,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 331,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 332,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 333,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 334,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 335,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 336,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 337,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 338,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 339,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 340,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 341,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 342,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 343,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 344,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 345,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 346,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 347,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 348,
                                status: "booked",
                                block: 20,
                                unitSize: 555.56,
                                north: "27.92",
                                east: "19.18",
                                south: "30.56",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 349,
                                status: "booked",
                                block: 20,
                                unitSize: 439.7,
                                north: "30.56",
                                east: "17.16",
                                south: "32.93",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 350,
                                status: "booked",
                                block: 20,
                                unitSize: 632.99,
                                north: "32.93",
                                east: "13.18",
                                south: "29.58",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 351,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 352,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 353,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 354,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 355,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 356,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 357,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 358,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 359,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 360,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 361,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 362,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 363,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 364,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 365,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 366,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 367,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 368,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_27_369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 369,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 370,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 371,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 372,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 373,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 374,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 375,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 376,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 377,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 378,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 379,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 380,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 381,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 382,
                                status: "sold",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 383,
                                status: "booked",
                                block: 21,
                                unitSize: 577.06,
                                north: "20.61",
                                east: "28.0",
                                south: "20.61",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 384,
                                status: "booked",
                                block: 21,
                                unitSize: 556.45,
                                north: "20.61",
                                east: "27.0",
                                south: "20.61",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 385,
                                status: "available",
                                block: 22,
                                unitSize: 913.53,
                                north: "28.36",
                                east: "30.29",
                                south: "32.54",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 386,
                                status: "available",
                                block: 22,
                                unitSize: 610.65,
                                north: "17.54",
                                east: "31.3",
                                south: "21.86",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 387,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 388,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 389,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 390,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 391,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 392,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 393,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 394,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 395,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 396,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 397,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 398,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 399,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 400,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 401,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 402,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 403,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 404,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 405,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 406,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 407,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 408,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 409,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 410,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 411,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 412,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 413,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 414,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 415,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 416,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 417,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 418,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 419,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 420,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 421,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 422,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 423,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 424,
                                status: "available",
                                block: 23,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 425,
                                status: "available",
                                block: 23,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 426,
                                status: "booked",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_27_427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 427,
                                status: "booked",
                                block: 24,
                                unitSize: 687.5,
                                north: "35.0",
                                east: "20.0",
                                south: "35.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_27_428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 428,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 429,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 430,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 431,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 432,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 433,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 434,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 435,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 436,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 437,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 438,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 439,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 440,
                                status: "available",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 441,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 442,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 443,
                                status: "available",
                                block: 24,
                                unitSize: 1200.0,
                                north: "30.0",
                                east: "40.0",
                                south: "30.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 444,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 445,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 446,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 447,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_27_448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 448,
                                status: "sold",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_27_449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 449,
                                status: "sold",
                                block: 24,
                                unitSize: 720.0,
                                north: "20.0",
                                east: "36.0",
                                south: "20.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    //index 62
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_62_3,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 3,
                                status: "sold",
                                block: 2,
                                unitSize: 564.04,
                                north: "32.47",
                                east: "17.91",
                                south: "30.76",
                                west: "17.85",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_4,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 4,
                                status: "sold",
                                block: 2,
                                unitSize: 450.51,
                                north: "30.76",
                                east: "15.0",
                                south: "29.31",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_5,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 5,
                                status: "sold",
                                block: 2,
                                unitSize: 428.78,
                                north: "29.31",
                                east: "15.0",
                                south: "27.86",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_6,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 6,
                                status: "sold",
                                block: 2,
                                unitSize: 407.05,
                                north: "27.86",
                                east: "15.0",
                                south: "26.41",
                                west: "15.07",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_7,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 7,
                                status: "sold",
                                block: 2,
                                unitSize: 494.09,
                                north: "15.0",
                                east: "32.97",
                                south: "15.0",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_8,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 8,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_9,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 9,
                                status: "sold",
                                block: 2,
                                unitSize: 495.05,
                                north: "15.0",
                                east: "33.04",
                                south: "15.0",
                                west: "32.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_10,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 10,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_11,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 11,
                                status: "sold",
                                block: 2,
                                unitSize: 496.01,
                                north: "15.0",
                                east: "33.1",
                                south: "15.0",
                                west: "33.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_12,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 12,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_13,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 13,
                                status: "sold",
                                block: 2,
                                unitSize: 496.97,
                                north: "15.0",
                                east: "33.16",
                                south: "15.0",
                                west: "33.1",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_14,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 14,
                                status: "sold",
                                block: 2,
                                unitSize: 676.9,
                                north: "27.43",
                                east: "31.54",
                                south: "17.7",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_15,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 15,
                                status: "sold",
                                block: 2,
                                unitSize: 591.7,
                                north: "23.22",
                                east: "34.97",
                                south: "12.43",
                                west: "33.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_16,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 16,
                                status: "available",
                                block: 3,
                                unitSize: 1505.24,
                                north: "41.34",
                                east: "40.21",
                                south: "41.44",
                                west: "28.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "40",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_17,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 17,
                                status: "available",
                                block: 3,
                                unitSize: 564.55,
                                north: "26.99",
                                east: "28.16",
                                south: "25.0",
                                west: "18.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_18,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 18,
                                status: "available",
                                block: 3,
                                unitSize: 1245.11,
                                north: "41.44",
                                east: "29.65",
                                south: "41.56",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_19,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 19,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_20,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 20,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_21,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 21,
                                status: "available",
                                block: 3,
                                unitSize: 1249.02,
                                north: "41.56",
                                east: "35.0",
                                south: "41.7",
                                west: "30.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_22,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 22,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_23,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 23,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_24,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 24,
                                status: "available",
                                block: 3,
                                unitSize: 1732.36,
                                north: "41.7",
                                east: "47.22",
                                south: "43.48",
                                west: "35.67",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_25,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 25,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_26,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 26,
                                status: "available",
                                block: 3,
                                unitSize: 430.75,
                                north: "25.0",
                                east: "20.67",
                                south: "25.93",
                                west: "13.79",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_27,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 27,
                                status: "sold",
                                block: 4,
                                unitSize: 410.76,
                                north: "20.26",
                                east: "19.02",
                                south: "17.7",
                                west: "32.38",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_28,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 28,
                                status: "available",
                                block: 4,
                                unitSize: 929.99,
                                north: "29.58",
                                east: "32.38",
                                south: "22.0",
                                west: "52.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_29,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 29,
                                status: "available",
                                block: 4,
                                unitSize: 1054.93,
                                north: "36.08",
                                east: "12.51",
                                south: "45.62",
                                west: "31.63",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_30,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 30,
                                status: "available",
                                block: 4,
                                unitSize: 946.77,
                                north: "45.62",
                                east: "19.64",
                                south: "45.41",
                                west: "21.99",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_31,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 31,
                                status: "available",
                                block: 4,
                                unitSize: 906.86,
                                north: "45.41",
                                east: "20.0",
                                south: "45.27",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_32,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 32,
                                status: "available",
                                block: 4,
                                unitSize: 821.97,
                                north: "39.7",
                                east: "20.19",
                                south: "42.5",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_33,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 33,
                                status: "available",
                                block: 4,
                                unitSize: 904.12,
                                north: "45.27",
                                east: "20.0",
                                south: "45.14",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_34,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 34,
                                status: "available",
                                block: 4,
                                unitSize: 877.85,
                                north: "42.5",
                                east: "20.19",
                                south: "45.29",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_35,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 35,
                                status: "available",
                                block: 4,
                                unitSize: 901.37,
                                north: "45.14",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_36,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 36,
                                status: "available",
                                block: 4,
                                unitSize: 695.31,
                                north: "15.29",
                                east: "40.41",
                                south: "20.06",
                                west: "37.29",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_37,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 37,
                                status: "available",
                                block: 4,
                                unitSize: 544.69,
                                north: "15.0",
                                east: "37.29",
                                south: "15.13",
                                west: "35.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_38,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 38,
                                status: "available",
                                block: 4,
                                unitSize: 515.29,
                                north: "15.0",
                                east: "35.33",
                                south: "15.13",
                                west: "33.37",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_39,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 39,
                                status: "sold",
                                block: 4,
                                unitSize: 485.88,
                                north: "15.0",
                                east: "33.37",
                                south: "15.13",
                                west: "31.41",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_40,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 40,
                                status: "sold",
                                block: 4,
                                unitSize: 456.48,
                                north: "15.0",
                                east: "31.41",
                                south: "15.13",
                                west: "29.45",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_41,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 41,
                                status: "sold",
                                block: 4,
                                unitSize: 424.48,
                                north: "15.0",
                                east: "29.45",
                                south: "14.94",
                                west: "27.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_42,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 42,
                                status: "sold",
                                block: 5,
                                unitSize: 437.58,
                                north: "15.32",
                                east: "28.7",
                                south: "15.17",
                                west: "28.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_43,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 43,
                                status: "sold",
                                block: 5,
                                unitSize: 430.6,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_44,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 44,
                                status: "sold",
                                block: 5,
                                unitSize: 430.62,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_45,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 45,
                                status: "sold",
                                block: 5,
                                unitSize: 430.65,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_46,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 46,
                                status: "available",
                                block: 5,
                                unitSize: 504.49,
                                north: "20.03",
                                east: "28.71",
                                south: "15.98",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_47,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 47,
                                status: "available",
                                block: 5,
                                unitSize: 597.19,
                                north: "35.17",
                                east: "17.0",
                                south: "35.09",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_48,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 48,
                                status: "available",
                                block: 5,
                                unitSize: 676.21,
                                north: "40.98",
                                east: "17.0",
                                south: "38.58",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_49,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 49,
                                status: "available",
                                block: 5,
                                unitSize: 595.73,
                                north: "35.09",
                                east: "17.0",
                                south: "35.0",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_50,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 50,
                                status: "available",
                                block: 5,
                                unitSize: 635.38,
                                north: "38.58",
                                east: "17.0",
                                south: "36.17",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_51,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 51,
                                status: "sold",
                                block: 5,
                                unitSize: 364.26,
                                north: "16.0",
                                east: "22.66",
                                south: "15.89",
                                west: "23.04",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_52,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 52,
                                status: "sold",
                                block: 5,
                                unitSize: 371.71,
                                north: "16.0",
                                east: "23.04",
                                south: "16.0",
                                west: "23.42",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_53,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 53,
                                status: "sold",
                                block: 5,
                                unitSize: 377.86,
                                north: "16.0",
                                east: "23.42",
                                south: "16.0",
                                west: "23.81",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_54,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 54,
                                status: "available",
                                block: 5,
                                unitSize: 516.4,
                                north: "23.17",
                                east: "23.81",
                                south: "19.75",
                                west: "24.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_62,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 62,
                                status: "sold",
                                block: 7,
                                unitSize: 543.18,
                                north: "19.11",
                                east: "26.89",
                                south: "21.69",
                                west: "26.58",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_63,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 63,
                                status: "sold",
                                block: 7,
                                unitSize: 406.04,
                                north: "21.69",
                                east: "18.08",
                                south: "23.43",
                                west: "18.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_64,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 64,
                                status: "sold",
                                block: 7,
                                unitSize: 437.33,
                                north: "23.43",
                                east: "18.08",
                                south: "25.16",
                                west: "18.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_65,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 65,
                                status: "sold",
                                block: 7,
                                unitSize: 488.24,
                                north: "15.0",
                                east: "32.58",
                                south: "15.0",
                                west: "32.52",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_66,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 66,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_67,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 67,
                                status: "sold",
                                block: 7,
                                unitSize: 752.78,
                                north: "26.06",
                                east: "32.52",
                                south: "20.31",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_68,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 68,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.0",
                                south: "15.0",
                                west: "30.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_69,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 69,
                                status: "available",
                                block: 7,
                                unitSize: 847.8,
                                north: "15.11",
                                east: "32.91",
                                south: "20.31",
                                west: "38.33",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_70,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 70,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.47",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_71,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 71,
                                status: "available",
                                block: 7,
                                unitSize: 589.54,
                                north: "15.12",
                                east: "38.33",
                                south: "15.0",
                                west: "40.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_72,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 72,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_73,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 73,
                                status: "available",
                                block: 7,
                                unitSize: 618.58,
                                north: "15.12",
                                east: "40.27",
                                south: "15.0",
                                west: "42.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_74,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 74,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_75,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 75,
                                status: "available",
                                block: 7,
                                unitSize: 647.61,
                                north: "15.12",
                                east: "42.21",
                                south: "15.0",
                                west: "44.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_76,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 76,
                                status: "available",
                                block: 7,
                                unitSize: 707.99,
                                north: "29.89",
                                east: "22.14",
                                south: "24.65",
                                west: "22.89",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_77,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 77,
                                status: "available",
                                block: 7,
                                unitSize: 443.39,
                                north: "24.65",
                                east: "22.0",
                                south: "15.65",
                                west: "23.77",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_78,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 78,
                                status: "available",
                                block: 7,
                                unitSize: 413.77,
                                north: "30.65",
                                east: "15.0",
                                south: "24.52",
                                west: "16.21",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_79,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 79,
                                status: "sold",
                                block: 7,
                                unitSize: 321.7,
                                north: "24.52",
                                east: "15.0",
                                south: "18.38",
                                west: "16.21",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_80,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 80,
                                status: "sold",
                                block: 8,
                                unitSize: 330.17,
                                north: "15.7",
                                east: "20.29",
                                south: "15.87",
                                west: "21.63",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_81,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 81,
                                status: "sold",
                                block: 8,
                                unitSize: 334.03,
                                north: "15.05",
                                east: "21.63",
                                south: "15.0",
                                west: "22.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_82,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 82,
                                status: "available",
                                block: 8,
                                unitSize: 401.64,
                                north: "23.2",
                                east: "22.91",
                                south: "11.0",
                                west: "27.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_83,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 83,
                                status: "available",
                                block: 8,
                                unitSize: 828.76,
                                north: "41.87",
                                east: "18.35",
                                south: "22.23",
                                west: "40.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_84,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 84,
                                status: "available",
                                block: 8,
                                unitSize: 462.59,
                                north: "18.92",
                                east: "27.68",
                                south: "12.0",
                                west: "34.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_85,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 85,
                                status: "available",
                                block: 8,
                                unitSize: 358.81,
                                north: "17.29",
                                east: "19.21",
                                south: "16.0",
                                west: "25.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_86,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 86,
                                status: "sold",
                                block: 8,
                                unitSize: 494.08,
                                north: "18.37",
                                east: "25.64",
                                south: "17.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_87,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 87,
                                status: "available",
                                block: 8,
                                unitSize: 495.01,
                                north: "33.0",
                                east: "15.0",
                                south: "33.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_88,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 88,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_89,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 89,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_90,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 90,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_91,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 91,
                                status: "available",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_92,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 92,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_93,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 93,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_94,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 94,
                                status: "sold",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_95,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 95,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_96,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 96,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_97,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 97,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_98,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 98,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_99,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 99,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 100,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 101,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 102,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 103,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 104,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 105,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 106,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 107,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 108,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 109,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 110,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 111,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 112,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 113,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 114,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 115,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 116,
                                status: "sold",
                                block: 9,
                                unitSize: 774.58,
                                north: "22.0",
                                east: "36.66",
                                south: "22.21",
                                west: "33.75",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 117,
                                status: "sold",
                                block: 9,
                                unitSize: 679.6,
                                north: "21.0",
                                east: "33.75",
                                south: "21.2",
                                west: "30.97",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 118,
                                status: "sold",
                                block: 9,
                                unitSize: 649.33,
                                north: "22.0",
                                east: "30.97",
                                south: "22.21",
                                west: "28.06",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 119,
                                status: "sold",
                                block: 10,
                                unitSize: 529.13,
                                north: "21.58",
                                east: "22.47",
                                south: "20.0",
                                west: "30.44",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 120,
                                status: "available",
                                block: 10,
                                unitSize: 575.11,
                                north: "18.34",
                                east: "30.44",
                                south: "17.0",
                                west: "37.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 121,
                                status: "available",
                                block: 10,
                                unitSize: 734.49,
                                north: "19.42",
                                east: "37.22",
                                south: "18.0",
                                west: "44.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 122,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 123,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 124,
                                status: "available",
                                block: 10,
                                unitSize: 1107.03,
                                north: "27.0",
                                east: "41.0",
                                south: "27.0",
                                west: "41.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 125,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 126,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 127,
                                status: "available",
                                block: 10,
                                unitSize: 513.01,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 128,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 129,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 130,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 131,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 132,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 133,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 134,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 135,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 136,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 137,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 138,
                                status: "sold",
                                block: 10,
                                unitSize: 596.35,
                                north: "28.0",
                                east: "23.15",
                                south: "28.27",
                                west: "19.44",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 139,
                                status: "sold",
                                block: 10,
                                unitSize: 476.74,
                                north: "27.0",
                                east: "19.44",
                                south: "27.26",
                                west: "15.87",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 140,
                                status: "available",
                                block: 11,
                                unitSize: 868.22,
                                north: "17.66",
                                east: "37.77",
                                south: "31.96",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 141,
                                status: "available",
                                block: 11,
                                unitSize: 692.6,
                                north: "16.96",
                                east: "32.37",
                                south: "29.22",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 142,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 143,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 144,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 145,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 146,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 147,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 148,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 149,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 150,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 151,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 152,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 153,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 154,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 155,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 156,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 157,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 158,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 159,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 160,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 161,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 162,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 163,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 164,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 165,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 166,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 167,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 168,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 169,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 170,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 171,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 172,
                                status: "available",
                                block: 11,
                                unitSize: 776.21,
                                north: "26.65",
                                east: "35.0",
                                south: "17.7",
                                west: "36.17",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 173,
                                status: "available",
                                block: 11,
                                unitSize: 866.01,
                                north: "32.7",
                                east: "30.0",
                                south: "25.03",
                                west: "31.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 174,
                                status: "available",
                                block: 12,
                                unitSize: 450.1,
                                north: "26.38",
                                east: "13.99",
                                south: "27.51",
                                west: "20.3",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 175,
                                status: "sold",
                                block: 12,
                                unitSize: 669.5,
                                north: "27.51",
                                east: "23.22",
                                south: "30.71",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 176,
                                status: "available",
                                block: 12,
                                unitSize: 406.16,
                                north: "18.56",
                                east: "20.3",
                                south: "18.0",
                                west: "24.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 177,
                                status: "sold",
                                block: 12,
                                unitSize: 414.0,
                                north: "18.0",
                                east: "23.0",
                                south: "18.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 178,
                                status: "sold",
                                block: 12,
                                unitSize: 310.82,
                                north: "15.47",
                                east: "18.83",
                                south: "15.0",
                                west: "22.61",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 179,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 180,
                                status: "available",
                                block: 12,
                                unitSize: 367.5,
                                north: "15.47",
                                east: "22.61",
                                south: "15.0",
                                west: "26.39",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 181,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 182,
                                status: "available",
                                block: 12,
                                unitSize: 424.19,
                                north: "15.47",
                                east: "26.39",
                                south: "15.0",
                                west: "30.17",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 183,
                                status: "available",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 184,
                                status: "available",
                                block: 12,
                                unitSize: 390.87,
                                north: "15.47",
                                east: "24.17",
                                south: "15.0",
                                west: "27.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 185,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 186,
                                status: "available",
                                block: 12,
                                unitSize: 447.56,
                                north: "15.47",
                                east: "27.95",
                                south: "15.0",
                                west: "31.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 187,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 188,
                                status: "available",
                                block: 12,
                                unitSize: 504.25,
                                north: "15.47",
                                east: "31.73",
                                south: "15.0",
                                west: "35.51",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 189,
                                status: "sold",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 190,
                                status: "available",
                                block: 12,
                                unitSize: 545.02,
                                north: "11.24",
                                east: "35.51",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 191,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 192,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 193,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 194,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 195,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 196,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 197,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 198,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "10",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 199,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 200,
                                status: "sold",
                                block: 13,
                                unitSize: 312.66,
                                north: "19.8",
                                east: "15.14",
                                south: "21.89",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 201,
                                status: "sold",
                                block: 13,
                                unitSize: 343.99,
                                north: "21.89",
                                east: "15.14",
                                south: "23.98",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 202,
                                status: "sold",
                                block: 13,
                                unitSize: 375.33,
                                north: "23.98",
                                east: "15.14",
                                south: "26.07",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 203,
                                status: "sold",
                                block: 13,
                                unitSize: 406.66,
                                north: "26.07",
                                east: "15.14",
                                south: "28.16",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 204,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 205,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 206,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 207,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 208,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 209,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 210,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 211,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 212,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 213,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 214,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 215,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 216,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 217,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 218,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 219,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 220,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 221,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 222,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 223,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 224,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 225,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 226,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 227,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 228,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 229,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 230,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 231,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 232,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 233,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 234,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 235,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 236,
                                status: "sold",
                                block: 14,
                                unitSize: 821.93,
                                north: "20.67",
                                east: "38.49",
                                south: "20.0",
                                west: "43.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 237,
                                status: "available",
                                block: 14,
                                unitSize: 926.37,
                                north: "20.67",
                                east: "43.71",
                                south: "20.0",
                                west: "48.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 238,
                                status: "available",
                                block: 14,
                                unitSize: 554.82,
                                north: "25.84",
                                east: "18.93",
                                south: "25.0",
                                west: "25.46",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 239,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 240,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 241,
                                status: "booked",
                                block: 14,
                                unitSize: 1350.0,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 242,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 243,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 244,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 245,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 246,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 247,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 248,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 249,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 250,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 251,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 252,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 253,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 254,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 255,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 256,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 257,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 258,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 259,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 260,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 261,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 262,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 263,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 264,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 265,
                                status: "available",
                                block: 15,
                                unitSize: 692.65,
                                north: "30.0",
                                east: "23.09",
                                south: "30.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 266,
                                status: "sold",
                                block: 15,
                                unitSize: 808.1,
                                north: "35.0",
                                east: "23.09",
                                south: "35.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 267,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 268,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 269,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 270,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 271,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 272,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 273,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 274,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 275,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 276,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 277,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 278,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 279,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 280,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 281,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 282,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 283,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 284,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 285,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 286,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 287,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 288,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 289,
                                status: "sold",
                                block: 17,
                                unitSize: 549.56,
                                north: "19.18",
                                east: "27.67",
                                south: "19.0",
                                west: "30.18",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 290,
                                status: "sold",
                                block: 17,
                                unitSize: 532.22,
                                north: "17.16",
                                east: "30.18",
                                south: "17.0",
                                west: "32.43",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 291,
                                status: "sold",
                                block: 17,
                                unitSize: 640.12,
                                north: "19.18",
                                east: "32.43",
                                south: "19.0",
                                west: "34.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 292,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 293,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 294,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 295,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 296,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 297,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 298,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 299,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 300,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 301,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 302,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 303,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 304,
                                status: "available",
                                block: 17,
                                unitSize: 500.01,
                                north: "25.0",
                                east: "20.0",
                                south: "25.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 305,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 306,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 307,
                                status: "available",
                                block: 17,
                                unitSize: 1000.02,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 308,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 309,
                                status: "sold",
                                block: 17,
                                unitSize: 832.14,
                                north: "30.0",
                                east: "24.32",
                                south: "24.29",
                                west: "26.35",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_62_310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 310,
                                status: "sold",
                                block: 17,
                                unitSize: 992.29,
                                north: "25.0",
                                east: "41.35",
                                south: "25.24",
                                west: "38.04",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 311,
                                status: "sold",
                                block: 18,
                                unitSize: 477.46,
                                north: "24.46",
                                east: "19.18",
                                south: "27.11",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 312,
                                status: "sold",
                                block: 18,
                                unitSize: 630.13,
                                north: "27.11",
                                east: "22.21",
                                south: "30.17",
                                west: "22.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 313,
                                status: "sold",
                                block: 18,
                                unitSize: 580.62,
                                north: "30.17",
                                east: "13.18",
                                south: "26.82",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 314,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 315,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 316,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 317,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 318,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 319,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 320,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 321,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 322,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 323,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 324,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 325,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 326,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 327,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 328,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 329,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 330,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 331,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 332,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 333,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 334,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 335,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 336,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 337,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 338,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 339,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 340,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 341,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 342,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 343,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 344,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 345,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 346,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 347,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 348,
                                status: "booked",
                                block: 20,
                                unitSize: 555.56,
                                north: "27.92",
                                east: "19.18",
                                south: "30.56",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 349,
                                status: "booked",
                                block: 20,
                                unitSize: 439.7,
                                north: "30.56",
                                east: "17.16",
                                south: "32.93",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 350,
                                status: "booked",
                                block: 20,
                                unitSize: 632.99,
                                north: "32.93",
                                east: "13.18",
                                south: "29.58",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 351,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 352,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 353,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 354,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 355,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 356,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 357,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 358,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 359,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 360,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 361,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 362,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 363,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 364,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 365,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 366,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 367,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 368,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_62_369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 369,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 370,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 371,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 372,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 373,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 374,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 375,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 376,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 377,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 378,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 379,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 380,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 381,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 382,
                                status: "sold",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 383,
                                status: "booked",
                                block: 21,
                                unitSize: 577.06,
                                north: "20.61",
                                east: "28.0",
                                south: "20.61",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 384,
                                status: "booked",
                                block: 21,
                                unitSize: 556.45,
                                north: "20.61",
                                east: "27.0",
                                south: "20.61",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 385,
                                status: "available",
                                block: 22,
                                unitSize: 913.53,
                                north: "28.36",
                                east: "30.29",
                                south: "32.54",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 386,
                                status: "available",
                                block: 22,
                                unitSize: 610.65,
                                north: "17.54",
                                east: "31.3",
                                south: "21.86",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 387,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 388,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 389,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 390,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 391,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 392,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 393,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 394,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 395,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 396,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 397,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 398,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 399,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 400,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 401,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 402,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 403,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 404,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 405,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 406,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 407,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 408,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 409,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 410,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 411,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 412,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 413,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 414,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 415,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 416,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 417,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 418,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 419,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 420,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 421,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 422,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 423,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 424,
                                status: "available",
                                block: 23,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 425,
                                status: "available",
                                block: 23,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 426,
                                status: "booked",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_62_427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 427,
                                status: "booked",
                                block: 24,
                                unitSize: 687.5,
                                north: "35.0",
                                east: "20.0",
                                south: "35.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_62_428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 428,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 429,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 430,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 431,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 432,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 433,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 434,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 435,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 436,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 437,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 438,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 439,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 440,
                                status: "available",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 441,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 442,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 443,
                                status: "available",
                                block: 24,
                                unitSize: 1200.0,
                                north: "30.0",
                                east: "40.0",
                                south: "30.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 444,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 445,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 446,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 447,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_62_448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 448,
                                status: "sold",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_62_449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 449,
                                status: "sold",
                                block: 24,
                                unitSize: 720.0,
                                north: "20.0",
                                east: "36.0",
                                south: "20.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    //index 89
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_89_3,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 3,
                                status: "sold",
                                block: 2,
                                unitSize: 564.04,
                                north: "32.47",
                                east: "17.91",
                                south: "30.76",
                                west: "17.85",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_4,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 4,
                                status: "sold",
                                block: 2,
                                unitSize: 450.51,
                                north: "30.76",
                                east: "15.0",
                                south: "29.31",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_5,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 5,
                                status: "sold",
                                block: 2,
                                unitSize: 428.78,
                                north: "29.31",
                                east: "15.0",
                                south: "27.86",
                                west: "15.07",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_6,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 6,
                                status: "sold",
                                block: 2,
                                unitSize: 407.05,
                                north: "27.86",
                                east: "15.0",
                                south: "26.41",
                                west: "15.07",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_7,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 7,
                                status: "sold",
                                block: 2,
                                unitSize: 494.09,
                                north: "15.0",
                                east: "32.97",
                                south: "15.0",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_8,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 8,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_9,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 9,
                                status: "sold",
                                block: 2,
                                unitSize: 495.05,
                                north: "15.0",
                                east: "33.04",
                                south: "15.0",
                                west: "32.97",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_10,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 10,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_11,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 11,
                                status: "sold",
                                block: 2,
                                unitSize: 496.01,
                                north: "15.0",
                                east: "33.1",
                                south: "15.0",
                                west: "33.04",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_12,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 12,
                                status: "sold",
                                block: 2,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_13,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 13,
                                status: "sold",
                                block: 2,
                                unitSize: 496.97,
                                north: "15.0",
                                east: "33.16",
                                south: "15.0",
                                west: "33.1",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_14,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 14,
                                status: "sold",
                                block: 2,
                                unitSize: 676.9,
                                north: "27.43",
                                east: "31.54",
                                south: "17.7",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_15,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 15,
                                status: "sold",
                                block: 2,
                                unitSize: 591.7,
                                north: "23.22",
                                east: "34.97",
                                south: "12.43",
                                west: "33.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_16,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 16,
                                status: "available",
                                block: 3,
                                unitSize: 1505.24,
                                north: "41.34",
                                east: "40.21",
                                south: "41.44",
                                west: "28.16",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "40",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_17,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 17,
                                status: "available",
                                block: 3,
                                unitSize: 564.55,
                                north: "26.99",
                                east: "28.16",
                                south: "25.0",
                                west: "18.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_18,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 18,
                                status: "available",
                                block: 3,
                                unitSize: 1245.11,
                                north: "41.44",
                                east: "29.65",
                                south: "41.56",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_19,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 19,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_20,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 20,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_21,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 21,
                                status: "available",
                                block: 3,
                                unitSize: 1249.02,
                                north: "41.56",
                                east: "35.0",
                                south: "41.7",
                                west: "30.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_22,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 22,
                                status: "sold",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_23,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 23,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_24,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 24,
                                status: "available",
                                block: 3,
                                unitSize: 1732.36,
                                north: "41.7",
                                east: "47.22",
                                south: "43.48",
                                west: "35.67",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_25,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 25,
                                status: "available",
                                block: 3,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_26,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 26,
                                status: "available",
                                block: 3,
                                unitSize: 430.75,
                                north: "25.0",
                                east: "20.67",
                                south: "25.93",
                                west: "13.79",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_27,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 27,
                                status: "sold",
                                block: 4,
                                unitSize: 410.76,
                                north: "20.26",
                                east: "19.02",
                                south: "17.7",
                                west: "32.38",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_28,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 28,
                                status: "available",
                                block: 4,
                                unitSize: 929.99,
                                north: "29.58",
                                east: "32.38",
                                south: "22.0",
                                west: "52.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_29,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 29,
                                status: "available",
                                block: 4,
                                unitSize: 1054.93,
                                north: "36.08",
                                east: "12.51",
                                south: "45.62",
                                west: "31.63",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_30,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 30,
                                status: "available",
                                block: 4,
                                unitSize: 946.77,
                                north: "45.62",
                                east: "19.64",
                                south: "45.41",
                                west: "21.99",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_31,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 31,
                                status: "available",
                                block: 4,
                                unitSize: 906.86,
                                north: "45.41",
                                east: "20.0",
                                south: "45.27",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_32,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 32,
                                status: "available",
                                block: 4,
                                unitSize: 821.97,
                                north: "39.7",
                                east: "20.19",
                                south: "42.5",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_33,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 33,
                                status: "available",
                                block: 4,
                                unitSize: 904.12,
                                north: "45.27",
                                east: "20.0",
                                south: "45.14",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_34,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 34,
                                status: "available",
                                block: 4,
                                unitSize: 877.85,
                                north: "42.5",
                                east: "20.19",
                                south: "45.29",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_35,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 35,
                                status: "available",
                                block: 4,
                                unitSize: 901.37,
                                north: "45.14",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_36,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 36,
                                status: "available",
                                block: 4,
                                unitSize: 695.31,
                                north: "15.29",
                                east: "40.41",
                                south: "20.06",
                                west: "37.29",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_37,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 37,
                                status: "available",
                                block: 4,
                                unitSize: 544.69,
                                north: "15.0",
                                east: "37.29",
                                south: "15.13",
                                west: "35.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_38,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 38,
                                status: "available",
                                block: 4,
                                unitSize: 515.29,
                                north: "15.0",
                                east: "35.33",
                                south: "15.13",
                                west: "33.37",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_39,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 39,
                                status: "sold",
                                block: 4,
                                unitSize: 485.88,
                                north: "15.0",
                                east: "33.37",
                                south: "15.13",
                                west: "31.41",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_40,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 40,
                                status: "sold",
                                block: 4,
                                unitSize: 456.48,
                                north: "15.0",
                                east: "31.41",
                                south: "15.13",
                                west: "29.45",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_41,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 41,
                                status: "sold",
                                block: 4,
                                unitSize: 424.48,
                                north: "15.0",
                                east: "29.45",
                                south: "14.94",
                                west: "27.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_42,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 42,
                                status: "sold",
                                block: 5,
                                unitSize: 437.58,
                                north: "15.32",
                                east: "28.7",
                                south: "15.17",
                                west: "28.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_43,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 43,
                                status: "sold",
                                block: 5,
                                unitSize: 430.6,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_44,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 44,
                                status: "sold",
                                block: 5,
                                unitSize: 430.62,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_45,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 45,
                                status: "sold",
                                block: 5,
                                unitSize: 430.65,
                                north: "15.0",
                                east: "28.71",
                                south: "15.0",
                                west: "28.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_46,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 46,
                                status: "available",
                                block: 5,
                                unitSize: 504.49,
                                north: "20.03",
                                east: "28.71",
                                south: "15.98",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_47,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 47,
                                status: "available",
                                block: 5,
                                unitSize: 597.19,
                                north: "35.17",
                                east: "17.0",
                                south: "35.09",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_48,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 48,
                                status: "available",
                                block: 5,
                                unitSize: 676.21,
                                north: "40.98",
                                east: "17.0",
                                south: "38.58",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_49,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 49,
                                status: "available",
                                block: 5,
                                unitSize: 595.73,
                                north: "35.09",
                                east: "17.0",
                                south: "35.0",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_50,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 50,
                                status: "available",
                                block: 5,
                                unitSize: 635.38,
                                north: "38.58",
                                east: "17.0",
                                south: "36.17",
                                west: "17.17",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_51,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 51,
                                status: "sold",
                                block: 5,
                                unitSize: 364.26,
                                north: "16.0",
                                east: "22.66",
                                south: "15.89",
                                west: "23.04",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_52,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 52,
                                status: "sold",
                                block: 5,
                                unitSize: 371.71,
                                north: "16.0",
                                east: "23.04",
                                south: "16.0",
                                west: "23.42",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_53,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 53,
                                status: "sold",
                                block: 5,
                                unitSize: 377.86,
                                north: "16.0",
                                east: "23.42",
                                south: "16.0",
                                west: "23.81",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_54,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 54,
                                status: "available",
                                block: 5,
                                unitSize: 516.4,
                                north: "23.17",
                                east: "23.81",
                                south: "19.75",
                                west: "24.52",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_62,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 62,
                                status: "sold",
                                block: 7,
                                unitSize: 543.18,
                                north: "19.11",
                                east: "26.89",
                                south: "21.69",
                                west: "26.58",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_63,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 63,
                                status: "sold",
                                block: 7,
                                unitSize: 406.04,
                                north: "21.69",
                                east: "18.08",
                                south: "23.43",
                                west: "18.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_64,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 64,
                                status: "sold",
                                block: 7,
                                unitSize: 437.33,
                                north: "23.43",
                                east: "18.08",
                                south: "25.16",
                                west: "18.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_65,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 65,
                                status: "sold",
                                block: 7,
                                unitSize: 488.24,
                                north: "15.0",
                                east: "32.58",
                                south: "15.0",
                                west: "32.52",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_66,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 66,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_67,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 67,
                                status: "sold",
                                block: 7,
                                unitSize: 752.78,
                                north: "26.06",
                                east: "32.52",
                                south: "20.31",
                                west: "32.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_68,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 68,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.0",
                                south: "15.0",
                                west: "30.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_69,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 69,
                                status: "available",
                                block: 7,
                                unitSize: 847.8,
                                north: "15.11",
                                east: "32.91",
                                south: "20.31",
                                west: "38.33",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_70,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 70,
                                status: "available",
                                block: 7,
                                unitSize: 529.72,
                                north: "20.31",
                                east: "30.47",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_71,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 71,
                                status: "available",
                                block: 7,
                                unitSize: 589.54,
                                north: "15.12",
                                east: "38.33",
                                south: "15.0",
                                west: "40.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_72,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 72,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_73,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 73,
                                status: "available",
                                block: 7,
                                unitSize: 618.58,
                                north: "15.12",
                                east: "40.27",
                                south: "15.0",
                                west: "42.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_74,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 74,
                                status: "available",
                                block: 7,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_75,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 75,
                                status: "available",
                                block: 7,
                                unitSize: 647.61,
                                north: "15.12",
                                east: "42.21",
                                south: "15.0",
                                west: "44.14",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "40",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_76,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 76,
                                status: "available",
                                block: 7,
                                unitSize: 707.99,
                                north: "29.89",
                                east: "22.14",
                                south: "24.65",
                                west: "22.89",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_77,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 77,
                                status: "available",
                                block: 7,
                                unitSize: 443.39,
                                north: "24.65",
                                east: "22.0",
                                south: "15.65",
                                west: "23.77",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_78,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 78,
                                status: "available",
                                block: 7,
                                unitSize: 413.77,
                                north: "30.65",
                                east: "15.0",
                                south: "24.52",
                                west: "16.21",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_79,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 79,
                                status: "sold",
                                block: 7,
                                unitSize: 321.7,
                                north: "24.52",
                                east: "15.0",
                                south: "18.38",
                                west: "16.21",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_80,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 80,
                                status: "sold",
                                block: 8,
                                unitSize: 330.17,
                                north: "15.7",
                                east: "20.29",
                                south: "15.87",
                                west: "21.63",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_81,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 81,
                                status: "sold",
                                block: 8,
                                unitSize: 334.03,
                                north: "15.05",
                                east: "21.63",
                                south: "15.0",
                                west: "22.91",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_82,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 82,
                                status: "available",
                                block: 8,
                                unitSize: 401.64,
                                north: "23.2",
                                east: "22.91",
                                south: "11.0",
                                west: "27.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_83,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 83,
                                status: "available",
                                block: 8,
                                unitSize: 828.76,
                                north: "41.87",
                                east: "18.35",
                                south: "22.23",
                                west: "40.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_84,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 84,
                                status: "available",
                                block: 8,
                                unitSize: 462.59,
                                north: "18.92",
                                east: "27.68",
                                south: "12.0",
                                west: "34.21",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_85,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 85,
                                status: "available",
                                block: 8,
                                unitSize: 358.81,
                                north: "17.29",
                                east: "19.21",
                                south: "16.0",
                                west: "25.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_86,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 86,
                                status: "sold",
                                block: 8,
                                unitSize: 494.08,
                                north: "18.37",
                                east: "25.64",
                                south: "17.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_87,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 87,
                                status: "available",
                                block: 8,
                                unitSize: 495.01,
                                north: "33.0",
                                east: "15.0",
                                south: "33.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_88,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 88,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_89,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 89,
                                status: "available",
                                block: 8,
                                unitSize: 900.02,
                                north: "45.0",
                                east: "20.0",
                                south: "45.0",
                                west: "20.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_90,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 90,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_91,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 91,
                                status: "available",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_92,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 92,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_93,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 93,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_94,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 94,
                                status: "sold",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_95,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 95,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_96,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 96,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_97,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 97,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_98,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 98,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_99,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 99,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_100,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 100,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_101,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 101,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_102,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 102,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_103,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 103,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_104,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 104,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_105,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 105,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_106,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 106,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_107,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 107,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_108,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 108,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_109,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 109,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_110,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 110,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_111,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 111,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_112,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 112,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_113,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 113,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_114,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 114,
                                status: "available",
                                block: 9,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_115,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 115,
                                status: "sold",
                                block: 9,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_116,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 116,
                                status: "sold",
                                block: 9,
                                unitSize: 774.58,
                                north: "22.0",
                                east: "36.66",
                                south: "22.21",
                                west: "33.75",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_117,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 117,
                                status: "sold",
                                block: 9,
                                unitSize: 679.6,
                                north: "21.0",
                                east: "33.75",
                                south: "21.2",
                                west: "30.97",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_118,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 118,
                                status: "sold",
                                block: 9,
                                unitSize: 649.33,
                                north: "22.0",
                                east: "30.97",
                                south: "22.21",
                                west: "28.06",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_119,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 119,
                                status: "sold",
                                block: 10,
                                unitSize: 529.13,
                                north: "21.58",
                                east: "22.47",
                                south: "20.0",
                                west: "30.44",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_120,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 120,
                                status: "available",
                                block: 10,
                                unitSize: 575.11,
                                north: "18.34",
                                east: "30.44",
                                south: "17.0",
                                west: "37.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_121,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 121,
                                status: "available",
                                block: 10,
                                unitSize: 734.49,
                                north: "19.42",
                                east: "37.22",
                                south: "18.0",
                                west: "44.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_122,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 122,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_123,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 123,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_124,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 124,
                                status: "available",
                                block: 10,
                                unitSize: 1107.03,
                                north: "27.0",
                                east: "41.0",
                                south: "27.0",
                                west: "41.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_125,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 125,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_126,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 126,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_127,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 127,
                                status: "available",
                                block: 10,
                                unitSize: 513.01,
                                north: "27.0",
                                east: "19.0",
                                south: "27.0",
                                west: "19.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_128,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 128,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_129,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 129,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_130,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 130,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_131,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 131,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_132,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 132,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_133,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 133,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_134,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 134,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_135,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 135,
                                status: "sold",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_136,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 136,
                                status: "sold",
                                block: 10,
                                unitSize: 420.01,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_137,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 137,
                                status: "available",
                                block: 10,
                                unitSize: 405.01,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_138,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 138,
                                status: "sold",
                                block: 10,
                                unitSize: 596.35,
                                north: "28.0",
                                east: "23.15",
                                south: "28.27",
                                west: "19.44",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_139,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 139,
                                status: "sold",
                                block: 10,
                                unitSize: 476.74,
                                north: "27.0",
                                east: "19.44",
                                south: "27.26",
                                west: "15.87",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_140,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 140,
                                status: "available",
                                block: 11,
                                unitSize: 868.22,
                                north: "17.66",
                                east: "37.77",
                                south: "31.96",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_141,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 141,
                                status: "available",
                                block: 11,
                                unitSize: 692.6,
                                north: "16.96",
                                east: "32.37",
                                south: "29.22",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_142,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 142,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_143,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 143,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_144,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 144,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_145,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 145,
                                status: "booked",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_146,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 146,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_147,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 147,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_148,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 148,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_149,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 149,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_150,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 150,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_151,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 151,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_152,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 152,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_153,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 153,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_154,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 154,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_155,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 155,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_156,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 156,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_157,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 157,
                                status: "available",
                                block: 11,
                                unitSize: 518.75,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_158,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 158,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_159,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 159,
                                status: "available",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_160,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 160,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_161,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 161,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_162,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 162,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_163,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 163,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 164,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 165,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 166,
                                status: "available",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 167,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 168,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 169,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 170,
                                status: "sold",
                                block: 11,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 171,
                                status: "sold",
                                block: 11,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 172,
                                status: "available",
                                block: 11,
                                unitSize: 776.21,
                                north: "26.65",
                                east: "35.0",
                                south: "17.7",
                                west: "36.17",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 173,
                                status: "available",
                                block: 11,
                                unitSize: 866.01,
                                north: "32.7",
                                east: "30.0",
                                south: "25.03",
                                west: "31.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 174,
                                status: "available",
                                block: 12,
                                unitSize: 450.1,
                                north: "26.38",
                                east: "13.99",
                                south: "27.51",
                                west: "20.3",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 175,
                                status: "sold",
                                block: 12,
                                unitSize: 669.5,
                                north: "27.51",
                                east: "23.22",
                                south: "30.71",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 176,
                                status: "available",
                                block: 12,
                                unitSize: 406.16,
                                north: "18.56",
                                east: "20.3",
                                south: "18.0",
                                west: "24.83",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 177,
                                status: "sold",
                                block: 12,
                                unitSize: 414.0,
                                north: "18.0",
                                east: "23.0",
                                south: "18.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 178,
                                status: "sold",
                                block: 12,
                                unitSize: 310.82,
                                north: "15.47",
                                east: "18.83",
                                south: "15.0",
                                west: "22.61",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 179,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 180,
                                status: "available",
                                block: 12,
                                unitSize: 367.5,
                                north: "15.47",
                                east: "22.61",
                                south: "15.0",
                                west: "26.39",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 181,
                                status: "sold",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 182,
                                status: "available",
                                block: 12,
                                unitSize: 424.19,
                                north: "15.47",
                                east: "26.39",
                                south: "15.0",
                                west: "30.17",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 183,
                                status: "available",
                                block: 12,
                                unitSize: 435.0,
                                north: "15.0",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 184,
                                status: "available",
                                block: 12,
                                unitSize: 390.87,
                                north: "15.47",
                                east: "24.17",
                                south: "15.0",
                                west: "27.95",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 185,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 186,
                                status: "available",
                                block: 12,
                                unitSize: 447.56,
                                north: "15.47",
                                east: "27.95",
                                south: "15.0",
                                west: "31.73",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 187,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 188,
                                status: "available",
                                block: 12,
                                unitSize: 504.25,
                                north: "15.47",
                                east: "31.73",
                                south: "15.0",
                                west: "35.51",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 189,
                                status: "sold",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 190,
                                status: "available",
                                block: 12,
                                unitSize: 545.02,
                                north: "11.24",
                                east: "35.51",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 191,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 192,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 193,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 194,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 195,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 196,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 197,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 198,
                                status: "available",
                                block: 12,
                                unitSize: 546.8,
                                north: "15.0",
                                east: "36.45",
                                south: "15.0",
                                west: "36.45",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "10",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 199,
                                status: "available",
                                block: 12,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 200,
                                status: "sold",
                                block: 13,
                                unitSize: 312.66,
                                north: "19.8",
                                east: "15.14",
                                south: "21.89",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 201,
                                status: "sold",
                                block: 13,
                                unitSize: 343.99,
                                north: "21.89",
                                east: "15.14",
                                south: "23.98",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 202,
                                status: "sold",
                                block: 13,
                                unitSize: 375.33,
                                north: "23.98",
                                east: "15.14",
                                south: "26.07",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 203,
                                status: "sold",
                                block: 13,
                                unitSize: 406.66,
                                north: "26.07",
                                east: "15.14",
                                south: "28.16",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 204,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 205,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 206,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 207,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 208,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 209,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 210,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 211,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 212,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 213,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 214,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 215,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 216,
                                status: "available",
                                block: 13,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 217,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 218,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 219,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 220,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 221,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 222,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 223,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 224,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 225,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 226,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 227,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 228,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 229,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 230,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 231,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 232,
                                status: "available",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 233,
                                status: "sold",
                                block: 13,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 234,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 235,
                                status: "sold",
                                block: 13,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 236,
                                status: "sold",
                                block: 14,
                                unitSize: 821.93,
                                north: "20.67",
                                east: "38.49",
                                south: "20.0",
                                west: "43.71",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 237,
                                status: "available",
                                block: 14,
                                unitSize: 926.37,
                                north: "20.67",
                                east: "43.71",
                                south: "20.0",
                                west: "48.93",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 238,
                                status: "available",
                                block: 14,
                                unitSize: 554.82,
                                north: "25.84",
                                east: "18.93",
                                south: "25.0",
                                west: "25.46",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 239,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 240,
                                status: "sold",
                                block: 14,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 241,
                                status: "booked",
                                block: 14,
                                unitSize: 1350.0,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 242,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 243,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 244,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 245,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 246,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 247,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 248,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 249,
                                status: "available",
                                block: 14,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 250,
                                status: "available",
                                block: 14,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 251,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 252,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 253,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 254,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 255,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 256,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 257,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 258,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 259,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 260,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 261,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 262,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 263,
                                status: "available",
                                block: 15,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 264,
                                status: "available",
                                block: 15,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 265,
                                status: "available",
                                block: 15,
                                unitSize: 692.65,
                                north: "30.0",
                                east: "23.09",
                                south: "30.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 266,
                                status: "sold",
                                block: 15,
                                unitSize: 808.1,
                                north: "35.0",
                                east: "23.09",
                                south: "35.0",
                                west: "23.09",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 267,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 268,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 269,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 270,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 271,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 272,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 273,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 274,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 275,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 276,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 277,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 278,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 279,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 280,
                                status: "sold",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 281,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 282,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 283,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 284,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 285,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 286,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 287,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 288,
                                status: "available",
                                block: 16,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 289,
                                status: "sold",
                                block: 17,
                                unitSize: 549.56,
                                north: "19.18",
                                east: "27.67",
                                south: "19.0",
                                west: "30.18",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 290,
                                status: "sold",
                                block: 17,
                                unitSize: 532.22,
                                north: "17.16",
                                east: "30.18",
                                south: "17.0",
                                west: "32.43",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 291,
                                status: "sold",
                                block: 17,
                                unitSize: 640.12,
                                north: "19.18",
                                east: "32.43",
                                south: "19.0",
                                west: "34.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 292,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 293,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 294,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 295,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 296,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 297,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 298,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 299,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 300,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 301,
                                status: "sold",
                                block: 17,
                                unitSize: 375.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 302,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 303,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 304,
                                status: "available",
                                block: 17,
                                unitSize: 500.01,
                                north: "25.0",
                                east: "20.0",
                                south: "25.0",
                                west: "20.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 305,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 306,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 307,
                                status: "available",
                                block: 17,
                                unitSize: 1000.02,
                                north: "25.0",
                                east: "40.0",
                                south: "25.0",
                                west: "40.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 308,
                                status: "sold",
                                block: 17,
                                unitSize: 450.01,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 309,
                                status: "sold",
                                block: 17,
                                unitSize: 832.14,
                                north: "30.0",
                                east: "24.32",
                                south: "24.29",
                                west: "26.35",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_89_310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 310,
                                status: "sold",
                                block: 17,
                                unitSize: 992.29,
                                north: "25.0",
                                east: "41.35",
                                south: "25.24",
                                west: "38.04",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 311,
                                status: "sold",
                                block: 18,
                                unitSize: 477.46,
                                north: "24.46",
                                east: "19.18",
                                south: "27.11",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 312,
                                status: "sold",
                                block: 18,
                                unitSize: 630.13,
                                north: "27.11",
                                east: "22.21",
                                south: "30.17",
                                west: "22.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 313,
                                status: "sold",
                                block: 18,
                                unitSize: 580.62,
                                north: "30.17",
                                east: "13.18",
                                south: "26.82",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 314,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 315,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 316,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 317,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 318,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 319,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 320,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 321,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 322,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 323,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 324,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 325,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 326,
                                status: "available",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 327,
                                status: "sold",
                                block: 18,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 328,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 329,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 330,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 331,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 332,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 333,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 334,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 335,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 336,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 337,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 338,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 339,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 340,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 341,
                                status: "available",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 342,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 343,
                                status: "sold",
                                block: 19,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 344,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 345,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 346,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 347,
                                status: "sold",
                                block: 19,
                                unitSize: 429.26,
                                north: "28.62",
                                east: "15.0",
                                south: "28.62",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 348,
                                status: "booked",
                                block: 20,
                                unitSize: 555.56,
                                north: "27.92",
                                east: "19.18",
                                south: "30.56",
                                west: "19.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 349,
                                status: "booked",
                                block: 20,
                                unitSize: 439.7,
                                north: "30.56",
                                east: "17.16",
                                south: "32.93",
                                west: "17.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 350,
                                status: "booked",
                                block: 20,
                                unitSize: 632.99,
                                north: "32.93",
                                east: "13.18",
                                south: "29.58",
                                west: "19.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 351,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 352,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 353,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 354,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 355,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 356,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 357,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 358,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 359,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 360,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 361,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 362,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 363,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 364,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 365,
                                status: "booked",
                                block: 20,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 366,
                                status: "booked",
                                block: 20,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 367,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 368,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_89_369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 369,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 370,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 371,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 372,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 373,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 374,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 375,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 376,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 377,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 378,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 379,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 380,
                                status: "booked",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 381,
                                status: "booked",
                                block: 21,
                                unitSize: 420.0,
                                north: "15.0",
                                east: "28.0",
                                south: "15.0",
                                west: "28.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 382,
                                status: "sold",
                                block: 21,
                                unitSize: 405.0,
                                north: "15.0",
                                east: "27.0",
                                south: "15.0",
                                west: "27.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 383,
                                status: "booked",
                                block: 21,
                                unitSize: 577.06,
                                north: "20.61",
                                east: "28.0",
                                south: "20.61",
                                west: "28.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 384,
                                status: "booked",
                                block: 21,
                                unitSize: 556.45,
                                north: "20.61",
                                east: "27.0",
                                south: "20.61",
                                west: "27.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 385,
                                status: "available",
                                block: 22,
                                unitSize: 913.53,
                                north: "28.36",
                                east: "30.29",
                                south: "32.54",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 386,
                                status: "available",
                                block: 22,
                                unitSize: 610.65,
                                north: "17.54",
                                east: "31.3",
                                south: "21.86",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 387,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 388,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 389,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 390,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 391,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 392,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 393,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 394,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 395,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 396,
                                status: "available",
                                block: 22,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 397,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 398,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 399,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 400,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 401,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 402,
                                status: "available",
                                block: 22,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 403,
                                status: "available",
                                block: 22,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 404,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 405,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 406,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 407,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 408,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 409,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 410,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 411,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 412,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 413,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 414,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 415,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 416,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 417,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 418,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 419,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 420,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 421,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 422,
                                status: "available",
                                block: 23,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 423,
                                status: "available",
                                block: 23,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 424,
                                status: "available",
                                block: 23,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 425,
                                status: "available",
                                block: 23,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 426,
                                status: "booked",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_89_427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 427,
                                status: "booked",
                                block: 24,
                                unitSize: 687.5,
                                north: "35.0",
                                east: "20.0",
                                south: "35.0",
                                west: "20.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                        },
                        {
                            Polygon: LandPlot_89_428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 428,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 429,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 430,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 431,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 432,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 433,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 434,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 435,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 436,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 437,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 438,
                                status: "available",
                                block: 24,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 439,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 440,
                                status: "available",
                                block: 24,
                                unitSize: 600.0,
                                north: "30.0",
                                east: "20.0",
                                south: "30.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 441,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 442,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 443,
                                status: "available",
                                block: 24,
                                unitSize: 1200.0,
                                north: "30.0",
                                east: "40.0",
                                south: "30.0",
                                west: "40.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 444,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 445,
                                status: "available",
                                block: 24,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 446,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 447,
                                status: "available",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_89_448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 448,
                                status: "sold",
                                block: 24,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_89_449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 449,
                                status: "sold",
                                block: 24,
                                unitSize: 720.0,
                                north: "20.0",
                                east: "36.0",
                                south: "20.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];
