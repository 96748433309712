import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import TestVideo from "../../../static/images/contents/master-plan/vr/C1_Low.mp4";

import "./VR.scss";
import { ModalExit } from "../../modal/Modal";
import { useEffect, useRef } from "react";

export default function VideoVR({ panorama, closeFunc }) {
    const refContainer = useRef(null);
    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            1,
            100
        );
        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.rotateSpeed *= -0.27;
        const radius = 30;

        controls.enablePan = false;
        controls.enableZoom = false;

        let spherical = new THREE.Spherical(1, Math.PI / 2, 0);
        spherical.makeSafe();
        camera.position.setFromSpherical(spherical);

        let src = TestVideo;
        const video = document.createElement("video");
        video.src = src;
        video.loop = true;
        video.muted = true;
        video.playsInline = true;
        video.crossOrigin = "anonymous";
        video.play();

        renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(renderer.domElement);

        refContainer.current &&
            refContainer.current.appendChild(renderer.domElement);

        const texture = new THREE.VideoTexture(video);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.magFilter = THREE.NearestFilter;
        const geometry = new THREE.SphereGeometry(radius, 48, 32);
        const material = new THREE.MeshBasicMaterial({ map: texture });
        material.side = THREE.BackSide;

        const sphere = new THREE.Mesh(geometry, material);
        scene.add(sphere);

        function animate() {
            requestAnimationFrame(animate);

            renderer.render(scene, camera);
        }

        animate();

        window.addEventListener("resize", onWindowResize);

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        renderer.domElement.addEventListener("wheel", handleZoom);

        function clamp(value, min, max) {
            return Math.min(Math.max(value, min), max);
        }

        function handleZoom(e) {
            const cameraFOVUpdated = clamp(camera.fov + e.deltaY / 10, 10, 100);
            if (cameraFOVUpdated < 40) {
                camera.fov = 40;
            } else if (cameraFOVUpdated > 100) {
                camera.fov = 100;
            } else {
                camera.fov = cameraFOVUpdated;
            }
            camera.updateProjectionMatrix();
        }
    }, []);

    return (
        <>
            <ModalExit closeFunc={closeFunc} />
            {/* <iframe
                id="video"
                title="vidoe"
                src="https://361.visengine.com/361/vrt/alaqtar/2/"
                style={{ width: "100%", height: "100%", border: "none" }}
            /> */}

            <div ref={refContainer}></div>
        </>
    );
}
