import React from "react";

import "./SvgCustoms.scss";

function SvgCustom({ Element, className, onClick }) {
    return (
        <Element
            className={`svg-custom ${className ? className : ""}`}
            onClick={onClick}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
        />
    );
}

export default function SvgCustoms({ customs }) {
    return (
        <div className="svg-customs">
            <svg
                viewBox="0 0 1920 1080"
                version="1.0"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
            >
                {customs.map((area, i) => (
                    <SvgCustom key={i} {...area} />
                ))}
            </svg>
        </div>
    );
}
