import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import VR from "../../../../components/main/vr/VR";
import VideoVR from "../../../../components/main/vr/VideoVR";
// import VideoVR from "../../../../components/main/vr/VideoVR";

import VR_C1 from "../../../../static/images/contents/master-plan/vr/C1_Low.jpg";
import VR_C2 from "../../../../static/images/contents/master-plan/vr/C2_Low.jpg";
import VR_C3 from "../../../../static/images/contents/master-plan/vr/C3_Low.jpg";

import Zone1 from "../zone-1/Zone1";
import Zone2 from "../zone-2/Zone2";
import Zone5 from "../zone-5/Zone5";

import { ReactComponent as Zone5_21 } from "../../../../static/images/contents/master-plan/polygons/21.svg";
import { ReactComponent as VR360_21_1 } from "../../../../static/images/contents/master-plan/icons/21/vr360_1.svg";
import { ReactComponent as VR360_21_2 } from "../../../../static/images/contents/master-plan/icons/21/vr360_2.svg";
import { ReactComponent as VR360_21_3 } from "../../../../static/images/contents/master-plan/icons/21/vr360_3.svg";
import { ReactComponent as Church_21_1 } from "../../../../static/images/contents/master-plan/icons/21/church_1.svg";
import { ReactComponent as Church_21_2 } from "../../../../static/images/contents/master-plan/icons/21/church_2.svg";
import { ReactComponent as Church_21_3 } from "../../../../static/images/contents/master-plan/icons/21/church_3.svg";
import { ReactComponent as Church_21_4 } from "../../../../static/images/contents/master-plan/icons/21/church_4.svg";
import { ReactComponent as Church_21_5 } from "../../../../static/images/contents/master-plan/icons/21/church_5.svg";
import { ReactComponent as Church_21_6 } from "../../../../static/images/contents/master-plan/icons/21/church_6.svg";
import { ReactComponent as Church_21_7 } from "../../../../static/images/contents/master-plan/icons/21/church_7.svg";
import { ReactComponent as Church_21_8 } from "../../../../static/images/contents/master-plan/icons/21/church_8.svg";
import { ReactComponent as Church_21_9 } from "../../../../static/images/contents/master-plan/icons/21/church_9.svg";
import { ReactComponent as Hospital_21_1 } from "../../../../static/images/contents/master-plan/icons/21/hospital_1.svg";
import { ReactComponent as Hospital_21_2 } from "../../../../static/images/contents/master-plan/icons/21/hospital_2.svg";
import { ReactComponent as Hospital_21_3 } from "../../../../static/images/contents/master-plan/icons/21/hospital_3.svg";
import { ReactComponent as Hospital_21_4 } from "../../../../static/images/contents/master-plan/icons/21/hospital_4.svg";
import { ReactComponent as School_21_1 } from "../../../../static/images/contents/master-plan/icons/21/school_1.svg";
import { ReactComponent as School_21_2 } from "../../../../static/images/contents/master-plan/icons/21/school_2.svg";
import { ReactComponent as School_21_3 } from "../../../../static/images/contents/master-plan/icons/21/school_3.svg";
import { ReactComponent as Shopping_21_1 } from "../../../../static/images/contents/master-plan/icons/21/shopping_1.svg";
import { ReactComponent as Shopping_21_2 } from "../../../../static/images/contents/master-plan/icons/21/shopping_2.svg";
import { ReactComponent as Shopping_21_3 } from "../../../../static/images/contents/master-plan/icons/21/shopping_3.svg";

import { ReactComponent as Zone5_46 } from "../../../../static/images/contents/master-plan/polygons/46.svg";
import { ReactComponent as VR360_46_1 } from "../../../../static/images/contents/master-plan/icons/46/vr360_1.svg";
import { ReactComponent as VR360_46_2 } from "../../../../static/images/contents/master-plan/icons/46/vr360_2.svg";
import { ReactComponent as VR360_46_3 } from "../../../../static/images/contents/master-plan/icons/46/vr360_3.svg";
import { ReactComponent as Church_46_1 } from "../../../../static/images/contents/master-plan/icons/46/church_1.svg";
import { ReactComponent as Church_46_2 } from "../../../../static/images/contents/master-plan/icons/46/church_2.svg";
import { ReactComponent as Church_46_3 } from "../../../../static/images/contents/master-plan/icons/46/church_3.svg";
import { ReactComponent as Church_46_4 } from "../../../../static/images/contents/master-plan/icons/46/church_4.svg";
import { ReactComponent as Church_46_5 } from "../../../../static/images/contents/master-plan/icons/46/church_5.svg";
import { ReactComponent as Church_46_6 } from "../../../../static/images/contents/master-plan/icons/46/church_6.svg";
import { ReactComponent as Church_46_7 } from "../../../../static/images/contents/master-plan/icons/46/church_7.svg";
import { ReactComponent as Church_46_8 } from "../../../../static/images/contents/master-plan/icons/46/church_8.svg";
import { ReactComponent as Church_46_9 } from "../../../../static/images/contents/master-plan/icons/46/church_9.svg";
import { ReactComponent as Hospital_46_1 } from "../../../../static/images/contents/master-plan/icons/46/hospital_1.svg";
import { ReactComponent as Hospital_46_2 } from "../../../../static/images/contents/master-plan/icons/46/hospital_2.svg";
import { ReactComponent as Hospital_46_3 } from "../../../../static/images/contents/master-plan/icons/46/hospital_3.svg";
import { ReactComponent as Hospital_46_4 } from "../../../../static/images/contents/master-plan/icons/46/hospital_4.svg";
import { ReactComponent as School_46_1 } from "../../../../static/images/contents/master-plan/icons/46/school_1.svg";
import { ReactComponent as School_46_2 } from "../../../../static/images/contents/master-plan/icons/46/school_2.svg";
import { ReactComponent as School_46_3 } from "../../../../static/images/contents/master-plan/icons/46/school_3.svg";
import { ReactComponent as Shopping_46_1 } from "../../../../static/images/contents/master-plan/icons/46/shopping_1.svg";
import { ReactComponent as Shopping_46_2 } from "../../../../static/images/contents/master-plan/icons/46/shopping_2.svg";
import { ReactComponent as Shopping_46_3 } from "../../../../static/images/contents/master-plan/icons/46/shopping_3.svg";

import { ReactComponent as Zone5_77 } from "../../../../static/images/contents/master-plan/polygons/77.svg";
import { ReactComponent as VR360_77_1 } from "../../../../static/images/contents/master-plan/icons/77/vr360_1.svg";
import { ReactComponent as VR360_77_2 } from "../../../../static/images/contents/master-plan/icons/77/vr360_2.svg";
import { ReactComponent as VR360_77_3 } from "../../../../static/images/contents/master-plan/icons/77/vr360_3.svg";
import { ReactComponent as Church_77_1 } from "../../../../static/images/contents/master-plan/icons/77/church_1.svg";
import { ReactComponent as Church_77_2 } from "../../../../static/images/contents/master-plan/icons/77/church_2.svg";
import { ReactComponent as Church_77_3 } from "../../../../static/images/contents/master-plan/icons/77/church_3.svg";
import { ReactComponent as Church_77_4 } from "../../../../static/images/contents/master-plan/icons/77/church_4.svg";
import { ReactComponent as Church_77_5 } from "../../../../static/images/contents/master-plan/icons/77/church_5.svg";
import { ReactComponent as Church_77_6 } from "../../../../static/images/contents/master-plan/icons/77/church_6.svg";
import { ReactComponent as Church_77_7 } from "../../../../static/images/contents/master-plan/icons/77/church_7.svg";
import { ReactComponent as Church_77_8 } from "../../../../static/images/contents/master-plan/icons/77/church_8.svg";
import { ReactComponent as Church_77_9 } from "../../../../static/images/contents/master-plan/icons/77/church_9.svg";
import { ReactComponent as Hospital_77_1 } from "../../../../static/images/contents/master-plan/icons/77/hospital_1.svg";
import { ReactComponent as Hospital_77_2 } from "../../../../static/images/contents/master-plan/icons/77/hospital_2.svg";
import { ReactComponent as Hospital_77_3 } from "../../../../static/images/contents/master-plan/icons/77/hospital_3.svg";
import { ReactComponent as Hospital_77_4 } from "../../../../static/images/contents/master-plan/icons/77/hospital_4.svg";
import { ReactComponent as School_77_1 } from "../../../../static/images/contents/master-plan/icons/77/school_1.svg";
import { ReactComponent as School_77_2 } from "../../../../static/images/contents/master-plan/icons/77/school_2.svg";
import { ReactComponent as School_77_3 } from "../../../../static/images/contents/master-plan/icons/77/school_3.svg";
import { ReactComponent as Shopping_77_1 } from "../../../../static/images/contents/master-plan/icons/77/shopping_1.svg";
import { ReactComponent as Shopping_77_2 } from "../../../../static/images/contents/master-plan/icons/77/shopping_2.svg";
import { ReactComponent as Shopping_77_3 } from "../../../../static/images/contents/master-plan/icons/77/shopping_3.svg";

import { ReactComponent as Zone5_106 } from "../../../../static/images/contents/master-plan/polygons/106.svg";
import { ReactComponent as VR360_106_1 } from "../../../../static/images/contents/master-plan/icons/106/vr360_1.svg";
import { ReactComponent as VR360_106_2 } from "../../../../static/images/contents/master-plan/icons/106/vr360_2.svg";
import { ReactComponent as VR360_106_3 } from "../../../../static/images/contents/master-plan/icons/106/vr360_3.svg";
import { ReactComponent as Church_106_1 } from "../../../../static/images/contents/master-plan/icons/106/church_1.svg";
import { ReactComponent as Church_106_2 } from "../../../../static/images/contents/master-plan/icons/106/church_2.svg";
import { ReactComponent as Church_106_3 } from "../../../../static/images/contents/master-plan/icons/106/church_3.svg";
import { ReactComponent as Church_106_4 } from "../../../../static/images/contents/master-plan/icons/106/church_4.svg";
import { ReactComponent as Church_106_5 } from "../../../../static/images/contents/master-plan/icons/106/church_5.svg";
import { ReactComponent as Church_106_6 } from "../../../../static/images/contents/master-plan/icons/106/church_6.svg";
import { ReactComponent as Church_106_7 } from "../../../../static/images/contents/master-plan/icons/106/church_7.svg";
import { ReactComponent as Church_106_8 } from "../../../../static/images/contents/master-plan/icons/106/church_8.svg";
import { ReactComponent as Church_106_9 } from "../../../../static/images/contents/master-plan/icons/106/church_9.svg";
import { ReactComponent as Hospital_106_1 } from "../../../../static/images/contents/master-plan/icons/106/hospital_1.svg";
import { ReactComponent as Hospital_106_2 } from "../../../../static/images/contents/master-plan/icons/106/hospital_2.svg";
import { ReactComponent as Hospital_106_3 } from "../../../../static/images/contents/master-plan/icons/106/hospital_3.svg";
import { ReactComponent as Hospital_106_4 } from "../../../../static/images/contents/master-plan/icons/106/hospital_4.svg";
import { ReactComponent as School_106_1 } from "../../../../static/images/contents/master-plan/icons/106/school_1.svg";
import { ReactComponent as School_106_2 } from "../../../../static/images/contents/master-plan/icons/106/school_2.svg";
import { ReactComponent as School_106_3 } from "../../../../static/images/contents/master-plan/icons/106/school_3.svg";
import { ReactComponent as Shopping_106_1 } from "../../../../static/images/contents/master-plan/icons/106/shopping_1.svg";
import { ReactComponent as Shopping_106_2 } from "../../../../static/images/contents/master-plan/icons/106/shopping_2.svg";
import { ReactComponent as Shopping_106_3 } from "../../../../static/images/contents/master-plan/icons/106/shopping_3.svg";


import { ReactComponent as Zone_1_Icon_21_EN } from "../../../../static/images/contents/master-plan/icons/21/en/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_46_EN } from "../../../../static/images/contents/master-plan/icons/46/en/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_77_EN } from "../../../../static/images/contents/master-plan/icons/77/en/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_106_EN } from "../../../../static/images/contents/master-plan/icons/106/en/3d_zone_1.svg";

import { ReactComponent as Zone_1_Icon_21_AR } from "../../../../static/images/contents/master-plan/icons/21/ar/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_46_AR } from "../../../../static/images/contents/master-plan/icons/46/ar/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_77_AR } from "../../../../static/images/contents/master-plan/icons/77/ar/3d_zone_1.svg";
import { ReactComponent as Zone_1_Icon_106_AR } from "../../../../static/images/contents/master-plan/icons/106/ar/3d_zone_1.svg";

import { ReactComponent as Zone_2_Icon_21_EN } from "../../../../static/images/contents/master-plan/icons/21/en/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_46_EN } from "../../../../static/images/contents/master-plan/icons/46/en/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_77_EN } from "../../../../static/images/contents/master-plan/icons/77/en/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_106_EN } from "../../../../static/images/contents/master-plan/icons/106/en/3d_zone_2.svg";

import { ReactComponent as Zone_2_Icon_21_AR } from "../../../../static/images/contents/master-plan/icons/21/ar/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_46_AR } from "../../../../static/images/contents/master-plan/icons/46/ar/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_77_AR } from "../../../../static/images/contents/master-plan/icons/77/ar/3d_zone_2.svg";
import { ReactComponent as Zone_2_Icon_106_AR } from "../../../../static/images/contents/master-plan/icons/106/ar/3d_zone_2.svg";

import { ReactComponent as Zone_5_Icon_21_EN } from "../../../../static/images/contents/master-plan/icons/21/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_46_EN } from "../../../../static/images/contents/master-plan/icons/46/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_77_EN } from "../../../../static/images/contents/master-plan/icons/77/en/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_106_EN } from "../../../../static/images/contents/master-plan/icons/106/en/3d_zone_5.svg";

import { ReactComponent as Zone_5_Icon_21_AR } from "../../../../static/images/contents/master-plan/icons/21/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_46_AR } from "../../../../static/images/contents/master-plan/icons/46/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_77_AR } from "../../../../static/images/contents/master-plan/icons/77/ar/3d_zone_5.svg";
import { ReactComponent as Zone_5_Icon_106_AR } from "../../../../static/images/contents/master-plan/icons/106/ar/3d_zone_5.svg";


import { ReactComponent as Zone1_22 } from "../../../../static/images/contents/master-plan/polygons/22.svg";
import { ReactComponent as Zone1_47 } from "../../../../static/images/contents/master-plan/polygons/47.svg";
import { ReactComponent as Zone1_78 } from "../../../../static/images/contents/master-plan/polygons/78.svg";
import { ReactComponent as Zone1_107 } from "../../../../static/images/contents/master-plan/polygons/107.svg";

import { ReactComponent as Zone2_22 } from "../../../../static/images/contents/master-plan/polygons/23.svg";
import { ReactComponent as Zone2_47 } from "../../../../static/images/contents/master-plan/polygons/48.svg";
import { ReactComponent as Zone2_78 } from "../../../../static/images/contents/master-plan/polygons/79.svg";
import { ReactComponent as Zone2_107 } from "../../../../static/images/contents/master-plan/polygons/108.svg";

export const masterPlanStops = (openZone, setModalContent, language) => [
    {
        index: 21,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone1_22,
                            className: "zone-1-area",
                            infobox: null,
                            onClick: () => openZone(<Zone1/>, "menu_zone_1"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone2_22,
                            className: "zone-2-area",
                            infobox: null,
                            onClick: () => openZone(<Zone2/>, "menu_zone_2"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone5_21,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: () => openZone(<Zone5/>, "menu_zone_5"),
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_21_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_21_9,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_21_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_21_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_21_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_21_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_21_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_21_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_21_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_21_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_21_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_21_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: VR360_21_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_21_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_21_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_1_Icon_21_EN
                                    : Zone_1_Icon_21_AR,
                            className: "zone-1-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_2_Icon_21_EN
                                    : Zone_2_Icon_21_AR,
                            className: "zone-2-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_21_EN
                                    : Zone_5_Icon_21_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 46,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone1_47,
                            className: "zone-1-area",
                            infobox: null,
                            onClick: () => openZone(<Zone1/>, "menu_zone_1"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone2_47,
                            className: "zone-2-area",
                            infobox: null,
                            onClick: () => openZone(<Zone2/>, "menu_zone_2"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone5_46,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: () => openZone(<Zone5/>, "menu_zone_5"),
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_46_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_46_9,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_46_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_46_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_46_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_46_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_46_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_46_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_46_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_46_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_46_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_46_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: VR360_46_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_46_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_46_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_1_Icon_46_EN
                                    : Zone_1_Icon_46_AR,
                            className: "zone-1-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_2_Icon_46_EN
                                    : Zone_2_Icon_46_AR,
                            className: "zone-2-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_46_EN
                                    : Zone_5_Icon_46_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 77,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone1_78,
                            className: "zone-1-area",
                            infobox: null,
                            onClick: () => openZone(<Zone1/>, "menu_zone_1"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone2_78,
                            className: "zone-2-area",
                            infobox: null,
                            onClick: () => openZone(<Zone2/>, "menu_zone_2"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone5_77,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: () => openZone(<Zone5/>, "menu_zone_5"),
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_77_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_77_9,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_77_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_77_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_77_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_77_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_77_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_77_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_77_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_77_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_77_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_77_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: VR360_77_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_77_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_77_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_1_Icon_77_EN
                                    : Zone_1_Icon_77_AR,
                            className: "zone-1-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_2_Icon_77_EN
                                    : Zone_2_Icon_77_AR,
                            className: "zone-2-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_77_EN
                                    : Zone_5_Icon_77_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 106,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: Zone1_107,
                            className: "zone-1-area",
                            infobox: null,
                            onClick: () => openZone(<Zone1/>, "menu_zone_1"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone2_107,
                            className: "zone-2-area",
                            infobox: null,
                            onClick: () => openZone(<Zone2/>, "menu_zone_2"),
                            isInfoboxOnMobile: false,
                        },
                        {
                            Polygon: Zone5_106,
                            className: "zone-5-area",
                            infobox: null,
                            onClick: () => openZone(<Zone5/>, "menu_zone_5"),
                            isInfoboxOnMobile: false,
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_106_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_106_9,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_106_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_106_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_106_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_106_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_106_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_106_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_106_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_106_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_106_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_106_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: VR360_106_1,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                ),
                        },
                        {
                            Element: VR360_106_2,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    // <VR id="walking-area" panorama={VR_C1} />
                                    <VideoVR />
                                ),
                        },
                        {
                            Element: VR360_106_3,
                            className: "vr360",
                            onClick: () =>
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                ),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_1_Icon_106_EN
                                    : Zone_1_Icon_106_AR,
                            className: "zone-1-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_2_Icon_106_EN
                                    : Zone_2_Icon_106_AR,
                            className: "zone-2-icon",
                            onClick: () => console.log("clicked"),
                        },
                        {
                            Element:
                                language === "en"
                                    ? Zone_5_Icon_106_EN
                                    : Zone_5_Icon_106_AR,
                            className: "zone-5-icon",
                            onClick: () => console.log("clicked"),
                        },
                    ]}
                />
            </>
        ),
    },
];