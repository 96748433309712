import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { motion, AnimatePresence } from "framer-motion";

import { MainContext } from "../../pages/main/Main";

import "./Infobox.scss";
import "./Adaptations.scss";
import { infobox_animation } from "./animations";

import { useTranslation } from "react-i18next";

function InfoboxText({ text }) {
    return (
        <div className="infobox-text">
            <span className="infobox-text-inner">{text}</span>
        </div>
    );
}

function Infobox({ pos, inner, text, isMobileOptimized = false, onClick }) {
    const infoboxRef = useRef();
    const [position, setPosition] = useState({ left: pos.left, top: pos.top });

    useEffect(() => {
        if (infoboxRef.current) {
            const infoboxWidth = infoboxRef.current.offsetWidth;
            const infoboxHeight = infoboxRef.current.offsetHeight;

            let leftPos = pos.left - infoboxWidth / 2;
            let topPos = pos.top - infoboxHeight - 5;

            if (leftPos < 0) {
                leftPos = 5;
            } else if (leftPos + infoboxWidth > window.innerWidth) {
                leftPos = window.innerWidth - infoboxWidth - 5;
            }

            if (topPos < 0) {
                topPos = pos.top + 15;
            } else if (topPos + infoboxHeight > window.innerHeight) {
                topPos = window.innerHeight - infoboxHeight - 5;
            }

            setPosition({ left: leftPos, top: topPos });
        }
    }, [pos, isMobileOptimized]);

    return (
        <motion.div
            ref={infoboxRef}
            className="infobox"
            style={{
                left: position.left,
                top: position.top,
                pointerEvents: isMobileOptimized ? "all" : "none",
            }}
            {...infobox_animation(isMobileOptimized)}
            {...(isMobileOptimized && {
                onMouseDown: (e) => e.stopPropagation(),
                onClickCapture: onClick,
            })}
        >
            {inner ? inner : text && <InfoboxText text={text} />}
        </motion.div>
    );
}

export function WithInfobox({
    children,
    inner,
    text,
    isClickableOnMobileInfobox = true,
    onClick,
}) {
    const [changeContent, setModalContent, pageClicked] =
        useContext(MainContext);

    const isMobile = useMediaQuery("(max-width: 800px), (max-height: 400px)");

    const [isOver, setIsOver] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [cursorPos, setCursorPos] = useState({ left: 0, top: 0 });

    useEffect(() => {
        setIsClicked(false);
    }, [pageClicked]);

    function handleMouseMove(e) {
        setCursorPos({ left: e.clientX, top: e.clientY });
    }

    function handleMouseClickMobile(e) {
        handleMouseMove(e);
        setIsClicked((clicked) => !clicked);
    }

    function handleMouseClick() {
        if (onClick) {
            if (!isMobile || !isClickableOnMobileInfobox) {
                onClick();
            }
        }
    }

    return (
        <>
            {createPortal(
                <AnimatePresence mode="wait">
                    {(isOver || isClicked) && (
                        <Infobox
                            pos={cursorPos}
                            inner={inner}
                            text={text}
                            isMobileOptimized={
                                isClickableOnMobileInfobox && isMobile
                            }
                            onClick={onClick}
                        />
                    )}
                </AnimatePresence>,
                document.body
            )}

            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        ...(isClickableOnMobileInfobox && isMobile
                            ? {
                                  onClick: handleMouseClickMobile,
                              }
                            : !isClickableOnMobileInfobox && isMobile
                            ? {
                                  onClick: handleMouseClick,
                              }
                            : !isMobile && {
                                  onMouseEnter: () => setIsOver(true),
                                  onMouseLeave: () => setIsOver(false),
                                  onMouseMove: handleMouseMove,
                                  onClick: handleMouseClick,
                              }),
                    });
                }

                return child;
            })}
        </>
    );
}
