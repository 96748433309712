import { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./Frame.scss";
import { wrapper_animation } from "./animations";

export default function Frame({ image, wrapper, wrapper_animation_key = 1 }) {
    const frameRef = useRef();

    useEffect(() => {
        if (frameRef === null) return;
        const frame = frameRef.current;
        frame.scrollTop = (frame.scrollHeight - frame.clientHeight) / 2;
        frame.scrollLeft = (frame.scrollWidth - frame.clientWidth) / 2;
    }, []);

    return (
        <div className="frame" ref={frameRef}>
            <AnimatePresence mode="wait">
                <motion.div
                    className="frame-wrapper"
                    key={wrapper_animation_key}
                    {...wrapper_animation}
                >
                    {wrapper ? (
                        wrapper
                    ) : (
                        <svg
                            style={{ height: "100%" }}
                            viewBox="0 0 1920 1080"
                            version="1.0"
                            preserveAspectRatio="xMidYMid slice"
                            xmlns="http://www.w3.org/2000/svg"
                        ></svg>
                    )}
                </motion.div>
            </AnimatePresence>
            <img className="frame-image" src={image} alt="frame" />
        </div>
    );
}
