import { Link } from "react-router-dom";

import "./LanguageButton.scss";
import "./Adaptations.scss";

import { useTranslation } from "react-i18next";

export default function LanguageButton() {
    const [t, i18n] = useTranslation();

    const getNextLanguage = () => {
        if (i18n.language === "en") {
            return "ar";
        } else if (i18n.language === "ar") {
            return "en";
        }
    };

    return (
        <Link to={"/" + getNextLanguage()} className="language-button" style={{ fontFamily: t('font_inter')}}>
            <span className="language-button-text">{t("menu_language")}</span>
        </Link>
    );
}
