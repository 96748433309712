import { AnimatePresence, motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import "./PopUp.scss";
// import "./Adaptations.scss";
import { popup_animation } from "./animations";

import TriangleImage from "../../static/images/popup-triangle.svg";
import { useEffect, useState } from "react";

export default function PopUp({ title, desc }) {
    const [isActive, setIsActive] = useState(true);
    const [t, i18] = useTranslation();
    useEffect(() => {
        setTimeout(() => setIsActive(false), 5000);
    });
    const closeFunc = () => setIsActive(false);

    return (
        <AnimatePresence>
            {isActive && (
                <motion.div
                    className="pop-up"
                    {...popup_animation}
                    onClick={closeFunc}
                >
                    <div className="pop-up-box"
                    style={{
                        textAlign: i18.language === "ar" ? "right" : "left",
                        // width: i18.language === "ar" ? "300px" : "",
                     }}
                    >
                        <div className="pop-up-box-title"
                        style={{
                            fontWeight: i18.language === "ar" ? "500" : "700",
                         }}
                        >
                            <span className="pop-up-box-title-text"
                            style={{fontFamily: t("font_salvager"),
                                fontSize: i18.language === "ar" ? "25px" : "",
                            }}>
                                {title}
                            </span>
                        </div>
                        <div className="pop-up-box-desc"
                        style={{
                            width: i18.language === "ar" ? "205px" : "",
                        }}>
                            <span className="pop-up-box-title-text"
                            style={{fontFamily: t("font_inter"),
                                fontSize: i18.language === "ar" ? "15px" : "",
                            }}>
                                {desc}
                            </span>
                        </div>
                    </div>
                    <div className="pop-up-triangle">
                        <img src={TriangleImage} alt="triangle" />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
