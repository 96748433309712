import { useTranslation } from "react-i18next";

import "./LandInfo.scss";

function LandInfoParam({ label, value, unit }) {
    const [t, i18] = useTranslation();
    return (
        <div className="land-info-parameter"
        style={{fontFamily: t("font_inter")}}>
            <span className="land-info-parameter-text">{label}</span>
            <span className="land-info-parameter-text">
                {i18.language === "ar" ? (
                        <>
                            <div>
                                {unit && " " + unit}
                            </div>
                            <span className="land-info-parameter-text-highlight">
                                {value}
                            </span>
                        </>
                    ) : ( 
                        <>
                            <span className="land-info-parameter-text-highlight">
                                {value}
                            </span>
                            {unit && " " + unit}
                        </>
                    )
                }
                {/* <span className="land-info-parameter-text-highlight">
                    {value}
                </span>
                {unit && " " + unit} */}
            </span>
        </div>
    );
}

export default function LandInfo({
    title,
    index,
    status,
    electricTransformer,
    unitSize,
    block,
}) {
    const [t] = useTranslation();

    return (
        <div className="land-info">
            <div className="land-info-top">
                <div className="land-info-title">
                    <span
                        className="land-info-title-text"
                        style={{ fontFamily: t("font_salvager") }}
                    >
                        {t(title)} {index}
                    </span>
                </div>

                <div className="land-info-parameters">
                    <LandInfoParam
                        label={t("unit_size")}
                        value={unitSize}
                        unit={t("square_meters")}
                    />
                    {/* <LandInfoParam label={t("block")} value={block} /> */}
                </div>

                {electricTransformer ? (
                    <span className="land-info-electric-transformer">
                        {t("land_info_electric_transformer")}
                    </span>
                ) : null}
            </div>
            <div className={`land-info-bottom land-info-bottom-${status}`}>
                <div
                    className="land-info-status"
                    style={{ fontFamily: t("font_salvager") }}
                >
                    <span className="land-info-status-text">
                        {t(`land_info_status_${status}`)}
                    </span>
                </div>
            </div>
        </div>
    );
}
