import { useTranslation } from "react-i18next";

import "./BlockInfo.scss";

function BlockInfoParam({ label, value, unit }) {
    const [t, i18] = useTranslation();
    return (
        <div className="block-info-parameter"
        style={{fontFamily: t("font_inter")}}>
            <span className="block-info-parameter-text">{label}</span>
            <span className="block-info-parameter-text">
                {i18.language === "ar" ? (
                        <>
                            <div>
                                {unit && " " + unit}
                            </div>
                            <span className="land-info-parameter-text-highlight">
                                {value}
                            </span>
                        </>
                    ) : ( 
                        <>
                            <span className="land-info-parameter-text-highlight">
                                {value}
                            </span>
                            {unit && " " + unit}
                        </>
                    )
                }
                {/* <span className="block-info-parameter-text-highlight">
                    {value}
                </span>
                {unit && " " + unit} */}
            </span>
        </div>
    );
}

export default function BlockInfo({ title, index, status, blockSize }) {
    const [t] = useTranslation();

    return (
        <div className="block-info">
            <div className="block-info-top">
                <div className="block-info-title">
                    <span
                        className="block-info-title-text"
                        style={{ fontFamily: t("font_salvager") }}
                    >
                        {t(title)} {index}
                    </span>
                </div>
                <div className="block-info-parameters">
                    <BlockInfoParam
                        label={t("block_size")}
                        value={blockSize}
                        unit={t("square_meters")}
                    />
                </div>
            </div>
            <div className={`block-info-bottom block-info-bottom-${status}`}>
                <div
                    className="block-info-status"
                    style={{ fontFamily: t("font_salvager") }}
                >
                    <span className="block-info-status-text">
                        {t(`land_info_status_${status}`)}
                    </span>
                </div>
            </div>
        </div>
    );
}
