import "./TopLogo.scss";
import "./Adaptations.scss";

import TopLogoAlZumurudaImage from "../../../../static/images/AlZumurudaLogo.png";
import TopLogoAlaqtarImage from "../../../../static/images/AlaqtarLogo.png";

export function TopLogoAlZumuruda() {
    return (
        <div className="top-logo top-logo-al-zumuruda">
            <img
                className="top-logo-al-zumuruda-image"
                src={TopLogoAlZumurudaImage}
                alt="alaqtar-logo"
            />
        </div>
    );
}

export function TopLogoAlaqtar() {
    return (
        <div className="top-logo top-logo-alaqtar">
            <img
                className="top-logo-alaqtar-image"
                src={TopLogoAlaqtarImage}
                alt="alaqtar-logo"
            />
        </div>
    );
}
