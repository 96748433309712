import ReactSwitch from "react-switch";

import "./Switch.scss";
import { useTranslation } from "react-i18next";

export default function Switch({
    switchChecked,
    handleSwitchCheck,
    labelText,
    // labelText2
}) {
    const { t, i18n } = useTranslation();
    const switchChange = () => handleSwitchCheck((state) => !state);

    return (
        <div
            className="switch"
            style={{
                flexDirection: i18n.language === "en" ? "row" : "row-reverse",
            }}
            onClick={switchChange}
        >
            <ReactSwitch
                className="switch-element"
                onChange={() => null}
                checked={switchChecked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#CC7D61"
                offColor="#BDC7C7"
                handleDiameter={15}
                onHandleColor="#fff"
                offHandleColor="#fff"
                height={17}
                width={30}
                borderRadius={40}
            />
            <div
                className="switch-label"
                style={{ fontFamily: t("font_inter") }}
            >
                <span className="switch-text">{labelText}</span>
            </div>
        </div>
    );
}
