import "pannellum";

import "./VR.scss";
import { useEffect } from "react";
import { ModalExit } from "../../modal/Modal";

export default function VR({ id, panorama, closeFunc }) {
    useEffect(() => {
        if (id && panorama) {
            window.pannellum.viewer(id, {
                autoLoad: true,
                showControls: false,
                type: "equirectangular",
                panorama: panorama,
            });
        }
    }, [id, panorama]);

    return (
        <>
            <ModalExit closeFunc={closeFunc} />
            <div className="vr" id={id} />
        </>
    );
}
