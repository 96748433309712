import { useContext } from "react";
import Reel from "../../../../components/main/reel/Reel";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Main";

import "./MasterPlan.scss";
import "./Adaptations.scss";
import { masterPlanStops } from "./stops";

import Buraydah from "../buraydah/Buraydah";
import BackButton from "../../../../components/main/menu/back-button/BackButton";
import ServicesButton from "../../../../components/main/menu/services/ServicesButton";
import { TopLogoAlZumuruda } from "../../../../components/main/menu/top-logo/TopLogo";

import startFrame from "../../../../static/images/contents/master-plan/renders/1/77.jpg";

export default function MasterPlan() {
    const { t, i18n } = useTranslation();

    const [changeContent, setModalContent] = useContext(MainContext);
    const openZone = (Component, buttonName) => {
        changeContent(Component, {            
            button: buttonName,
            element: null,
            head_title: null,
        });
    }
    return (
        <div className="master-plan" style={{ width: "100%", height: "100%" }}>
            <TopLogoAlZumuruda />
            <ServicesButton setModalContent={setModalContent} />
            <Reel
                folder="static/contents/master-plan/renders"
                amount={120}
                qualitySteps={1}
                defaultControlsPart={0.075}
                stops={masterPlanStops(
                    openZone,
                    setModalContent,
                    i18n.language
                )}
                overlay_elements={null} // Soon
                startFrame={startFrame}
            />

            <BackButton
                onClick={() =>
                    changeContent(<Buraydah />, {
                        button: "menu_buraydah",
                        element: null,
                        head_title: null,
                    })
                }
            />
        </div>
    );
}
