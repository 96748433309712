import React from "react"
import { ReactComponent as ElectricityIcon } from './../../../../static/images/icons/electricity.svg'
import { ReactComponent as WaterIcon } from './../../../../static/images/icons/water.svg'
import { ReactComponent as RoadsIcon } from './../../../../static/images/icons/roads.svg'
import { ReactComponent as StormwaterIcon } from './../../../../static/images/icons/stormwater.svg'
import CrossThinImage from "../../../../static/images/icons/cross-thin-black.svg";

import './ServicesModal.scss';
import { useTranslation } from "react-i18next"
// import './Adaptations.scss';

const services = (t) => [
  {
    name: t('electricity'),
    icon: <ElectricityIcon />,
  },
  {
    name: t('water'),
    icon: <WaterIcon />,
  },
  {
    name: t('roads'),
    icon: <RoadsIcon />,
  },
  {
    name: t('stormwaterDrainage'),
    icon: <StormwaterIcon />,
  }
];

export default function ServicesModal({ closeFunc }) {
  const { t } = useTranslation();
  return (
    <div className="services">
      <div className="services-close" onClick={closeFunc}>
        <img src={CrossThinImage} alt="" />
      </div>
      <h1 className="services-title">{t('menu_services')}</h1>
      <div className="services-body">
        {services(t).map((service, index) => <ServiceItem key={index} {...service} />)}
      </div>
    </div>
  )
}

function ServiceItem({ name, icon }) {
  return (
    <div className="service-item">
      <div className="service-item-icon">{icon}</div>
      <span className="service-item-name">{name}</span>
    </div>
  )
}